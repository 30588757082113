import * as Sentry from '@sentry/browser';
import { Observable, ObservableInput, ObservedValueOf, OperatorFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';

export const catchAndReportError = <T, O extends ObservableInput<any>>(
  selector: (err: any, caught: Observable<T>) => O,
): OperatorFunction<T, T | ObservedValueOf<O>> => {
  return catchError((err, caught: Observable<T>) => {
    Sentry.captureException(err);
    return selector(err, caught);
  });
};

export const reportSentryError = (error: Error) => {
  Sentry.captureException(error);
  return;
};
