import React from 'react';
import Styles from './TagRadio.module.sass';

interface TagRadioItemProps {
  onSelect?: () => void;
  selected?: boolean;
  value: string;
}

const TagRadioItem: React.FC<TagRadioItemProps> = props => {
  const { children, selected, onSelect } = props;
  return (
    <div
      onClick={onSelect}
      aria-selected={selected ? 'true' : 'false'}
      className={Styles.tagRadioItem}>
      {children}
    </div>
  );
};

interface TagRadioProps {
  onSelect?: (value: string) => void;
}

interface TagRadioState {
  selectedIndex?: number;
}

export class TagRadio<T> extends React.Component<TagRadioProps, TagRadioState> {
  static Item = TagRadioItem;

  state: TagRadioState = {
    selectedIndex: undefined,
  };

  render() {
    const { children, onSelect } = this.props;
    const { selectedIndex } = this.state;
    return (
      <div className={Styles.tagRadio}>
        {React.Children.map<any, any>(children, (child, index) => {
          return React.cloneElement(child as any, {
            onSelect: () => {
              onSelect && onSelect(child.props.value);
              this.setState(existing => {
                if (existing.selectedIndex === index) {
                  return {};
                }
                return { selectedIndex: index };
              });
            },
            selected: selectedIndex === index,
          });
        })}
      </div>
    );
  }
}
