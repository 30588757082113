import { CheckOutlined, CloseOutlined, MessageOutlined, SolutionOutlined } from '@ant-design/icons';
import { Modal, Row, Col, Avatar, List, Button } from 'antd';
import React from 'react';
import { Hits } from './VisaTranscript';
import { IntercomAPI } from '../../components';
import { Colors } from '../../theme';
import { SearchParams } from './VisaTranscript';
import Styles from './VisaTranscriptModal.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie, faUserGraduate } from '@fortawesome/pro-light-svg-icons';
import { matchColumns } from './VisaTranscriptsList';

interface VisaTranscriptProps {
  hit: Hits | undefined;
  showTranscript: boolean;
  searchParams: SearchParams;
  setShowTranscript: (showTranscript: boolean) => void;
}

export const VisaTranscriptModal: React.FC<VisaTranscriptProps> = (props) => {
  const { hit, showTranscript, setShowTranscript, searchParams } = props;
  if (hit) {
    const { matchedAttribs, transcript } = hit;
    return (
      <Modal
        key={'hit'}
        centered
        className={Styles.modal}
        onCancel={() => {
          setShowTranscript(false);
        }}
        title={
          <div className={Styles.title}>
            <SolutionOutlined />
            Visa Interview #{hit.index + 1}
            {matchedAttribs.visaAccepted ? (
              <div className={Styles.visaAccepted}>
                <CheckOutlined /> Visa Approved
              </div>
            ) : (
              <div className={Styles.visaRejected}>
                <CloseOutlined /> Visa Rejected
              </div>
            )}
          </div>
        }
        footer={null}
        closable
        destroyOnClose
        maskClosable
        visible={showTranscript}>
        <Row gutter={[12, 12]} justify="space-between" align="middle" className={Styles.transInfo}>
          {matchColumns(matchedAttribs, searchParams)}
        </Row>
        <Row>
          <List
            className={Styles.hitlist}
            size="large"
            header={null}
            footer={null}
            bordered
            dataSource={transcript}
            renderItem={(item: any, index) => {
              return (
                <List.Item>
                  <Row className={Styles.hitRow} key={index}>
                    <Col span={1}>
                      {item.speaker.toString().toUpperCase() !== 'VO' ? (
                        <Avatar
                          style={{ backgroundColor: Colors.Primary500 }}
                          icon={<FontAwesomeIcon icon={faUserGraduate} />}
                        />
                      ) : (
                        <Avatar
                          style={{ backgroundColor: Colors.Red400 }}
                          icon={<FontAwesomeIcon icon={faUserTie} />}
                        />
                      )}
                    </Col>
                    <Col className={Styles.hitText} span={23}>
                      {item.txt.charAt(0).toUpperCase() + item.txt.slice(1)}
                    </Col>
                  </Row>
                </List.Item>
              );
            }}
          />
        </Row>
        <Row align="middle" justify="end" className={Styles.footer}>
          Have questions about this interview?
          <Button
            type="primary"
            icon={<MessageOutlined />}
            onClick={() => {
              IntercomAPI.showWithMessagePreFilled(
                'Hey! I have questions about visa transcript.',
                false,
              );
            }}>
            {' '}
            Chat with Immigration Expert
          </Button>
        </Row>
      </Modal>
    );
  } else return null;
};
