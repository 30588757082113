export enum Colors {
  // Primary = '#8089FF',
  Primary = '#1890FF',
  White = '#FFFFFF',
  Black = '#000000',
  GreyDarkest = '#333333',
  GreyDarker = '#555555',
  GreyDark = '#888888',
  Grey = '#AAAAAA',
  GreyLight = '#CCCCCC',
  GreyLighter = '#E4E4E4',
  GreyLightest = '#F5F5F5',
  PaleGrey = '#FBFAFC',

  Primary1000 = '#002159',
  Primary900 = '#01337D',
  Primary800 = '#03449E',
  Primary700 = '#0552B5',
  Primary600 = '#0967D2',
  Primary500 = '#2186EB',
  Primary400 = '#47A3F3',
  Primary300 = '#7CC4FA',
  Primary200 = '#BAE3FF',
  Primary100 = '#E6F6FF',

  Neutral1000 = '#1F2933',
  Neutral900 = '#323F4B',
  Neutral800 = '#3E4C59',
  Neutral700 = '#52606D',
  Neutral600 = '#616E7C',
  Neutral500 = '#7B8794',
  Neutral400 = '#9AA5B1',
  Neutral300 = '#CBD2D9',
  Neutral200 = '#E4E7EB',
  Neutral100 = '#F5F7FA',

  Red1000 = '#610316',
  Red900 = '#8A041A',
  Red800 = '#AB091E',
  Red700 = '#CF1124',
  Red600 = '#E12D39',
  Red500 = '#EF4E4E',
  Red400 = '#F86A6A',
  Red300 = '#FF9B9B',
  Red200 = '#FFBDBD',
  Red100 = '#FFE3E3',

  Yellow1000 = '#8D2B0B',
  Yellow900 = '#B44D12',
  Yellow800 = '#CB6E17',
  Yellow700 = '#DE911D',
  Yellow600 = '#F0B429',
  Yellow500 = '#F7C948',
  Yellow400 = '#FADB5F',
  Yellow300 = '#FCE588',
  Yellow200 = '#FFF3C4',
  Yellow100 = '#FFFBEA',

  Teal1000 = '#004440',
  Teal900 = '#016457',
  Teal800 = '#048271',
  Teal700 = '#079A82',
  Teal600 = '#17B897',
  Teal500 = '#2DCCA7',
  Teal400 = '#5FE3C0',
  Teal300 = '#8EEDD1',
  Teal200 = '#C6F7E9',
  Teal100 = '#F0FCF9',
  GreenishTeal = '#2fd06e',
  Green = '#28C365',

  Orange1000 = '#841003',
  Orange900 = '#AD1D07',
  Orange800 = '#C52707',
  Orange700 = '#DE3A11',
  Orange600 = '#F35627',
  Orange500 = '#F9703E',
  Orange400 = '#FF9466',
  Orange300 = '#FFB088',
  Orange200 = '#FFD0B5',
  Orange100 = '#FFE8D9',
}

/**
 * Apply colors with transparency
 * @param color Choose from Colors
 * @param transparency Should be between 0 to 1
 */
export const withTransparency = (color: Colors, transparency: number) => {
  if (transparency < 0 || transparency > 1) {
    throw new Error(`Invalid transparency ${transparency} applied. Should be between 0 and 1!`);
  }
  return `${color}${(transparency * 255).toString(16)}`;
};
