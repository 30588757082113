import React from 'react';

import { Accordion } from '..';
import { PredefinedStep, StepStatus } from '../../repos';
import Styles from './CoPilot.module.sass';

export const renderCoPilot = (step: PredefinedStep) => {
  return (
    <Accordion.Item status={StepStatus.Incomplete} statusText={''} key={step.id} icon={'message'} title={step.title}>
      <iframe frameBorder={'0'} className={Styles.chatIframe} src="https://mind.gradly.us" title="Mind"></iframe>
    </Accordion.Item>
  );
};
