import { PredefinedStep } from '../../repos';

export const getIndexFromRoute = (pathname: string | undefined, steps: PredefinedStep[]) => {
  if (!pathname) {
    return -1;
  }
  const currentRouteName = pathname.split('/')[2];

  const index = steps.findIndex(
    (val: PredefinedStep) => val.route && val.route === currentRouteName,
  );

  return index;
};
