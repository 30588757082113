import React from 'react';
import { Accordion, AlertType } from '../../components';
import { PredefinedStep, StepStatus, UserProfile } from '../../repos';
import { LoanForm } from './LoanForm';

export enum LoanSubStatus {
  Academics = 0,
  Identity = 1,
  Financial = 2,
}

export const ApplyToBanks = (
  currentStep: PredefinedStep,
  currentSubStatus: LoanSubStatus,
  currentUser: UserProfile,
) => {
  return (
    <Accordion.Item
      lockOnComplete
      alert={
        currentStep.status !== StepStatus.Processing
          ? {
              message: 'Please upload your documents below to complete your common loan application.',
              type: AlertType.Info,
            }
          : undefined
      }
      showAI
      key={currentStep.id}
      icon={'bank'}
      status={currentStep.status || StepStatus.Incomplete}
      title={currentStep.title}
      step={currentStep}>
      <LoanForm subStatus={currentSubStatus || 0} uid={currentUser?.id} />
    </Accordion.Item>
  );
};
