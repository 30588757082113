import { RightOutlined } from '@ant-design/icons';
import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import React, { useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Accordion,
  WelcomeUserWithProgressHeading,
  AttendWebinarAccordionItem,
} from '../../components';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { RootState } from '../../redux';
import { CustomStep, RsvpStep, StagePredefinedSteps, UserProfile } from '../../repos';
import { renderCustomStep } from '../Home';
import { getIndexFromRoute, useStepCalculation } from '../utils';
import { ApplyToBanks } from './ApplyBanksStep';
import { FinalizeOfferStep } from './FinalizeOffer';
import Styles from './LoanStepContainer.module.sass';
import { MeetOfficialStep } from './MeetOfficial';
import { WelcomeScreen } from './WelcomeScreen';

interface LoanStepContainerProps extends RouteComponentProps {
  customSteps: CustomStep[];
  currentUser?: UserProfile | null;
  allPredefinedSteps: StagePredefinedSteps;
}
export enum StepID {
  ApplyToBanks = 'ApplyToBanks',
  AttendWebinar = 'AttendWebinar',
  FinalizeOffer = 'FinalizeOffer',
  MeetOfficial = 'MeetOfficial',
}

export const loanLockModalData = {
  description:
    'This step is locked. You need to complete "Apply to banks form" before you can proceed with this step.',
  ctaText: (
    <>
      Go to "Apply to banks"
      <RightOutlined />
    </>
  ),
  ctaAction: () => {
    navigate(`/${UserJourneyStageId.Loans}/apply-to-banks`);
  },
};

const LoanStepContainer: React.FC<LoanStepContainerProps> = (props) => {
  const { customSteps, currentUser, allPredefinedSteps } = props;
  const AccordionInstance = useRef<Accordion>(null);
  const { pathname } = useLocation();
  const [showWelcomeScreen, setShowWelcomeScreen] = useState<boolean>(true);
  const applyBanksSubStatus = useSelector((state: RootState) => state.Content.stepsStatus)[
    UserJourneyStageId.Loans
  ]?.[StepID.ApplyToBanks]?.subStatus;

  const {
    steps,
    stepsLeft,
    calculatedStepsCompleted,
    setCalculatedStepsCompleted,
    predefinedStepsStatus,
  } = useStepCalculation(
    Object.values(StepID),
    allPredefinedSteps,
    customSteps,
    UserJourneyStageId.Loans,
    showWelcomeScreen,
    currentUser?.id,
  );

  if (currentUser)
    return !predefinedStepsStatus && showWelcomeScreen ? (
      <WelcomeScreen setShowWelcomeScreen={setShowWelcomeScreen} />
    ) : (
      <div className={`${Styles.container} animate__animated animate__fadeIn`}>
        <div className={Styles.childContainer}>
          <WelcomeUserWithProgressHeading
            stageID={UserJourneyStageId.Loans}
            title="Get an Education Loan"
            currentUser={currentUser}
            stepCount={{ total: steps.length, left: stepsLeft || 0 }}
          />
          <Accordion
            ref={AccordionInstance}
            onExpanded={(index) => {
              if (getIndexFromRoute(pathname, steps) === index) {
                return navigate!(`/${UserJourneyStageId.Loans}`);
              }
              const route = steps[index].route;
              navigate!(`/${UserJourneyStageId.Loans}/${route}`);
            }}
            defaultExpanded={getIndexFromRoute(pathname, steps)}
            className={Styles.accordion}>
            {steps.map((step) => {
              switch (step.id) {
                case StepID.ApplyToBanks:
                  return ApplyToBanks(step, applyBanksSubStatus, currentUser);
                case StepID.AttendWebinar:
                  return (
                    <AttendWebinarAccordionItem
                      key={step.route}
                      onCompleted={(completed) => {
                        if (calculatedStepsCompleted.includes(step.title) && !completed) {
                          setCalculatedStepsCompleted([
                            ...calculatedStepsCompleted.filter((title) => title !== step.title),
                          ]);
                          return;
                        }
                        if (!calculatedStepsCompleted.includes(step.title) && completed) {
                          setCalculatedStepsCompleted([...calculatedStepsCompleted, step.title]);
                          return;
                        }
                      }}
                      title={step.title}
                      rsvpStep={step as RsvpStep}
                    />
                  );
                case StepID.FinalizeOffer:
                  return FinalizeOfferStep(step, currentUser!.id);
                case StepID.MeetOfficial:
                  return MeetOfficialStep(
                    step,
                    currentUser!.id,
                    predefinedStepsStatus?.[StepID.MeetOfficial]?.bid,
                  );
                default: {
                  return renderCustomStep(
                    step,
                    calculatedStepsCompleted,
                    setCalculatedStepsCompleted,
                    currentUser!.shownBadges![UserJourneyStageId.Loans] || [],
                  );
                }
              }
            })}
          </Accordion>
        </div>
      </div>
    );
  else return null;
};

const mapStateToProps = (state: RootState) => {
  const { steps } = state.CustomSteps;
  const { currentUser } = state.Auth;
  const allPredefinedSteps = state.Content.predefinedSteps[UserJourneyStageId.Loans];
  return {
    currentUser,
    customSteps: steps[UserJourneyStageId.Loans] || [],
    allPredefinedSteps,
  };
};

export default connect(mapStateToProps, {})(LoanStepContainer);
