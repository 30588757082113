import { RcFile } from 'antd/lib/upload';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/storage';
import moment, { Moment } from 'moment';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { University } from '..';
import { applyDeleteMarkersForUndefined, firestoreQueryListener, FirestoreReference } from '../../utils';
import { convertFirestoreTimestampFieldsToMoment, convertMomentObjectsToDate, firestoreDocumentListener } from '../../utils/FirebaseHelpers';

const { firestore, storage } = firebase;
export interface SopReviewComment {
  startIndex: number;
  endIndex: number;
  textInHtml: string;
  markedForHelp?: boolean;
}

export interface SopTagAnnotation {
  tagId: string;
  startIndex: number;
  endIndex: number;
  commentInHtml?: string;
  markedForHelp?: boolean;
}

export interface UserSopVersion {
  id: string; // firestore document id
  createdAt: Moment;
  userId: string;
  studentName: string;
  url?: string;
  text?: string;
  feedbackSubmitted: boolean;
  feedbackSubmittedAt: Moment;
  submissionDeadline: Moment;
  greDate?: Moment;
  toeflDate?: Moment;
  takingPaidHelp: boolean;
  reviewRating?: number;
  reviewSeen?: boolean;
  moreHelpRequested?: boolean;
  moreHelpRequestedAt?: Moment;
  tagAnnotations?: SopTagAnnotation[];
  comments?: SopReviewComment[];
  resume: {
    url?: string;
    storagePath?: string;
  };
  universities: University[];
  sopId: string; // for versioning
}

export const listenToSopVersion = (userId: string) => {
  return firestoreQueryListener<{ sopVersion: UserSopVersion; universityIds?: string[] }>(FirestoreReference.UserSopVersion().where('userID', '==', userId), (snapshot) => {
    const data = snapshot.data();
    const userSopVersion = {
      ...convertFirestoreTimestampFieldsToMoment(data),
      id: snapshot.id,
    } as UserSopVersion;
    const universityIds = snapshot.get('universityIds');
    return {
      sopVersion: userSopVersion,
      universityIds: universityIds && Object.values(universityIds),
    };
  }).pipe(
    switchMap((versions) => {
      if (versions.length === 0) {
        return of(undefined);
      }
      const version = versions[0];
      const universityObservables: Observable<University[]> =
        version.universityIds && version.universityIds.length !== 0
          ? combineLatest(version.universityIds.map((universityId) => firestoreDocumentListener<University>(FirestoreReference.Universities().doc(universityId))))
          : of([]);
      return universityObservables.pipe(
        map((universities) => {
          return { ...version.sopVersion, universities } as UserSopVersion;
        }),
      );
    }),
  );
};

export const uploadResume = (resume: RcFile, userId: string): Observable<{ url: string; storagePath: string }> => {
  const extension = resume.name.split('.')[resume.name.split('.').length - 1];
  const resumeStorageReference = storage().ref().child('resumes').child(userId).child(`${moment().format()}.${extension}`);
  return from(resumeStorageReference.put(resume).then((snapshot) => snapshot.ref.getDownloadURL())).pipe(map((url) => ({ url, storagePath: resumeStorageReference.fullPath })));
};

export const createSopVersion = (sopVersion: Partial<UserSopVersion>) => {
  const id = FirestoreReference.UserSopVersion().doc().id;
  const batch = firestore().batch();
  const data = {
    ...applyDeleteMarkersForUndefined(
      convertMomentObjectsToDate({
        ...sopVersion,
        universityIds: sopVersion.universities && sopVersion.universities.map((uni) => uni.id),
      }),
    ),
    createdAt: firestore.FieldValue.serverTimestamp(),
    sopId: id,
  };
  if (sopVersion.universities) {
    const unverifiedUniversities = sopVersion.universities.filter((university) => !university.verified);
    for (const university of unverifiedUniversities) {
      batch.set(FirestoreReference.Universities().doc(university.id), university, { merge: true });
    }
  }
  delete data.universities;
  batch.set(FirestoreReference.UserSopVersion().doc(id), data, { merge: true });
  return from(batch.commit());
};

export const updateSopReviewRating = (sopId: string, rating: number) => {
  return from(FirestoreReference.UserSopVersion().doc(sopId).set({ reviewRating: rating }, { merge: true }));
};

export const markReviewAsSeen = (sopVersionId: string) => {
  return from(FirestoreReference.UserSopVersion().doc(sopVersionId).set({ reviewSeen: true }, { merge: true }));
};

export const markForHelp = (sopVersionId: string, comments: SopReviewComment[], tagAnnotations: SopTagAnnotation[]) => {
  const data = {
    comments,
    tagAnnotations,
  };
  return from(FirestoreReference.UserSopVersion().doc(sopVersionId).set(data, { merge: true }));
};

export const submitForHelp = (sopVersionId: string) => {
  const data = {
    moreHelpRequested: true,
    moreHelpRequestedAt: firestore.FieldValue.serverTimestamp(),
  };
  return from(FirestoreReference.UserSopVersion().doc(sopVersionId).set(data, { merge: true }));
};
