import { combineReducers } from 'redux';
import { AuthReducer, AuthStateType } from './AuthReducer';
import { CommonReducer, CommonStateType } from './CommonReducer';
import { ContentReducer, ContentStateType } from './ContentReducer';
import { CustomStepsReducer, CustomStepStateType } from './CustomStepsReducer';
import { InsuranceReducer, InsuranceStateType } from './InsuranceReducer';
import { ProfileReducer, ProfileStateType } from './ProfileReducer';
import { SopReviewReducer, SopReviewStateType } from './SopReviewReducer';

export const rootReducer = combineReducers({
  Auth: AuthReducer,
  Common: CommonReducer,
  Content: ContentReducer,
  Profile: ProfileReducer,
  SopReview: SopReviewReducer,
  Insurance: InsuranceReducer,
  CustomSteps: CustomStepsReducer,
});

export interface RootState {
  Auth: AuthStateType;
  Common: CommonStateType;
  Content: ContentStateType;
  Profile: ProfileStateType;
  SopReview: SopReviewStateType;
  Insurance: InsuranceStateType;
  CustomSteps: CustomStepStateType;
}
