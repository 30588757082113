import { CloseOutlined, MessageOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Modal } from 'antd';
import React from 'react';
import { Article, Content, ContentType, Webinar } from '../../repos';
import { IntercomAPI } from '../Intercom';
import { ArticlePopOverContent } from './ArticlePopOverContent';
import { BigMarkerWebinarPopOverContent } from './BigMarkerWebinarPopOverContent';
import './styles.scss';

interface Props {
  feedItem?: Content;
  visible?: boolean;
  data?: { url: string; chatURL: string };
  onCancel(): void;
}

const renderPopOverContent = (feedItem: Content, data: { url: string; chatURL: string }) => {
  switch (feedItem.type) {
    case ContentType.Webinar: {
      if (!data) {
        return null;
      }
      const webinar = feedItem as Webinar;
      const mode = data.url ? 'live' : 'recording';
      return (
        <BigMarkerWebinarPopOverContent
          mode={mode}
          title={webinar.title}
          recordingId={webinar.slots[0].recordingId}
          url={data.url}
          chatURL={data.chatURL}
        />
      );
    }
    case ContentType.Article: {
      const article = feedItem as Article;
      return <ArticlePopOverContent article={article} />;
    }
    default:
      return null;
  }
};

const renderPopOverFooter = (feedItem: Content, data: { url: string }) => {
  return (
    <div className="footer-container">
      <div className="got-questions-container">
        <span className="title">Got questions related to this?</span>
        <div className="message-us-action-container">
          {feedItem.author ? (
            <Avatar size={36} src={feedItem.author.displayPictureUrl}>
              {feedItem.author.name.substr(0, 1)}
            </Avatar>
          ) : (
            <Avatar size={36} icon={<UserOutlined />} />
          )}
          <Button onClick={() => IntercomAPI.show()} type="primary" icon={<MessageOutlined />}>
            Message Us
          </Button>
        </div>
      </div>
      {/* <div className="actions-container">
        <Button
          onClick={() => {
            document.execCommand(`copy ${data.url}`);
            message.success('The link to the webinar has been copied!', 5000);
          }}
          icon="share-alt">
          Share Link
        </Button>
      </div> */}
    </div>
  );
};

const closeIcon = <Button className="close-button" shape="circle" icon={<CloseOutlined />} />;

// TODO: Vamsee Chamakura 30/10/19 - Figure out loading
export const ContentPopOver: React.FC<Props> = (props) => {
  const { feedItem, data, onCancel, visible } = props;
  if (!data) {
    return null;
  }
  return (
    <Modal
      width="90vw"
      className="feed-detailed-popup"
      footer={feedItem && renderPopOverFooter(feedItem, data)}
      closeIcon={closeIcon}
      visible={visible}
      onCancel={() => onCancel()}>
      <div style={{ height: '80vh' }}>{feedItem && renderPopOverContent(feedItem, data)}</div>
    </Modal>
  );
};
