import React, { useState } from 'react';

import {
  AlertOutlined,
  AppstoreOutlined,
  BlockOutlined,
  BookOutlined,
  BranchesOutlined,
  CalendarOutlined,
  RightOutlined,
  SelectOutlined,
} from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import Styles from './WelcomeScreen.module.sass';
import { IntercomAPI } from '../../components';
import { stageIcons } from '../../repos';
import {
  boxCalculatorVisa,
  calendarVisa,
  circleProfileVisa,
  paperSearchVisa,
  sendVisa,
  videoRecorderVisa,
} from '../../assets';

interface ContentDataTypes {
  icon: any;
  title: string;
  description: string;
  id: string;
}
export const WelcomeScreen: React.FC<any> = (props) => {
  const { setShowWelcomeScreen } = props;
  const contentData: ContentDataTypes[] = [
    {
      icon: paperSearchVisa,
      title: 'Get Your I-20',
      description:
        'Automatically generate and send a compliant I-20 request form to your university.',
      id: '1',
    },
    {
      icon: boxCalculatorVisa,
      title: 'Complete DS-160',
      description: 'Complete your DS-160 in-app with our interactive guide & 24x7 chat assistance.',
      id: '2',
    },
    {
      icon: sendVisa,
      title: 'Pay SEVIS Fee',
      description:
        'Pay SEVIS fee instantly using Indian payment methods via Gradly’s virtual debit card.',
      id: '3',
    },
    {
      icon: calendarVisa,
      title: 'Book Visa Interview',
      description:
        'Book your visa interview in-app with our interactive guide & be notified automatically of new slots opening up.',
      id: '4',
    },
    {
      icon: videoRecorderVisa,
      title: 'Prepare for Visa Interview',
      description:
        'Get a SWOT analysis of your visa profile, complete a mock interview with immigration experts and receive a personalized list of documents for you to carry to your interview.',
      id: '5',
    },
    {
      icon: circleProfileVisa,
      title: 'Speak to Immigration Attorneys',
      description:
        'Book a session with vetted, top rated immigration attorneys if your visa gets rejected or is subject to administrative processing.',
      id: '6',
    },
  ];

  const getListItem = (item: ContentDataTypes, index: number) => {
    return (
      <Col span={8}>
        <Row align="top" wrap={false}>
          <Col flex="93px">
            <span className={Styles.index}>{index + 1}</span>
          </Col>
          <Col flex="auto" className={Styles.content}>
            <Col span={24} className={Styles.heroIcon}>
              <img src={item.icon} alt={'Icon ' + index}></img>
            </Col>
            <Col span={24}>
              <h1>{item.title}</h1>
              <p>{item.description}</p>
            </Col>
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <>
      <div className={Styles.welcomeScreen + ' animate__animated animate__fadeIn'}>
        <div className={Styles.welcomeScreenContainer}>
          <Row justify="space-between" align="top" className={Styles.welcomeBanner}>
            <Col span={18} className={Styles.header}>
              <img src={stageIcons['visas']} alt="visa icon"></img>
              <h2>Get your US visa in 6 simple steps.</h2>
            </Col>

            <Col span={6}>
              <Row justify="end">
                <Button
                  size="large"
                  type={'primary'}
                  onClick={() => {
                    setShowWelcomeScreen(false);
                  }}>
                  Get Started <RightOutlined />
                </Button>
              </Row>
            </Col>
          </Row>
          <Row justify="center" align="top" style={{ width: '100%' }}>
            <Row gutter={[0, 112]} className={Styles.contentList}>
              {contentData.map((item, index) => getListItem(item, index))}
            </Row>
          </Row>
        </div>
      </div>
    </>
  );
};
