import { BankOutlined, RightOutlined } from '@ant-design/icons';
import { Form, Button, Row, message } from 'antd';
import React from 'react';
import { UniversityInput } from '../UniversityInput';
import Styles from './NaturalForm.module.sass';

interface I20NaturalFormProps {
  onSubmit: (values: any) => void;
}

export const I20NaturalForm: React.FC<I20NaturalFormProps> = (props) => {
  return (
    <div className={Styles.insuranceContainer}>
      <Form
        onFinish={(data) => {
          if (!data.university) {
            message.error('Please select your university');
            return;
          }
          props.onSubmit(data.university[0]);
        }}
        layout="inline">
        <div className={Styles.formText}>
          I am attending <BankOutlined />{' '}
          <Form.Item required noStyle name="university">
            <UniversityInput
              multiple={false}
              showArrow={false}
              className={Styles.uniSelect}
              optionsClassName={Styles.naturalOptions}></UniversityInput>
          </Form.Item>
          .
        </div>
        <Form.Item style={{ margin: 0, marginTop: 12 }}>
          <Row justify="center">
            <Button type="primary" htmlType="submit">
              Submit <RightOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};
