import React, { FC, useEffect, useState } from 'react';

import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Skeleton,
  Steps,
  Modal,
  message,
  Tooltip,
} from 'antd';
import {
  countries,
  InsuranceRequestGBGType,
  InsuranceRequestPartner,
  InsuranceRequestStatus,
  markStepsStatus,
  PlanType,
  StepStatus,
  University,
  UserRepo,
} from '../../repos';
import {
  CheckCircleFilled,
  CheckCircleTwoTone,
  DollarOutlined,
  EditOutlined,
  FileTextFilled,
  IdcardOutlined,
  RightOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import Styles from './PurchaseForms.module.sass';
import { PhoneInput } from '../../components/PhoneInput';
import { isValidNumber } from 'libphonenumber-js';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  setAvailablePlans,
  setFormData,
  setInsuranceData,
  setPastEndDate,
  setSelectedPlan,
} from '../../redux';
import { UniversityInput } from '../../components';
import { Environment, FirestoreReference, reportSentryError } from '../../utils';
import moment from 'moment';
import dayjs from 'dayjs';
import { calculateInsurancePremium, getPlans } from './helpers';
import { GradlyLoading } from '../../App';
import { UserJourneyStageId } from '../../config';
import { ComparisonData, StepID } from './HealthInsuranceStepContainer';
import firebase from 'firebase/compat';
import { SignUpContainer } from '../SignUp';
import { SimpleModal } from '../../components/CommonModals/SimpleModal';
import { CreateGroupInput, JoinGroupInput } from './CreateGroupInput';
import { Colors } from '../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
const { Step } = Steps;
const { Option } = Select;

interface Props {
  comparisonData?: ComparisonData;
  status: StepStatus | undefined;
}

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 20 },
};

const all_countries: Record<string, string> = {};
countries.forEach((country) => {
  all_countries[country.code] = country.name;
});

const secQuestions = [
  { label: 'In which city did you complete your graduation?', value: 'cityGraduated' },
  { label: 'Who is your favorite sports person?', value: 'favoriteSportsPerson' },
  { label: "What is your mother's maiden last name?", value: 'mothersMaidenName' },
  { label: 'What is the name of your favorite pet?', value: 'favoritePet' },
  { label: "What is your father's middle name?", value: 'fathersMiddleName' },
  { label: "What is your favorite teacher's name?", value: 'favoriteTeachersName' },
  { label: 'What is your high school mascot?', value: 'highSchoolMascot' },
  { label: "What is your mother's middle name?", value: 'mothersMiddleName' },
  { label: 'Which city were you born in?', value: 'cityBorn' },
];

export const GBGPurchaseForm: FC<Props> = (props) => {
  const { university, dob, email } = props.comparisonData || {};
  const plans = useSelector((state: RootState) => state.Insurance.availablePlans) || [];
  const { insuranceData, selectedPlan, formData, pastEndDate, visaType } = useSelector(
    (state: RootState) => state.Insurance,
  );
  const [currentStep, setCurrentStep] = useState(props.status === StepStatus.Processing ? 2 : 0);
  const currentUser = useSelector((state: RootState) => state.Auth.currentUser);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [finalPremium, setFinalPremium] = useState('0');
  const [code, setCode] = useState<string>('');
  const [prefilledCode, setPrefilledCode] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState<University | null>(university || null);
  const [coverageOptions, setCoverageOptions] = useState<any>([]);
  const [agreement, setAgreement] = useState(false);
  const [paymentString, setPaymentString] = useState('Waiting for payment...');

  // Variables End

  //Hooks

  useEffect(() => {
    if (insuranceData) {
      calculateInsurancePremium(
        insuranceData.dob,
        insuranceData.universityID,
        insuranceData.insurancePlanID,
        moment(insuranceData.coverageStartDate, 'YYYY-MM-DD'),
        moment(insuranceData.coverageEndDate, 'YYYY-MM-DD'),
      ).then((data) => {
        if (data) setFinalPremium(data.toFixed(2));
      });
    }
  }, [insuranceData]);

  useEffect(() => {
    if (currentStep > 0 && currentUser)
      FirestoreReference.GroupCodes()
        .where('memberEmails', 'array-contains', currentUser.primaryEmail)
        .get()
        .then((snapshot) => {
          if (snapshot.docs && snapshot.docs.length > 0) {
            setPrefilledCode(snapshot.docs[0].data().code);
          }
        })
        .catch((error) => {
          console.debug('gc', error);
        });
  }, [currentUser, currentStep]);

  useEffect(() => {
    form.setFieldsValue(
      formData
        ? formData.account
        : {
            insurancePlanID: selectedPlan?.retailName,
            email: email,
          },
    );
    if (currentStep === 1 && pastEndDate) {
      form.setFieldsValue({ coverageStartDate: moment(pastEndDate.add(1, 'day').toDate()) });
    }
  }, [currentStep, pastEndDate]);

  useEffect(() => {
    if (!form.getFieldValue('university')) return;
    const uni = form.getFieldValue('university')[0] as University;
    setSelectedUniversity(uni);
    if (uni && uni.id === 'pitt') {
      dispatch(setPastEndDate(dayjs('2022/08/31')));
      return;
    } else {
      dispatch(setPastEndDate(undefined));
    }
    if (uni && uni.plans) {
      getPlans(uni, setPlans);
    } else {
      setPlans([]);
    }
    if (uni) {
      const options = [];
      if (uni.annualEndDate && uni.annualStartDate) {
        options.push({
          label: getTermLabel('Annual', `${uni.annualStartDate} - ${uni.annualEndDate}`),
          value: `annual:${uni.annualStartDate} - ${uni.annualEndDate}`,
        });
      }
      if (uni.graduatingEndDate && uni.graduatingStartDate) {
        options.push({
          label: getTermLabel('Graduating', `${uni.graduatingStartDate} - ${uni.graduatingEndDate}`),
          value: `graduating:${uni.graduatingStartDate} - ${uni.graduatingEndDate}`,
        });
      }
      if (uni.fallStartDate && uni.fallEndDate) {
        options.push({
          label: getTermLabel('Fall Graduating', `${uni.fallStartDate} - ${uni.fallEndDate}`),
          value: `fallgraduating:${uni.fallStartDate} - ${uni.fallEndDate}`,
        });
      }
      if (uni.springStartDate && uni.springEndDate) {
        options.push({
          label: getTermLabel('Spring Graduating', `${uni.springStartDate} - ${uni.springEndDate}`),
          value: `spring:${uni.springStartDate} - ${uni.springEndDate}`,
        });
      }
      if (uni.winterStartDate && uni.winterEndDate) {
        options.push({
          label: getTermLabel('Winter', `${uni.winterStartDate} - ${uni.winterEndDate}`),
          value: `winter:${uni.winterStartDate} - ${uni.winterEndDate}`,
        });
      }
      if (uni.summerStartDate && uni.summerEndDate) {
        options.push({
          label: getTermLabel('Summer Graduating', `${uni.summerStartDate} - ${uni.summerEndDate}`),
          value: `summer:${uni.summerStartDate} - ${uni.summerEndDate}`,
        });
      }
      setCoverageOptions(options);
    }
  }, [form.getFieldValue('university')]);

  // Hooks End

  // Methods

  const checkOutStripe = (docID: string, planName: string) => {
    if (!currentUser || !docID) return;
    setPaymentString('Redirecting to deposit payment...');
    markStepsStatus(
      currentUser.id,
      [UserJourneyStageId.HealthInsurance, StepID.PurchaseInsurance],
      StepStatus.Processing,
    );
    UserRepo.getIdToken().subscribe(async (token) => {
      fetch(`${Environment.api.baseUrl}/webhooks/stripe/stripe-checkout`, {
        method: 'POST',
        body: JSON.stringify({
          planName,
          email: currentUser?.primaryEmail,
          name: currentUser?.name,
          docID,
          partner: InsuranceRequestPartner.GBG,
          redirectUri: window.location.href,
          stripeCustomerID: currentUser?.stripeCustomerID,
        }),
        headers: {
          'content-type': 'application/json',
          authorization: token,
        },
      })
        .then(async (response) => {
          const responseURL = await response.json();
          if (responseURL.url) {
            window.location.href = responseURL.url;
          }
        })
        .catch((err) => reportSentryError(err));
    });
  };

  const createEnrollment = (formData: any, commit?: boolean) => {
    if (!currentUser || !formData) return;
    setLoading(true);
    const groupCode = formData.account.partOfGroup
      ? formData.account.createGroup === 'join'
        ? formData.account.gCode
        : code
      : undefined;
    const university = formData.personal1?.university[0] as University;
    if (!formData.account.partOfGroup) {
      _createEnrollment(formData, undefined, commit);
      setLoading(false);
      setCurrentStep(2);
      return;
    }
    UserRepo.getIdToken().subscribe(async (token) => {
      fetch(`${Environment.api.baseUrl}/webhooks/groupPlan/${formData.account.gCode ? 'applyCode' : 'createCode'}`, {
        method: 'POST',
        body: JSON.stringify({
          code: groupCode.trim(),
          name: currentUser.name,
          universityID: university.id,
          memberEmails: [1, 2, 3, 4].map((i) => {
            return formData.account['rEmail' + i];
          }),
        }),
        headers: {
          'content-type': 'application/json',
          authorization: token,
        },
      })
        .then(async (response) => {
          const data = await response.json();
          if (data.code) {
            setCode(data.code);
            _createEnrollment(formData, data.code, commit);
            setLoading(false);
            setCurrentStep(2);
          }
          if (data.error) {
            setLoading(false);
            form.setFields([
              {
                name: 'gCode',
                errors: [data.error || 'Please input valid group code'],
              },
            ]);
          }
        })
        .catch((error) => {
          message.error('Some error happened with group discount, please contact support!');
          setLoading(false);
        });
    });
  };

  const _createEnrollment = async (formData: any, groupCode?: string, commit?: boolean) => {
    if (!currentUser || !formData) return;
    const university = formData.account?.university[0] as University;
    const plan = plans[parseInt(formData.account.insurancePlanID)] || selectedPlan;
    if (plan.insuranceCompany === 'GBG') {
      const docRef = FirestoreReference.InsuranceGBGRequests().doc();
      const phonePrefix = formData.personal.prefix.value || formData.personal.prefix || 'US:1';
      const phoneNumber = `+${phonePrefix.split(':')[1]}${formData.personal.phone}`;
      let coverageStartDate, coverageEndDate, period;
      if (plan.isACA || university.noWaiver) {
        period = parseInt(formData.account.coveragePeriod);
        coverageStartDate = formData.account.coverageStartDate;
        coverageEndDate = moment(formData.account.coverageStartDate, 'YYYY-MM-DD')
          .add(period, 'M')
          .subtract(1, 'day')
          .format('YYYY-MM-DD');
      } else {
        period = formData.account.coveragePeriod.split(':');
        coverageStartDate = moment(period[1].split('-')[0]).format('YYYY-MM-DD');
        coverageEndDate = moment(period[1].split('-')[1]).format('YYYY-MM-DD');
      }

      const reqData: InsuranceRequestGBGType = {
        purchaseStartDate: moment().format('YYYY-MM-DD'),
        graduationYear: formData.account.graduationYear,
        coveragePeriod: period[0],
        coverageStartDate,
        coverageEndDate,
        passportCountry: formData.personal.citizenship,
        gender: formData.personal.gender,
        maritalStatus: formData.personal.maritalStatus,
        securityQuestion1: formData.account.securityQuestion1,
        securityQuestion2: formData.account.securityQuestion2,
        securityAns1: formData.account.securityAns1,
        securityAns2: formData.account.securityAns2,
        memberId: 'NA',
        status: InsuranceRequestStatus.PesonalisedPlansReceived,
        requestID: docRef.id,
        userID: currentUser.id,
        firstName: formData.personal.firstName,
        middleName: formData.personal.middleName,
        lastName: formData.personal.lastName,
        email: formData.account.email,
        mobileNumber: phoneNumber,
        dob: formData.personal.dob.format('YYYY-MM-DD'),
        nationality: formData.personal.nationality,
        destinationCountryCode: university.gbgDestinationCountry,
        destinationStateCode: university.gbgDestinationState,
        university: university.name,
        universityID: university.id,
        studentId: formData.account.studentID,
        insurancePlan: plan.retailName,
        insurancePlanID: plan.planID,
        homeCountryCode: formData.personal.nationality,
        homeAddress1: formData.personal.homeAddress,
        destinationAddress1: formData.personal.destinationAddress,
        year: parseInt(moment().format('YYYY')),
        partner: InsuranceRequestPartner.GBG,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        numberOfMonths: period,
        groupCode,
      };
      if (Environment.env !== 'production') reqData.testMe = true;
      setPaymentString('Redirecting to deposit payment...');
      calculateInsurancePremium(
        reqData.dob,
        reqData.universityID,
        reqData.insurancePlanID,
        moment(reqData.coverageStartDate, 'YYYY-MM-DD'),
        moment(reqData.coverageEndDate, 'YYYY-MM-DD'),
      ).then(async (data) => {
        if (data) {
          setFinalPremium(data.toFixed(2));
          reqData.finalPremium = parseFloat(data.toFixed(2));
        }
        dispatch(setInsuranceData({ ...reqData, draft: true }));
        if (commit) {
          await docRef.set(reqData);
          markStepsStatus(
            currentUser.id,
            [UserJourneyStageId.HealthInsurance, StepID.PurchaseInsurance],
            StepStatus.Processing,
          );
          checkOutStripe(docRef.id, plan.retailName);
        }
      });
    }
  };

  const setPlans = (plan: PlanType[]) => {
    dispatch(setAvailablePlans(plan));
  };

  const getTermLabel = (label: string, subLabel: string) => (
    <div className={Styles.termLabel}>
      {label} <div className={Styles.termSubLabel}>{subLabel}</div>
    </div>
  );

  const getLabel = (label: string, subLabel: string, required?: boolean) => {
    return (
      <div className={Styles.label}>
        {label} {required && <div className={Styles.requiredMark}>*</div>}
        <p className={Styles.subLabel}>{subLabel}</p>
      </div>
    );
  };

  const ConfirmationActions = () => {
    if (insuranceData) {
      if (insuranceData.depositConfirmed) {
        return (
          <Row align="middle" className={Styles.depositConfirmed}>
            <Row justify="center">
              <FontAwesomeIcon icon={faCircleCheck} /> <h1>Deposit Confirmed</h1>
            </Row>
          </Row>
        );
      }
      if (insuranceData.draft) {
        return (
          <Row align="middle" justify="center" className={Styles.proceedToPay}>
            <Button
              type={'default'}
              className={Styles.editApp}
              onClick={() => {
                setCurrentStep(0);
              }}>
              <EditOutlined /> Edit Application
            </Button>
            <Button
              className={Styles.paynow}
              type={'primary'}
              loading={loading}
              onClick={() => {
                createEnrollment(formData, true);
              }}>
              Continue To Payment <RightOutlined />
            </Button>
          </Row>
        );
      } else
        return (
          <Row align="middle" justify="center" className={Styles.processingDeposit}>
            <Row justify="center">
              {GradlyLoading({ height: '100%' }, true)}
              <Row align="middle" className={Styles.paymentFail}>
                <h1>{paymentString}</h1>
                <Button
                  type="default"
                  className={Styles.editApp}
                  onClick={async () => {
                    await FirestoreReference.InsuranceGBGRequests().doc(insuranceData.requestID).delete();
                    dispatch(setInsuranceData(undefined));
                    if (currentUser) {
                      FirestoreReference.UserStepsStatus(currentUser.id).set(
                        { 'health-insurance': {} },
                        { merge: false },
                      );
                      setCurrentStep(0);
                    }
                  }}>
                  <EditOutlined /> Edit Application
                </Button>
                <Button
                  danger
                  className={Styles.retry}
                  type="primary"
                  onClick={() => {
                    if (insuranceData) checkOutStripe(insuranceData?.requestID, insuranceData.insurancePlan);
                  }}>
                  Retry payment
                </Button>
              </Row>
            </Row>
          </Row>
        );
    } else {
      <Row></Row>;
    }
  };

  const ConfirmationScreen = () => {
    return (
      <Row gutter={[12, 12]} justify="center" className={Styles.payment + ' animate__animated animate__fadeIn'}>
        <Col xs={24} md={12}>
          <div className={Styles.planInfo}>
            <h3>
              <FileTextFilled /> Plan Details
            </h3>
            <span>{insuranceData?.insurancePlan || selectedPlan?.retailName}</span>
            {['0', 'NaN'].includes(finalPremium) ? <Skeleton.Input active={true} /> : <h1>${finalPremium}</h1>}
            <h2>
              {moment(insuranceData?.coverageStartDate, 'YYYY-MM-DD').format('MMM Do, YYYY')} -{' '}
              {moment(insuranceData?.coverageEndDate, 'YYYY-MM-DD').format('MMM Do, YYYY')}
            </h2>
            <ul className={Styles.liReasons}>
              <li key={1}>
                <CheckCircleTwoTone twoToneColor={Colors.Teal600} /> ${selectedPlan?.planFeatures.deductible}
                {selectedPlan?.planFeatures.isDeductibleAnnual ? ' annual deductible' : ' per injury/illness'}
              </li>
              <li key={2}>
                <CheckCircleTwoTone twoToneColor={Colors.Teal600} />{' '}
                {(selectedPlan?.planFeatures.coInsurance || 0) * 100}% Coinsurance
              </li>
              <li key={3}>
                <CheckCircleTwoTone twoToneColor={Colors.Teal600} /> {selectedPlan?.planFeatures.outOfPocketMax} out of
                pocket maximum
              </li>
            </ul>
          </div>
        </Col>
        <Col xs={24} md={12}>
          <div className={Styles.yourInfo}>
            <h3>
              <FontAwesomeIcon icon={faUser} /> Your Details
            </h3>
            <Row className={Styles.userDetails}>
              <Col span={8} className={Styles.detailLabel}>
                First Name:
              </Col>
              <Col span={16}>{formData?.firstName || insuranceData?.firstName}</Col>
              {(formData?.middleName || insuranceData?.middleName) && (
                <>
                  <Col span={8} className={Styles.detailLabel}>
                    Middle Name:
                  </Col>
                  <Col span={16}>{formData?.middleName || insuranceData?.middleName}</Col>
                </>
              )}
              <Col span={8} className={Styles.detailLabel}>
                Last Name:
              </Col>
              <Col span={16}>{formData?.lastName || insuranceData?.lastName}</Col>
              <Col span={8} className={Styles.detailLabel}>
                Email:
              </Col>
              <Col span={16}>{formData?.email || insuranceData?.email}</Col>
              <Col span={8} className={Styles.detailLabel}>
                Date of Birth:
              </Col>
              <Col span={16}>{dayjs(formData?.dob || insuranceData?.dob).format('MMMM D, YYYY')}</Col>
            </Row>
          </div>
        </Col>
        <Col span={24} className={Styles.payStatus}>
          {ConfirmationActions()}
        </Col>
      </Row>
    );
  };

  const children: React.ReactNode[] = plans.map((p, index) => <Option key={index}>{p.retailName}</Option>);

  let steps = [
    {
      title: 'Personal',
      icon: <UserOutlined />,
      form: (
        <Form
          form={form}
          {...formItemLayout}
          initialValues={formData ? formData.personal : {}}
          size={'large'}
          colon={false}
          className={Styles.form}
          labelWrap
          onValuesChange={(_, data) => {
            dispatch(setFormData({ ...formData, personal: { ...data, final: false } }));
          }}
          onFinish={(data) => {
            dispatch(setFormData({ ...formData, personal: { ...data, final: true } }));
            setCurrentStep(1);
          }}>
          <Row>
            <Col sm={24} md={8}>
              <Form.Item
                rules={[{ required: true, message: 'Please enter your first name.' }]}
                label="First Name"
                name="firstName">
                <Input />
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item label="Middle Name" name="middleName">
                <Input />
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item
                rules={[{ required: true, message: 'Please enter your last name.' }]}
                label="Last Name"
                name="lastName">
                <Input />
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item
                rules={[{ required: true, message: 'Please enter your date of birth.' }]}
                label="Date of Birth"
                name="dob">
                <DatePicker
                  defaultPickerValue={dob && dob?.isValid() ? dob : undefined || moment().subtract(17, 'years')}
                  hideDisabledOptions={true}
                  disabledDate={(date) => date.isAfter(moment().subtract(17, 'years'))}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item
                rules={[{ required: true, message: 'Please select your gender.' }]}
                label="Gender"
                name="gender">
                <Select placeholder="Choose">
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                  <Option value={0}>Other</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item
                rules={[{ required: true, message: 'Please select your marital status.' }]}
                label="Marital Status"
                name="maritalStatus">
                <Select placeholder="Choose">
                  <Option value="Single">Single</Option>
                  <Option value="Married">Married</Option>
                  <Option value="Divorced">Divorced</Option>
                  <Option value="Widowed">Widowed</Option>
                  <Option value="Other">Other</Option>
                  <Option value="Unknown">Unknown</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col sm={24} md={12}>
              <Form.Item
                hasFeedback
                label="Active Phone Number"
                name={'phone'}
                rules={[
                  {
                    required: true,
                    message: 'Please enter your phone number.',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!getFieldValue('prefix')) return Promise.reject(new Error('Please select ISD Code.'));
                      const prefix = getFieldValue('prefix').value || getFieldValue('prefix');
                      const phoneNumber = `+${prefix.split(':')[1]}${value}`;
                      if (!value || (phoneNumber && isValidNumber(phoneNumber))) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Please enter a valid phone number.'));
                    },
                  }),
                ]}>
                <PhoneInput />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={24} md={24}>
              <Form.Item required label="US Residential Address" name="destinationAddress">
                <Input />
              </Form.Item>
            </Col>
            <Col sm={24} md={24}>
              <Form.Item required label="Home Country Residential Address" name="homeAddress">
                <Input />
              </Form.Item>
            </Col>
            <Col sm={24} md={9}>
              <Form.Item
                className={Styles.noMark}
                rules={[{ required: true, message: 'Please select your nationality.' }]}
                label={getLabel('Nationality', 'The country in which you were born', true)}
                name="nationality">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Choose"
                  options={Object.entries(all_countries).map((c) => {
                    return { value: c[0], label: c[1] };
                  })}></Select>
              </Form.Item>
            </Col>
            <Col sm={24} md={9}>
              <Form.Item
                className={Styles.noMark}
                rules={[{ required: true, message: 'Please select your citizenship.' }]}
                label={getLabel('Citizenship', 'The country whose passport you actively hold', true)}
                name="citizenship">
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Choose"
                  options={Object.entries(all_countries).map((c) => {
                    return { value: c[0], label: c[1] };
                  })}></Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Next <RightOutlined />
            </Button>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: 'Account',
      icon: <IdcardOutlined />,
      form: (
        <Form
          key={'account'}
          form={form}
          {...formItemLayout}
          size={'large'}
          colon={false}
          className={Styles.form}
          labelWrap
          initialValues={
            formData?.account
              ? formData.account
              : {
                  insurancePlanID: selectedPlan?.retailName,
                  partOfGroup: prefilledCode ? true : undefined,
                  createGroup: prefilledCode ? 'join' : undefined,
                  gCode: prefilledCode,
                }
          }
          onValuesChange={(values, data) => {
            if (values.partOfGroup) {
              if (!currentUser || !selectedUniversity) return;
              UserRepo.getIdToken().subscribe(async (token) => {
                fetch(`${Environment.api.baseUrl}/webhooks/groupPlan/createCode`, {
                  method: 'POST',
                  body: JSON.stringify({
                    name: currentUser.name,
                    universityID: selectedUniversity.id,
                  }),
                  headers: {
                    'content-type': 'application/json',
                    authorization: token,
                  },
                })
                  .then(async (response) => {
                    const data = await response.json();
                    if (data.code) setCode(data.code);
                    if (data.error) {
                      message.error(data.error);
                      form.setFields([
                        {
                          name: 'partOfGroup',
                          errors: [data.error || 'Group plan not available at this time.'],
                        },
                      ]);
                    }
                  })
                  .catch((error) => {
                    message.error('Some error happened with group discount, please contact support!');
                  });
              });
            }
            if (values.insurancePlanID) {
              const plan = plans[parseInt(values.insurancePlanID)];
              if (plan && plan.insuranceCompany !== InsuranceRequestPartner.GBG) {
                Modal.confirm({
                  content: 'Insurance company has changed for this plan. Do you want to continue?',
                  onOk: () => {
                    dispatch(setSelectedPlan(plan));
                    dispatch(setInsuranceData(undefined));
                    dispatch(setFormData({}));
                  },
                });
              } else dispatch(setSelectedPlan(plan));
            }
          }}
          onFinish={(data) => {
            dispatch(
              setFormData({
                ...formData,
                account: { ...data, coverageStartDate: data.coverageStartDate?.format('YYYY-MM-DD'), final: true },
              }),
            );
            setAgreement(true);
          }}>
          <Row>
            <Col sm={24} md={8}>
              <Form.Item
                rules={[{ required: true, message: 'Please enter your first name.' }]}
                label="University"
                name="university">
                <UniversityInput
                  defaultSelectedUniversities={university ? [university] : []}
                  style={{ border: '1px solid #CBD2D9', borderRadius: 4 }}
                  multiple={false}
                />
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item
                rules={[
                  { required: true, message: 'Please enter your first name.' },
                  { type: 'email', message: 'Please enter a valid email address.' },
                ]}
                label="Student University Email ID"
                name="email">
                <Input type="email" />
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item
                rules={[{ required: true, message: 'Please enter your student ID.' }]}
                label="Student ID #"
                name="studentID">
                <Input />
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item
                rules={[{ required: true, message: 'Please select the insurance plan.' }]}
                label="Choice of Insurance Plan"
                name="insurancePlanID">
                <Select disabled={!form.getFieldValue('university')} placeholder="Choose">
                  {children}
                </Select>
              </Form.Item>
            </Col>
            <Col sm={24} md={8}>
              <Form.Item
                rules={[{ required: true, message: 'Please select your year of graduation.' }]}
                label="Year of Graduation"
                name="graduationYear">
                <Select
                  placeholder="Choose"
                  options={[2022, 2023, 2024, 2025, 2026, 2027].map((y) => {
                    return { value: y, label: y };
                  })}></Select>
              </Form.Item>
            </Col>
            {!university?.noWaiver && !university?.customDates ? (
              <Col span={24}>
                <Form.Item
                  className={Styles.noMark}
                  rules={[{ required: true, message: 'Please select your enrolment period.' }]}
                  label={getLabel(
                    'Covered Period',
                    "The periods of coverage below are chosen according to your university's waiver requirements.",
                    true,
                  )}
                  name="coveragePeriod">
                  <Radio.Group
                    disabled={!form.getFieldValue('university')}
                    className={Styles.radioGroup}
                    options={coverageOptions}></Radio.Group>
                </Form.Item>
              </Col>
            ) : (
              <>
                <Col xs={24} md={8}>
                  <Form.Item
                    rules={[{ required: true, message: 'Please select your coverage start date.' }]}
                    label="Coverage Start Date"
                    name="coverageStartDate">
                    <DatePicker
                      disabled={!!pastEndDate}
                      style={{ width: '100%' }}
                      disabledDate={(date) => {
                        if (pastEndDate) {
                          return date.isBefore(moment(pastEndDate.toDate()).add(1, 'days'));
                        }
                        return (
                          date.isBefore(moment()) &&
                          !['01-08-2022', '10-08-2022', '15-08-2022'].includes(date.format('DD-MM-YYYY'))
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item
                    rules={[{ required: true, message: 'Please select your coverage start date.' }]}
                    label="Coverage Period (in months)"
                    name="coveragePeriod">
                    <Select
                      options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((m) => {
                        return { key: m, value: m, label: `${m} months` };
                      })}></Select>
                  </Form.Item>
                </Col>{' '}
              </>
            )}
            <Col xs={24} md={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Please select yes if you are purchasing insurance as a part of a group.',
                  },
                ]}
                dependencies={['createGroup']}
                label="Are you purchasing insurance as a part of a group?"
                name="partOfGroup">
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Form.Item
              style={{ marginTop: -16, display: 'block' }}
              shouldUpdate={(p, c) => p.partOfGroup !== c.partOfGroup}>
              {({ getFieldValue, resetFields }) => {
                return (
                  getFieldValue('partOfGroup') && (
                    <Form.Item
                      style={{ paddingLeft: 6, marginRight: 200 }}
                      rules={[
                        {
                          required: true,
                          message:
                            'Please select Create a new group if you want to create a new group, otherwise select Join an existing group.',
                        },
                      ]}
                      shouldUpdate
                      name="createGroup">
                      <Radio.Group>
                        <Radio value={'new'}>Create a new group</Radio>
                        <Radio value={'join'}>Join an existing group</Radio>
                      </Radio.Group>
                    </Form.Item>
                  )
                );
              }}
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: 'Please select one choice',
                },
              ]}
              style={{ marginTop: -16 }}
              shouldUpdate={(p, c) => p.partOfGroup !== c.partOfGroup || p.createGroup !== c.createGroup}>
              {({ getFieldValue, resetFields }) => {
                return (
                  getFieldValue('partOfGroup') &&
                  (getFieldValue('createGroup') === 'new' ? (
                    <CreateGroupInput code={code} />
                  ) : (
                    getFieldValue('createGroup') && <JoinGroupInput />
                  ))
                );
              }}
            </Form.Item>
            <Col span={24}>
              <Form.Item
                label={
                  <div className={Styles.label}>
                    Security Questions
                    <p className={Styles.subLabel} style={{ color: '#52606d' }}>
                      The answers to these questions will be used to generate your GBG Insurance Portal account, where
                      you can file claims and view other insurance usage related information.
                    </p>
                  </div>
                }>
                <Row gutter={12} style={{ marginTop: 12 }}>
                  <Col sm={24} md={10}>
                    <Form.Item name="securityQuestion1">
                      <Select placeholder="Security Question 1" options={secQuestions}></Select>
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={14}>
                    <Form.Item name="securityAns1">
                      <Input placeholder="Answer to Security Question 1"></Input>
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={10}>
                    <Form.Item name="securityQuestion2">
                      <Select placeholder="Security Question 2" options={secQuestions}></Select>
                    </Form.Item>
                  </Col>
                  <Col sm={24} md={14}>
                    <Form.Item name="securityAns2">
                      <Input placeholder="Answer to Security Question 2"></Input>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Tooltip
              placement="bottom"
              title={
                !formData?.personal?.final ? 'Please submit changes in Personal form by clicking "Next" button' : ''
              }>
              <Button disabled={!formData?.personal?.final} type="primary" htmlType="submit">
                Next <RightOutlined />
              </Button>
            </Tooltip>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: 'Payment',
      icon: <DollarOutlined />,
      form: ConfirmationScreen(),
    },
  ];

  if (currentUser?.isAnonymous)
    steps = [
      {
        title: 'Register',
        icon: <UserAddOutlined />,
        form: <SignUpContainer email={currentUser?.primaryEmail} />,
      },
      ...steps,
    ];

  const Terms: FC = () => (
    <Row className={Styles.terms}>
      <ol>
        <li>
          You consent to the use and disclosure of your personally identifiable information, including sensitive health
          information, and other information in accordance with our Privacy Policy. If you do not consent to the
          disclosure of your personal information, we will not be able to evaluate your request and will not be able to
          provide you with the medical program, including the insurance product offered by Crum & Forster, SPC. For
          information regarding the use and disclosure of your personal information, please visit{' '}
          <a target={'_blank'} rel="noreferrer" href="https://gradly.us/privacy">
            our privacy policy
          </a>
          .
        </li>
        <li>
          By purchasing this coverage, you agree to subscribe and become a participant in the Fairmont Specialty Trust
          and understand that participation in the Trust is a prerequisite to procuring the insurance coverage. Click{' '}
          <a target={'_blank'} rel="noreferrer" href="https://gradly.us/gbg-subscription">
            here
          </a>{' '}
          for a copy of the subscription agreement.
        </li>
        <li>
          This insurance coverage is not subject to and does not provide certain insurance benefits required by the
          United States’ Patient Protection and Affordable Care Act (“PPACA”). PPACA requires certain US citizens or US
          residents to obtain PPACA compliant health insurance, or “minimum essential coverage.” In some cases, certain
          individuals may be deemed to have minimum essential coverage under PPACA even if their insurance coverage does
          not provide all of the benefits required by PPACA. Please consult an attorney or tax professional to determine
          whether this insurance coverage meets any obligations you may have under PPACA.
        </li>
        <li>
          This plan contains both insurance and non-insurance benefits. Insurance benefits are provided by Crum &
          Forster SPC through ITI SP pursuant to a policy issued to the Fairmont Specialty Trust.
        </li>
      </ol>
      <Row className={Styles.termsButtons}>
        <Button
          loading={loading}
          className={Styles.agree}
          type="primary"
          onClick={() => {
            createEnrollment(formData, false);
            setAgreement(false);
            setCurrentStep(2);
          }}>
          I agree
        </Button>
        <Button
          type="primary"
          className={Styles.decline}
          onClick={() => {
            setAgreement(false);
          }}>
          Decline
        </Button>
      </Row>
    </Row>
  );

  // Components End

  return (
    <Row>
      <SimpleModal visible={agreement} setVisible={setAgreement} title={'Terms and Conditions'}>
        <Terms />
      </SimpleModal>
      <Steps current={currentStep} className={Styles.steps}>
        {steps.map((item: any, index: number) => (
          <Step
            icon={currentStep > index || insuranceData?.depositConfirmed ? <CheckCircleFilled /> : item.icon}
            key={index}
            onClick={() => {
              // Want to access payment step.
              if (!currentUser?.isAnonymous && props.status !== StepStatus.Processing && index !== 2)
                setCurrentStep(index);
            }}
            title={item.title}
          />
        ))}
      </Steps>
      <Row className={Styles.formContainer}>{steps[currentStep] ? steps[currentStep].form : steps[2].form}</Row>
    </Row>
  );
};
