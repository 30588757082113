import { Link, RouteComponentProps } from '@reach/router';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-use';
import { Accordion, AlertProps, AlertType, WelcomeUserWithProgressHeading } from '../../components';
import { UserJourneyStageId, UserJourneyStages } from '../../config/userJourneyMap';
import { getSopVersion, RootState } from '../../redux';
import {
  CustomStep,
  PredefinedStep,
  StepStatus,
  StepType,
  UserProfile,
  UserSopVersion,
} from '../../repos';
import { Analytics, AnalyticsEventName } from '../../utils';
import ReviewFeedback from './ReviewFeedback';
import Styles from './SopReviewStepContainer.module.sass';
import SubmitSopForm from './SubmitSopForm';
import { getIndexFromRoute } from '../utils';
import { AttendWebinarAccordionItem } from '../../components/Webinar';
import { renderCustomStep } from '..';

interface SopReviewStepContainerProps extends RouteComponentProps {
  getSopVersion: typeof getSopVersion;
  sopVersion?: UserSopVersion;
  currentUser?: UserProfile | null;
  loading: boolean;
  customSteps: CustomStep[];
  allPredefinedSteps: { [key: string]: PredefinedStep };
}

enum StepID {
  SubmitSop = 'SubmitSop',
  ReviewFeedback = 'ReviewFeedback',
  AttendWebinar = 'AttendWebinar',
}

const SopReviewStepContainer: React.FC<SopReviewStepContainerProps> = (props) => {
  const {
    getSopVersion,
    loading,
    sopVersion,
    customSteps,
    currentUser,
    navigate,
    allPredefinedSteps,
  } = props;
  const { pathname } = useLocation();

  const predefinedSteps = ['SubmitSop', 'ReviewFeedback', 'AttendWebinar'].map((step: string) => ({
    ...(allPredefinedSteps[step] as PredefinedStep),
    id: step,
  }));

  const steps: (PredefinedStep | CustomStep)[] = predefinedSteps
    .map((step, idx) => ({
      ...step,
      order: idx,
    }))
    .concat(...customSteps)
    .sort((step1, step2) => step1.order - step2.order);

  const AccordionInstance = useRef<Accordion>(null);

  const [calculatedStepsCompleted = [], setCalculatedStepsCompleted] = useState<string[]>();

  const stepsLeft =
    steps.length -
    customSteps.filter(
      (step) => step.type !== StepType.RsvpToWebinar && step.status === StepStatus.Complete,
    ).length -
    calculatedStepsCompleted.length;

  useEffect(() => {
    if (!window.location.pathname.includes('sop-review')) {
      navigate!('/sop-review');
    }
    getSopVersion();
  }, []);

  useEffect(() => {
    const completedItems = [];
    if (sopVersion) {
      completedItems.push(StepID.SubmitSop);
    }
    if (sopVersion && sopVersion.moreHelpRequested !== undefined) {
      completedItems.push(StepID.ReviewFeedback);
    }
    setCalculatedStepsCompleted(completedItems);
  }, [sopVersion && sopVersion.moreHelpRequested]);

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100vh',
        }}>
        <LoadingOutlined style={{ fontSize: 32 }} spin />
        <div style={{ marginTop: 12 }}>Loading</div>
      </div>
    );
  }

  const renderSubmitFormStep = (key: string) => {
    return (
      <Accordion.Item
        key={key}
        icon="file-add"
        status={sopVersion ? StepStatus.Complete : StepStatus.Incomplete}
        alert={
          sopVersion && {
            type: AlertType.Success,
            message: (
              <span>
                This step is done, you can{' '}
                <Link to="/sop-review/sop-review-feedback">
                  view our feedback on your SOP under "Review feedback from us" below
                </Link>
              </span>
            ),
          }
        }
        title={key}>
        <SubmitSopForm />
      </Accordion.Item>
    );
  };

  const renderReviewFeedback = (key: string) => {
    let alert: AlertProps | undefined = undefined;
    if (!sopVersion) {
      alert = {
        type: AlertType.Neutral,
        message: (
          <span>
            <Link to="/sop-review/submit-sop">Complete "Submit SOP for feedback" </Link>
            above to unlock this step! It'll only take a minute.
          </span>
        ),
      };
    } else if (sopVersion && sopVersion.feedbackSubmitted) {
      if (sopVersion.moreHelpRequested) {
        alert = {
          message:
            'We have received your help request. An expert will get in touch with you shortly!',
          type: AlertType.Success,
        };
      }
    } else {
      alert = {
        message:
          "We are reviewing your SOP. Once your feedback is ready, we'll send you an email and a notification so that you don't miss it",
        type: AlertType.Processing,
      };
    }

    return (
      <Accordion.Item
        key={key}
        icon="file-done"
        alert={alert}
        status={
          sopVersion
            ? sopVersion.feedbackSubmitted
              ? sopVersion.moreHelpRequested
                ? StepStatus.Complete
                : StepStatus.ReadyForReview
              : StepStatus.Processing
            : StepStatus.Locked
        }
        title={key}>
        <ReviewFeedback />
      </Accordion.Item>
    );
  };

  const logAnalyticsOnStepExpanded = (route: string, customStep?: CustomStep) => {
    const stepRoutes = allPredefinedSteps;
    switch (route) {
      case stepRoutes[StepID.SubmitSop].route:
        Analytics.log(AnalyticsEventName.SOPRequestReviewFormOpened);
        break;
      case stepRoutes[StepID.ReviewFeedback].route:
        if (sopVersion && sopVersion.feedbackSubmitted) {
          Analytics.log(AnalyticsEventName.SOPRequestReviewFeedbackViewed);
          break;
        }
        Analytics.log(AnalyticsEventName.SOPRequestReviewFeedbackOpened);
        break;
      case stepRoutes[StepID.AttendWebinar].route:
        Analytics.log(AnalyticsEventName.SOPRequestReviewAttendWebinarOpened);
        break;
      default: {
        if (!customStep) {
          break;
        }
        const { status, type, title, associatedStageId, stageProgressionOn } = customStep;
        if (status === StepStatus.Locked) {
          break;
        }
        const statusUpdatedAt = stageProgressionOn[status];
        const timeInSeconds = (statusUpdatedAt && moment().diff(statusUpdatedAt, 'seconds')) || -1;
        Analytics.log(AnalyticsEventName.CustomStepOpened, {
          status,
          type,
          title,
          timeInSeconds,
          associatedStage: UserJourneyStages[associatedStageId].title,
        });
        break;
      }
    }
  };

  return (
    <div className={Styles.container}>
      <div className={Styles.childContainer}>
        <WelcomeUserWithProgressHeading
          title="Write a great statement of purpose"
          stageID={UserJourneyStageId.SopReview}
          currentUser={currentUser}
          stepCount={{ total: steps.length, left: stepsLeft || 0 }}
        />
        <Accordion
          ref={AccordionInstance}
          onExpanded={(index) => {
            if (getIndexFromRoute(pathname, steps) === index) {
              return navigate!('/sop-review');
            }
            const route = steps[index].route;
            logAnalyticsOnStepExpanded(route);
            navigate!(`/sop-review/${route}`);
          }}
          defaultExpanded={getIndexFromRoute(pathname, steps)}
          className={Styles.accordion}>
          {steps.map((step) => {
            switch (step.id) {
              case StepID.SubmitSop:
                return renderSubmitFormStep(step.title);
              case StepID.ReviewFeedback:
                return renderReviewFeedback(step.title);
              case StepID.AttendWebinar:
                return (
                  <AttendWebinarAccordionItem
                    key={step.title}
                    onCompleted={(completed) => {
                      if (calculatedStepsCompleted.includes(step.title) && !completed) {
                        setCalculatedStepsCompleted([
                          ...calculatedStepsCompleted.filter((title) => title !== step.title),
                        ]);
                        return;
                      }
                      if (!calculatedStepsCompleted.includes(step.title) && completed) {
                        setCalculatedStepsCompleted([...calculatedStepsCompleted, step.title]);
                        return;
                      }
                    }}
                    title={step.title}
                  />
                );
              default: {
                renderCustomStep(
                  step,
                  calculatedStepsCompleted,
                  setCalculatedStepsCompleted,
                  currentUser?.shownBadges[UserJourneyStageId.SopReview] || [],
                );
              }
            }
          })}
        </Accordion>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { loading, sopVersion } = state.SopReview;
  const { steps } = state.CustomSteps;
  const { currentUser } = state.Auth;
  const allPredefinedSteps = state.Content.predefinedSteps[UserJourneyStageId.SopReview];

  return {
    loading,
    sopVersion,
    currentUser,
    customSteps: steps[UserJourneyStageId.SopReview] || [],
    allPredefinedSteps,
  };
};

export default connect(mapStateToProps, { getSopVersion })(SopReviewStepContainer);
