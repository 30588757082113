import Countdown from 'antd/lib/statistic/Countdown';
import moment, { Moment } from 'moment';
import React from 'react';
import { Subject, timer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AlertType } from '../../components';
import { StepStatus, WebinarRegistration } from '../../repos';

export type WebinarStatusCallback = (message: string | JSX.Element, status: StepStatus, alertType: AlertType, statusText?: string | JSX.Element) => void;

export class WebinarManager {
  private updateSubject = new Subject<{
    message: string | JSX.Element;
    status: StepStatus;
    alertType: AlertType;
    statusText?: string | JSX.Element;
  }>();
  registeredSlot?: { startTime: Moment; endTime: Moment };
  webinarRegistration?: WebinarRegistration;

  constructor(onStatusUpdated: WebinarStatusCallback, registeredSlot?: { startTime: Moment; endTime: Moment }, webinarRegistration?: WebinarRegistration) {
    this.registeredSlot = registeredSlot;
    this.webinarRegistration = webinarRegistration;
    this.updateSubject.pipe(debounceTime(1000)).subscribe(({ message, status, statusText, alertType }) => {
      onStatusUpdated(message, status, alertType, statusText);
    });
  }

  startObserver = () => {
    if (!this.registeredSlot) {
      return;
    }
    const { endTime } = this.registeredSlot;

    if (endTime.isBefore(moment())) {
      return;
    }
    const subscription = timer(endTime.toDate()).subscribe(() => {
      this.updateStatus();
    });

    return () => {
      console.debug('Unsubscribe called');
      subscription.unsubscribe();
      this.updateSubject.unsubscribe();
    };
  };

  updateStatus = () => {
    this.updateSubject.next({
      message: this.getMessage(),
      status: this.getStepStatus(),
      alertType: this.getAlertType(),
      statusText: this.getStatusText(),
    });
  };

  getMessage = () => {
    if (!!this.webinarRegistration && !!this.registeredSlot) {
      const { startTime, endTime } = this.registeredSlot;
      if (startTime.isAfter(moment())) {
        return 'You have been registered for the webinar. You can change your registration before the start time.';
      }
      if (moment().isBetween(startTime, endTime)) {
        return (
          <span>
            The webinar is live now. Press <strong>Join Webinar</strong> to start viewing it
          </span>
        );
      }
      return 'The webinar has ended, but you can watch the recording anytime';
    }
    return '';
  };

  getStepStatus = () => {
    if (!!this.webinarRegistration && !!this.registeredSlot) {
      const { startTime, endTime } = this.registeredSlot;
      if (startTime.isAfter(moment())) {
        return StepStatus.Processing;
      }
      if (moment().isBetween(startTime, endTime)) {
        return StepStatus.ReadyForReview;
      }
      return StepStatus.Complete;
    }
    return StepStatus.Incomplete;
  };

  getAlertType = () => {
    if (!!this.webinarRegistration && !!this.registeredSlot) {
      const { startTime, endTime } = this.registeredSlot;
      if (startTime.isAfter(moment())) {
        return AlertType.Processing;
      }
      if (moment().isBetween(startTime, endTime)) {
        return AlertType.Ready;
      }
      return AlertType.Success;
    }
    return AlertType.Info;
  };

  getStatusText = () => {
    if (!!this.webinarRegistration && !!this.registeredSlot) {
      const { startTime, endTime } = this.registeredSlot;
      if (startTime.isAfter(moment())) {
        return <Countdown onFinish={() => {}} value={startTime.format()} format="[Starts in] D[d] H[h] mm[m] ss[s]" />;
      }
      if (moment().isBetween(startTime, endTime)) {
        return 'Live';
      }
    }
  };
}
