import { produce } from 'immer';
import { SopTag, UserSopVersion, UserTestScoreInformation } from '../../repos';
import { SopReviewAction, SopReviewActionTypes } from '../actions';

export interface SopReviewStateType {
  readonly sopVersion?: UserSopVersion;
  readonly submittingSop?: boolean;
  readonly sopTags?: { [key: string]: SopTag };
  readonly loading: boolean;
  readonly userTestScoreInformation?: UserTestScoreInformation;
}

const initialState: SopReviewStateType = { loading: false };

export const SopReviewReducer = (state = initialState, action: SopReviewAction) => {
  return produce(state, draft => {
    draft.submittingSop = false;
    switch (action.type) {
      case SopReviewActionTypes.GetSopVersion:
        draft.loading = true;
        break;
      case SopReviewActionTypes.SetSopVersion:
        draft.sopVersion = action.payload.sopVersion;
        draft.loading = false;
        break;
      case SopReviewActionTypes.SubmitSopForm:
        draft.submittingSop = true;
        break;
      case SopReviewActionTypes.SetSopTags:
        draft.sopTags = action.payload.sopTags;
        break;
      case SopReviewActionTypes.SetUserTestScoreInformation:
        draft.userTestScoreInformation = action.payload.userTestScoreInformation;
        break;
      default:
        break;
    }
    return draft;
  });
};
