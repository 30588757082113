import React from 'react';

import { RightOutlined } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import Styles from './WelcomeScreen.module.sass';
import { electricWave, roundIcon, welcomeHealthInsurance } from '../../assets';
import {
  faBallot,
  faBallotCheck,
  faCapsules,
  faCheckCircle,
  faCreditCardFront,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { stageIcons } from './../../repos/Common/Common';

export const WelcomeScreen: React.FC<any> = (props) => {
  const { setShowWelcomeScreen } = props;

  return (
    <>
      <div className={Styles.welcomeScreen + ' animate__animated animate__fadeIn'}>
        <div className={Styles.welcomeScreenContainer}>
          <Row justify="space-between" align="middle" className={Styles.welcomeBanner}>
            <Col span={15} className={Styles.header}>
              <img src={stageIcons['health-insurance']} alt="health insurance icon"></img>
              <h2>Get compliant health insurance</h2>
            </Col>
            <Col span={9}>
              <Row justify="end">
                <Button
                  style={{ marginRight: '24px' }}
                  size="large"
                  type={'primary'}
                  onClick={() => {
                    setShowWelcomeScreen(false);
                  }}>
                  Get Started <RightOutlined />
                </Button>
              </Row>
            </Col>
          </Row>

          <Row justify="center">
            <Col span={10}>
              <img
                className={Styles.welcomePic}
                src={welcomeHealthInsurance}
                alt="Welcome Pic"></img>
            </Col>
            <Col className={Styles.heroInfo} span={12}>
              <div className={Styles.spinnerContainer}>
                <img
                  alt=""
                  className={`${Styles.spinner} animate__animated animate__rotate`}
                  src={roundIcon}></img>
                <img className={Styles.wave} alt="" src={electricWave}></img>
              </div>
              <h1>Plans designed for you and your university. </h1>
              <ul>
                <li>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Pre-approved by your university
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Based on your health profile
                </li>
                <li>
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Waiver guide generated for your university within 24 hours.
                </li>
              </ul>
            </Col>
          </Row>
          <Row justify="center" className={Styles.steps}>
            <Col className={Styles.step}>
              <div>
                <FontAwesomeIcon icon={faBallot} />
                <span>Choose your plan and pay the initial fee.</span>
              </div>
            </Col>
            <Col className={Styles.step}>
              <div>
                <FontAwesomeIcon icon={faBallotCheck} />
                <span>Receive waiver approval and university insurance refund.</span>
              </div>
            </Col>
            <Col className={Styles.step}>
              <div>
                <FontAwesomeIcon icon={faCreditCardFront} />
                <span>Pay premium after waiver is approved.</span>
              </div>
            </Col>
            <Col className={Styles.step}>
              <div>
                <FontAwesomeIcon icon={faCapsules} />
                <span>Receive medicines' list & year round help.</span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
