import { groupBy } from 'lodash';
import { of } from 'rxjs';
import { filter, map, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { RootEpic } from '.';
import { CustomStepRepo } from '../../repos';
import { catchAndReportError } from '../../utils';
import {
  CustomStepActionTypes,
  setAuthError,
  setCustomSteps,
  setError,
  updateCustomStepStatusSuccess,
} from '../actions';

export const listenToCustomStepsEpic: RootEpic = (action$, store) => {
  return action$.pipe(
    filter(isOfType(CustomStepActionTypes.ListenToCustomSteps)),
    withLatestFrom(store),
    switchMap(([, state]) => {
      const currentUser = state.Auth.currentUser;
      if (!currentUser) {
        return of(setAuthError());
      }
      return CustomStepRepo.listenToCustomSteps(currentUser.id).pipe(
        map(steps => setCustomSteps(groupBy(steps, step => step.associatedStageId))),
        catchAndReportError(error => of(setError(error.message))),
      );
    }),
  );
};

export const updateCustomStepStatusEpic: RootEpic = action$ => {
  return action$.pipe(
    filter(isOfType(CustomStepActionTypes.UpdateCustomStepStatus)),
    switchMap(action => {
      const { stepId, status, payload } = action.payload;
      return CustomStepRepo.updateStatus(stepId, status, payload).pipe(
        mapTo(updateCustomStepStatusSuccess()),
        catchAndReportError(error => of(setError(error.message))),
      );
    }),
  );
};
