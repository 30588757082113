import React, { FC, useEffect, useState } from 'react';
import { DownloadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Row, Col, Button, Collapse, Checkbox } from 'antd';
import { Accordion, DocsHelpModal } from '../../components';
import { PredefinedStep, StepStatus, UserProfile } from '../../repos';
import { downloadFile } from '../../utils';
import { visaDocsData, visaDocTitles } from './VisaDocsData';
import Styles from './CompileVisaDocs.module.sass';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { StepID } from './VisaStepContainer';

const { Panel } = Collapse;
const pdfURL =
  'https://firebasestorage.googleapis.com/v0/b/gradly-staging.appspot.com/o/VAC_appointment_docs.pdf?alt=media&token=c1a94a15-fed3-4ee4-bb52-bc608570019e';

const CompileVisaDocsHooks: FC<any> = (props) => {
  const { step } = props;
  const [checked, setChecked] = useState<Record<string, string[]>>({});
  const [showHelp, setShowHelp] = useState(false);
  const [helpData, setHelpData] = useState<any>();

  useEffect(() => {
    if (step.status === StepStatus.Complete) {
      setChecked(
        Object.keys(visaDocsData).reduce((acc: Record<string, string[]>, curr: string) => {
          acc[curr] = Object.keys(visaDocsData[curr]);
          return acc;
        }, {}),
      );
    }
  }, [step]);

  const renderChecklist = (key: string) => {
    return (
      <>
        <DocsHelpModal helpData={helpData} showHelp={showHelp} setShowHelp={setShowHelp} />
        <Checkbox.Group
          onChange={(checkedValues) => {
            setChecked({ ...checked, [key]: checkedValues.map((value) => value.toString()) });
          }}
          value={checked[key] || []}
          options={Object.keys(visaDocsData[key]).map((item) => {
            return {
              label: (
                <span>
                  {visaDocsData[key][item].title}{' '}
                  <QuestionCircleOutlined
                    onClick={(e) => {
                      e.preventDefault();
                      setHelpData(visaDocsData[key][item]);
                      setShowHelp(true);
                    }}
                  />
                </span>
              ),
              value: item,
            };
          })}></Checkbox.Group>
      </>
    );
  };

  const renderPanels = () => {
    return visaDocTitles.map((key, index) => {
      return (
        <Panel
          header={key}
          key={index}
          className={
            (checked[key] && checked[key].length === Object.keys(visaDocsData[key]).length) ||
            step.status === StepStatus.Complete
              ? Styles.completed
              : ''
          }>
          {renderChecklist(key)}
        </Panel>
      );
    });
  };

  return (
    <Row className={Styles.container}>
      <Col span={24}>
        <Button
          onClick={(e) => {
            e.preventDefault();
            downloadFile(pdfURL, 'VAC_appointment_docs.pdf');
          }}
          type="primary"
          download={'VAC_appointment_docs.pdf'}
          icon={<DownloadOutlined />}>
          Download Checklist as PDF
        </Button>
      </Col>
      <Col span={24}>
        <Collapse className={Styles.listCollapse}>{renderPanels()}</Collapse>
      </Col>
    </Row>
  );
};

export const CompileVisaDocs = (step: PredefinedStep, currentUser: UserProfile) => {
  return (
    <Accordion.Item
      key={step.route}
      icon={'contacts'}
      status={step.status || StepStatus.Incomplete}
      title={step.title}
      showMarkAsComplete={{
        uid: currentUser.id,
        route: [UserJourneyStageId.VisaApplication, StepID.CompileDocs],
      }}>
      <CompileVisaDocsHooks step={step} currentUser={currentUser} />
    </Accordion.Item>
  );
};
