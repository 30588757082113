import { HelpDataType } from '../../components';

interface VisaDocsDataType {
  [key: string]: HelpDataType;
}

export const visaDocTitles = [
  'Set 1: Personal Information',
  'Set 2: Educational Documents',
  'Set 3: Work-related Documents (if applicable)',
  'Set 4: Financial Documents',
];

export const visaDocsData: VisaDocsDataType = {
  'Set 1: Personal Information': {
    passport: {
      title: 'Passport (current and all the previous ones)',
      description:
        'An Indian passport is issued by the Ministry of External Affairs of the Republic of India to Indian citizens for the purpose of international travel.',
      image:
        'https://res.cloudinary.com/gradly/image/upload/v1648277809/assets/visaDocsData/Indian_Passport_Information_page_blank_q8m1nl.png',
      dos: [
        'Please present both current and all the previous passports.',
        'A passport valid for travel to the United States with validity dates at least six months beyond your intended period of stay in the United States',
      ],
      donts: [],
    },
    'mrv-reciept': {
      title: 'MRV Fee Receipt',
      description:
        'The MRV is a government-mandated visa processing fee that is required to be paid whether your visa is approved for travel or not. The receipt generated from the payment of the Machine Readable Visa (MRV) fee is termed as MRV Receipt.',
      image:
        'https://res.cloudinary.com/gradly/image/upload/v1648277627/assets/visaDocsData/mrv_hx58n4.jpg',
      dos: [],
      donts: [],
    },
    'visa-interview-appointment-letter': {
      title: 'Visa Interview Appointment Letter',
      description:
        'An Appointment Letter comes from the U.S. Consulate General when a visa interview has been scheduled or is ready to be scheduled.',
      image:
        'https://res.cloudinary.com/gradly/image/upload/v1648277629/assets/visaDocsData/Visa_interview_appointment_letter_s2jvqk.png',
      dos: [],
      donts: [],
    },
    'sevis-fee-receipt': {
      title: 'SEVIS Fee Reciept',
      description:
        'The SEVIS fee is required of all foreign nationals who come to the United States for the purpose of pursuing a full course of study. The fee is paid one time for each continuous, uninterrupted period of F-1 status. The receipt generated from the payment of the SEVIS fee is termed as SEVIS Receipt.',
      image:
        'https://res.cloudinary.com/gradly/image/upload/v1648277630/assets/visaDocsData/SEVIS_fee_receipt_hoiays.png',
      dos: [],
      donts: [],
    },
    'passport-size-photo': {
      title: 'Passport Size Photograph',
      description:
        'The correct size of a passport photo is 2 x 2 inches (51 x 51 mm). The head of the person in the photo must be between 1 -1 3/8 inches (25 - 35 mm) from the bottom of the chin to the top of the head.',
      image:
        'https://res.cloudinary.com/gradly/image/upload/v1648277631/assets/visaDocsData/Passport_size_photo_ri6oi4.png',
      dos: [
        'Submit a color photo, taken in last 6 months',
        'Use a clear image of your face. Do not use filters commonly used on social media.',
        'Have someone else take your photo. No selfies.',
        'Take off your eyeglasses for your photo. ',
        'Use a plain white or off-white background.',
      ],
      donts: [],
    },
  },
  'Set 2: Educational Documents': {
    transcripts: {
      title: 'Academic Transcripts like Class 10th and 12th Certificates and mark-sheets',
      image:
        'https://res.cloudinary.com/gradly/image/upload/v1645629129/assets/class10result_cfy3pq.jpg',
      description:
        'This refers to your Class 10 (Senior Secondary Certificate Examination) and Class 12 (Higher Secondary Certificate Examination) transcript.',
      dos: [
        'Ensure that your roll no., name and date of birth are clearly visible.',
        'Only a physical marksheet provided by the board is accepted.',
      ],
      donts: [],
    },
    'gre-scorecard': {
      title: 'GRE/GMAT/SAT scorecard',
      image:
        'https://res.cloudinary.com/gradly/image/upload/v1648277860/assets/visaDocsData/GRE_Scorecard_j4ojme.png',
      description:
        'The Graduate Record Examinations is a standardized test that is an admissions requirement for many graduate schools in the United States and Canada and few in other countries. The scorecard indicates the score obtained by a candidate from the GRE.',
      dos: [
        'Ensure that all the necessary stamps and signatures are clearly visible.',
        'Ensure that your roll no., name and date of birth are clearly visible.',
      ],
      donts: [],
    },
    'ielts-scorecard': {
      title: 'IELTS/TOEFL/PTE scorecard',
      description:
        'Test of English as a Foreign Language is a standardized test to measure the English language ability of non-native speakers wishing to enroll in English-speaking universities. The scorecard indicates the score obtained by a candidate from the TOEFL.',
      image:
        'https://res.cloudinary.com/gradly/image/upload/v1648277937/assets/visaDocsData/TOEFL-Sample-scaled_gptk9p.jpg',
      dos: [
        'Ensure that all the necessary stamps and signatures are clearly visible.',
        'Ensure that your roll no., name and date of birth are clearly visible.',
      ],
      donts: [],
    },
    'degree-certificate': {
      title:
        'Bachelor’s Degree/ Provisional Degree Certificate or Course completion certificate or mark-sheet for all the years as well as the consolidated mark sheet (if applicable)',
      description:
        "A bachelor's degree or baccalaureate is an undergraduate academic degree awarded by colleges and universities upon completion of a course of study lasting three to six years and Course Completion Certificate is just a certification given to a candidate who completes the requirements of the course.",
      image:
        'https://res.cloudinary.com/gradly/image/upload/v1648277934/assets/visaDocsData/Bachelor_s_Degree_w6yqqq.jpg',
      dos: [
        'Original copies are preferred.',
        'Ensure that the university’s letterhead information is clearly visible.',
        'Ensure that the document is legible.',
      ],
      donts: [],
    },
    'passport-size-photo': {
      title: 'Passport Size Photograph',
      description:
        'The correct size of a passport photo is 2 x 2 inches (51 x 51 mm). The head of the person in the photo must be between 1 -1 3/8 inches (25 - 35 mm) from the bottom of the chin to the top of the head.',
      image:
        'https://res.cloudinary.com/gradly/image/upload/v1648277631/assets/visaDocsData/Passport_size_photo_ri6oi4.png',
      dos: [
        'Submit a color photo, taken in last 6 months',
        'Use a clear image of your face. Do not use filters commonly used on social media.',
        'Have someone else take your photo. No selfies.',
        'Take off your eyeglasses for your photo. ',
        'Use a plain white or off-white background.',
      ],
      donts: [],
    },
  },
  'Set 3: Work-related Documents (if applicable)': {
    'joining-letter': {
      title: 'Joining Letter',
      description:
        'A joining letter is a letter from the candidate to the employer accepting the job offer.',
      image:
        'https://res.cloudinary.com/gradly/image/upload/v1648278095/assets/visaDocsData/Joining-Letter-for-a-New-Employee_ftsgwp.png',
      dos: [
        'Original copies are preferred.',
        'Ensure that the company’s letterhead information is clearly visible.',
        'Ensure that the document is legible.',
      ],
      donts: [],
    },
    'salary-slips': {
      title: 'Salary Slips (last six months)',
      description:
        "A salary slip is a document issued by an employer to an employee. It contains a detailed description of the employee's salary components like HRA, LTA, Bonus paid etc and deductions for a specified time period, usually a month.",
      image:
        'https://res.cloudinary.com/gradly/image/upload/v1648278096/assets/visaDocsData/salary_slip_1_yocjlc.jpg',
      dos: [
        'Please produce salary slips for the last six months of employment.',
        'Original copies are preferred.',
        'Ensure that the company’s letterhead information is clearly visible.',
        'Ensure that the document is legible.',
      ],
      donts: [],
    },
    'relieving-letter': {
      title: 'Relieving Letter',
      description:
        'Relieving letter is the formal way of informing the employee that his/her resignation has been accepted and he is relieved from his responsibilities and duties he/she was bound with subject to the contract of employment signed by him/her.',
      image:
        'https://res.cloudinary.com/gradly/image/upload/v1648278097/assets/visaDocsData/reliving_letter_iuzdn7.png',
      dos: [
        'Original copies are preferred.',
        'Ensure that the company’s letterhead information is clearly visible.',
        'Ensure that the document is legible.',
      ],
      donts: [],
    },
  },
  'Set 4: Financial Documents': {
    'financial-support-documents': {
      title:
        'Financial support documents as indicated on Form I-20 (i.e. bank statements, awards, letters or loan approval letters)',
      description:
        'Financial support documents are either bank statements, award letters, or loan approval letters.',
      image:
        'https://res.cloudinary.com/gradly/image/upload/v1648278249/assets/visaDocsData/Bank-loan-approval-letter_gtcoqc.jpg',
      dos: [
        'Ensure that the organisation/bank’s letterhead information is clearly visible.',
        'Ensure that the document is legible.',
      ],
      donts: [
        'Photocopies of bank statements will not be accepted unless you can also show original copies of bank statements or original bank books.',
      ],
    },
    'saving-bank-acc-statement': {
      title: 'Saving Bank Account Statement (recommended: last three years)',
      description:
        'A bank statement or account statement is a document issued by a bank describing the activities in a depositor’s savings account or current account during the period. Account-holders can keep a track of their transactions and account through bank statements.',
      image:
        'https://res.cloudinary.com/gradly/image/upload/v1648278241/assets/visaDocsData/bank_account_statement_ulxsgc.jpg',
      dos: [
        'Ensure that the bank’s letterhead information is clearly visible.',
        'Ensure that the document is legible.',
        'The statement should include transactions that are at least dated from three years.',
      ],
      donts: [
        'Photocopies of bank statements will not be accepted unless you can also show original copies of bank statements or original bank books.',
      ],
    },
    'fixed-deposit-certificates': {
      title: 'Fixed Deposit Certificates (recommended: last three years)',
      description:
        'FDR or a Fixed Deposit certificate is a document that is given by the bank  to the depositor with the history and tenure of deposits',
      image:
        'https://res.cloudinary.com/gradly/image/upload/v1648278241/assets/visaDocsData/Fixed_deposit_drqs61.jpg',
      dos: [
        'The certificate should include deposits that are at least dated from three years.',
        'Ensure that your name, deposit amount and bank’s name are clearly visible.',
        'Ensure that it’s attested by an authorized gazetted officer.',
      ],
      donts: [],
    },
    'tax-returns': {
      title: 'Tax Returns for the past three years',
      description:
        'An tax return is a form used to file information about your income and tax to the Income Tax Department. As per the income tax laws, the return must be filed every year by an individual or business that earns any income during a financial year.',
      image:
        'https://res.cloudinary.com/gradly/image/upload/v1648278243/assets/visaDocsData/itr_n8c23m.jpg',
      dos: [
        'The document should include returns that are at least dated from three years.',
        'Ensure that your name, tax amount and date are clearly visible.',
        'Ensure that it’s attested by an authorized gazetted officer.',
      ],
      donts: [],
    },
    'property-docs': {
      title: 'Property Documents (residential, commercial, ancestral and/or farmland)',
      description:
        'A property document could be document of residential, commercial, ancestral asset and/or farmland.',
      image:
        'https://res.cloudinary.com/gradly/image/upload/v1648278317/assets/visaDocsData/propertytaxcopy_szmbqi.jpg',
      dos: [
        'Ensure that your name, tax amount and date are clearly visible.',
        'Ensure that it’s attested by an authorized gazetted officer.',
      ],
      donts: [],
    },
  },
};
