import { produce } from 'immer';
import { Content, PredefinedSteps, Webinar, WebinarRegistration } from '../../repos';
import { ContentAction, ContentActionTypes } from '../actions';

export interface ContentStateType {
  readonly contentItems?: Content[];
  readonly loading: boolean;
  readonly webinarUrl?: string;
  readonly webinarChatUrl?: string;
  readonly webinarRegistrations?: WebinarRegistration[];
  readonly customStepWebinars: { [key: string]: Webinar };
  readonly predefinedSteps: PredefinedSteps;
  readonly stepsStatus: { [key: string]: any };
}

const initialState: ContentStateType = {
  loading: false,
  customStepWebinars: {},
  predefinedSteps: {},
  stepsStatus: { loading: true },
};

export const ContentReducer = (state = initialState, action: ContentAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ContentActionTypes.SetContentItems:
        draft.contentItems = action.payload.contentItems;
        break;
      case ContentActionTypes.SetWebinarRegistrations:
        draft.webinarRegistrations = action.payload.registrations;
        break;
      case ContentActionTypes.RegisterForWebinar:
        draft.loading = true;
        break;
      case ContentActionTypes.RegisterForWebinarSuccess:
        draft.loading = false;
        break;
      case ContentActionTypes.RegisterForWebinarError:
        draft.loading = false;
        break;
      case ContentActionTypes.UnregisterForWebinar:
        draft.loading = true;
        break;
      case ContentActionTypes.UnregisterForWebinarSuccess:
        draft.loading = false;
        break;
      case ContentActionTypes.UnregisterForWebinarError:
        draft.loading = false;
        break;
      case ContentActionTypes.JoinWebinar:
        draft.loading = true;
        draft.webinarUrl = undefined;
        break;
      case ContentActionTypes.SetUrlToJoinWebinar:
        draft.loading = false;
        draft.webinarUrl = action.payload.url;
        draft.webinarChatUrl = action.payload.chatURL;
        break;
      case ContentActionTypes.JoinWebinarError:
        draft.loading = false;
        draft.webinarUrl = undefined;
        break;
      case ContentActionTypes.SetWebinar:
        draft.customStepWebinars[action.payload.webinarId] = action.payload.webinar;
        break;
      case ContentActionTypes.SetPredefinedSteps:
        draft.predefinedSteps = action.payload.steps;
        break;
      case ContentActionTypes.SetStepsStatus:
        draft.stepsStatus = action.payload.stepsStatus;
        break;
      default:
        break;
    }
    return draft;
  });
};
