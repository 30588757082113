import { RcFile } from 'antd/lib/upload';
import { Moment } from 'moment';
import { action, ActionType } from 'typesafe-actions';
import {
  SopReviewComment,
  SopTag,
  SopTagAnnotation,
  University,
  UserSopVersion,
  UserTestScoreInformation,
} from '../../repos';

export enum SopReviewActionTypes {
  GetSopVersion = 'SopReviewActionType/GetSopVersion',
  SetSopVersion = 'SopReviewActionType/SetSopVersion',
  SubmitSopForm = 'SopReviewActionType/SubmitSopForm',
  SetSopTags = 'SopReviewActionType/SetSopTags',
  SubmitSopReviewRating = 'SopReviewActionType/SubmitSopReviewRating',
  MarkedCurrentReviewAsSeen = 'SopReviewActionType/MarkedCurrentReviewAsSeen',
  MarkHelpItems = 'SopReviewActionType/MarkHelpItems',
  SubmitHelpRequest = 'SopReviewActionType/SubmitHelpRequest',
  SetUserTestScoreInformation = 'SopReviewActionType/SetUserTestScoreInformation',
}

export const getSopVersion = () => action(SopReviewActionTypes.GetSopVersion);

export const setSopVersion = (sopVersion?: UserSopVersion) =>
  action(SopReviewActionTypes.SetSopVersion, { sopVersion });

export const setUserTestScoreInformation = (userTestScoreInformation: UserTestScoreInformation) =>
  action(SopReviewActionTypes.SetUserTestScoreInformation, { userTestScoreInformation });

export const submitSopForm = (
  sop?: string,
  greScore?: { quant: number; verbal: number; writing: number },
  greDate?: Moment,
  toeflScore?: number,
  toeflDate?: Moment,
  universities?: University[],
  sopHelp?: string,
  loanStatus?: string,
  sopDeadlineDate?: Moment,
  resumeFile?: RcFile,
) =>
  action(SopReviewActionTypes.SubmitSopForm, {
    sop,
    greScore,
    greDate,
    toeflScore,
    toeflDate,
    universities,
    sopHelp,
    loanStatus,
    sopDeadlineDate,
    resumeFile,
  });

export const setSopTags = (sopTags: { [key: string]: SopTag }) =>
  action(SopReviewActionTypes.SetSopTags, { sopTags });

export const submitSopReviewRating = (rating: number) =>
  action(SopReviewActionTypes.SubmitSopReviewRating, { rating });

export const markCurrentReviewAsSeen = (sopVersionId: string) =>
  action(SopReviewActionTypes.MarkedCurrentReviewAsSeen, { sopVersionId });

export const markHelpItems = (
  sopVersionId: string,
  comments: SopReviewComment[],
  tagAnnotations: SopTagAnnotation[],
) => action(SopReviewActionTypes.MarkHelpItems, { sopVersionId, comments, tagAnnotations });

export const submitHelpRequest = (sopVersionId: string) =>
  action(SopReviewActionTypes.SubmitHelpRequest, { sopVersionId });

const sopReviewActions = {
  getSopVersion,
  setSopVersion,
  submitSopForm,
  setSopTags,
  submitSopReviewRating,
  markCurrentReviewAsSeen,
  markHelpItems,
  submitHelpRequest,
  setUserTestScoreInformation,
};

export type SopReviewAction = ActionType<typeof sopReviewActions>;
