import { Icon as LegacyIcon } from '@ant-design/compatible';
import { CaretRightOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Radio, Tag } from 'antd';
import Countdown from 'antd/lib/statistic/Countdown';
import { intersectionBy } from 'lodash';
import moment from 'moment';
import React, { CSSProperties, useEffect, useState } from 'react';
import { joinWebinar, registerForWebinar, unregisterForWebinar } from '../../redux';
import { Webinar, WebinarRegistration, WebinarType } from '../../repos';
import { ContentPopOver } from '../ContentPopOver';
import './styles.scss';

export enum WebinarAction {
  Register = 'Register',
  Unregister = 'Unregister',
  WatchLive = 'WatchLive',
  WatchRecording = 'WatchRecording',
}

interface WebinarCardProps {
  webinar: Webinar;
  registrations: WebinarRegistration[];
  registering: boolean;
  unregistering: boolean;
  joiningWebinar: boolean;
  popUpData: any;
  style?: CSSProperties;
  onActionClicked(action: WebinarAction, conferenceId: string): void;
}

export const handleWebinarAction = (
  action: WebinarAction,
  conferenceId: string,
  join: typeof joinWebinar,
  register: typeof registerForWebinar,
  unregister: typeof unregisterForWebinar,
) => {
  switch (action) {
    case WebinarAction.Register:
      register(conferenceId);
      break;
    case WebinarAction.Unregister:
      unregister(conferenceId);
      break;
    case WebinarAction.WatchLive:
      join(conferenceId);
      break;
    case WebinarAction.WatchRecording:
      break;
  }
};

export const WebinarCard: React.FC<WebinarCardProps> = (props) => {
  const {
    webinar,
    style,
    registrations,
    registering,
    unregistering,
    joiningWebinar,
    onActionClicked,
    popUpData,
  } = props;
  const availableSlots = webinar.slots.filter((slot) => slot.endTime > moment());
  const registeredSlotNumber =
    registrations.length === 0
      ? undefined
      : availableSlots.findIndex((item) => item.conferenceId === registrations[0].conferenceId);
  const [selectedSlot, setSelectedSlot] = useState<number>(registeredSlotNumber || 0);
  const [showWebinarPopUp, setShowWebinarPopUp] = useState<{ webinar: Webinar; data: any }>();

  useEffect(() => {
    popUpData && setShowWebinarPopUp({ webinar, data: popUpData });
  }, [popUpData]);

  if (availableSlots.length === 0) {
    return <span>No webinars scheduled</span>;
  }
  const webinarRegistrations = intersectionBy(registrations, availableSlots, 'conferenceId');
  const webinarRegistration = webinarRegistrations.find(
    (registration) => registration.conferenceId === availableSlots[selectedSlot].conferenceId,
  );

  const isWebinarYetToStart = availableSlots[selectedSlot].startTime.isAfter(moment());
  const isWebinarLive = moment().isBetween(
    availableSlots[selectedSlot].startTime,
    availableSlots[selectedSlot].endTime,
    's',
  );
  const hasWebinarEnded = availableSlots[selectedSlot].endTime.isBefore(moment());

  const titleComponent = (
    <div className="header-container">
      {webinar.author ? (
        <Avatar size={58} src={webinar.author.displayPictureUrl}>
          {webinar.author.name.substr(0, 1)}
        </Avatar>
      ) : (
        <Avatar size={58} icon={<UserOutlined />} />
      )}
      <div className="title-container">
        <span className="title">{webinar.title}</span>
        <span className="description">{webinar.author ? `by ${webinar.author.name}` : ''}</span>
      </div>
    </div>
  );

  const tagComponent = <Tag color="geekblue">{webinar.topics[0].name.toUpperCase()}</Tag>;

  const webinarContent = () => {
    return <img alt="Webinar agenda" src={webinar.contentPictureUrl} />;
  };

  const subText = () => {
    switch (webinar.webinarType) {
      case WebinarType.BigMarker:
        switch (true) {
          case isWebinarYetToStart:
            return 'Happening On';
          case isWebinarLive:
            return (
              <Countdown
                value={availableSlots[selectedSlot].endTime.format()}
                format="H[h] mm[m] ss[s left]"
              />
            );
          case hasWebinarEnded:
            return 'Happened On';
        }
        break;
      default:
        return null;
    }
  };

  const primaryText = () => {
    switch (webinar.webinarType) {
      case WebinarType.BigMarker:
        switch (true) {
          case isWebinarYetToStart:
            return `${availableSlots[selectedSlot].startTime.format(
              'MMM DD, hh:mm A',
            )} to ${availableSlots[selectedSlot].endTime.format('hh:mm A')}`;
          case isWebinarLive:
            return 'HAPPENING NOW';
          case hasWebinarEnded:
            return `${availableSlots[selectedSlot].startTime.format(
              'MMM DD, hh:mm A',
            )} to ${availableSlots[selectedSlot].endTime.format('hh:mm A')}`;
        }
        break;
      default:
        return null;
    }
  };

  const footerActionButtons = () => {
    switch (webinar.webinarType) {
      case WebinarType.BigMarker:
        switch (true) {
          case isWebinarYetToStart:
            return (
              <div className="actions-container">
                <Button
                  loading={webinarRegistration ? unregistering : registering}
                  onClick={() =>
                    webinarRegistration
                      ? onActionClicked(
                          WebinarAction.Unregister,
                          availableSlots[selectedSlot].conferenceId,
                        )
                      : onActionClicked(
                          WebinarAction.Register,
                          availableSlots[selectedSlot].conferenceId,
                        )
                  }
                  type={webinarRegistration ? 'default' : 'primary'}
                  style={webinarRegistration ? { background: '#7ed321', color: 'white' } : {}}>
                  {!(registering || unregistering) && (
                    <LegacyIcon
                      type={webinarRegistration ? 'check-circle' : 'plus-circle'}
                      theme={webinarRegistration ? 'filled' : 'outlined'}
                    />
                  )}
                  {webinarRegistration
                    ? unregistering
                      ? 'Unregistering'
                      : 'Registered'
                    : registering
                    ? 'Registering'
                    : 'Register'}
                </Button>
              </div>
            );
          case isWebinarLive:
            return (
              <div className="actions-container">
                <Button
                  type="primary"
                  danger
                  loading={joiningWebinar}
                  icon={<LegacyIcon type={joiningWebinar ? '' : 'caret-right'} />}
                  onClick={() =>
                    onActionClicked(
                      WebinarAction.WatchLive,
                      availableSlots[selectedSlot].conferenceId,
                    )
                  }>
                  {joiningWebinar ? 'JOINING' : 'JOIN LIVE'}
                </Button>
              </div>
            );
          case hasWebinarEnded:
            return (
              <div className="actions-container">
                <Button
                  type="primary"
                  icon={<CaretRightOutlined />}
                  onClick={() =>
                    onActionClicked(
                      WebinarAction.WatchRecording,
                      availableSlots[selectedSlot].conferenceId,
                    )
                  }>
                  Watch
                </Button>
              </div>
            );
        }
        break;
      default:
        return null;
    }
  };

  return (
    <Card
      key={webinar.id}
      title={titleComponent}
      className="webinar-card"
      extra={tagComponent}
      style={style}
      hoverable>
      <ContentPopOver
        feedItem={showWebinarPopUp ? showWebinarPopUp.webinar : undefined}
        data={showWebinarPopUp ? showWebinarPopUp.data : undefined}
        onCancel={() => setShowWebinarPopUp(undefined)}
      />
      <div className="webinar-container">{webinarContent()}</div>
      <div className="webinar-footer">
        <div className="text-container">
          <span className="sub-text">{subText()}</span>
          <span className="primary-text">{primaryText()}</span>
        </div>
        <div className="available-slots">
          <Radio.Group
            onChange={(e) => setSelectedSlot(e.target.value)}
            defaultValue={selectedSlot}>
            {availableSlots.map((slot, i) => {
              return (
                <Radio.Button
                  disabled={slot.endTime < moment()}
                  key={slot.startTime.format()}
                  value={i}>
                  {slot.startTime.format("Do MMM 'YY h:mm A")}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </div>
        {footerActionButtons()}
      </div>
    </Card>
  );
};
