import React, { useEffect, useState } from 'react';
import { Accordion, I20NaturalForm, IntercomAPI } from '../../components';
import { markStepsStatus, PredefinedStep, StepStatus, University, UniversityRepo } from '../../repos';
import { Alert, Button, Col, Row, Spin, Steps } from 'antd';
import Styles from './I20Form.module.sass';
import { CheckOutlined, DownloadOutlined, FileAddFilled } from '@ant-design/icons';
import { PersonalDataForm } from './PersonalDataForm';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { downloadFile, FirestoreReference } from '../../utils';
import { FundSourcesForm } from './FundSourcesForm';
import { i20DownloadIllustration } from '../../assets';
import { Colors } from '../../theme';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { StepID } from './VisaStepContainer';
import { GradlyLoading } from '../../App';

interface Unidata {
  id: string;
  name: string;
}
interface I20FormProps {
  currentStep: PredefinedStep;
  uid: string;
  uni?: Unidata;
}

export const I20Form: React.FC<I20FormProps> = (props) => {
  const { uid, currentStep, uni } = props;
  const [university, setUniversity] = useState<Unidata | undefined>(uni);
  const [step, setStep] = useState<number>(0);
  const [filledI20URL, setFilledI20URL] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (uid) {
      FirestoreReference.VisaApplications(uid)
        .doc('i20Data')
        .get()
        .then((doc) => {
          if (doc.exists) {
            setStep(1);
          }
        });
      FirestoreReference.VisaApplications(uid)
        .doc('filledI20')
        .onSnapshot(
          (doc) => {
            if (doc.exists) {
              const longURL = doc.data()?.i20LongURL;
              setFilledI20URL(longURL ? longURL : '');
              setUniversity(doc.data()?.university || { name: false });
            }
          },
          (error) => {
            console.debug('Filled I20', error);
          },
        );
    }
  }, [uid]);

  if (!uid) {
    return null;
  }
  const steps = [
    {
      title: 'Personal Data',
      content: <PersonalDataForm setStep={setStep} uid={uid} />,
    },
    {
      title: 'Sources of Funds',
      content: <FundSourcesForm uid={uid} />,
    },
  ];
  const pdfURL = filledI20URL || 'https://admission.brown.edu/sites/g/files/dprerj526/files/2020-03/I-20-request-form.pdf';

  if (!university)
    return (
      <Row className={Styles.uniForm} justify="center">
        <I20NaturalForm
          onSubmit={function (uni: University): void {
            const unidata = { id: uni.id, name: uni.name };
            setUniversity(unidata);
            markStepsStatus(uid, [UserJourneyStageId.VisaApplication, currentStep.id], StepStatus.Pending, { university: unidata });
          }}
        />
      </Row>
    );
  else
    return (
      <Row className={Styles.container}>
        {filledI20URL && !university.name ? null : (
          <Col span={24}>
            <h3>
              Showing results for <span>{university?.name}</span>
            </h3>
          </Col>
        )}
        <Row justify="center" style={{ width: '100%', marginTop: 10 }}>
          <Col span={12} className={filledI20URL ? Styles.prefilledViews : Styles.pdfviews}>
            {!filledI20URL && (
              <div>
                <Button
                  href={pdfURL}
                  onClick={(e) => {
                    e.preventDefault();
                    downloadFile(pdfURL, 'I20-Form.pdf');
                  }}
                  className={Styles.downloadB}
                  type="link"
                  download={'I-20 Request Form.pdf'}
                  icon={<DownloadOutlined />}>
                  Download PDF
                </Button>
              </div>
            )}
            <Spin spinning={loading} indicator={<GradlyLoading height={'120%'} />}>
              <iframe
                title="I20 Document"
                frameBorder={0}
                onLoad={() => {
                  setLoading(false);
                }}
                className={Styles.pdfContainer}
                src={pdfURL + '#toolbar=0'}
                width="100%"
                height="100%">
                {pdfURL}
              </iframe>
            </Spin>
            <p>
              Source URL:{' '}
              <a target="_blank" href={pdfURL} rel="noreferrer">
                {pdfURL}
              </a>
            </p>
          </Col>
          {filledI20URL ? (
            <Col span={12}>
              <Row align="top" justify="center" className={Styles.downloadFilledPDF}>
                <img src={i20DownloadIllustration} alt="Download"></img>
                <h1>Your I-20 request form is ready for download.</h1>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    downloadFile(pdfURL, 'I20-Form.pdf');
                  }}
                  type="primary"
                  download={'I-20 Request Form.pdf'}
                  icon={<DownloadOutlined />}>
                  Download Now
                </Button>
                <Alert
                  showIcon
                  type={'info'}
                  message={'Your next steps:'}
                  description={
                    <>
                      <ol>
                        <li>Get all the required signatures.</li>
                        <li>Fill out the 'Official Certification of Funds and Amounts' section of the form.</li>
                        <li>Mail/e-mail the form to the university.</li>
                      </ol>
                      <span>
                        Need help?{' '}
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            IntercomAPI.showWithMessagePreFilled(`Hey! I have questions about my I-20 application.`, false);
                          }}>
                          <u>Chat with us.</u>
                        </a>
                      </span>
                    </>
                  }></Alert>
              </Row>
            </Col>
          ) : (
            <Col span={12}>
              <div className={Styles.formTitle}>
                <FileAddFilled /> Auto generate your I-20 request form below
              </div>
              <Steps className={Styles.steps} current={step}>
                {steps.map((item, index) => (
                  <Steps.Step
                    onClick={() => {
                      setStep(index);
                    }}
                    key={item.title}
                    title={item.title}
                  />
                ))}
              </Steps>
              <div className={Styles.stepForm}>{steps[step].content}</div>
            </Col>
          )}
        </Row>
      </Row>
    );
};

export const renderI20Form = (currentStep: PredefinedStep, uid: string, university: University) => {
  return (
    <Accordion.Item
      status={currentStep.status || StepStatus.Incomplete}
      key={currentStep.id}
      icon={'file-search'}
      title={currentStep.title}
      showMarkAsComplete={university ? { uid, route: [UserJourneyStageId.VisaApplication, currentStep.id] } : undefined}>
      <I20Form currentStep={currentStep} uid={uid} uni={university} />
    </Accordion.Item>
  );
};
