import { HelpData } from '../../components';

export const consularAppointmentHelpData: HelpData = {
  Login: [
    {
      title: 'Start Application',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646931060/assets/ConsularAppointment/Group_190_hatipo.png',
    },
  ],
  'New Application': [
    {
      title: 'New Application',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646931353/assets/ConsularAppointment/Frame_126_h4l94z.png',
    },
  ],
  'Visa Type': [
    {
      title: 'Visa Type',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1648018029/assets/ConsularAppointment/visa-type_y61suc.png',
    },
  ],
  'Post/Residence': [
    {
      title: 'Post/Residence',
      message: 'Select your State of residence from the list.',
    },
  ],
  'Visa Category': [
    {
      title: 'Visa Category',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1648018189/assets/ConsularAppointment/visa-cat_qo1rqu.png',
    },
  ],

  'Visa Class': [
    {
      title: 'Visa Class',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1648018230/assets/ConsularAppointment/visa-class_c7gb3w.png',
    },
  ],
};
