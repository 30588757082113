import { Row, Button, Alert, Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { debitCardIllustration } from '../../assets';
import {
  Accordion,
  AssistanceCard,
  AlertType,
  AccordionModal,
  InstructionalModal,
  InstructionalData,
  MarkAsCompleteButton,
} from '../../components';
import { PredefinedStep, StepStatus } from '../../repos';
import Styles from './PaySEVISFee.module.sass';
import {
  ExclamationCircleOutlined,
  InfoCircleFilled,
  RightOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { CardImage, CardInfo } from '../ForexStep/CardDetails';
import { CreateGradlyCardForm } from '../ForexStep/CreateGradlyCard';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { navigate } from '@reach/router';
import { setCustomSteps } from './../../redux/actions/CustomStepActions';

const DebitCardView: React.FC = () => {
  const cardInfo = useSelector((state: RootState) => state.Auth.currentUser?.cardDetails);
  const card_funds = useSelector((state: RootState) => state.Auth.currentUser?.card_funds);
  const [showCardForm, setShowCardForm] = useState(false);

  if (!cardInfo)
    if (!showCardForm)
      return (
        <Row align="top" className={Styles.cardWelcome}>
          <div>
            <img alt="Debit Card" src={debitCardIllustration}></img>
            <h1>Get your US Debit Card</h1>
            <p>
              Get a virtual US debit card with no hidden costs and pay your SEVIS fee in a matter of
              seconds.
            </p>
            <Button
              onClick={() => {
                setShowCardForm(true);
              }}
              type="primary">
              Generate Card <RightOutlined />
            </Button>
          </div>
        </Row>
      );
    else {
      return (
        <Alert
          className={Styles.formAlert}
          icon={<InfoCircleFilled />}
          type="info"
          showIcon
          message={'You need a US Debit Card to pay the fee.'}
          description={
            <>
              <p>
                Ut fuga enim aut eaque reprehenderit aut earum dolor aut quos corporis ea velit
                natus.
              </p>
              <CreateGradlyCardForm hideImage={true} />
            </>
          }></Alert>
      );
    }
  else
    return (
      <Row align="top" justify="start" className={Styles.cardDetails}>
        {card_funds && card_funds / 100 >= 350 ? (
          <>
            <Alert
              type="success"
              className={Styles.sFeeAlert}
              showIcon
              message={'Your US Debit Card has enough balance!'}></Alert>
            <CardImage />
            <CardInfo />
          </>
        ) : (
          <>
            <Alert
              type="error"
              icon={<ExclamationCircleOutlined />}
              className={Styles.iFeeAlert}
              showIcon
              message={"The SEVIS fee amounts to $350. You don't have enough balance."}></Alert>
            <CardImage />
            <CreateGradlyCardForm className={Styles.topupForm} topup={true} />
          </>
        )}
      </Row>
    );
};

interface PaySEVISFeeProps {
  step: PredefinedStep;
}

export const PaySEVISFee: React.FC<PaySEVISFeeProps> = (props) => {
  const { step } = props;
  const currentUser = useSelector((state: RootState) => state.Auth.currentUser);
  const [visible, setVisible] = useState(false);
  const [insVisible, setInsVisible] = useState(false);
  const insData = {
    title: (
      <>
        <SolutionOutlined /> {step.title}
      </>
    ),
    url: 'https://www.gradly.us/immigration',
    type: 'article' as 'article' | 'video',
    chatText: 'Chat with Immigration Expert',
    intercomMsg: `Hey! I've questions about SEVIS fee.`,
  };
  const [currentStep, setCurrentStep] = useState(step.status === StepStatus.Complete ? 2 : 0);

  const alert = {
    type: AlertType.Info,
    message: (
      <>
        You can pay your SEVIS fee following the steps below. To learn more about the SEVIS fee{' '}
        <a
          onClick={() => {
            setInsVisible(true);
          }}>
          read this article
        </a>
        .
      </>
    ),
  };

  useEffect(() => {
    if (window.location.pathname.includes(step.route)) {
      setVisible(true);
    }
  }, [window.location.pathname]);

  if (!currentUser) return null;
  const subSteps = [
    {
      title: 'Pay your SEVIS Fee',
      content: {
        left: (
          <Row align="top" className={Styles.step1}>
            <h1>Prepare your I-20 & payment method for $350.</h1>
            <h2>
              The SEVIS fee payment can only be made digitally using a valid US card with at least
              $350 in balance. Prepare your card, or use a Gradly US debit card as shown on your
              right.
            </h2>
            <Button
              type="primary"
              onClick={() => {
                setCurrentStep(1);
              }}>
              Continue <RightOutlined />
            </Button>
          </Row>
        ),
        right: <DebitCardView />,
      },
      spans: [13, 11],
      alert: alert,
    },
    {
      title: 'Pay your SEVIS Fee',
      content: {
        left: (
          <Row className={Styles.step2}>
            <h1>
              Click{' '}
              <a
                onClick={() => {
                  const newwindow = window.open(
                    'https://www.fmjfee.com',
                    'Pay Fee',
                    'height=600,width=950,left=200,top=100',
                  );
                  newwindow!.focus();
                }}>
                here
              </a>{' '}
              to open the SEVIS fee payment website.
            </h1>
            <h2>Once the modal opens, click on the button “Pay I-901 Fee” as shown below.”</h2>
            <img
              src="https://res.cloudinary.com/gradly/image/upload/v1652780675/assets/SEVIS_Fee_Step_2_fl1viw.gif"
              alt="step 2"></img>
            <Button
              type="primary"
              onClick={() => {
                setCurrentStep(2);
              }}>
              Continue <RightOutlined />
            </Button>
          </Row>
        ),
        right: <DebitCardView />,
      },
      spans: [13, 11],
      alert: alert,
    },
    {
      title: 'Pay your SEVIS Fee',
      content: {
        left: (
          <Row className={Styles.step3}>
            <h1>Fill in details from your I-20 and make the $350 payment.</h1>
            <h2>
              You can find your I-20 specific details, such as your SEVIS ID, as shown in the sample
              I-20 image below.
            </h2>
            <Image
              src="https://res.cloudinary.com/gradly/image/upload/v1652781941/assets/I-20_image_bg4gkh.png"
              preview={{
                style: { height: '70vh' },
                src: 'https://res.cloudinary.com/gradly/image/upload/v1652781941/assets/NewI20_l67gsp.jpg',
              }}
            />
            <div>
              <MarkAsCompleteButton
                status={step.status || StepStatus.Incomplete}
                uid={currentUser.id}
                route={[UserJourneyStageId.VisaApplication, step.id]}
              />
            </div>
          </Row>
        ),
        right: <DebitCardView />,
      },
      spans: [13, 11],
      alert: alert,
    },
  ];

  return (
    <>
      <InstructionalModal visible={insVisible} setVisible={setInsVisible} {...insData} />
      <AccordionModal
        width={1316}
        title={step.title}
        icon={<SolutionOutlined />}
        step={step}
        showMarkAsComplete={{
          uid: currentUser.id,
          route: [UserJourneyStageId.VisaApplication, step.id],
        }}
        visible={visible}
        onCompleted={() => setVisible(false)}
        onCancel={() => setVisible(false)}>
        <AssistanceCard
          footer={[]}
          steps={subSteps}
          currentStep={currentStep}
          containerClass={Styles.paysevisContainer}
          setCurrentStep={setCurrentStep}></AssistanceCard>
      </AccordionModal>
      <Accordion.Item
        key={step.route}
        icon="solution"
        highlighted
        status={step.status || StepStatus.Incomplete}
        title={step.title}
        onExpanded={() => {
          navigate!(`/${UserJourneyStageId.VisaApplication}/${step.route}`);
          setVisible(true);
        }}></Accordion.Item>
    </>
  );
};
