import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Accordion, AlertType, IntercomAPI, SlotBook } from '../../components';
import { bankNames, markStepsStatus, PredefinedStep, StepStatus } from '../../repos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake } from '@fortawesome/pro-regular-svg-icons';
import Styles from './MeetOfficial.module.sass';
import { Moment } from 'moment';
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  RightOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { FirestoreReference } from '../../utils';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { loanLockModalData, StepID } from './LoanStepContainer';
import { faCalendarExclamation } from '@fortawesome/pro-duotone-svg-icons';

interface MeetOfficialProps {
  uid: string;
  status: StepStatus;
  bid: string | undefined;
}

interface Agent {
  name: string;
  phone: string;
}

export const MeetOfficial: React.FC<MeetOfficialProps> = (props) => {
  const { uid, status, bid } = props;
  const [date, selectedDate] = useState<Moment>(moment());
  const [selectedSlot, setSelectedSlot] = useState<Moment>();
  const [slots, setSlots] = useState<Moment[]>([]);
  const [agent, setAgent] = useState<Agent | undefined>();
  const [address, setAddress] = useState<string | undefined>();

  useEffect(() => {
    if (bid) {
      if (!agent) {
        FirestoreReference.Slots()
          .doc('loanAgents')
          .collection(bid)
          .doc('agent')
          .get()
          .then((doc) => {
            setAgent(doc.data() as Agent);
          })
          .catch((err) => {
            console.error(err);
          });
      }
      FirestoreReference.Slots()
        .doc('loanAgents')
        .collection(bid)
        .doc(date.format('MM-DD-YYYY'))
        .get()
        .then((doc) => {
          setSlots(doc.data()?.slots?.map((slot: any) => moment(slot.toDate())) || []);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [date, setSlots, bid, agent]);

  useEffect(() => {
    FirestoreReference.LoanApplication(uid)
      .doc('identity')
      .get()
      .then((doc) => {
        setAddress(doc.data()?.residential_address as string);
      })
      .catch((err) => {
        console.error(err);
      });
    if (status === StepStatus.Complete) {
      FirestoreReference.LoanApplication(uid)
        .doc('slotInfo')
        .get()
        .then((doc) => {
          setSelectedSlot(moment(doc.data()?.slot.toDate()));
          setAgent(doc.data()?.agent as Agent);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [status]);

  function confirmSlot() {
    if (selectedSlot && agent) {
      FirestoreReference.LoanApplication(uid)
        .doc('slotInfo')
        .set({ agent: agent, slot: selectedSlot?.toDate() });
      markStepsStatus(uid, [UserJourneyStageId.Loans, StepID.MeetOfficial], StepStatus.Complete);
    }
  }

  if (status === StepStatus.Complete && agent) {
    return (
      <Row justify="center" align="middle" className={Styles.confirmContainer}>
        <Row align="middle" justify="center" className={Styles.successText}>
          <CheckCircleOutlined /> Congratulations! You’ve secured your education loan.
        </Row>
        <img
          src="https://res.cloudinary.com/gradly/image/upload/v1645447222/assets/agent_wkq3oi.png"
          alt="Agent"></img>
        <Row align="middle" justify="center" className={Styles.appDetails}>
          <p>
            Appointment with <b>{agent.name}</b> is confirmed for{' '}
            <span>
              {selectedSlot?.format('MMMM D, YYYY')} at {selectedSlot?.format('h A')}
            </span>
            .
          </p>
          <p>
            You can reach <b>{agent.name}</b> at <span>{agent.phone}.</span>{' '}
          </p>
        </Row>
      </Row>
    );
  } else
    return (
      <Row className={Styles.container}>
        <Col span={12}>
          <SlotBook
            setSelectedDate={selectedDate}
            setSelectedSlot={setSelectedSlot}
            slots={slots}
          />
        </Col>
        <Col span={12}>
          {slots.length !== 0 && (
            <Row className={Styles.finalInfo}>
              <Col span={22} offset={2}>
                <p>You’ll be visited at:</p>
              </Col>

              <Col span={2}>
                <EnvironmentOutlined />
              </Col>
              <Col span={22}>
                <span>{address || 'No address available contact support!'}</span>
              </Col>
            </Row>
          )}
          {slots.length !== 0 ? (
            <Row className={Styles.finalInfo}>
              <Col span={22} offset={2}>
                <p>Your chosen slot:</p>
              </Col>
              <Col span={2}>
                <ClockCircleOutlined />
              </Col>
              <Col span={22}>
                <span>{selectedSlot?.format('h:mm A, dddd, MMMM Do')}</span>
              </Col>
            </Row>
          ) : (
            <Row wrap={true} className={Styles.noSlots}>
              <FontAwesomeIcon icon={faCalendarExclamation} />
              <h1>No current slots available</h1>
              <h2>
                We are sorry, but no slots are available right now. You can request new slots below.
              </h2>
              <Button
                type="primary"
                onClick={() => {
                  IntercomAPI.showWithMessagePreFilled(
                    `Hello! I want to request slots to meet with the bank official to sign my loan documents.`,
                    false,
                  );
                }}>
                Request a slot <RightOutlined />
              </Button>
            </Row>
          )}
        </Col>
        <Button
          disabled={!selectedSlot || slots.length === 0}
          onClick={() => {
            confirmSlot();
          }}
          size="large"
          type={'primary'}>
          Confirm Slot <RightOutlined />
        </Button>
      </Row>
    );
};

export const MeetOfficialStep = (currentStep: PredefinedStep, uid: string, bid: string) => {
  const { status } = currentStep;
  return (
    <Accordion.Item
      key={currentStep.id}
      lockModal={loanLockModalData}
      icon={<FontAwesomeIcon icon={faHandshake}></FontAwesomeIcon>}
      alert={
        status !== StepStatus.Complete && bid
          ? {
              message: `${bankNames[bid]} official will visit you for document signature collection. Choose a slot from below.`,
              type: AlertType.Info,
            }
          : undefined
      }
      status={currentStep.status || StepStatus.Locked}
      title={currentStep.title}>
      <MeetOfficial
        uid={uid}
        status={currentStep.status || StepStatus.Locked}
        bid={bid}></MeetOfficial>
    </Accordion.Item>
  );
};
