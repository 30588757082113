export const visaIllustration = require('./visaIllustration.svg').default;
export const debitCardIllustration = require('./debitCard.png').default;
export const i20DownloadIllustration = require('./download.png').default;
export const crossVisa = require('./cross.png').default;
export const checkVisa = require('./check.png').default;
export const searchVisa = require('./search.png').default;
export const celebrationVisa = require('./celebration.png').default;
export const mockInterviewImage = require('./mockinterview.png').default;
export const boxCalculatorVisa = require('./box-calculator.png').default;
export const calendarVisa = require('./calendar.png').default;
export const circleProfileVisa = require('./circle-profile.png').default;
export const paperSearchVisa = require('./paper-search.png').default;
export const sendVisa = require('./send.png').default;
export const videoRecorderVisa = require('./video-recorder.png').default;
