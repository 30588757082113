import React, { useState, useEffect } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { Col, Row, Button } from 'antd';
import { IntercomAPI } from '../../components';
import Styles from './ErrorScreen.module.sass';

export const ErrorScreen: React.FC = () => {
	return <Row align="middle">
		<Col className={Styles.errorText} xs={24} lg={12}>
			<h1>Uh-oh! We encountered an error.</h1>
			<p>Please message us below to request access to plans for your university. </p>
			<Button type="primary" onClick={() => {
				IntercomAPI.showWithMessagePreFilled(
					`Hey! I have questions about insurance plans for my University.`,
					false,
				);
			}}>Request Access <RightOutlined /></Button>
		</Col>
		<Col xs={24} lg={12}>
			<img className={Styles.illustration} alt="Empty" src="https://res.cloudinary.com/gradly/image/upload/v1658575867/assets/insuranceIllus/9_efvfbe.png"></img>
		</Col>
	</Row>
}