import { store } from '../redux/store';
import { StepsStatus, StepStatus } from '../repos';

const getProp: any = (object: any, path: string[]) => {
  if (path.length === 1) return object[path[0]];
  else if (path.length === 0) return;
  else {
    if (object[path[0]]) return getProp(object[path[0]], path.slice(1));
    else {
      object[path[0]] = {};
      return getProp(object[path[0]], path.slice(1));
    }
  }
};

export const getStepStatus = (stepRoute: string[]) => {
  const stepsStatus = Object.assign({}, store.getState().Content.stepsStatus);
  try {
    return getProp(stepsStatus, stepRoute)?.status || StepStatus.Pending;
  } catch (e) {
    console.error('Error in getStepStatus', e);
  }
  return StepStatus.Pending;
};
