import {
  QuestionCircleOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  MessageFilled,
} from '@ant-design/icons';
import { Button, Col, Divider, Image, Modal, Row } from 'antd';
import React from 'react';
import { IntercomAPI } from '..';
import Styles from './DocsHelpModal.module.sass';

interface DocsHelpProps {
  helpData: any;
  showHelp: boolean;
  setShowHelp: (showHelp: boolean) => void;
}

export interface HelpDataType {
  [key: string]: {
    title: string;
    image?: string;
    subheading?: string;
    description?: string;
    dos?: string[];
    donts?: string[];
    column?: string;
  };
}

export const DocsHelpModal: React.FC<DocsHelpProps> = (props) => {
  const { showHelp, setShowHelp, helpData } = props;

  if (helpData) {
    helpData.dos = helpData.dos || [];
    helpData.donts = helpData.donts || [];
    return (
      <Modal
        centered
        className={Styles.modal}
        onCancel={() => {
          setShowHelp(false);
        }}
        title={
          <div className={Styles.title}>
            <QuestionCircleOutlined />
            {helpData.title}
          </div>
        }
        footer={null}
        closable
        destroyOnClose
        maskClosable
        visible={showHelp}>
        {helpData.image ? (
          <Row justify="center" className={Styles.imgContainer}>
            <Image
              preview={false}
              placeholder={<Image preview={false} src="error" />}
              src={helpData.image}
              alt={helpData.title}></Image>
          </Row>
        ) : null}
        <Row className={Styles.detailsContainer}>
          <h3>{helpData.subheading}</h3>
          {helpData.description && <h4>What is it?</h4>}
          <p>{helpData.description}</p>
          {helpData.image ? <Divider></Divider> : null}
          <Row>
            {!helpData.donts.length === !helpData.dos.length ? null : (
              <Col span={24}>
                <h4>Instructions</h4>
              </Col>
            )}
            {helpData.dos.length > 0 && (
              <Col span={helpData.donts.length > 0 ? 12 : 24}>
                {helpData.donts.length > 0 && <Row justify="center">Do's</Row>}
                <ul className={Styles.liDos}>
                  {helpData.dos.map((d: string, i: number) => (
                    <li key={i}>
                      <CheckCircleFilled /> {d}
                    </li>
                  ))}
                </ul>
              </Col>
            )}
            {helpData.donts.length > 0 && (
              <Col span={helpData.dos.length > 0 ? 12 : 24}>
                {helpData.dos.length > 0 && <Row justify="center">Dont's</Row>}
                <ul className={Styles.liDonts}>
                  {helpData.donts.map((d: string, i: number) => (
                    <li key={i}>
                      <CloseCircleFilled />
                      {d}
                    </li>
                  ))}
                </ul>
              </Col>
            )}
          </Row>
          <Row justify="end" align="middle" className={Styles.helpButton}>
            <span>Need more help?</span>
            <Button
              size="large"
              icon={<MessageFilled />}
              onClick={() => {
                IntercomAPI.showWithMessagePreFilled(
                  `Hey! I have questions about ${helpData.title} document.`,
                  false,
                );
              }}>
              Chat with us
            </Button>
          </Row>
        </Row>
      </Modal>
    );
  } else return null;
};
