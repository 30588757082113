import { Link, RouteComponentProps } from '@reach/router';
import { CheckCircleOutlined, LoadingOutlined, MessageOutlined } from '@ant-design/icons';
import { Avatar, Button, message, Modal, Popover } from 'antd';
import { PopoverProps } from 'antd/lib/popover';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { submitSopStudyTable } from '../../assets';
import { AccordionItemPublicProps, Alert, AlertType, HtmlToReact } from '../../components';
import { UserJourneyTitle } from '../../config/userJourneyMap';
import {
  markCurrentReviewAsSeen,
  markHelpItems,
  RootState,
  submitHelpRequest,
  submitSopReviewRating,
} from '../../redux';
import {
  ContentType,
  SopReviewComment,
  SopTag,
  SopTagAnnotation,
  UserProfile,
  UserSopVersion,
  Video,
} from '../../repos';
import { Analytics, AnalyticsEventName, DOMHelpers, Typeforms } from '../../utils';
import Styles from './ReviewFeedback.module.sass';
import moment from 'moment';
import { Widget } from '@typeform/embed-react';

class InformationPopover extends React.Component<PopoverProps> {
  state = {
    visible: false,
  };

  closeTooltip = () => this.setState({ visible: false });

  render() {
    return (
      <Popover
        visible={this.state.visible}
        onVisibleChange={(visible) => this.setState({ visible })}
        {...this.props}
      />
    );
  }
}

interface GeneralComment {
  title?: string;
  textInHtml?: string;
  commentInHtml?: string;
  startIndex: number;
  endIndex: number;
  markedForHelp?: boolean;
}

const processAndMarkForHelp = (
  sopVersion: UserSopVersion,
  comment: GeneralComment,
  comments: SopReviewComment[],
  tagAnnotations: SopTagAnnotation[],
  markForHelp: typeof markHelpItems,
  setShowMoreModalData?: React.Dispatch<
    React.SetStateAction<{ comment: GeneralComment; sopVersion: UserSopVersion } | undefined>
  >,
) => {
  const { startIndex, endIndex } = comment;
  const markedComments = comments.map((comment) => {
    if (comment.startIndex === startIndex && comment.endIndex === endIndex) {
      const mutable = { ...comment, markedForHelp: true };
      return mutable;
    }
    return comment;
  });
  const markedTagAnnotations = tagAnnotations.map((tag) => {
    if (tag.startIndex === startIndex && tag.endIndex === endIndex) {
      const mutable = { ...tag, markedForHelp: true };
      return mutable;
    }
    return tag;
  });
  setShowMoreModalData &&
    setShowMoreModalData({ sopVersion, comment: { ...comment, markedForHelp: true } });
  markForHelp(sopVersion.id, markedComments, markedTagAnnotations);
};

const renderContent = (
  sopVersion: UserSopVersion,
  markForHelp: typeof markHelpItems,
  sopTags: { [key: string]: SopTag } | undefined,
  setShowMoreModalData: React.Dispatch<
    React.SetStateAction<{ comment: GeneralComment; sopVersion: UserSopVersion } | undefined>
  >,
) => {
  const { text = '', tagAnnotations = [], comments = [] } = sopVersion;

  const collectableElements: React.ReactElement[] = [];
  let lastIndex = 0;

  const allComments: GeneralComment[] = tagAnnotations
    .filter((tag) => !!sopTags && !!sopTags[tag.tagId])
    .map((tag) => ({
      id: `${tag.startIndex}-${tag.endIndex}`,
      ...tag,
      textInHtml: sopTags![tag.tagId].textInHtml,
      title: sopTags![tag.tagId].label,
    }));

  allComments.push(...comments);
  allComments.sort((a, b) => a.startIndex - b.startIndex);
  let substringTextBlocks = [];

  for (const comment of allComments) {
    const { startIndex, endIndex, textInHtml } = comment;

    substringTextBlocks = text.substring(lastIndex, startIndex).split('\n');
    for (let idx = 0; idx < substringTextBlocks.length; idx += 1) {
      collectableElements.push(
        <span key={`${lastIndex}-${startIndex}-${idx}`}>{substringTextBlocks[idx]}</span>,
      );
      idx !== substringTextBlocks.length - 1 &&
        collectableElements.push(
          <br key={`br-${lastIndex}-${startIndex}-${idx}-1`} />,
          <br key={`br-${lastIndex}-${startIndex}-${idx}-2`} />,
        );
    }
    let toolTip: InformationPopover | null = null;
    substringTextBlocks = text.substring(startIndex, endIndex).split('\n');
    for (let idx = 0; idx < substringTextBlocks.length; idx += 1) {
      collectableElements.push(
        <InformationPopover
          key={`popover-${startIndex}-${endIndex}-${idx}`}
          ref={(ref) => (toolTip = ref)}
          placement="top"
          title={null}
          content={
            textInHtml ? (
              renderTagAndComment(comment, sopVersion, markForHelp, setShowMoreModalData, {
                onShowMoreClicked: () => {
                  toolTip!.closeTooltip();
                  setShowMoreModalData({ comment, sopVersion });
                },
                tagContentHeight: '15vh',
                commentHeight: '10vh',
              })
            ) : (
              <div
                style={{
                  width: 300,
                  height: 300,
                  display: 'flex',
                  flexDirection: 'row',
                  backgroundColor: 'white',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <LoadingOutlined spin />
                <div style={{ marginLeft: 12 }}>Loading</div>
              </div>
            )
          }
          trigger={'click'}>
          <span key={`${startIndex}-${endIndex}`} className={Styles.highlighted}>
            {substringTextBlocks[idx]}
          </span>
        </InformationPopover>,
      );
      idx !== substringTextBlocks.length - 1 &&
        collectableElements.push(
          <br key={`br-popover-${startIndex}-${endIndex}-${idx}-1`} />,
          <br key={`br-popover-${startIndex}-${endIndex}-${idx}-2`} />,
        );
    }
    lastIndex = endIndex;
  }

  substringTextBlocks = text.substring(lastIndex, text.length).split('\n');
  for (let idx = 0; idx < substringTextBlocks.length; idx += 1) {
    collectableElements.push(
      <span key={`${lastIndex}-${text.length}-${idx}`}>{substringTextBlocks[idx]}</span>,
    );
    idx !== substringTextBlocks.length - 1 &&
      collectableElements.push(
        <br key={`br-${lastIndex}-${text.length}-${idx}-1`} />,
        <br key={`br-${lastIndex}-${text.length}-${idx}-2`} />,
      );
  }

  return collectableElements;
};

const renderTagAndComment = (
  comment: GeneralComment,
  sopVersion: UserSopVersion,
  markForHelp: typeof markHelpItems,
  setShowMoreModalData: React.Dispatch<
    React.SetStateAction<{ comment: GeneralComment; sopVersion: UserSopVersion } | undefined>
  >,
  config: {
    tagContentHeight: string;
    commentHeight: string;
    onShowMoreClicked?: () => void;
  },
) => {
  const { comments = [], tagAnnotations = [] } = sopVersion;
  const { textInHtml, title = 'Comment', markedForHelp, commentInHtml } = comment;
  const { onShowMoreClicked } = config;
  return (
    <div className={Styles.popoverContainer}>
      <span className={Styles.popOverTitle}>{title}</span>
      <div className={Styles.separator}></div>
      <HtmlToReact className={Styles.htmlContent} htmlString={textInHtml || ''} />
      {commentInHtml && (
        <div className={Styles.commentContainer}>
          <div className={Styles.heading}>
            <MessageOutlined className={Styles.icon} />
            <div className={Styles.title}>COMMENTS</div>
          </div>
          <div className={Styles.comment}>
            <Avatar
              className={Styles.avatar}
              src="https://firebasestorage.googleapis.com/v0/b/gradly-production.appspot.com/o/contentAuthorHeadshots%2Fwxi9Y4nt4FpejZAXtOWY.png?alt=media&token=7a2dc7d8-ceb5-4cd2-8476-3f2bf6c93ec0"
              alt="Rishabh Singh"
            />
            <div className={Styles.description}>
              <div className={Styles.name}>Rishabh Singh</div>
              <HtmlToReact
                className={Styles.content}
                style={{ maxHeight: config.commentHeight }}
                htmlString={commentInHtml}
              />
            </div>
          </div>
        </div>
      )}
      <div className={Styles.buttonsContainer}>
        {markedForHelp ? (
          <div className={Styles.markedForHelp}>
            <CheckCircleOutlined className={Styles.markIcon} />
            <div className={Styles.text}>Marked for help</div>
          </div>
        ) : (
          <Button
            onClick={() =>
              processAndMarkForHelp(
                sopVersion,
                comment,
                comments,
                tagAnnotations,
                markForHelp,
                onShowMoreClicked ? undefined : setShowMoreModalData,
              )
            }>
            Need Help
          </Button>
        )}
        {onShowMoreClicked && (
          <Button
            className={Styles.readMoreButton}
            type="primary"
            onClick={() => onShowMoreClicked()}>
            Read More
          </Button>
        )}
      </div>
    </div>
  );
};
interface ReviewFeedbackProps extends RouteComponentProps, Partial<AccordionItemPublicProps> {
  sopTags?: { [key: string]: SopTag };
  sopVersion?: UserSopVersion;
  currentUser?: UserProfile;
  videos: Video[];
  submitSopReviewRating: typeof submitSopReviewRating;
  markCurrentReviewAsSeen: typeof markCurrentReviewAsSeen;
  markHelpItems: typeof markHelpItems;
  submitHelpRequest: typeof submitHelpRequest;
}

const ReviewFeedback: React.FC<ReviewFeedbackProps> = (props) => {
  const {
    sopVersion,
    sopTags,
    currentUser,
    videos,
    markCurrentReviewAsSeen,
    markHelpItems,
    submitHelpRequest,
  } = props;
  const [showHelpForm = false, setShowingHelpForm] = useState<boolean>();
  const [showMoreModalData, setShowMoreModalData] = useState<{
    comment: GeneralComment;
    sopVersion: UserSopVersion;
  }>();
  // TODO: This is a hack to stop the error, fix it properly
  const [
    selectedVideo = videos[0] || {
      videoId: 'fasdfasdfads',
      title: 'Empty title',
      createdAt: moment(new Date()),
    },
    setSelectedVideo,
  ] = useState<any>();

  useEffect(() => {
    if (sopVersion && sopVersion.feedbackSubmitted && !sopVersion.reviewSeen) {
      Analytics.log(AnalyticsEventName.SOPRequestReviewFeedbackViewed);
      markCurrentReviewAsSeen(sopVersion.id);
    }
  }, [sopVersion]);

  useEffect(() => {
    if (!selectedVideo) {
      return;
    }
    const url = `https://fast.wistia.com/embed/medias/${selectedVideo.videoId}.jsonp`;
    if (!DOMHelpers.isScriptAlreadyLoaded(url)) {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
    }
  }, [selectedVideo && selectedVideo.videoId]);

  if (!sopVersion) {
    return (
      <div className={Styles.incompleteContainer}>
        <div className={Styles.incompleteContent}>
          <p>
            Once we leave feedback on your SOP, you will be able to read our specific comments as
            well as the exact "type" of error you may have made in your SOP draft. Further, with
            each comment, you will find a link to read more about it, so that you can go beyond just
            understanding <strong>what</strong> needs to change in your SOP to actually
            understanding <strong>how</strong> you can change it.
          </p>
          <p>
            If after this you still need help re-writing a part of your SOP, you can mark a specific
            comment for help in this section itself and we will reach out to you personally.
          </p>
        </div>
        <img src={submitSopStudyTable} />
      </div>
    );
  }

  if (!sopVersion.feedbackSubmitted) {
    return (
      <div className={Styles.processingContainer}>
        <div className={Styles.content}>
          <p>
            While you wait for the feedback, you can{' '}
            <Link style={{ fontWeight: 600 }} to="/sop-review/attend-webinar">
              register for our webinar
            </Link>{' '}
            and watch the videos we created about SOP writing. In these videos we cover the
            following topics:
          </p>
          <ul>
            {videos.map((video) => {
              return (
                <li key={video.title} onClick={() => setSelectedVideo(video)}>
                  {video.title}
                </li>
              );
            })}
          </ul>
        </div>
        <div key={selectedVideo.videoId} className={Styles.videoCarousel}>
          <div
            onClick={() =>
              Analytics.log(AnalyticsEventName.ContentConsumed, {
                associatedStage: UserJourneyTitle.SopReview,
                metadata: { contentId: selectedVideo.videoId, contentType: ContentType.Video },
              })
            }
            className="wistia_responsive_padding"
            style={{ height: '100%', width: '100%', position: 'relative', marginBottom: -74 }}>
            <div
              className="wistia_responsive_wrapper"
              style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}>
              <span
                className={`wistia_embed wistia_async_${selectedVideo.videoId} popover=true`}
                style={{
                  display: 'inline-block',
                  height: '100%',
                  position: 'relative',
                  width: '100%',
                }}>
                &nbsp;
              </span>
            </div>
          </div>
          <div className={Styles.description}>
            <div className={Styles.title}>{selectedVideo.title}</div>
            <div className={Styles.date}>{selectedVideo.createdAt.format('Do MMMM YYYY')}</div>
          </div>
        </div>
      </div>
    );
  }

  const { comments = [], tagAnnotations = [], moreHelpRequested } = sopVersion;

  const helpItemsCount =
    comments.filter((comment) => comment.markedForHelp).length +
    tagAnnotations.filter((tag) => tag.markedForHelp).length;

  const requestHelp = () => {
    if (!sopVersion) {
      return;
    }
    submitHelpRequest(sopVersion.id);
  };

  const renderSubmitButton = () => {
    if (sopVersion.moreHelpRequested) {
      return null;
    }
    return (
      <Button
        className={Styles.submitButton}
        onClick={() => {
          if (helpItemsCount !== 0) {
            Analytics.log(AnalyticsEventName.SOPRequestReviewAdditionalHelpRequestStarted);
            setShowingHelpForm(true);
            return;
          }
          const messageString =
            'We have received your request for additional help, ' + "we'll get in touch shortly.";
          message.success(messageString, 8000);
          Analytics.log(AnalyticsEventName.SOPRequestReviewAdditionalHelpRequestSubmitted);
          requestHelp();
        }}
        type="primary">
        {helpItemsCount === 0 ? 'Mark as Complete' : 'Submit Request'}
      </Button>
    );
  };

  const renderHelpItemsAlert = () => {
    if (moreHelpRequested) {
      return;
    }
    let message = 'You currently have 0 items marked for help.';
    if (helpItemsCount !== 0) {
      message = `You have indicated that you need help with ${helpItemsCount} item${
        helpItemsCount === 1 ? '' : 's'
      }. Please press the Submit Request button to submit your request.`;
    }
    return <Alert style={{ marginBottom: 24 }} message={message} type={AlertType.Ready} />;
  };
  const typeform = Typeforms.SopHelpForm(
    (currentUser && (currentUser.name || '').split(' ')[0]) || 'Gradly User',
    helpItemsCount.toString(),
  );

  return (
    <div className={Styles.feedbackContainer}>
      {renderHelpItemsAlert()}
      <p className={Styles.description}>
        <span>Here is feedback about your SOP.</span>
        <span> To get help with any comment, just hover on the comment and press </span>
        <strong>Need help </strong>
        <span>button. After you have finished marking your help items, press </span>
        <strong>Submit Request </strong>
        <span>to send it to us.</span>
        <span>If you don't need any further help press </span>
        <strong>Mark as Complete</strong>
      </p>
      <div className={Styles.feedbackContent}>
        {renderContent(sopVersion, markHelpItems, sopTags, setShowMoreModalData)}
      </div>
      {renderSubmitButton()}
      <Modal
        footer={null}
        width={348}
        visible={showMoreModalData !== undefined}
        onCancel={() => setShowMoreModalData(undefined)}>
        {showMoreModalData &&
          renderTagAndComment(
            showMoreModalData.comment,
            showMoreModalData.sopVersion,
            markHelpItems,
            setShowMoreModalData,
            { tagContentHeight: '35vh', commentHeight: '20vh' },
          )}
      </Modal>
      <Modal
        footer={null}
        width={'80vw'}
        visible={showHelpForm}
        onCancel={() => setShowingHelpForm(false)}>
        <Widget
          id={typeform.id}
          hidden={typeform.hidden}
          onSubmit={() => {
            const messageString =
              'We have received your request for additional help, ' + "we'll get in touch shortly.";
            message.success(messageString, 7);
            setTimeout(() => {
              requestHelp();
              setShowingHelpForm(false);
            }, 1500);
          }}
          style={{ height: '70vh' }}
          hideHeaders
          hideFooter
          opacity={100}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { sopVersion, sopTags } = state.SopReview;
  const { currentUser } = state.Auth;
  const { contentItems = [] } = state.Content;
  const contentItemsForStep = contentItems
    .filter(
      (item) => item.associatedSteps.includes('sop-review') && item.type === ContentType.Video,
    )
    .map((content) => content as Video);
  return {
    sopVersion,
    sopTags,
    currentUser: currentUser ? currentUser : undefined,
    videos: contentItemsForStep,
  };
};

export default connect(mapStateToProps, {
  submitSopReviewRating,
  markCurrentReviewAsSeen,
  markHelpItems,
  submitHelpRequest,
})(ReviewFeedback);
