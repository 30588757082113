import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button } from 'antd';
import React from 'react';
import { FulfillmentResponse } from '../../../repos';
import { Colors } from '../../../theme';
import Styles from '../CustomStep.module.sass';
import { HtmlToReact } from '../../HtmlToReact';

interface Props {
  response: FulfillmentResponse;
  className?: string;
  primaryButton?: {
    text: string;
    onClick(): void;
  };
}

const getFileIconAndColor = (type: string) => {
  switch (true) {
    case type.includes('pdf'):
      return { type: 'file-pdf', color: Colors.Orange1000 };
    case type.includes('img') || type.includes('image'):
      return { type: 'file-image', color: Colors.Teal1000 };
    case type.includes('txt') || type.includes('text'):
      return { type: 'file-text', color: Colors.Neutral600 };
    case type.includes('zip') || type.includes('octet-stream'):
      return { type: 'file-zip', color: Colors.Yellow600 };
    case type.includes('excel') || type.includes('spreadsheet') || type.includes('xls'):
      return { type: 'file-excel', color: Colors.Teal600 };
    case type.includes('word') || type.includes('doc'):
      return { type: 'file-word', color: Colors.Primary600 };
    default:
      return { type: 'file', color: '' };
  }
};

export const StepFulfillmentView: React.FC<Props> = (props) => {
  const { response, primaryButton, className } = props;
  if (!response) return <></>;
  const numberOfAttachments = response.attachments?.length;
  return (
    <div className={`${Styles.content} ${className}`}>
      {numberOfAttachments > 0 && (
        <span className={Styles.subTitle}>Attachments ({numberOfAttachments})</span>
      )}
      {numberOfAttachments > 0 && (
        <div className={Styles.attachmentsContainer}>
          {response.attachments.map((attachment) => {
            const icon = getFileIconAndColor(attachment.type);
            return (
              <div
                className={Styles.attachment}
                onClick={() => window.open(attachment.url, '_blank')}>
                <LegacyIcon
                  className={Styles.icon}
                  type={icon.type}
                  style={{ color: icon.color }}
                />
                <span className={Styles.fileName}>{attachment.title}</span>
              </div>
            );
          })}
        </div>
      )}
      {response.htmlString && <span className={Styles.subTitle}>Comments</span>}
      {response.htmlString && <HtmlToReact htmlString={response.htmlString} />}
      {primaryButton && (
        <Button type="primary" onClick={primaryButton.onClick}>
          {primaryButton.text}
        </Button>
      )}
    </div>
  );
};
