// Code Reference - https://github.com/nhagen/react-intercom/
import PropTypes from 'prop-types';
import { Component } from 'react';
const windowAsAny = window as any;
const canUseDOM = !!(typeof windowAsAny !== 'undefined' && windowAsAny.document && windowAsAny.document.createElement);

interface Props {
  appID: string;
  [key: string]: any;
}

// https://developers.intercom.com/installing-intercom/docs/intercom-javascript
// const IntercomAPIApplier = (...args: any) => {
//   if (canUseDOM && windowAsAny.Intercom) {
//     windowAsAny.Intercom.apply(null, args);
//   } else {
//     console.warn('Intercom not initialized yet');
//   }
// };

const IntercomAPIApplier = (...args: any) => {
  if (canUseDOM && windowAsAny.FrontChat) {
    windowAsAny.FrontChat.apply(null, args);
  } else {
    console.warn('FrontChat not initialized yet');
  }
};

export const IntercomAPI = {
  showWithMessagePreFilled: (message?: string, endWithNewLines = true) =>
    IntercomAPIApplier('show'),
  hide: () => IntercomAPIApplier('hide'),
  show: () => IntercomAPIApplier('show'),
  showTour: (tourID: number) => IntercomAPIApplier('showTour', tourID),
};

export default class Intercom extends Component {
  static propTypes = {
    appID: PropTypes.string.isRequired,
  };

  static displayName = 'Intercom';

  constructor(props: Props) {
    super(props);

    const { appID, ...otherProps } = props;

    if (!appID || !canUseDOM) {
      return;
    }

    if (!windowAsAny.Intercom) {
      (function () {
        const w = windowAsAny;
        const ic = w.Intercom;
        if (typeof ic === 'function') {
          ic('reattach_activator');
          ic('update', w.intercomSettings);
        } else {
          const d = document;
          const i: any = function () {
            i.c(arguments);
          };
          i.q = [];
          i.c = function (args: any) {
            i.q.push(args);
          };
          w.Intercom = i;
          const l = function () {
            const s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.intercom.io/widget/' + appID;
            const x: any = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === 'complete') {
            l();
          } else if (w.attachEvent) {
            w.attachEvent('onload', l);
          } else {
            w.addEventListener('load', l, false);
          }
        }
      })();
    }

    windowAsAny.intercomSettings = { ...otherProps, app_id: appID };

    if (windowAsAny.Intercom) {
      windowAsAny.Intercom('boot', otherProps);
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const { appID, ...otherProps } = nextProps;

    if (!canUseDOM) return;

    windowAsAny.intercomSettings = { ...otherProps, app_id: appID };

    if (windowAsAny.Intercom) {
      if (this.loggedIn(this.props) && !this.loggedIn(nextProps)) {
        // Shutdown and boot each time the user logs out to clear conversations
        windowAsAny.Intercom('shutdown');
        windowAsAny.Intercom('boot', otherProps);
      } else {
        windowAsAny.Intercom('update', otherProps);
      }
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    if (!canUseDOM || !windowAsAny.Intercom) return false;

    windowAsAny.Intercom('shutdown');

    delete windowAsAny.Intercom;
    delete windowAsAny.intercomSettings;
  }

  loggedIn(props: any) {
    if (props.email) return props.email || props.user_id;
  }

  render() {
    return false;
  }
}
