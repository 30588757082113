import { of } from 'rxjs';
import { filter, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { RootEpic } from '.';
import { UserRepo } from '../../repos';
import { catchAndReportError } from '../../utils';
import { ProfileActionTypes, setError, setMessage } from '../actions';

export const setAcademicInformationEpic: RootEpic = $action => {
  return $action;
};

export const updateUserProfileEpic: RootEpic = ($action, store) => {
  return $action.pipe(
    filter(isOfType(ProfileActionTypes.UpdateUserProfile)),
    withLatestFrom(store),
    switchMap(([action, state]) => {
      const { currentUser } = state.Auth;
      if (!currentUser) {
        return of(setError('Something went wrong. Please sign in again'));
      }
      const { userProfile } = action.payload;
      return UserRepo.updateUserProfile(currentUser.id, userProfile).pipe(
        mapTo(setMessage('Thank you for the information, your Gradly account is ready to go!')),
        catchAndReportError(error => of(setError(error.message))),
      );
    }),
  );
};

export const updateAcademicInformationEpic: RootEpic = ($action, store) => {
  return $action.pipe(
    filter(isOfType(ProfileActionTypes.UpdateAcademicInformation)),
    withLatestFrom(store),
    switchMap(([action, state]) => {
      const { currentUser } = state.Auth;
      if (!currentUser) {
        return of(setError('Something went wrong. Please sign in again'));
      }
      const { academicInformation } = action.payload;
      return UserRepo.updateUserAcademicInformation(currentUser.id, academicInformation).pipe(
        mapTo(setMessage()),
        catchAndReportError(error => of(setError(error.message))),
      );
    }),
  );
};

export const updateApplicationInterestEpic: RootEpic = ($action, store) => {
  return $action.pipe(
    filter(isOfType(ProfileActionTypes.UpdateApplicationInterest)),
    withLatestFrom(store),
    switchMap(([action, state]) => {
      const { currentUser } = state.Auth;
      if (!currentUser) {
        return of(setError('Something went wrong. Please sign in again'));
      }
      const { applicationInterest } = action.payload;
      return UserRepo.updateUserApplicationInterest(currentUser.id, applicationInterest).pipe(
        mapTo(setMessage()),
        catchAndReportError(error => of(setError(error.message))),
      );
    }),
  );
};

export const updateUserApplicationsEpic: RootEpic = ($action, store) => {
  return $action.pipe(
    filter(isOfType(ProfileActionTypes.UpdateUserApplications)),
    withLatestFrom(store),
    switchMap(([action, state]) => {
      const { currentUser } = state.Auth;
      if (!currentUser) {
        return of(setError('Something went wrong. Please sign in again'));
      }
      const { applications } = action.payload;
      return UserRepo.updateUserApplications(currentUser.id, applications).pipe(
        mapTo(setMessage()),
        catchAndReportError(error => of(setError(error.message))),
      );
    }),
  );
};
