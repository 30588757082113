import React, { useState, useEffect } from 'react';

import { CheckOutlined, ForwardFilled } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import Styles from './ForexStepContainer.module.sass';
import { FirestoreReference } from '../../utils';
import { GradlyLoading } from '../../App';

interface CompareDataTypes {
  logo: string;
  name: string;
  rgets: string;
  timeTaken: string;
  diff?: string;
  cheapest?: boolean;
  exchangeRateINRToUSD: string;
}

const ForexRatesTable: React.FC<any> = (props) => {
  const { expanded, setExpanded } = props;
  const [compareData, setCompareData] = useState<CompareDataTypes[] | undefined>(undefined);
  useEffect(() => {
    FirestoreReference.Forex()
      .doc('forexRates')
      .get()
      .then((doc) => {
        setCompareData(doc.data()?.compareData as CompareDataTypes[]);
      })
      .catch((err) => {});
  }, []);
  if (compareData)
    return (
      <div
        style={expanded ? { margin: 0, height: 800, padding: 48 } : {}}
        className={Styles.ratesContainer + ' animate__animated animate__fadeIn'}>
        <h1>Compare with live forex rates.</h1>
        {expanded ? (
          <div style={{ height: 44 }}></div>
        ) : (
          <Button
            type="link"
            onClick={() => {
              setExpanded(true);
            }}>
            View all <ForwardFilled />
          </Button>
        )}
        <div className={Styles.tableContainer}>
          <ul className={Styles.leftUL}>
            <li>
              <Row justify="center" align="middle">
                <Col span="12">
                  <p>Sending 100,000.00 INR with</p>
                </Col>
                <Col span="12">
                  <p>Receipient gets</p>
                  <span>(Total after fees)</span>
                </Col>
              </Row>
            </li>
            {(expanded ? compareData : compareData.slice(0, 3)).map((data) => (
              <li key={data.name}>
                {data.cheapest ? (
                  <span className={Styles.cheapestTag}>
                    <CheckOutlined /> <span>Cheapest</span>
                  </span>
                ) : (
                  <></>
                )}
                <Row justify="center" align="middle">
                  <Col span="12">
                    <img src={data.logo} alt={data.name}></img>
                  </Col>
                  <Col span="12">
                    {data.rgets}
                    {data.diff ? <span>▼ {data.diff} </span> : ''}
                  </Col>
                </Row>
              </li>
            ))}
          </ul>
          <ul className={Styles.rightUL}>
            <li>
              <Row justify="center" align="middle">
                <Col span="10">
                  <p>Time Taken</p>
                  <span>(in hours)</span>
                </Col>
                <Col span="14">
                  <p>Today’s Exchange Rate</p>
                  <span>{`(1USD -> INR)`}</span>
                </Col>
              </Row>
            </li>
            {(expanded ? compareData : compareData.slice(0, 3)).map((data) => (
              <li key={data.name}>
                <Row justify="center" align="middle">
                  <Col span="10">~ {data.timeTaken}</Col>
                  <Col span="14">
                    {data.exchangeRateINRToUSD} <span className={Styles.redDot}></span>
                  </Col>
                </Row>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  else return null;
};
export default ForexRatesTable;
