import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { algoliaClient, AlgoliaIndexName } from '../../services';
import { VisaType } from '../../redux/reducers/InsuranceReducer';

export interface PlanMapping {
  planID: string;
  premiums?: Premium[];
}
export interface Premium {
  minAge: number;
  maxAge: number;
  retailPrice: number;
  insuranceCompanyPrice: number;
  retailPriceHC: number;
  insuranceCompanyPriceHC: number;
}
export interface PlanType {
  planID: string;
  policyID: string;
  policyName: string;
  retailName: string;
  insuranceCompany: 'GBG' | 'WellAway';
  isACA: boolean;
  premiums: Premium[];
  recommended?: boolean;
  brochureURL: string;
  fullBrochureURL?: string;
  year?: number;
  visaType: VisaType;
  planFeatures: {
    deductible: number;
    idCardPolicy: string;
    isDeductibleAnnual?: boolean;
    coInsurance: number;
    officeVisitCoPay: number;
    outOfPocketMax: string;
    idCardType?: string;
  };
}
export interface University {
  id: string;
  name: string;
  gbgDestinationCountry: string;
  gbgDestinationState: string;
  plans: PlanMapping[];
  waiverDeadline: string;
  stateCode: string;
  fallStartDate?: string;
  fallEndDate?: string;
  customDates?: boolean;
  graduatingStartDate?: string;
  graduatingEndDate?: string;
  annualStartDate?: string;
  annualEndDate?: string;
  springStartDate?: string;
  springEndDate?: string;
  winterStartDate?: string;
  winterEndDate?: string;
  summerStartDate?: string;
  summerEndDate?: string;
  waiverGuide?: string;
  waiverGuideGBG?: string;
  url?: string;
  verified: boolean;
  noWaiver?: boolean;
}

export const searchUniversity = (query: string) => {
  return from(
    algoliaClient.initIndex(AlgoliaIndexName.Universities).search<University>(query, {
      hitsPerPage: 10,
      filters: 'verified:true',
    }),
  ).pipe(
    map((response) => {
      return response.hits.map((hit) => ({ ...hit, id: (hit as any).objectID }));
    }),
  );
};
