import React, { useState } from 'react';

import { RightOutlined, CheckCircleFilled, ForwardFilled } from '@ant-design/icons';
import { Row, Col, Button } from 'antd';
import Styles from './WelcomeScreen.module.sass';
import { bankLogos } from '../../repos';
import { WelcomeScreenModal } from './WelcomeScreenModal';
import { stageIcons } from './../../repos/Common/Common';

interface CompareDataTypes {
  logo: any;
  interest_rate: number;
  margin_money: number;
  hidden_fees: number;
}
export const WelcomeScreen: React.FC<any> = (props) => {
  const [showAllOffers, setShowAllOffers] = useState(false);
  const compareData: CompareDataTypes[] = [
    {
      logo: bankLogos['axis'],
      interest_rate: 8.9,
      margin_money: 15,
      hidden_fees: 2,
    },
    {
      logo: bankLogos['avanse'],
      interest_rate: 8.4,
      margin_money: 12,
      hidden_fees: 4,
    },
    {
      logo: bankLogos['icici'],
      interest_rate: 8.4,
      margin_money: 10,
      hidden_fees: 7,
    },
  ];
  const { setShowWelcomeScreen } = props;
  return (
    <>
      <WelcomeScreenModal
        showAllOffers={showAllOffers}
        setShowAllOffers={setShowAllOffers}
        setShowWelcomeScreen={setShowWelcomeScreen}></WelcomeScreenModal>
      <div className={Styles.welcomeScreen + ' animate__animated animate__fadeIn'}>
        <div className={Styles.welcomeScreenContainer}>
          <Row justify="space-between" align="middle" className={Styles.welcomeBanner}>
            <Col span={12} className={Styles.header}>
              <img src={stageIcons.loans} alt={'loan icon'}></img>
              <h2>1 Application. 11 Banks.</h2>
            </Col>
            <Col span={12}>
              <Button
                size="large"
                type={'primary'}
                onClick={() => {
                  setShowWelcomeScreen(false);
                }}>
                Get Started <RightOutlined />
              </Button>
            </Col>
          </Row>
          <Row justify="center" className={Styles.logosContainer}>
            <Col>
              {Object.values(bankLogos).map((bankLogo, index) => (
                <img key={index} src={bankLogo} alt={'logo'} />
              ))}
            </Col>
          </Row>
          <Row justify="center" style={{ width: '100%' }}>
            <Row justify="space-between" className={Styles.iconsContainer}>
              <div>
                <span>1</span>
                <p>Complete our online application to be matched with 11+ lenders.</p>
              </div>
              <div>
                <span>2</span>
                <p>
                  Your application is processed and loan offers show up on your Gradly dashboard.
                </p>
              </div>
              <div>
                <span>3</span>
                <p>Accept the best fit-offer online & choose when to sign the loan agreement.</p>
              </div>
            </Row>
            <Row className={Styles.listContainer}>
              <Col span={10}>
                <h2>Why should you choose us?</h2>
                <ul className={Styles.liReasons}>
                  <li key={1}>
                    <CheckCircleFilled /> Apply to 11+ lenders with 1 simple application. Get a
                    decision on your application in days, not weeks.
                  </li>
                  <li key={2}>
                    <CheckCircleFilled /> Compare different loan offers with Gradly’s loan
                    comparison tool, supplemented by our loan comparison webinar & chat-based
                    assistance 24x7.
                  </li>
                  <li key={3}>
                    <CheckCircleFilled /> No processing fees.
                  </li>
                </ul>
              </Col>
              <Col span={14}>
                <Row justify="end" align="middle" className={Styles.ratesContainer}>
                  <Button
                    size="small"
                    type="link"
                    shape="round"
                    onClick={() => {
                      setShowAllOffers(true);
                    }}>
                    Show more <ForwardFilled />
                  </Button>
                  <div>
                    <ul className={Styles.leftUL}>
                      <li>
                        <Row justify="center" align="middle">
                          <Col span="12">
                            <p>Bank</p>
                          </Col>
                          <Col span="12">
                            <p>Interest Rate</p>
                          </Col>
                        </Row>
                      </li>
                      {compareData.map((data, i) => (
                        <li key={i}>
                          <Row justify="center" align="middle">
                            <Col span="12">
                              <img src={data.logo} alt={data.logo}></img>
                            </Col>
                            <Col span="12">
                              <p>{data.interest_rate}%</p>
                            </Col>
                          </Row>
                        </li>
                      ))}
                    </ul>
                    <ul className={Styles.rightUL}>
                      <li>
                        <Row justify="center" align="middle">
                          <Col span="12">
                            <p>Margin Money</p>
                          </Col>
                          <Col span="12">
                            <p>Hidden Fees</p>
                          </Col>
                        </Row>
                      </li>
                      {compareData.map((data, i) => (
                        <li key={i}>
                          <Row justify="center" align="middle">
                            <Col span="12">
                              <p>{data.margin_money}%</p>
                            </Col>
                            <Col span="12">
                              <p>{data.hidden_fees}%</p>
                            </Col>
                          </Row>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Row>
              </Col>
            </Row>
          </Row>
        </div>
      </div>
    </>
  );
};
