import { CheckOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import React, { FC } from 'react';
import { markStepsStatus, StepStatus } from '../../repos';
import Styles from './Buttons.module.sass';

interface MarkAsCompleteButtonProps extends ButtonProps {
  status: StepStatus | string;
  uid: string;
  route: string[];
  metadata?: any;
  onClick?: (e: any) => void;
  onCompleted?: () => void;
}

export const MarkAsCompleteButton: FC<MarkAsCompleteButtonProps> = (props) => {
  const { status, uid, route, metadata, onClick, onCompleted, ...rest } = props;
  if (status !== StepStatus.Complete)
    return (
      <Button
        {...rest}
        type="primary"
        icon={<CheckOutlined />}
        className={Styles.markCompleteButton + (rest.className ? ` ${rest.className}` : '')}
        onClick={(e) => {
          if (onClick) onClick(e);
          else markStepsStatus(uid, route, StepStatus.Complete, metadata);
          onCompleted && onCompleted();
        }}>
        MARK STEP AS COMPLETED
      </Button>
    );
  else return null;
};
