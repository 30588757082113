import { Card } from 'antd';
import React, { FC } from 'react';
import { IFrameAssist } from '../IFrameAssist';
import { PDFViewer } from '../PDFViewer';
import Styles from './Assistance.module.sass';

interface AssistanceCardProps {
  steps: any[];
  footer?: any;
  pdfURL?: string;
  currentStep: number;
  containerClass?: string;
  setCurrentStep?: (step: number) => void;
}

export const AssistanceCard: FC<AssistanceCardProps> = (props) => {
  const { pdfURL, steps, footer, currentStep, containerClass, setCurrentStep } = props;

  return (
    <Card className={Styles.container} actions={footer}>
      {pdfURL ? (
        <PDFViewer pdfFile={pdfURL} pageNumber={currentStep} />
      ) : (
        <IFrameAssist
          steps={steps}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          containerClass={containerClass}
        />
      )}
    </Card>
  );
};
