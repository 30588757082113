import React, { CSSProperties } from 'react';
import { Form, Input, Select } from 'antd';
import { getCountries, getCountryCallingCode } from 'libphonenumber-js';

interface Props {
  style?: CSSProperties;
  defaultISD?: { label: string; value: string };
  prefixName?: string;
}

export const PhoneInput: React.FC<Props> = (props) => {
  const isdCodes = getCountries().map((country) => {
    return {
      label: `${country} (+${getCountryCallingCode(country)})`,
      value: `${country}:${getCountryCallingCode(country)}`,
    };
  });
  const prefixSelector = (
    <Form.Item hasFeedback name={props.prefixName || 'prefix'} noStyle initialValue={'IN:91'}>
      <Select
        defaultValue={props.defaultISD}
        disabled={props.defaultISD !== undefined}
        bordered={false}
        showSearch
        style={{ width: 120 }}
        options={isdCodes}
        filterOption={(input, option) => !!(option && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0)}></Select>
    </Form.Item>
  );
  return <Input {...props} addonBefore={prefixSelector} />;
};
