import React, { FC, useEffect, useState } from 'react';
import { CheckCircleFilled, CheckOutlined, MessageFilled } from '@ant-design/icons';
import { faFileInvoiceDollar, faHourglassClock, faTriangleExclamation } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { GradlyLoading } from '../../App';
import { RootState } from '../../redux';

import Styles from './PayInvoice.module.sass';
import { FirestoreReference } from '../../utils';
import { PlanType, StepStatus } from '../../repos';
import { IntercomAPI } from '../../components';

interface Props {
  status: StepStatus | undefined;
}

export const PayInvoice: FC<Props> = (props) => {
  const { status } = props;
  const { insuranceData } = useSelector((state: RootState) => state.Insurance);
  const [plan, setPlan] = useState<PlanType | undefined>();

  useEffect(() => {
    if (insuranceData)
      FirestoreReference.InsurancePlan(insuranceData?.insurancePlanID).get().then((plan) => {
        setPlan(plan.data() as PlanType);
      });
  }, [insuranceData]);
  if (!insuranceData || !plan) return <GradlyLoading />;

  const InvoiceRight = () => {
    if (status === StepStatus.ReadyForReview && !insuranceData.invoiceFailed && !insuranceData.invoiceProcessing) {
      return <Row align='middle' className={Styles.payDeposit}>
        <Row justify='center'>
          <FontAwesomeIcon icon={faFileInvoiceDollar} />
          <h1>Pay your Insurance premium</h1>
          <Row align='middle' className={Styles.paymentFail}>
            <Button
              type='primary'
              className={Styles.invoiceButton}
              target='_blank'
              href={insuranceData.invoiceURL}
              rel='noreferrer'>
              Pay Premium
            </Button>
          </Row>
        </Row>
      </Row>;
    } else if (status === StepStatus.Complete) {
      return <Row align='middle' className={Styles.depositConfirmed}>
        <Row justify='center'>
          <CheckCircleFilled /> <h1>Invoice Paid</h1>
          <Button
            type='primary'
            className={Styles.invoiceButton}
            target='_blank'
            href={insuranceData.invoiceURL}
            rel='noreferrer'>
            Download receipt
          </Button>
        </Row>
      </Row>;
    } else if (status === StepStatus.Processing)
      return <Row align='middle' className={Styles.processingDeposit}>
        <Row justify='center'>
          <FontAwesomeIcon icon={faHourglassClock} />
          <h1>Processing Payment...</h1>
          <Button
            type='primary'
            disabled
            className={Styles.invoiceButtonDisabled}>
            Download receipt
          </Button>
        </Row>
      </Row>;
    else if (insuranceData.invoiceFailed) return <Row align='middle' className={Styles.invoiceFailed}>
      <Row justify='center'>
        <FontAwesomeIcon icon={faTriangleExclamation} />
        <h1>Payment failed</h1>
        <Button
          className={Styles.invoiceButton}
          type={'primary'}
          icon={<MessageFilled />}
          onClick={() => {
            IntercomAPI.showWithMessagePreFilled();
          }}>
          Chat with us
        </Button>
      </Row>
    </Row>;
    else return <GradlyLoading />;
  };
  return (
    <Row className={Styles.payment}>
      <Col xs={24} md={12} className={Styles.planInfo}>
        <span>{insuranceData?.insurancePlan}</span>
        <Row gutter={[0, 6]} className={Styles.priceTable}>
          <Col xs={12} md={10}>
            Total Premium:
          </Col>
          <Col xs={12} md={14}>
            <h1>${insuranceData?.finalPremium ? insuranceData?.finalPremium : 'Some error occurred, please contact support.'}</h1>
          </Col>
          <Col xs={12} md={10}>
            Deposit Amount:
          </Col>
          <Col xs={12} md={14}>
            <h2>-$25</h2>
          </Col>
          {insuranceData.groupCode ? <><Col xs={12} md={10}>
            Group discount:
          </Col>
            <Col xs={12} md={14}>
              <h2>-$15</h2>
            </Col></> : <></>}
          <Col xs={12} md={10}>
            Invoice Amount:
          </Col>
          <Col xs={12} md={14}>
            <h2>${insuranceData?.finalPremium ? insuranceData?.finalPremium - 25 - ((insuranceData.groupCode && 15) || 0) : 'Some error occurred, please contact support.'}</h2>
          </Col>
        </Row>

        <h2>
          {moment(insuranceData?.coverageStartDate, 'YYYY-MM-DD').format('MMM Do, YYYY')} -{' '}
          {moment(insuranceData?.coverageEndDate, 'YYYY-MM-DD').format('MMM Do, YYYY')}
        </h2>
        <ul className={Styles.liReasons}>
          <li key={1}>
            <CheckOutlined /> ${plan?.planFeatures.deductible} Annual Deductible
          </li>
          <li key={2}>
            <CheckOutlined /> {(plan?.planFeatures.coInsurance || 0) * 100}% Coinsurance
          </li>
          <li key={3}>
            <CheckOutlined /> {plan?.planFeatures.outOfPocketMax} out of pocket maximum
          </li>
        </ul>
      </Col>
      <Col xs={24} md={12} className={Styles.payStatus}>
        <InvoiceRight />
      </Col>
    </Row>
  );
};
