import { of } from 'rxjs';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { RootEpic } from '.';
import { UniversityRepo } from '../../repos';
import { catchAndReportError } from '../../utils';
import { CommonActionTypes, setError, setUniversities } from '../actions';

export const searchUniversitiesEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(CommonActionTypes.SearchUniversity)),
    debounceTime(150),
    switchMap((action) => {
      const { query } = action.payload;
      return UniversityRepo.searchUniversity(query).pipe(
        map(setUniversities),
        catchAndReportError((error) => of(setError(error.message))),
      );
    }),
  );
};

// export const listenToNotificationsEpic: RootEpic = (action$) => {
//   return action$.pipe(
//     filter(isOfType(CommonActionTypes.ListenToNotifications)),
//     switchMap((action) => {
//       return CommonRepo.listenToNotifications().pipe(
//         map((notification: Notification) => setNotification(notification)),
//         catchAndReportError((error) => of(setError(error.message))),
//       );
//     }),
//   );
// };
