import { action, ActionType } from 'typesafe-actions';
import { Content, Webinar, WebinarRegistration } from '../../repos';
import { PredefinedSteps, StepsStatus } from './../../repos/Content/Content';

export enum ContentActionTypes {
  ListenToStepRelatedContentItems = 'ContentAction/ListenToStepRelatedContentItems',
  SetContentItems = 'ContentAction/SetContentItems',
  ListenToWebinarRegistrations = 'ContentAction/ListenToWebinarRegistrations',
  SetWebinarRegistrations = 'ContentAction/SetWebinarRegistrations',
  RegisterForWebinar = 'ContentAction/RegisterForWebinar',
  RegisterForWebinarSuccess = 'ContentAction/RegisterForWebinarSuccess',
  RegisterForWebinarError = 'ContentAction/RegisterForWebinarError',
  UnregisterForWebinar = 'ContentAction/UnregisterForWebinar',
  UnregisterForWebinarSuccess = 'ContentAction/UnregisterForWebinarSuccess',
  UnregisterForWebinarError = 'ContentAction/UnregisterForWebinarError',
  JoinWebinar = 'ContentAction/JoinWebinar',
  SetUrlToJoinWebinar = 'ContentAction/SetUrlToJoinWebinar',
  JoinWebinarError = 'ContentAction/JoinWebinarError',
  GetWebinar = 'ContentAction/GetWebinar',
  SetWebinar = 'ContentAction/SetWebinar',
  ListenToPredefinedSteps = 'ContentAction/ListenToPredefinedSteps',
  SetPredefinedSteps = 'ContentAction/SetPredefinedSteps',
  ListenToStepsStatus = 'ContentAction/ListenToStepsStatus',
  SetStepsStatus = 'ContentAction/SetStepsStatus',
  GetStepsStatus = 'ContentAction/GetStepsStatus',
}

export const listenToContentItems = () =>
  action(ContentActionTypes.ListenToStepRelatedContentItems);

export const setContentItems = (contentItems: Content[]) =>
  action(ContentActionTypes.SetContentItems, { contentItems });

export const listenToWebinarRegistrations = () =>
  action(ContentActionTypes.ListenToWebinarRegistrations);

export const setWebinarRegistrations = (registrations: WebinarRegistration[]) =>
  action(ContentActionTypes.SetWebinarRegistrations, { registrations });

export const registerForWebinar = (conferenceId: string) =>
  action(ContentActionTypes.RegisterForWebinar, { conferenceId });

export const unregisterForWebinar = (conferenceId: string) =>
  action(ContentActionTypes.UnregisterForWebinar, { conferenceId });

export const registerForWebinarSuccess = (conferenceId: string) =>
  action(ContentActionTypes.RegisterForWebinarSuccess, { conferenceId });

export const unregisterForWebinarSuccess = (conferenceId: string) =>
  action(ContentActionTypes.UnregisterForWebinarSuccess, { conferenceId });

export const registerForWebinarError = () => action(ContentActionTypes.RegisterForWebinarError);

export const unregisterForWebinarError = () => action(ContentActionTypes.UnregisterForWebinarError);

export const joinWebinar = (conferenceId: string) =>
  action(ContentActionTypes.JoinWebinar, { conferenceId });

export const setUrlToJoinWebinar = (url?: string, chatURL?: string) =>
  action(ContentActionTypes.SetUrlToJoinWebinar, { url, chatURL });

export const joinWebinarError = () => action(ContentActionTypes.JoinWebinarError);

export const getWebinar = (webinarId: string) =>
  action(ContentActionTypes.GetWebinar, { webinarId });

export const setWebinar = (webinarId: string, webinar: Webinar) =>
  action(ContentActionTypes.SetWebinar, { webinarId, webinar });

export const setPredefinedSteps = (steps: PredefinedSteps) =>
  action(ContentActionTypes.SetPredefinedSteps, { steps });

export const listenToPredefinedSteps = () => action(ContentActionTypes.ListenToPredefinedSteps);

export const listenToStepsStatus = () => action(ContentActionTypes.ListenToStepsStatus);

export const setStepsStatus = (stepsStatus: StepsStatus) =>
  action(ContentActionTypes.SetStepsStatus, { stepsStatus });

export const getStepsStatus = () => action(ContentActionTypes.GetStepsStatus);

const ContentActions = {
  listenToContentItems,
  setContentItems,
  listenToWebinarRegistrations,
  setWebinarRegistrations,
  registerForWebinar,
  unregisterForWebinar,
  registerForWebinarSuccess,
  unregisterForWebinarSuccess,
  registerForWebinarError,
  unregisterForWebinarError,
  joinWebinar,
  setUrlToJoinWebinar,
  joinWebinarError,
  getWebinar,
  setWebinar,
  setPredefinedSteps,
  listenToPredefinedSteps,
  listenToStepsStatus,
  setStepsStatus,
  getStepsStatus,
};

export type ContentAction = ActionType<typeof ContentActions>;
