import { RouteComponentProps } from '@reach/router';
import { Button } from 'antd';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { attendWebinarIllustration } from '../../assets';
import { ContentPopOver, TagRadio } from '../../components';
import { UserJourneyTitle } from '../../config/userJourneyMap';
import {
  joinWebinar,
  registerForWebinar,
  RootState,
  setUrlToJoinWebinar,
  unregisterForWebinar,
} from '../../redux';
import { ContentType, Webinar } from '../../repos';
import { Analytics, AnalyticsEventName } from '../../utils';
import Styles from './RegisterForWebinar.module.sass';

interface RegisterForWebinarProps extends RouteComponentProps {
  joinWebinar: typeof joinWebinar;
  registerForWebinar: typeof registerForWebinar;
  unregisterForWebinar: typeof unregisterForWebinar;
  setUrlToJoinWebinar: typeof setUrlToJoinWebinar;
  webinars: Webinar[];
  liveWebinarUrl?: string;
  liveWebinarChatUrl?: string;
  loading?: boolean;
  webinar?: Webinar;
  registeredSlot?: {
    startTime: Moment;
    endTime: Moment;
    conferenceId: string;
    recordingId?: string;
  };
}

const RegisterForWebinar: React.FC<RegisterForWebinarProps> = (props) => {
  const {
    joinWebinar,
    registerForWebinar,
    unregisterForWebinar,
    loading,
    webinars,
    liveWebinarUrl,
    liveWebinarChatUrl,
    setUrlToJoinWebinar,
    registeredSlot,
    webinar: customStepWebinar,
  } = props;

  const webinar = customStepWebinar || webinars[0];
  const [selectedSlot = registeredSlot && registeredSlot.conferenceId, setSelectedSlot] =
    useState<string>();
  const [showWebinar = false, setShowingWebinar] = useState<boolean>();
  return (
    <div className={Styles.container}>
      <div className={Styles.info}>
        <div className={Styles.benefits}>
          {((webinar && webinar.agenda) || []).map((topic, index) => {
            return (
              <div key={topic} className={Styles.benefitContainer}>
                <div className={Styles.benefit}>
                  <div className={Styles.index}>{index + 1}</div>
                  <div className={Styles.topic}>{topic}</div>
                </div>
                <div className={Styles.connector}></div>
              </div>
            );
          })}
        </div>
        {registeredSlot && registeredSlot.recordingId ? (
          <iframe
            onClick={() => {
              Analytics.log(AnalyticsEventName.ContentConsumed, {
                associatedStage: UserJourneyTitle.SopReview,
                metadata: {
                  contentId: registeredSlot.recordingId || 'not-found',
                  contentType: ContentType.Webinar,
                },
              });
            }}
            allowFullScreen
            title={'webinar'}
            // onLoad={() => setLoading(false)}
            src={`https://www.bigmarker.com/recordings/${registeredSlot.recordingId}`}
          />
        ) : (
          <img alt={'Attend Webinar'} src={attendWebinarIllustration} />
        )}
      </div>
      {webinar && !registeredSlot && (
        <div className={Styles.webinarRegistrations}>
          <span className={Styles.webinarHeading}>
            <span className={Styles.hand}>👉</span>
            <span className={Styles.selectWebinar}>Select a webinar</span>
          </span>
          <TagRadio
            onSelect={(conferenceId) => {
              setSelectedSlot(conferenceId);
            }}>
            {webinar.slots
              .filter((slot) => slot.startTime.isAfter(moment()))
              .map((slot) => {
                return (
                  <TagRadio.Item key={slot.conferenceId} value={slot.conferenceId}>
                    <div className={Styles.date}>
                      <span>{slot.startTime.format("Do MMM 'YY")}</span>
                      <p>
                        {slot.startTime.format('h:mm a').replace(':00', '')} to{' '}
                        {slot.endTime.format('h:mm a').replace(':00', '')}
                      </p>
                    </div>
                  </TagRadio.Item>
                );
              })}
          </TagRadio>
        </div>
      )}
      {webinar && registeredSlot && (
        <div className={Styles.registrationInfo}>
          <span>You have been registered for the webinar at: </span>
          <span className={Styles.timing}>
            {registeredSlot.startTime.format('hh:mm a [|] ddd, DD MMMM YYYY')}
          </span>
        </div>
      )}
      <ContentPopOver
        feedItem={webinar}
        onCancel={() => {
          setUrlToJoinWebinar();
          setShowingWebinar(false);
        }}
        visible={showWebinar}
        data={
          liveWebinarUrl && liveWebinarChatUrl
            ? { url: liveWebinarUrl, chatURL: liveWebinarChatUrl }
            : undefined
        }
      />
      <Button
        hidden={registeredSlot && moment().isAfter(registeredSlot.endTime)}
        onClick={() => {
          if (!selectedSlot) {
            return;
          }
          if (registeredSlot) {
            if (
              moment().isBetween(registeredSlot.startTime, registeredSlot.endTime) &&
              !liveWebinarUrl
            ) {
              joinWebinar(registeredSlot.conferenceId);
              Analytics.log(AnalyticsEventName.ContentConsumed, {
                associatedStage: UserJourneyTitle.SopReview,
                metadata: {
                  contentId: registeredSlot.conferenceId,
                  contentType: ContentType.Webinar,
                },
              });
              setShowingWebinar(true);
              return;
            }
            if (moment().isBefore(registeredSlot.startTime)) {
              unregisterForWebinar(selectedSlot);
              setSelectedSlot(undefined);
            }
            return;
          }
          Analytics.log(AnalyticsEventName.SOPRequestReviewWebinarRegisterClicked);
          registerForWebinar(selectedSlot);
        }}
        disabled={!selectedSlot}
        loading={loading}
        className={Styles.submitButton}
        type="primary">
        {(() => {
          if (!registeredSlot) {
            return 'Register';
          }
          if (registeredSlot) {
            if (moment().isBefore(registeredSlot.startTime)) {
              return 'Register for Different Webinar';
            }
            if (
              moment().isBetween(registeredSlot.startTime, registeredSlot.endTime) &&
              !liveWebinarUrl
            ) {
              return 'Join Webinar';
            }
            return 'Watch';
          }
        })()}
      </Button>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const {
    webinarRegistrations = [],
    contentItems = [],
    customStepWebinars = [],
    loading,
    webinarUrl,
    webinarChatUrl,
  } = state.Content;
  const webinars = contentItems.filter(
    (item) =>
      item.type === ContentType.Webinar &&
      (item as Webinar).slots.filter((slot) => slot.endTime.isAfter(moment())).length > 0,
  ) as Webinar[];
  const allWebinars = Object.values(customStepWebinars).concat(webinars);
  const webinarRegistration = webinarRegistrations.filter((registration) => {
    return allWebinars
      .map((webinar) =>
        webinar.slots.map((slot) => slot.conferenceId).includes(registration.conferenceId),
      )
      .reduce((prev, next) => prev || next, false);
  })[0];
  const registeredSlot =
    webinarRegistration &&
    allWebinars
      .map(
        (item) =>
          item.slots.filter((slot) => slot.conferenceId === webinarRegistration.conferenceId)[0],
      )
      .filter((webinar) => !!webinar)[0];
  return {
    webinars,
    loading,
    webinarRegistration,
    registeredSlot,
    liveWebinarUrl: webinarUrl,
    liveWebinarChatUrl: webinarChatUrl,
  };
};

export default connect(mapStateToProps, {
  joinWebinar,
  registerForWebinar,
  unregisterForWebinar,
  setUrlToJoinWebinar,
})(RegisterForWebinar);
