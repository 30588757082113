import { CheckOutlined } from '@ant-design/icons';
import { faIndianRupee, faScaleBalanced, faClock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, Modal, Row } from 'antd';
import React, { useState } from 'react';
import Styles from './AcceptOfferModal.module.sass';
import { LoanOffer } from './FinalizeOffer';
import { FirestoreReference } from './../../utils/FirestoreDocumentReference';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { StepID } from './LoanStepContainer';
import { bankLogos, bankNames, markStepsStatus, StepStatus } from '../../repos';
import { navigate } from '@reach/router';

interface AcceptOfferModalProps {
  uid: string;
  offer: LoanOffer | undefined;
  showHelp: boolean;
  setShowHelp: (showHelp: any) => void;
}

export const AcceptOfferModal: React.FC<AcceptOfferModalProps> = (props) => {
  const { offer, showHelp, setShowHelp, uid } = props;
  const [accepted, setAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const tncPDF =
    'https://firebasestorage.googleapis.com/v0/b/enlite-exam-staging.appspot.com/o/Enlite%20Exam%20Paper.pdf?alt=media&token=4089ac89-4a40-47cd-8635-97ad03c69109';

  if (!offer) {
    setShowHelp(undefined);
    return <></>;
  }

  const onAcceptOffer = async () => {
    setLoading(true);
    await FirestoreReference.LoanApplication(uid)
      .doc('offers')
      .set({ [offer.id]: { accepted: true } }, { merge: true });

    await markStepsStatus(
      uid,
      [UserJourneyStageId.Loans, StepID.FinalizeOffer],
      StepStatus.Complete,
      { bankName: bankNames[offer.bid] },
    );

    await markStepsStatus(
      uid,
      [UserJourneyStageId.Loans, StepID.MeetOfficial],
      StepStatus.Incomplete,
      { bid: offer.bid },
    );
    navigate!(`/${UserJourneyStageId.Loans}/meet-bank-official`);
    setShowHelp(undefined);
  };

  return (
    <Modal
      centered
      className={Styles.modal}
      onCancel={() => {
        setShowHelp(undefined);
      }}
      title={
        <Row justify="center" align="middle" className={Styles.title}>
          <img src={bankLogos[offer.bid]} alt={offer.bname}></img>
          {offer.bname}'s loan offer
        </Row>
      }
      footer={null}
      closable
      maskClosable
      visible={showHelp}>
      <Row justify="center" className={Styles.container}>
        <Row justify="space-between" className={Styles.iconsContainer}>
          <div>
            <FontAwesomeIcon icon={faScaleBalanced} />
            <p>{offer.rate_of_interest}%</p>
          </div>
          <div>
            <FontAwesomeIcon icon={faClock} />
            <p>{offer.mor_period} years</p>
          </div>
          <div>
            <FontAwesomeIcon icon={faIndianRupee} />
            <p>{offer.amount}L</p>
          </div>
        </Row>
        <Row className={Styles.tnc}>
          <object
            data={(offer.tncPDF || tncPDF) + '#toolbar=0'}
            type="application/pdf"
            width="100%"
            height="100%">
            <p>
              Check the tnc
              <a href={offer.tncPDF || tncPDF}>here!</a>
            </p>
          </object>
        </Row>
        <Row className={Styles.detailsContainer}>
          <Checkbox
            onChange={(e) => {
              setAccepted(e.target.checked);
            }}>
            I have read the offer letter and agree to the terms and conditions outlined.
          </Checkbox>
        </Row>
        <Row justify="end">
          <Button
            size="large"
            danger
            onClick={() => {
              setShowHelp(undefined);
            }}>
            Cancel
          </Button>
          <Button
            loading={loading}
            disabled={!accepted}
            size="large"
            type="primary"
            onClick={() => {
              onAcceptOffer();
            }}
            icon={<CheckOutlined />}>
            Accept Offer
          </Button>
        </Row>
      </Row>
    </Modal>
  );
};
