import {
  UserAcademicInformation,
  UserInformation,
  UserProfile,
  UserApplicationInterest,
  UserApplication,
} from '../../repos';
import { action, ActionType } from 'typesafe-actions';

export enum ProfileActionTypes {
  UpdateUserProfile = 'Profile/UpdateUserProfile',
  UpdateAcademicInformation = 'Profile/UpdateAcademicInformation',
  UpdateApplicationInterest = 'Profile/UpdateApplicationInterest',
  UpdateUserApplications = 'Profile/UpdateUserApplications',
  SetAcademicInformation = 'Profile/SetAcademicInformation',
  SetUserInformation = 'Profile/SetUserInformation',
}

export const updateUserProfile = (userProfile: Partial<UserProfile>) =>
  action(ProfileActionTypes.UpdateUserProfile, { userProfile });

export const updateAcademicInformation = (academicInformation: Partial<UserAcademicInformation>) =>
  action(ProfileActionTypes.UpdateAcademicInformation, { academicInformation });

export const updateApplicationInterest = (applicationInterest: Partial<UserApplicationInterest>) =>
  action(ProfileActionTypes.UpdateApplicationInterest, { applicationInterest });

export const updateUserApplications = (applications: Partial<UserApplication>[]) =>
  action(ProfileActionTypes.UpdateUserApplications, { applications });

export const setAcademicInformation = (academicInformation: UserAcademicInformation) =>
  action(ProfileActionTypes.SetAcademicInformation, { academicInformation });

export const setUserInformation = (userInformation: UserInformation) =>
  action(ProfileActionTypes.SetUserInformation, { userInformation });

const profileActions = {
  updateUserProfile,
  updateAcademicInformation,
  updateApplicationInterest,
  updateUserApplications,
  setAcademicInformation,
  setUserInformation,
};

export type ProfileAction = ActionType<typeof profileActions>;
