import { CloseOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useState, CSSProperties } from 'react';
import { commonIllustrations } from '../../assets';
import Styles from './HtmlToReact.module.sass';
const HTMLProcessor = require('html-to-react');

interface Props {
  htmlString: string;
  className?: string;
  style?: CSSProperties;
}

export const HtmlToReact: React.FC<Props> = props => {
  const { htmlString, className, style } = props;
  const [urlToShow, setUrlToShow] = useState<string>();

  const htmlToReactParser = new HTMLProcessor.Parser();
  const processNodeDefinitions = new HTMLProcessor.ProcessNodeDefinitions(React);
  const processingInstructions = [
    {
      // Custom <a> processing
      shouldProcessNode: (node: any) => node.parent && node.name === 'a',
      processNode: (node: any, children: any) => {
        return React.createElement(
          'span',
          {
            key: node.attribs.href,
            className: Styles.spanLink,
            onClick: () => setUrlToShow(node.attribs.href),
          },
          children[0],
        );
      },
    },
    {
      // Anything else
      shouldProcessNode: (node: any) => true,
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ];

  const closeIcon = (
    <Button size="large" className={Styles.closeButton} shape="circle" icon={<CloseOutlined />} />
  );

  return (
    <div className={className} style={style}>
      <Modal
        className={Styles.popUp}
        visible={urlToShow !== undefined}
        width="80vw"
        footer={null}
        closeIcon={closeIcon}
        onCancel={() => setUrlToShow(undefined)}>
        <iframe
          title={urlToShow}
          src={urlToShow}
          className={Styles.iframe}
          style={{ background: `url(${commonIllustrations.loadingGif}) center center no-repeat` }}
        />
      </Modal>
      {htmlToReactParser.parseWithInstructions(htmlString, () => true, processingInstructions)}
    </div>
  );
};
