import { Select } from 'antd';
import { find, unionBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState, searchUniversity } from '../../redux';
import { University } from '../../repos';

interface Props {
  value?: any;
  onChange?: (value: University[]) => void;
  multiple?: boolean;
  style?: React.CSSProperties;
  onSelect?: (universities: University[]) => void;
  universities?: University[];
  defaultSelectedUniversities?: University[];
  searchUniversity: typeof searchUniversity;
  disabled?: boolean;
  showArrow?: boolean;
  className?: string;
  optionsClassName?: string;
  prefix?: any;
  placeholder?: string;
}

const UniversityInput: React.FC<Props> = (props) => {
  const {
    multiple = true,
    universities = [],
    searchUniversity,
    defaultSelectedUniversities = [],
    onSelect,
    style,
    disabled,
    className,
    showArrow,
    prefix,
    placeholder,
    optionsClassName,
  } = props;

  const [selectedUniversities, setSelectedUniversities] = useState<University[]>(
    defaultSelectedUniversities,
  );

  useEffect(() => {
    if (defaultSelectedUniversities && defaultSelectedUniversities.length > 0) {
      setSelectedUniversities(defaultSelectedUniversities)
      props.onChange!(defaultSelectedUniversities);
    }
  }, [defaultSelectedUniversities?.length]);

  useEffect(() => {
    if (defaultSelectedUniversities && defaultSelectedUniversities.length > 0) {
      searchUniversity(defaultSelectedUniversities[0].name);
    }
    searchUniversity('university');
  }, []);

  const children = unionBy(universities, selectedUniversities, 'id').map((university) => {
    return (
      <Select.Option value={university.name} key={university.id} className={optionsClassName}>
        {prefix || null} {university.name}
      </Select.Option>
    );
  });

  return (
    <Select
      showSearch
      bordered={false}
      className={className}
      maxTagCount={'responsive'}
      disabled={disabled}
      style={style}
      placement="bottomRight"
      showArrow={showArrow === undefined ? true : showArrow}
      onSearch={(query) => {
        searchUniversity(query);
      }}
      onBlur={() => {
        searchUniversity('');
      }}
      filterOption={false}
      placeholder={placeholder || selectedUniversities[0]?.name || 'university'}
      dropdownStyle={{ minWidth: 'max-content' }}
      onSelect={(universityId: string) => {
        const value = find(universities, ['name', universityId]);
        if (!value) return;
        let selections: University[] = [];
        if (!multiple) {
          selections = [value];
        } else {
          selections = unionBy(selectedUniversities, [value], 'id');
        }
        onSelect && onSelect(selections);
        setSelectedUniversities(selections);
        props.onChange!(selections);
      }}
      onDeselect={(universityId: string) => {
        const selections = Array.from(selectedUniversities).filter(
          (uni) => uni.name !== universityId,
        );
        onSelect && onSelect(selections);
        setSelectedUniversities(selections);
      }}
      mode={multiple ? 'multiple' : undefined}
      tokenSeparators={[',']}>
      {children}
    </Select>
  );
};

const mapStateToProps = (state: RootState) => {
  const { universities } = state.Common;
  return { universities };
};

export default connect(mapStateToProps, { searchUniversity })(UniversityInput);
