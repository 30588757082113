import { produce } from 'immer';
import { Notification, University } from '../../repos';
import { CommonAction, CommonActionTypes } from '../actions';

export interface CommonStateType {
  readonly error?: string;
  readonly successMessage?: string;
  readonly universities: University[];
  readonly notification: Notification | undefined;
}

const initialState: CommonStateType = { universities: [], notification: undefined };

export const CommonReducer = (state = initialState, action: CommonAction) => {
  return produce(state, (draft) => {
    draft.error = undefined;
    draft.successMessage = undefined;
    switch (action.type) {
      case CommonActionTypes.SetError:
        draft.error = action.payload.error;
        break;
      case CommonActionTypes.SetMessage:
        draft.successMessage = action.payload.message;
        break;
      case CommonActionTypes.SetUniversities:
        draft.universities = action.payload.universities;
        break;
      case CommonActionTypes.SetNotification:
        draft.notification = action.payload.notification;
        break;
      default:
        break;
    }
    return draft;
  });
};
