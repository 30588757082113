import React, { FC, useEffect } from 'react';
import { RouteComponentProps, Link, navigate, useNavigate } from '@reach/router';
import { sendRecoveryEmail, RootState, setError, setMessage } from '../../redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Input, Button, Col, Form, Row } from 'antd';
import { Routes } from '../../App';
import { authIllustration, logo } from '../../assets';
import Styles from './styles.module.sass';
import { Alert, AlertType } from '../../components';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';

interface Props extends RouteComponentProps {
  loading: boolean;
  code?: string;
  sendRecoveryEmail: typeof sendRecoveryEmail;
}

const ResetPasswordContainer: FC<Props> = (props) => {
  const { sendRecoveryEmail, loading, code } = props;
  const { successMessage, error } = useSelector((state: RootState) => state.Common);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setError());
  }, []);

  useEffect(() => {
    if (code && code !== 'done') {
      firebase
        .auth()
        .verifyPasswordResetCode(code || '')
        .catch((error) => {
          dispatch(setError('Invalid or expired code. Please try again.'));
        });
    }
    if (code === 'done') dispatch(setMessage('You can now sign in with your new password.'));
  }, [code]);

  const resetPassword = (newPassword: any) => {
    firebase
      .auth()
      .verifyPasswordResetCode(code || '')
      .then((email) => {
        firebase
          .auth()
          .confirmPasswordReset(code || '', newPassword)
          .then(() => {
            navigate(`/${Routes.ResetPassword}/done`);
          })
          .catch(() => {
            dispatch(setError('Invalid or expired code. Please try again.'));
          });
      })
      .catch(() => {
        dispatch(setError('Invalid or expired code. Please try again.'));
      });
  };

  return (
    <Row wrap={false} className={Styles.container}>
      <Col xs={0} md={0} xl={8}>
        <div className={Styles.authIllustration}>
          <img src={authIllustration} alt="Auth"></img>
        </div>
      </Col>
      <Col flex={'auto'}>
        <Row justify="center" className={Styles.authContainer}>
          <img src={logo} alt="Gradly Logo" />
          <h1>{code === 'done' ? 'Password changed!' : 'Reset your password'}</h1>
          {error && (
            <Alert
              className={Styles.alerts}
              message={error}
              type={AlertType.Error}
              icon={<CloseCircleOutlined />}></Alert>
          )}
          {successMessage ? (
            <Alert
              className={Styles.alerts}
              message={successMessage}
              type={AlertType.Success}
              icon={<CheckCircleOutlined />}></Alert>
          ) : (
            <>
              <Form
                name="reset-pass"
                size="large"
                className={Styles.form}
                validateTrigger="onBlur"
                onFinish={(data) => {
                  if (data.email) sendRecoveryEmail(data.email);
                  if (data.password) resetPassword(data.password);
                }}>
                {(!code || error) && (
                  <p>
                    Enter the email address associated with your account and we'll send you a link
                    to reset your password.
                  </p>
                )}
                {code && !error ? (
                  <>
                    <Form.Item
                      hasFeedback
                      name={'password'}
                      rules={[{ required: true, message: 'Please input your Password!' }]}>
                      <Input.Password
                        placeholder="Password"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      name={'confirm_pass'}
                      dependencies={['password']}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: 'Please confirm your password!',
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error('The two passwords that you entered do not match!'),
                            );
                          },
                        }),
                      ]}>
                      <Input.Password
                        placeholder="Confirm Password"
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </Form.Item>
                  </>
                ) : (
                  <Form.Item
                    name={'email'}
                    rules={[
                      {
                        type: 'email',
                        message: 'This is not a valid email address!',
                      },
                      {
                        required: true,
                        message: 'Please input your email address!',
                      },
                    ]}>
                    <Input type="email" placeholder="Email address"></Input>
                  </Form.Item>
                )}
                <Form.Item>
                  <Button loading={loading} block type="primary" htmlType="submit">
                    Continue
                  </Button>
                </Form.Item>
              </Form>
              <Link className={Styles.secondaryButton} to={Routes.Home}>
                Return to sign in
              </Link>
            </>
          )}
          {code === 'done' && (
            <Button
              onClick={() => {
                navigate('/');
              }}
              className={Styles.signInButton}
              type={'primary'}
              size={'large'}>
              Return to sign in
            </Button>
          )}
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: RootState) => {
  const { loading } = state.Auth;
  return { loading };
};

export default connect(mapStateToProps, { sendRecoveryEmail })(ResetPasswordContainer);
