import { combineEpics, Epic } from 'redux-observable';
import { RootAction } from '../actions';
import { RootState } from '../reducers';
import {
  listenToAuthStateEpic,
  resetPasswordEpic,
  sendSignInLinkToEmail,
  signInUsingEmailEpic,
  signInUsingPhoneEpic,
  signOutEpic,
  signUpEpic,
  verifyOtpEpic,
  verifySignInLinkEpic,
} from './AuthEpics';
import { searchUniversitiesEpic } from './CommonEpics';
import {
  joinWebinarEpic,
  listenToCustomStepWebinar,
  listenToStepRelatedContentItemsEpic,
  listenToWebinarRegistrationsEpic,
  listenToPredefinedStepsEpic,
  registerForWebinarEpic,
  unregisterForWebinarEpic,
  listenToStepsStatusEpic,
} from './ContentEpics';
import { listenToCustomStepsEpic, updateCustomStepStatusEpic } from './CustomStepEpics';
import {
  updateAcademicInformationEpic,
  updateApplicationInterestEpic,
  updateUserApplicationsEpic,
  updateUserProfileEpic,
} from './ProfileEpics';
import {
  getScoresEpic,
  getSopVersionEpic,
  listenToSopReviewComments,
  markHelpItemsEpic,
  markSopReviewAsSeenEpic,
  submitHelpRequestEpic,
  submitSopVersionEpic,
  updateSopReviewRatingEpic,
} from './SopReviewEpics';

export type RootEpic = Epic<RootAction, RootAction, RootState>;

export default combineEpics(
  signInUsingEmailEpic,
  listenToAuthStateEpic,
  signOutEpic,
  signInUsingPhoneEpic,
  verifyOtpEpic,
  resetPasswordEpic,
  signUpEpic,
  sendSignInLinkToEmail,
  verifySignInLinkEpic,
  searchUniversitiesEpic,
  listenToStepRelatedContentItemsEpic,
  listenToWebinarRegistrationsEpic,
  listenToPredefinedStepsEpic,
  listenToStepsStatusEpic,
  registerForWebinarEpic,
  unregisterForWebinarEpic,
  joinWebinarEpic,
  listenToCustomStepWebinar,
  updateUserProfileEpic,
  updateAcademicInformationEpic,
  updateApplicationInterestEpic,
  updateUserApplicationsEpic,
  getSopVersionEpic,
  submitSopVersionEpic,
  listenToSopReviewComments,
  updateSopReviewRatingEpic,
  markSopReviewAsSeenEpic,
  markHelpItemsEpic,
  submitHelpRequestEpic,
  getScoresEpic,
  listenToCustomStepsEpic,
  updateCustomStepStatusEpic,
);
