import { Form, Input, Row, Skeleton } from 'antd';
import React from 'react';
import Styles from './CreateGroupInput.module.sass';

export const CreateGroupInput: React.FC<any> = (props) => {
	const { code } = props;
	return (
		<Row className={Styles.container}>
			<h1>Your group code is: {code || <Skeleton.Input active style={{ width: '100px', height: '24px' }} />}</h1>
			<h2>Please enter the university email addresses of other group members below.</h2>
			<p>
				Your group members will receive an email with the group code. To avail the group discount,
				all members will need to provide the group code during payment.
			</p>
			<ol>
				{[1, 2, 3, 4].map((i) => <li key={i}>
					<Form.Item
						rules={[
							{ required: true, message: 'Please enter university email address.' },
							{ type: 'email', message: 'Please enter a valid email address.' },
							({ getFieldValue }) => ({
								validator(_, value) {
									for (const j of [1, 2, 3, 4].filter((j) => j !== i)) {
										if (getFieldValue(`rEmail${j}`) === value) {
											return Promise.reject('This email address is already in use.');
										}
									}
									return Promise.resolve();
								},
							})
						]}
						name={'rEmail' + i}>
						<Input />
					</Form.Item>
				</li>)}
			</ol>
		</Row >
	);
};

export const JoinGroupInput: React.FC<any> = (props) => {
	return (
		<Row className={Styles.containerJ}>
			<h2>Please enter your group code.</h2>
			<p>
				You would have received an email with this code when one of your group members created the group.
			</p>
			<Form.Item rules={[
				{ required: true, message: 'Please enter a valid group code.' },
			]} name={'gCode'}>
				<Input />
			</Form.Item>
		</Row >
	);
};
