import { HelpData } from '../../components';

export const ds160HelpData: HelpData = {
  'Getting Started': [
    {
      title: 'Start Application',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646923971/assets/DS160/start-application_a7ntyu.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646923971/assets/DS160/start-application_a7ntyu.png',
    },
    {
      title: 'Application Information',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925861/assets/DS160/Group_155_u46pam.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925861/assets/DS160/Group_155_u46pam.png',
    },
  ],
  'Personal 1': [
    {
      title: 'Name and aliases',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925863/assets/DS160/Group_156_wrdmg1.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925863/assets/DS160/Group_156_wrdmg1.png',
    },
    {
      title: 'Sex and Marital Status',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925861/assets/DS160/Group_157_qpbmid.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925861/assets/DS160/Group_157_qpbmid.png',
    },
    {
      title: 'Date and Place of Birth',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1647506066/assets/DS160/date_and_place_of_birth_yhec2x.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1647506066/assets/DS160/date_and_place_of_birth_yhec2x.png',
    },
  ],
  'Personal 2': [
    {
      title: 'National ID',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925863/assets/DS160/Group_160_aq6jox.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925863/assets/DS160/Group_160_aq6jox.png',
    },
    {
      title: 'Nationality',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925865/assets/DS160/Group_161_grgxon.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925865/assets/DS160/Group_161_grgxon.png',
    },
  ],
  Travel: [
    {
      title: 'Travel Plans',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925862/assets/DS160/Group_163_rcgoqx.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925862/assets/DS160/Group_163_rcgoqx.png',
    },
    {
      title: 'Purpose of Trip',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925864/assets/DS160/Group_162_ytgzao.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925864/assets/DS160/Group_162_ytgzao.png',
    },
    {
      title: 'Accomodation Plans',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925863/assets/DS160/Group_164_oyxwdx.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925863/assets/DS160/Group_164_oyxwdx.png',
    },
  ],
  'Travel Companions': [
    {
      title: 'Persons Travelling',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925862/assets/DS160/Group_165_hlhmi0.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925862/assets/DS160/Group_165_hlhmi0.png',
    },
  ],
  'Previous US Travel': [
    {
      title: 'Previously Issued US Visa',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925863/assets/DS160/Group_167_sxla0y.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925863/assets/DS160/Group_167_sxla0y.png',
    },
    {
      title: 'Previous US Visit',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925863/assets/DS160/Group_166_bldpqs.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925863/assets/DS160/Group_166_bldpqs.png',
    },
    {
      title: 'Other Information',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925863/assets/DS160/Group_168_zaw1np.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925863/assets/DS160/Group_168_zaw1np.png',
    },
  ],
  'Address and Phone': [
    {
      title: 'Address',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925863/assets/DS160/Group_169_xypafa.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925863/assets/DS160/Group_169_xypafa.png',
    },
    {
      title: 'Email Address',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925858/assets/DS160/Group_171_swi3cc.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925858/assets/DS160/Group_171_swi3cc.png',
    },
    {
      title: 'Social Media',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925858/assets/DS160/Group_172_xgsgpf.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925858/assets/DS160/Group_172_xgsgpf.png',
    },
    {
      title: 'Phone',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925857/assets/DS160/Group_170_lrglme.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925857/assets/DS160/Group_170_lrglme.png',
    },
  ],
  'Passport Info': [
    {
      title: 'Type and Number',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925858/assets/DS160/Group_173_dmpr05.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925858/assets/DS160/Group_173_dmpr05.png',
    },
    {
      title: 'Issue Details',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925860/assets/DS160/Group_174_bk1aqb.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925860/assets/DS160/Group_174_bk1aqb.png',
    },
    {
      title: 'Lost or stolen',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925858/assets/DS160/Group_175_k8eej3.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925858/assets/DS160/Group_175_k8eej3.png',
    },
  ],
  'US Contact': [
    {
      title: 'Contact Person',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925859/assets/DS160/Group_176_udvlyp.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925859/assets/DS160/Group_176_udvlyp.png',
    },
    {
      title: 'Address Phone Number',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925859/assets/DS160/Group_177_l9tbw5.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925859/assets/DS160/Group_177_l9tbw5.png',
    },
  ],
  Family: [
    {
      title: 'Father',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925859/assets/DS160/Group_178_cewftf.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925859/assets/DS160/Group_178_cewftf.png',
    },
    {
      title: 'Mother',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925859/assets/DS160/Group_179_fve2n6.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925859/assets/DS160/Group_179_fve2n6.png',
    },
    {
      title: 'Other Relatives',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925859/assets/DS160/Group_180_mm95jn.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925859/assets/DS160/Group_180_mm95jn.png',
    },
  ],
  'Work/Education/Training (Present)': [
    {
      title: 'Occupation Details',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925860/assets/DS160/Group_181_vl7xml.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925860/assets/DS160/Group_181_vl7xml.png',
    },
  ],
  'Work/Education/Training (Previous)': [
    {
      title: 'Employer Details',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925860/assets/DS160/Group_182_br3nis.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925860/assets/DS160/Group_182_br3nis.png',
    },
    {
      title: 'Employment Details',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925861/assets/DS160/Group_183_o8kop4.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925861/assets/DS160/Group_183_o8kop4.png',
    },
    {
      title: 'Education Details',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1647864006/assets/DS160/Group_184_yr4vey.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1647864006/assets/DS160/Group_184_yr4vey.png',
    },
  ],
  'Work/Education/Training (Additional)': [
    {
      title: 'Tribe and Languages',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925861/assets/DS160/Group_185_mwanzb.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925861/assets/DS160/Group_185_mwanzb.png',
    },
    {
      title: 'Travel and Work',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925861/assets/DS160/Group_186_lewysc.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925861/assets/DS160/Group_186_lewysc.png',
    },
    {
      title: 'Organized Training',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925861/assets/DS160/Group_187_isriux.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925861/assets/DS160/Group_187_isriux.png',
    },
  ],
  'Security & Background': [
    {
      title: 'Security & Background',
      message: "Select 'Yes' or 'No' for each question. Provide complete details for the questions you select 'Yes'.",
    },
  ],
  'Student Exchange Visa': [
    {
      title: 'Additional Contact',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925864/assets/DS160/Group_188_ghn0ep.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925864/assets/DS160/Group_188_ghn0ep.png',
    },
    {
      title: 'SEVIS',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1646925864/assets/DS160/Group_189_tvlxq9.png',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/c_thumb,w_366,g_face/v1646925864/assets/DS160/Group_189_tvlxq9.png',
    },
  ],
};
