import { BankFilled, LikeFilled, FundFilled } from '@ant-design/icons';
import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Accordion, WelcomeUserWithProgressHeading } from '../../components';
import { ComingSoonWelcomeScreen } from '../../components/WelcomeScreen';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { RootState } from '../../redux';
import { CustomStep, StagePredefinedSteps, UserProfile } from '../../repos';
import { renderCustomStep } from '../Home';
import { getIndexFromRoute, useStepCalculation } from '../utils';
import Styles from './USBankAccountsStepContainer.module.sass';

interface USBankAccountsStepContainerProps extends RouteComponentProps {
  customSteps: CustomStep[];
  currentUser?: UserProfile | null;
  allPredefinedSteps: StagePredefinedSteps;
}

export enum StepID {}

const USBankAccountsStepContainer: React.FC<USBankAccountsStepContainerProps> = (props) => {
  const { customSteps, currentUser, allPredefinedSteps } = props;
  const AccordionInstance = useRef<Accordion>(null);
  const { pathname } = useLocation();
  const [showWelcomeScreen, setShowWelcomeScreen] = useState<boolean>(true);
  const {
    steps,
    stepsLeft,
    calculatedStepsCompleted,
    setCalculatedStepsCompleted,
    predefinedStepsStatus,
  } = useStepCalculation(
    Object.values(StepID),
    allPredefinedSteps,
    customSteps,
    UserJourneyStageId.USBankAccounts,
    showWelcomeScreen,
    currentUser?.id,
  );

  const comingSoonsteps = [
    {
      icon: <BankFilled />,
      title: 'Open Your Account in Minutes',
      items: ['No SSN Needed', 'Digital KYC', 'Account Ready Before You Fly'],
    },
    {
      icon: <LikeFilled />,
      title: 'Transfer Money',
      items: [
        'Lowest Forex Rates - Guaranteed',
        'Deep Integration with UPI',
        'No Transaction or Monthly Fees',
      ],
    },
    {
      icon: <FundFilled />,
      title: 'Use Instantly',
      items: ['Instant US Virtual Cards', 'Mobile & Web App', 'Student Discounts'],
    },
  ];

  if (currentUser)
    return !predefinedStepsStatus && showWelcomeScreen ? (
      <ComingSoonWelcomeScreen
        title={'Get a US bank account, anywhere.'}
        stageID={UserJourneyStageId.USBankAccounts}
        steps={comingSoonsteps}
        description={
          'Get a US bank account in minutes. No SSN, no fees, and lowest forex rate in the market guaranteed. Activate your account before you fly!'
        }
        listItems={[
          'No SSN Needed',
          'Lowest Forex Charge Guarantee',
          'Activate Account in Home Country',
        ]}
      />
    ) : (
      <div className={Styles.container + ' animate__animated animate__fadeIn'}>
        <div className={Styles.childContainer}>
          <WelcomeUserWithProgressHeading
            stageID={UserJourneyStageId.USBankAccounts}
            title="Get help in Visa Application"
            currentUser={currentUser}
            stepCount={{ total: steps.length, left: stepsLeft || 0 }}
          />
          <Accordion
            ref={AccordionInstance}
            onExpanded={(index) => {
              if (getIndexFromRoute(pathname, steps) === index) {
                return navigate!(`/${UserJourneyStageId.USBankAccounts}`);
              }
              const route = steps[index].route;
              navigate!(`/${UserJourneyStageId.USBankAccounts}/${route}`);
            }}
            defaultExpanded={getIndexFromRoute(pathname, steps)}
            className={Styles.accordion}>
            {steps.map((step) => {
              switch (step.id) {
                default: {
                  return renderCustomStep(
                    step,
                    calculatedStepsCompleted,
                    setCalculatedStepsCompleted,
                    currentUser.shownBadges[UserJourneyStageId.USBankAccounts] || [],
                  );
                }
              }
            })}
          </Accordion>
        </div>
      </div>
    );
  else return <> </>;
};

const mapStateToProps = (state: RootState) => {
  const { steps } = state.CustomSteps;
  const { currentUser } = state.Auth;
  const allPredefinedSteps = state.Content.predefinedSteps[UserJourneyStageId.USBankAccounts];
  return {
    currentUser,
    customSteps: steps[UserJourneyStageId.USBankAccounts] || [],
    allPredefinedSteps,
  };
};

export default connect(mapStateToProps, {})(USBankAccountsStepContainer);
