import { navigate } from '@reach/router';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { RootState } from '../../redux';
import { StagePredefinedSteps, PredefinedStep, StepStatus, CustomStep, StepType, markStepsStatus } from '../../repos';

export const useStepCalculation = (
  StepIDs: string[],
  allPredefinedSteps: StagePredefinedSteps,
  customSteps: CustomStep[],
  stageID: UserJourneyStageId,
  showWelcomeScreen?: boolean,
  uid?: string,
) => {
  const predefinedStepsStatus = useSelector((state: RootState) => state.Content.stepsStatus);
  const predefinedSteps = StepIDs.map((step: string) => ({
    ...(allPredefinedSteps[step] as PredefinedStep),
    id: step,
    status: predefinedStepsStatus[stageID]?.[step]?.status,
  })).sort((a, b) => a.order - b.order);

  const [calculatedStepsCompleted = [], setCalculatedStepsCompleted] = useState<string[]>(
    predefinedSteps.filter((step) => [StepStatus.Complete].includes(step.status)).map((step) => step.id),
  );

  const steps: (PredefinedStep | CustomStep)[] = predefinedSteps
    .filter((step) => step.title)
    .map((step, idx) => ({
      ...step,
      order: idx + (step.status === StepStatus.Complete ? 99 : 0),
    }))
    .concat(...customSteps)
    .sort((step1, step2) => step1.order - step2.order)
    .filter((step) => !step.hidden);

  const stepsLeft =
    steps.length -
    customSteps.filter((step) => step.type !== StepType.RsvpToWebinar && step.status === StepStatus.Complete).length -
    calculatedStepsCompleted.length;

  useEffect(() => {
    if (!window.location.pathname.includes(stageID)) {
      navigate!(`/${stageID}`);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (!showWelcomeScreen && uid) {
      markStepsStatus(uid, [stageID, StepIDs[0]], StepStatus.Pending);
    }
  }, [showWelcomeScreen]);

  useEffect(() => {
    if (predefinedStepsStatus)
      setCalculatedStepsCompleted(
        predefinedSteps.filter((step) => [StepStatus.Complete].includes(step.status)).map((step) => step.id),
      );
  }, [predefinedStepsStatus]);

  return {
    steps,
    stepsLeft,
    calculatedStepsCompleted,
    setCalculatedStepsCompleted,
    predefinedStepsStatus: predefinedStepsStatus[stageID],
  };
};
