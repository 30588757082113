import { QuestionCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { FileUploader } from '../../components/Uploader';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { FirestoreReference } from '../../utils';
import Styles from './Subforms.module.sass';
import { LoanSubStatus } from './ApplyBanksStep';
import { StepID } from './LoanStepContainer';
import { StepStatus } from '../../repos';
import { DocsHelpModal } from '../../components';
import { helpData } from './loanHelpData';
import { SubFormProps } from './LoanForm';

const layout = {
  labelCol: { span: 14 },
  wrapperCol: { span: 6 },
};

export const AcademicsForm: React.FC<SubFormProps> = (props) => {
  const [showHelp, setShowHelp] = useState(false);
  const [helpID, setHelpID] = useState('result_10');
  const { uid, allFormData, setAllFormData, current } = props;
  const ACADEMICS_CURRENT = LoanSubStatus.Academics;
  const [form] = Form.useForm();

  const onSubmit = (values: any) => {
    if (uid) {
      FirestoreReference.LoanApplication(uid).doc('academics').set(values);
      FirestoreReference.UserStepsStatus(uid).set(
        {
          [UserJourneyStageId.Loans]: {
            [StepID.ApplyToBanks]: {
              status: StepStatus.Incomplete,
              subStatus: LoanSubStatus.Identity,
            },
          },
        },
        { merge: true },
      );
    }
  };

  // Preload all help images in background
  useEffect(() => {
    Object.values(helpData).forEach((help) => {
      help.image &&
        fetch(help.image)
          .then((res) => {})
          .catch((err) => {});
    });
  }, []);

  const getLabel = (label: string, hid: string, subLabel?: string) => {
    return (
      <div className={Styles.labelDiv}>
        {label}
        <QuestionCircleOutlined
          onClick={(e) => {
            e.preventDefault();
            setHelpID(hid);
            setTimeout(() => {
              setShowHelp(true);
            }, 100);
          }}
        />
        {subLabel && <p>{subLabel}</p>}
      </div>
    );
  };

  return (
    <>
      <DocsHelpModal
        helpData={helpData[helpID]}
        showHelp={showHelp}
        setShowHelp={setShowHelp}></DocsHelpModal>
      <Form
        form={form}
        requiredMark={false}
        onFinish={onSubmit}
        initialValues={allFormData.academics}
        onValuesChange={(_, values) => {
          setAllFormData({ academics: values });
        }}
        labelAlign={'left'}
        labelWrap
        className={
          `${Styles.form} ${props.current !== ACADEMICS_CURRENT ? 'all-disabled' : ''}` +
          ' animate__animated animate__fadeIn'
        }
        {...layout}>
        <Form.Item
          rules={[{ required: true, message: 'Please upload your class 10 Marksheet' }]}
          name="result_10"
          label={getLabel('Class 10 Marksheet', 'result_10')}
          valuePropName="fileList">
          <FileUploader
            form={form}
            maxFileSize={2}
            maxCount={1}
            storagePath={`userApplication/${uid}/loans/result_10`}
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Please upload your class 12 Marksheet' }]}
          name="result_12"
          label={getLabel('Class 12 Marksheet', 'result_12')}
          valuePropName="fileList">
          <FileUploader
            form={form}
            maxFileSize={2}
            maxCount={1}
            storagePath={`userApplication/${uid}/loans/result_12`}
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Please upload your Proof of Admission' }]}
          name="proof_of_admission"
          label={getLabel('Proof of Admission', 'proof_of_admission')}
          valuePropName="fileList">
          <FileUploader
            form={form}
            maxFileSize={2}
            maxCount={10}
            storagePath={`userApplication/${uid}/loans/proof_of_admission`}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Please upload your Undergraduate Marksheets (per semester)',
            },
          ]}
          name="under_graduate_result"
          label={getLabel('Undergraduate Marksheets', 'under_graduate_result', '(per semester)')}
          valuePropName="fileList">
          <FileUploader
            form={form}
            maxFileSize={2}
            maxCount={10}
            storagePath={`userApplication/${uid}/loans/under_graduate_result`}
          />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: 'Please upload your International entrance test results' },
          ]}
          name="entrance_exam_result_abroad"
          label={getLabel(
            'International Entrance Test Results',
            'entrance_exam_result_abroad',
            '(GRE, GMAT, TOEFL, etc.)',
          )}
          valuePropName="fileList">
          <FileUploader
            form={form}
            maxFileSize={2}
            maxCount={10}
            storagePath={`userApplication/${uid}/loans/entrance_exam_result_abroad`}
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Please upload your Indian entrance test results' }]}
          name="entrance_exam_result_domestic"
          label={getLabel(
            'Indian Entrance Test Results',
            'entrance_exam_result_domestic',
            '(CAT, CET, GMAT, CMAT, JEE, NEET, GRE, etc.)',
          )}
          valuePropName="fileList">
          <FileUploader
            form={form}
            maxFileSize={2}
            maxCount={10}
            storagePath={`userApplication/${uid}/loans/entrance_exam_result_domestic`}
          />
        </Form.Item>

        <Form.Item style={{ width: '100%', marginTop: 56 }}>
          <Button disabled={current !== ACADEMICS_CURRENT} type="primary" htmlType="submit">
            Submit <RightOutlined />
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
