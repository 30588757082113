import React from 'react';
import { FillFormStep, StepStatus } from '../../../repos';
import Styles from '../CustomStep.module.sass';
import { customStepIllustrations, commonIllustrations } from '../../../assets';
import { AlertProps, AlertType } from '../../Alert';
import { StepFulfillmentView } from './StepFulfillmentView';
import { Widget } from '@typeform/embed-react';

interface Props {
  step: FillFormStep;
  onSubmit(): void;
  onMarkAsComplete(): void;
}

export const getFillFormAlert = (step: FillFormStep): AlertProps | undefined => {
  switch (step.status) {
    case StepStatus.Locked:
      return;
    case StepStatus.Incomplete:
      return;
    case StepStatus.Processing:
      return { type: AlertType.Processing, message: 'We are processing your request.' };
    case StepStatus.ReadyForReview:
      return {
        type: AlertType.Ready,
        message: 'Your request is ready for review. Please review it below.',
      };
    case StepStatus.Complete:
      return {
        type: AlertType.Success,
        message: 'This step is done, you can always view our response below.',
      };
  }
};

export const FillFormStepView: React.FC<Props> = (props) => {
  const { step, onSubmit, onMarkAsComplete } = props;

  switch (step.status) {
    case StepStatus.Incomplete:
      const formID = step.typeformUrl.match(/to\/(.*)#/) || [''];
      return (
        <Widget
          id={formID[1]}
          onSubmit={onSubmit}
          style={{ background: `url(${commonIllustrations.loadingGif}) center center no-repeat` }}
          className={Styles.fillFormStepContainerIncomplete}
          hideHeaders
          hideFooter
          opacity={100}
        />
      );
    case StepStatus.Processing:
      return (
        <div key={step.status} className={Styles.fillFormStepContainerProcessing}>
          <div className={Styles.answersContainer}>
            {!step.userResponse ? (
              <span>We are processing your answers, they'll show up here in a few moments.</span>
            ) : (
              Object.entries(step.userResponse).map(([id, value]) => {
                return (
                  <div key={id} className={Styles.answerItem}>
                    <span key={`question_${id}`} className={Styles.question}>
                      {value.question}
                    </span>
                    <span key={`answer_${id}`} className={Styles.answer}>
                      {value.answer}
                    </span>
                  </div>
                );
              })
            )}
          </div>
          <img src={customStepIllustrations.fillFormProcessing} />
        </div>
      );
    case StepStatus.ReadyForReview:
      return (
        <div key={step.status} className={Styles.fillFormStepContainerReady}>
          <StepFulfillmentView
            response={step.fulfillmentResponse!}
            primaryButton={{ text: 'Mark as Complete', onClick: () => onMarkAsComplete() }}
          />
        </div>
      );
    case StepStatus.Complete:
      return (
        <div key={step.status} className={Styles.fillFormStepContainerComplete}>
          <StepFulfillmentView response={step.fulfillmentResponse!} />
        </div>
      );
    case StepStatus.Locked:
      return <div></div>;
    case StepStatus.Pending:
      return <div></div>;
  }
};
