import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { mapValues, isArray, isPlainObject } from 'lodash';
import moment from 'moment';
import { Observable, Observer } from 'rxjs';
import { Environment } from './Environment';

export type Identifiable = { id: string };

export const applyDeleteMarkersForUndefined = (object: any): any => {
  return mapValues(object, (value, _) => {
    if (value === undefined) {
      return firebase.firestore.FieldValue.delete();
    }
    if (typeof value === 'object' && !(value instanceof Date)) {
      return applyDeleteMarkersForUndefined(value);
    }
    return value;
  });
};

export const firestoreCollectionListener = <T>(ref: firebase.firestore.CollectionReference, convert?: boolean): Observable<T> => {
  return new Observable((observer: Observer<T>) => {
    try {
      ref.onSnapshot(
        (querySnapshot) => {
          try {
            observer.next(
              querySnapshot.docs.reduce((acc, doc) => {
                acc[doc.id] = convert ? convertFirestoreTimestampFieldsToMoment(doc.data()) : doc.data();
                return acc;
              }, {} as any),
            );
          } catch (error) {
            if (Environment.env !== 'production') {
              observer.error(error);
            }
          }
        },
        (error) => {
          // console.debug(ref.id, error);
        },
      );
    } catch {}
  });
};

export const firestoreQueryListener = <T>(
  ref: firebase.firestore.CollectionReference | firebase.firestore.Query,
  synthesizer: (snapshot: firebase.firestore.DocumentSnapshot) => T = defaultDocumentSnapshotSynthesizer,
): Observable<T[]> => {
  return new Observable((observer: Observer<T[]>) => {
    try {
      ref.onSnapshot(
        (querySnapshot) => {
          try {
            observer.next(querySnapshot.docs.map(synthesizer));
          } catch (error) {
            // observer.error(error);
          }
        },
        (error) => {
          // console.debug(ref, error);
        },
      );
    } catch {}
  });
};

const defaultDocumentSnapshotSynthesizer = <T>(snapshot: firebase.firestore.DocumentSnapshot): T & Identifiable => {
  const data = snapshot.data();
  if (!data) {
    throw new Error('Document does not exist');
  }
  return { ...(data as any), id: snapshot.id };
};

export const firestoreDocumentListener = <T>(
  ref: firebase.firestore.DocumentReference,
  synthesizer: (snapshot: firebase.firestore.DocumentSnapshot) => T = defaultDocumentSnapshotSynthesizer,
): Observable<T> => {
  return new Observable((observer: Observer<T>) => {
    ref.onSnapshot(
      (documentSnapshot) => {
        if (!documentSnapshot.exists) {
          if (Environment.env !== 'production') new Error('Document snapshot does not exist');
          return;
        }
        try {
          observer.next(synthesizer(documentSnapshot));
        } catch (error) {
          // console.debug(ref.id, error);
        }
      },
      (error) => {},
    );
  });
};

export const firestoreDocumentListenerWithFallback = <T>(
  ref: firebase.firestore.DocumentReference,
  fallbackValue: T,
  synthesizer: (snapshot: firebase.firestore.DocumentSnapshot) => T = defaultDocumentSnapshotSynthesizer,
): Observable<T> => {
  return new Observable((observer: Observer<T>) => {
    ref.onSnapshot(
      (documentSnapshot) => {
        if (!documentSnapshot.exists) {
          observer.next(fallbackValue);
          return;
        }
        try {
          observer.next(synthesizer(documentSnapshot));
        } catch (error) {
          // console.debug(ref.id, error);
        }
      },
      (error) => {
        // console.debug(ref.id, error);
      },
    );
  });
};

export const convertFirestoreTimestampFieldsToMoment = (data: any): any => {
  switch (true) {
    case isArray(data):
      return (data as any[]).map((item) => convertFirestoreTimestampFieldsToMoment(item));
    case data instanceof firebase.firestore.Timestamp:
      return moment((data as firebase.firestore.Timestamp).seconds * 1000);
    case typeof data === 'string':
      return data.includes('202') && moment(data).isValid() ? moment(data) : data; // Hack if agenda has year or not.
    case isPlainObject(data):
      return Object.entries(data).reduce((acc, [key, value]) => {
        acc[key] = convertFirestoreTimestampFieldsToMoment(value);
        return acc;
      }, {} as { [k: string]: any });
    default:
      return data;
  }
};

export const convertMomentObjectsToDate = (data: any) => {
  return Object.entries(data).reduce((acc, [key, value]) => {
    acc[key] = value;
    if (moment.isMoment(value)) {
      acc[key] = value.toDate();
    }
    return acc;
  }, {} as { [k: string]: any });
};
