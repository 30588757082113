import React, { useEffect, useState } from 'react';

import { RightOutlined, SelectOutlined } from '@ant-design/icons';
import { faStopwatch, faShieldCheck, faBadgePercent } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Button, Modal } from 'antd';
import Styles from './ForexStepContainer.module.sass';
import { stageIcons } from '../../repos';
import ForexRatesTable from './ForexRatesTable';

export const WelcomeScreen: React.FC<any> = (props) => {
  const [expanded, setExpanded] = useState(false);
  const { setShowWelcomeScreen } = props;

  useEffect(() => {
    if (expanded) {
      Modal.info({
        content: <ForexRatesTable expanded={true} />,
        onCancel: () => setExpanded(false),
        className: Styles.modalContainer,
        maskClosable: true,
      });
    }
  }, [expanded]);
  return (
    <div className={Styles.welcomeScreen + ' animate__animated animate__fadeIn'}>
      <div className={Styles.welcomeScreenContainer}>
        <Row justify="space-between" align="middle" className={Styles.welcomeBanner}>
          <Col span={15} className={Styles.header}>
            <img src={stageIcons.forex} alt="health insurance icon"></img>
            <h2>Get a US debit card in seconds.</h2>
          </Col>
          <Col span={9}>
            <Row justify="end">
              <Button
                style={{ marginRight: '8px' }}
                type={'primary'}
                onClick={() => {
                  setShowWelcomeScreen(false);
                }}>
                Get Started <RightOutlined />
              </Button>
              <Button
                className={Styles.compareButton}
                onClick={() => {
                  setExpanded(true);
                }}>
                Compare <SelectOutlined rotate={90} />
              </Button>
            </Row>
          </Col>
        </Row>
        <Row justify="center" style={{ width: '100%' }}>
          <Row justify="space-between" className={Styles.iconsContainer}>
            <Col span={8}>
              <FontAwesomeIcon icon={faStopwatch} />
              <p>Generated in seconds.</p>
            </Col>
            <Col span={8}>
              <FontAwesomeIcon icon={faShieldCheck} />
              <p>Instant transfers from India.</p>
            </Col>
            <Col span={8}>
              <FontAwesomeIcon icon={faBadgePercent} />
              <p>Lowest Forex Rates - Guaranteed</p>
            </Col>
          </Row>
          <ForexRatesTable expanded={expanded} setExpanded={setExpanded} />
        </Row>
      </div>
    </div>
  );
};
