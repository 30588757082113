import { RouteComponentProps } from '@reach/router';
import { Carousel, Pagination } from 'antd';
import React, { CSSProperties } from 'react';
import { VideoCard } from '../../components';
import { Video } from '../../repos';
import { Analytics, AnalyticsEventName } from '../../utils';
import './styles.scss';
import { UserJourneyStage } from '../../config/userJourneyMap';
import { CarouselRef } from 'antd/lib/carousel';

interface Props extends RouteComponentProps {
  videos: Video[];
  carouselHeight: string | number;
  associatedStage: UserJourneyStage;
  style?: CSSProperties;
}

export class VideoCarousel extends React.Component<Props> {
  carousel: CarouselRef | null = null;
  carouselStep = 0;

  onPageChange = (page: number) => {
    if (!this.carousel) {
      return;
    }
    if (this.carouselStep + 1 === page) {
      this.carousel.next();
    } else if (this.carouselStep - 1 === page) {
      this.carousel.prev();
    } else {
      this.carousel.goTo(page, false);
    }
    this.carouselStep = page;
  };

  render() {
    const { videos, carouselHeight, style, associatedStage } = this.props;

    if (videos.length === 0) {
      return null;
    }

    return (
      <div style={{ flexDirection: 'column', width: '100%' }}>
        <Carousel
          className="video-carousel"
          style={style}
          ref={(ref) => (this.carousel = ref)}
          dots={false}>
          {videos.map((video) => (
            <VideoCard
              onVideoPlayed={() =>
                Analytics.log(AnalyticsEventName.ContentConsumed, {
                  associatedStage: associatedStage.title,
                  metadata: { contentId: video.id, contentType: video.type },
                })
              }
              videoHeight={carouselHeight}
              key={video.id}
              video={video}
            />
          ))}
        </Carousel>
        <Pagination
          style={{ margin: '16px auto' }}
          size="small"
          pageSize={1}
          total={videos.length}
          onChange={this.onPageChange}
        />
      </div>
    );
  }
}
