import { MessageOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { UserProfile } from '../../repos';
import { useMobile } from '../../services';
import { IntercomAPI } from '../Intercom';
import Styles from './WelcomeUser.module.sass';
import { navigate } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStars } from '@fortawesome/pro-duotone-svg-icons';
import { Environment } from '../../utils';
import { welcomePicture } from '../../assets';

interface Props {
  currentUser?: UserProfile | null;
  className?: string;
  route?: string;
}

export const WelcomeUser: React.FC<Props> = (props) => {
  const { currentUser, className, route } = props;
  const isMobile = useMobile();
  if (isMobile) {
    return (
      <div className={Styles.mobileHelp}>
        Have questions?
        <Button
          className={Styles.chatButton}
          onClick={() => IntercomAPI.show()}
          icon={<MessageOutlined />}
          type='primary'>
          Chat with us
        </Button>
      </div>
    );
  }
  if (Environment.env === 'production')
    return (
      <div className={className}>
        <div className={Styles.messageBox}>
          <div className={Styles.title}>Rishabh from Gradly</div>
          <div className={Styles.message}>
            {currentUser &&
              `Hi${
                currentUser.name && `, ${currentUser.name.split(' ')[0]}`
              }! I’m Rishabh from Gradly. I’m here to help you with anything you need during your immigration process.`}
          </div>
          <Button className={Styles.chatButton} onClick={() => IntercomAPI.show()} type='primary' size='small'>
            Chat with Rishabh
          </Button>
        </div>
        <img style={{ width: 183 }} src={welcomePicture} alt='Rishabh' />
      </div>
    );
  else
    return (
      <div className={className}>
        <div className={Styles.messageBoxAI}>
          <div className={Styles.title}>Gradly AI</div>
          <div className={Styles.message}>
            {currentUser &&
              `Hi, ${
                currentUser.name.split(' ')[0]
              }! I'm Gradly's health insurance co-pilot. Feel free to ask me any questions.`}
          </div>

          <Button
            className={Styles.chatButtonAI}
            onClick={() => {
              navigate(`/${route}/chat`);
            }}
            type='primary'
            size='small'>
            <FontAwesomeIcon icon={faStars} style={{ marginRight: 4 }} />
            Chat with Gradly Co-Pilot
          </Button>
        </div>
        {/* <img style={{ width: 183 }} src={welcomePicture} alt="Rishabh" /> */}
      </div>
    );
};
