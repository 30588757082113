import { Card, Col, Row } from 'antd';
import React from 'react';
import Styles from './CardTable.module.sass';

interface CardHeader {
  content: any;
  className?: string;
}

interface CardRow {
  contents: any[];
  className?: string;
  tag?: string;
  highlight?: boolean;
}

export interface CardTableData {
  headers?: CardHeader[];
  rows: CardRow[];
  spans?: number[];
}

interface CardTableProps {
  className?: string;
  data: CardTableData;
  align?: 'middle' | 'top' | 'bottom' | 'stretch' | undefined;
  justify?: 'center' | 'start' | 'end' | 'space-around' | 'space-between' | undefined;
}

export const CardTable: React.FC<CardTableProps> = (props) => {
  const { data, align, justify } = props;
  const { headers, rows, spans } = data;
  return (
    <Row className={`${Styles.container} ${props.className || ''}`}>
      {headers && (
        <Row align={'middle'} justify={'center'} className={Styles.headerContainer}>
          {headers.map((item, index) => (
            <Col
              className={`${Styles.header} ${item.className || ''}`}
              key={index}
              span={spans ? spans[index] : Math.round(24 / headers.length)}>
              {item.content}
            </Col>
          ))}
        </Row>
      )}
      {rows.map((row, index) => (
        <Card key={index} hoverable className={Styles.cards}>
          <Row
            align={align || 'middle'}
            justify={justify || 'center'}
            className={`${Styles.row} ${row.className || ''}`}>
            {row.contents.map((content, index) => (
              <Col
                className={`${Styles.content} ${content.className || ''}`}
                key={index}
                span={spans ? spans[index] : Math.round(24 / row.contents.length)}>
                {content.content}
              </Col>
            ))}
          </Row>
        </Card>
      ))}
    </Row>
  );
};

export default CardTable;
