import React, { useEffect, useMemo, useState } from 'react';

import { RightOutlined, MessageOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Radio, Row, Tag } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { GradlyLoading } from '../../App';
import { RootState, setSelectedPlan } from '../../redux';
import { PlanType } from '../../repos';
import { ComparisonData } from './HealthInsuranceStepContainer';
import { calPremiumWithData } from './helpers';
import Styles from './Recommendation.module.sass';

interface UsesSubItems {
  amount: number;
  label: string;
}

const { Panel } = Collapse;
interface LineItems {
  title: string;
  img: string;
  items: UsesSubItems[];
}

interface InsuranceUses {
  savings: number;
  lineItems: LineItems[];
}

export interface RecommendationData {
  plan: PlanType;
  finalPremium: number;
  lowUse: InsuranceUses;
  highUse: InsuranceUses;
  mediumUse: InsuranceUses;
}

interface Props {
  comparisonData: ComparisonData;
}

export const Recommendation: React.FC<Props> = (props) => {
  const { comparisonData } = props;
  const [recData, setRecData] = useState<RecommendationData>();
  const insuranceRoot = useSelector((state: RootState) => state.Insurance);
  const plans = useMemo(() => insuranceRoot.availablePlans || [], [insuranceRoot]);
  const selectedPlan = insuranceRoot.selectedPlan;
  const dispatch = useDispatch();

  const [insuranceUse, setInsuranceUse] = useState<any>('lowUse');

  const [op, setOp] = useState<{ name: string; amount: string }[]>([]);

  const buildOP = (plans: PlanType[]) => {
    const opt = [];
    for (const plan of plans.slice(0, 5)) {
      const p = calPremiumWithData(
        comparisonData.dob.format('YYYY-MM-DD'),
        comparisonData.university,
        plan,
        moment(),
        moment().add(1, 'year').subtract(1, 'day'),
      );
      if (p > 0) opt.push({ name: plan.retailName, amount: p.toFixed(2) });
    }
    return opt;
  };

  useEffect(() => {
    if (plans.length > 0) {
      setOp(buildOP(plans));
    }

    if (comparisonData && plans.length > 0) {
      const plan = plans[0];
      // dispatch(setSelectedPlan(plan))
      const premium = calPremiumWithData(
        comparisonData.dob.format('YYYY-MM-DD'),
        comparisonData.university,
        plan,
        moment(),
        moment().add(1, 'year').subtract(1, 'day'),
      );
      if (!premium) return;
      const d = {
        savings: 1428,
        lineItems: [
          {
            title: 'Sick Visit (Flu)',
            img: 'https://res.cloudinary.com/gradly/image/upload/v1657198703/assets/insuranceIllus/Sick_Visit_3x_mmyamk.png',
            items: [
              { amount: 150, label: 'Doctor Visit Cost' },
              { amount: 30, label: 'Medication Cost' },
            ],
          },
          {
            title: 'Follow Up Visit',
            img: 'https://res.cloudinary.com/gradly/image/upload/v1657198725/assets/insuranceIllus/Preventive_Care_Visit_3x_wctega.png',
            items: [{ amount: 100, label: 'Doctor Visit Cost' }],
          },
        ],
      };
      const rData: RecommendationData = {
        plan: plan,
        finalPremium: premium,
        lowUse: d,
        highUse: d,
        mediumUse: d,
      };
      setRecData(rData);
    }
  }, [comparisonData, plans]);

  if (recData && selectedPlan)
    return (
      <Row className={Styles.container}>
        <Row justify="center" className={Styles.useContainer}>
          <Radio.Group
            value={insuranceUse}
            optionType="button"
            buttonStyle="solid"
            onChange={(e) => setInsuranceUse(e.target.value)}>
            <Radio.Button defaultChecked value="lowUse">
              Low Use
            </Radio.Button>
            <Radio.Button value="mediumUse">Medium Use</Radio.Button>
            <Radio.Button value="highUse">High Use</Radio.Button>
          </Radio.Group>
        </Row>
        <Col xs={24} lg={14} className={Styles.infoContainer}>
          <h1>
            Estimated Annual Expense with <span>{recData.plan.retailName.replace('Gradly', '')}</span>:{' '}
            <span>${recData.lowUse.savings}</span>
          </h1>
          <Row className={Styles.tags}>
            <Tag color={'blue'}>Deductible: ${recData.plan.planFeatures.deductible}</Tag>
            <Tag color={'orange'}>Copayment: ${recData.plan.planFeatures.officeVisitCoPay} per visit</Tag>
            <Tag color="red">Out of Pocket Maximum: {recData.plan.planFeatures.outOfPocketMax}</Tag>
          </Row>
          <Row className={Styles.tContainer}>
            <Row className={Styles.tRow}>
              <label>Insurance Premium</label>
              <span>${recData.finalPremium}</span>
            </Row>
            <Collapse
              className={Styles.priceCollapse}
              defaultActiveKey={['0']}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              ghost>
              {recData.lowUse.lineItems.map((lineItem, index) => (
                <Panel
                  header={
                    <Row className={Styles.subRow} key={index}>
                      <label>{lineItem.title}</label>
                      <span>
                        $
                        {lineItem.items.reduce((acc, item) => {
                          return acc + item.amount;
                        }, 0)}
                      </span>
                    </Row>
                  }
                  key={index}>
                  {lineItem.items.map((acc, index2) => {
                    return (
                      <Row className={Styles.subRow} key={index2}>
                        <label>{acc.label}</label>
                        <span>${acc.amount}</span>
                      </Row>
                    );
                  })}
                </Panel>
              ))}
            </Collapse>

            <Row className={Styles.tRow}>
              <label>Total expense</label>
              <span>
                $
                {recData.finalPremium +
                  recData.lowUse.lineItems.reduce(
                    (acc1, lineItem) => acc1 + lineItem.items.reduce((acc, item) => acc + item.amount, 0),
                    0,
                  )}
              </span>
            </Row>
          </Row>
          <Row className={Styles.pButtons}>
            <Button type="primary">
              {' '}
              Purchase Plan <RightOutlined />
            </Button>
            <Button>
              {' '}
              <MessageOutlined /> Chat with us
            </Button>
          </Row>
        </Col>
        <Col xs={0} lg={10}>
          <Row className={Styles.breakdown}>
            <span className={Styles.totalCost}>
              Total cost: $
              {recData.lowUse.lineItems.reduce(
                (acc1, lineItem) => acc1 + lineItem.items.reduce((acc, item) => acc + item.amount, 0),
                0,
              )}{' '}
            </span>
            {recData.lowUse.lineItems.map((item, index) => (
              <>
                <Row key={index} className={Styles.lineItem}>
                  <Col span={8}>
                    <img src={item.img} alt="doctor"></img>
                  </Col>
                  <Col span={16} className={Styles.lineItemText}>
                    <Row key={index} className={Styles.btitle}>
                      {' '}
                      <h1>{item.title}</h1>
                      {item.items.map((i, index2) => (
                        <Row key={index2} className={Styles.bRow}>
                          ${i.amount} - {i.label}{' '}
                        </Row>
                      ))}{' '}
                    </Row>
                  </Col>
                </Row>{' '}
                {recData.lowUse.lineItems.length - 1 !== index && <div className={Styles.downArrow} />}
              </>
            ))}
          </Row>
        </Col>
        <Row className={Styles.otherPlans}>
          <div className={Styles.header}>
            <h1>Other Plan Options:</h1>
            <Button type="link">
              {' '}
              Compare All Plans <RightOutlined />
            </Button>
          </div>
          <Row className={Styles.pCards}>
            {op
              .filter((p) => p.name !== selectedPlan.retailName)
              .map((plan, i) => (
                <Col key={i} className={Styles.planCard}>
                  <h1>{plan.name}</h1> <span>${plan.amount}</span>
                  <Button type="link">
                    View plan
                    <RightOutlined />
                  </Button>
                </Col>
              ))}
          </Row>
          <h2>Includes annual premium and other estimated expenses.</h2>
        </Row>
      </Row>
    );
  else {
    return <GradlyLoading />;
  }
};
