import { Button } from 'antd';
import React from 'react';
import Styles from './AskAIButton.module.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStars } from '@fortawesome/pro-solid-svg-icons';
import { navigate } from '@reach/router';

interface Props {
  route?: string;
}

export const AskAIButton: React.FC<Props> = (props) => {
  const { route } = props;
  return (
    <Button
      className={Styles.chatButton}
      onClick={() => {
        navigate(`../chat`);
      }}
      type="primary"
      size="small">
      <FontAwesomeIcon icon={faStars} style={{ marginRight: 4 }} />
      Ask AI
    </Button>
  );
};
