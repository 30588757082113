import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Calendar, Row, Col, Button } from 'antd';
import { CalendarMode } from 'antd/lib/calendar/generateCalendar';
import moment, { Moment } from 'moment';
import React, { FC, useEffect } from 'react';
import Styles from './SlotBook.module.sass';

interface SlotBookProps {
  setSelectedDate: (date: Moment) => void;
  setSelectedSlot: (slot: Moment) => void;
  slots: Moment[];
}

const dateRenderer = (currentDate: Moment) => {
  return currentDate.format('D');
};

const headerRender = (calendar: {
  value: Moment;
  type: CalendarMode;
  onChange: (date: Moment) => void;
  onTypeChange: (type: CalendarMode) => void;
}) => {
  return (
    <Row justify="space-between" align="middle" className={Styles.calHeader}>
      <Button
        type="link"
        icon={<LeftOutlined />}
        disabled={moment(calendar.value.toDate())
          .subtract(1, 'month')
          .isBefore(moment().startOf('month'))}
        onClick={() => {
          calendar.onChange(calendar.value.subtract(1, 'month'));
        }}></Button>
      {calendar.value.format('MMM YYYY')}
      <Button
        type="link"
        icon={<RightOutlined />}
        onClick={() => {
          calendar.onChange(calendar.value.add(1, 'month'));
        }}></Button>
    </Row>
  );
};

export const SlotBook: FC<SlotBookProps> = (props) => {
  const { setSelectedDate, slots, setSelectedSlot } = props;

  useEffect(() => {
    if (slots[0]) setSelectedSlot(slots[0]);
  }, [setSelectedSlot, slots]);

  return (
    <>
      <Calendar
        className={Styles.calendar}
        dateFullCellRender={dateRenderer}
        headerRender={headerRender}
        disabledDate={(currentDate) => currentDate.isBefore(moment(), 'day')}
        onSelect={(date) => {
          setSelectedDate(moment(date));
        }}
        fullscreen={false}
      />
      <Row className={Styles.slots}>
        {slots.map((slot) => {
          return (
            <Col span={5} key={slot.format('hh:mm')}>
              <Button
                shape="round"
                onClick={() => {
                  setSelectedSlot(slot);
                }}>
                {slot.format('hh:mm A')}
              </Button>
            </Col>
          );
        })}
      </Row>
    </>
  );
};
