import { Moment } from 'moment';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  convertFirestoreTimestampFieldsToMoment,
  Environment,
  firestoreQueryListener,
  FirestoreReference,
} from '../../utils';

export interface WebinarRegistration {
  id: string;
  conferenceId: string;
  attendeeId: string;
  attendeeName: string;
  didUnregister: boolean;
  createdAt: Moment;
  updatedAt: Moment;
}

export const listenToWebinarRegistrations = (userId: string): Observable<WebinarRegistration[]> => {
  return firestoreQueryListener(
    FirestoreReference.WebinarRegistrations()
      .where('attendeeId', '==', userId)
      .where('didUnregister', '==', false),
    (snapshot) =>
      convertFirestoreTimestampFieldsToMoment({
        ...snapshot.data(),
        id: snapshot.id,
      }) as WebinarRegistration,
  );
};

export const registerForWebinar = (conferenceId: string, idToken: string): Observable<boolean> => {
  return from(
    fetch(`${Environment.api.baseUrl}/webinars/bigMarker/register`, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        authorization: idToken,
      },
      body: JSON.stringify({ conferenceId }),
    }),
  ).pipe(
    switchMap((response: Response) =>
      from(response.json()).pipe(map((json) => ({ json, ok: response.ok }))),
    ),
    map((response) => {
      if (response.ok) {
        return true;
      }
      throw new Error(response.json.message);
    }),
  );
};

export const unregisterForWebinar = (
  conferenceId: string,
  idToken: string,
): Observable<boolean> => {
  return from(
    fetch(`${Environment.api.baseUrl}/webinars/bigMarker/register`, {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        authorization: idToken,
      },
      body: JSON.stringify({ conferenceId }),
    }),
  ).pipe(
    switchMap((response: Response) =>
      from(response.json()).pipe(map((json) => ({ json, ok: response.ok }))),
    ),
    map((response) => {
      if (response.ok) {
        return true;
      }
      throw new Error(response.json.message);
    }),
  );
};
