import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { CloseCircleOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Routes } from '../../App';
import { authIllustration, logo } from '../../assets';
import { RootState, setConfirmationResult, setError, signInUsingEmail } from '../../redux';
import { Analytics, AnalyticsEventName } from '../../utils';
import Styles from './styles.module.sass';
import { Alert, AlertType } from '../../components';

interface Props extends RouteComponentProps {
  loading: boolean;
  signInUsingEmail: typeof signInUsingEmail;
  setConfirmationResult: typeof setConfirmationResult;
}

const SignInContainer = (props: Props) => {
  const { signInUsingEmail, loading, setConfirmationResult } = props;
  const { error } = useSelector((state: RootState) => state.Common);
  const dispatch = useDispatch();

  useEffect(() => {
    setConfirmationResult();
    dispatch(setError());
  }, []);

  const signIn = (data: any) => {
    Analytics.log(AnalyticsEventName.UserSignInClicked, { origin: 'email-password' });
    const { email, password } = data;
    signInUsingEmail(email, password);
  };

  return (
    <Row wrap={false} className={Styles.container}>
      <Col xs={0} md={0} xl={8}>
        <div className={Styles.authIllustration}>
          <img src={authIllustration} alt="Auth"></img>
        </div>
      </Col>
      <Col flex={'auto'}>
        <Row justify="center" className={Styles.authContainer}>
          <img src={logo} alt="Gradly Logo" />
          <h1>Sign In</h1>
          <h2>
            Don’t have an account?
            <Link to={Routes.SignUp}>Sign up</Link>
          </h2>
          {error && (
            <Alert
              className={Styles.alerts}
              message={error}
              type={AlertType.Error}
              icon={<CloseCircleOutlined />}></Alert>
          )}
          <Form
            name="signin"
            size="large"
            validateTrigger="onBlur"
            className={Styles.form}
            onFinish={(data) => {
              signIn(data);
            }}>
            <Form.Item
              name={'email'}
              rules={[
                {
                  type: 'email',
                  message: 'This is not a valid email address!',
                },
                {
                  required: true,
                  message: 'Please input your email address!',
                },
              ]}>
              <Input type="email" placeholder="Email address"></Input>
            </Form.Item>

            <Form.Item
              name={'password'}
              rules={[{ required: true, message: 'Please input your Password!' }]}>
              <Input.Password
                placeholder="Password"
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <div className={Styles.switchAuth}>
              <Link to={Routes.ResetPassword}>Forgot password?</Link>
            </div>

            <Form.Item>
              <Button loading={loading} block type="primary" htmlType="submit">
                Sign in
              </Button>
            </Form.Item>
          </Form>
          <Link className={Styles.secondaryButton} to={Routes.PasswordlessSignIn}>
            Sign in without password
          </Link>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: RootState) => {
  const { loading } = state.Auth;
  return { loading };
};

export default connect(mapStateToProps, { signInUsingEmail, setConfirmationResult })(
  SignInContainer,
);
