import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { CloseCircleOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Routes } from '../../App';
import { authIllustration, logo } from '../../assets';
import { logout, RootState, setConfirmationResult, setCurrentUser, setError, signUp } from '../../redux';
import { Analytics, AnalyticsEventName } from '../../utils';
import Styles from './styles.module.sass';
import { getCountries, getCountryCallingCode, isValidNumber } from 'libphonenumber-js';
import { Alert, AlertType } from '../../components';

interface Props extends RouteComponentProps {
  confirmationResult?: firebase.auth.ConfirmationResult;
  loading: boolean;
  signUp: typeof signUp;
  setConfirmationResult: typeof setConfirmationResult;
  email?: string;
}

const SignUpContainer = (props: Props) => {
  const currentUser = useSelector((state: RootState) => state.Auth.currentUser);

  const isdCodes = getCountries().map((country) => {
    return {
      label: `${country} (+${getCountryCallingCode(country)})`,
      value: `${country}:${getCountryCallingCode(country)}`,
    };
  });

  const prefixSelector = (
    <Form.Item hasFeedback name="prefix" noStyle>
      <Select
        bordered={false}
        showSearch
        style={{ width: 120 }}
        options={isdCodes}
        filterOption={(input, option) =>
          !!(option && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0)
        }></Select>
    </Form.Item>
  );

  useEffect(() => {
    setConfirmationResult();
  }, []);

  const { signUp, confirmationResult, loading } = props;
  const { successMessage, error } = useSelector((state: RootState) => state.Common);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setError());
  }, []);

  const submit = (data: any) => {
    confirmationResult && Analytics.log(AnalyticsEventName.UserSignUpClicked);
    const {
      otp,
      firstName,
      middleName,
      lastName,
      email,
      isUniversityEmail,
      prefix,
      phone,
      isHomeCountryPhoneNumber,
      password,
    } = data;
    signUp(
      !!currentUser?.isAnonymous,
      otp,
      firstName,
      middleName,
      lastName,
      email,
      isUniversityEmail,
      `+${(prefix.value || prefix).split(':')[1]}${phone}`,
      isHomeCountryPhoneNumber,
      password,
    );
  };

  return (
    <Row wrap={false} className={Styles.container}>
      {currentUser?.isAnonymous ? null : (
        <Col xs={0} md={0} xl={8}>
          <div className={Styles.authIllustration}>
            <img src={authIllustration} alt="Auth"></img>
          </div>
        </Col>
      )}
      <Col flex={'auto'}>
        <Row className={Styles.authContainer}>
          {!currentUser?.isAnonymous && <img src={logo} alt="Gradly Logo" />}
          <h1>Sign Up</h1>
          <h2>
            Already have an account?
            <Link
              onClick={() => {
                dispatch(logout());
                dispatch(setCurrentUser(null));
              }}
              to={Routes.Home}>
              Sign In
            </Link>
          </h2>
          {error && (
            <Alert
              className={Styles.alerts}
              message={error}
              type={AlertType.Error}
              icon={<CloseCircleOutlined />}></Alert>
          )}
          <Form
            name="signup"
            validateTrigger="onBlur"
            initialValues={{
              email: props.email,
              isUniversityEmail: true,
              isHomeCountryPhoneNumber: true,
              prefix: { value: 'IN:91' },
            }}
            size="large"
            className={Styles.form}
            onFinish={(data) => {
              submit(data);
            }}>
            <Row gutter={[10, 0]}>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item name={'firstName'} rules={[{ required: true, message: 'Please input your first name!' }]}>
                  <Input placeholder="First Name"></Input>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item name={'middleName'}>
                  <Input placeholder="Middle Name"></Input>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Form.Item name={'lastName'} rules={[{ required: true, message: 'Please input your last name!' }]}>
                  <Input placeholder="Last Name"></Input>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name={'email'}
              rules={[
                {
                  type: 'email',
                  message: 'This is not a valid email address!',
                },
                {
                  required: true,
                  message: 'Please input your email address!',
                },
              ]}>
              <Input type="email" placeholder="Email address"></Input>
            </Form.Item>
            <div className={Styles.switchAuth}>
              Is your email from a university?
              <Form.Item noStyle name="isUniversityEmail" valuePropName="checked">
                <Switch unCheckedChildren="No" checkedChildren="Yes" />
              </Form.Item>
            </div>

            <Form.Item
              hasFeedback
              name={'phone'}
              rules={[
                {
                  required: true,
                  message: 'Please enter your phone number!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const prefix = getFieldValue('prefix').value || getFieldValue('prefix');
                    const phoneNumber = `+${prefix.split(':')[1]}${value}`;
                    if (!value || (phoneNumber && isValidNumber(phoneNumber))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Please enter a valid phone number!'));
                  },
                }),
              ]}>
              <Input type="tel" addonBefore={prefixSelector} />
            </Form.Item>
            <p>If you do not use WhatsApp, provide us with a number where we can reach you easily if needed.</p>
            <div className={Styles.switchAuth}>
              Is this your home country phone number?
              <Form.Item noStyle className={Styles.switchAuth} valuePropName="checked" name="isHomeCountryPhoneNumber">
                <Switch unCheckedChildren="No" checkedChildren="Yes" />
              </Form.Item>
            </div>

            <Form.Item
              hasFeedback
              name={'password'}
              rules={[{ required: true, message: 'Please input your Password!' }]}>
              <Input.Password
                placeholder="Password"
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item
              name={'confirm_pass'}
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
              ]}>
              <Input.Password
                placeholder="Confirm Password"
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
              />
            </Form.Item>
            <Form.Item>
              <Button loading={loading} block type="primary" htmlType="submit">
                Sign up
              </Button>
            </Form.Item>
          </Form>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: RootState) => {
  const { confirmationResult, loading } = state.Auth;
  return { confirmationResult, loading };
};

export default connect(mapStateToProps, { signUp, setConfirmationResult })(SignUpContainer);
