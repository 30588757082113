import { message } from 'antd';

const isScriptAlreadyLoaded = (url: string) =>
  document.querySelectorAll(`script[src="${url}"]`).length > 0;

export const DOMHelpers = {
  isScriptAlreadyLoaded,
};

export const downloadFile = (fileURL: string, fileName: string) => {
  message.loading({ key: 'downloading', content: 'Downloading...', duration: 0 });

  fetch(fileURL)
    .then((response) => {
      if (response.redirected) {
        window.location.href = response.url;
      }
      response
        .blob()
        .then((blob) => {
          return {
            contentType: response.headers.get('Content-Type'),
            raw: blob,
          };
        })
        .then((data) => {
          const url = window.URL.createObjectURL(new Blob([data.raw]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          message.destroy('downloading');
        });
    })
    .catch((err) => {
      console.error(err);
      message.destroy('downloading');
    });
};
