import { action, ActionType } from 'typesafe-actions';
import { University, Notification } from '../../repos';

export enum CommonActionTypes {
  SetError = 'CommonAction/SetError',
  SetMessage = 'CommonAction/SetMessage',
  SearchUniversity = 'CommonAction/SearchUniversity',
  SetUniversities = 'CommonAction/SetUniversities',
  ListenToNotifications = 'CommonAction/ListenToNotifications',
  SetNotification = 'CommonAction/SetNotification',
}

export const setMessage = (message?: string) => action(CommonActionTypes.SetMessage, { message });

export const setAuthError = () =>
  setError('There is a problem with your authentication, please logout & sign-in again.');

export const setError = (error?: string) => action(CommonActionTypes.SetError, { error });

export const searchUniversity = (query: string) =>
  action(CommonActionTypes.SearchUniversity, { query });

export const setUniversities = (universities: University[]) =>
  action(CommonActionTypes.SetUniversities, { universities });

export const setNotification = (notification: Notification) =>
  action(CommonActionTypes.SetNotification, { notification });

export const listenToNotifications = () => action(CommonActionTypes.ListenToNotifications);

const commonActions = {
  setMessage,
  setError,
  searchUniversity,
  setUniversities,
  setNotification,
  listenToNotifications,
};

export type CommonAction = ActionType<typeof commonActions>;
