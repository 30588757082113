import { Modal } from 'antd';
import React from 'react';
import Styles from './SimpleModal.module.sass';

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  title: string | React.ReactElement;
}

export const SimpleModal: React.FC<Props> = (props) => {
  const { visible, setVisible, title } = props;

  return (
    <Modal
      className={Styles.insModal}
      visible={visible}
      centered
      closable
      maskClosable
      destroyOnClose
      footer={null}
      onCancel={() => {
        setVisible(false);
      }}
      title={<div className={Styles.title}>{title}</div>}>
      {props.children}
    </Modal>
  );
};
