import React from 'react';
import { RootState } from '../../redux';
import { connect } from 'react-redux';

interface Props {}

const UpdateProfileContainer: React.FC<Props> = props => {
  return <div></div>;
};

const mapStateToProps = (state: RootState) => {};

export default connect(
  mapStateToProps,
  {},
)(UpdateProfileContainer);
