import { DownloadOutlined, FilePdfOutlined, MessageOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { boxClip } from '../../assets';
import { InstructionalModal, IntercomAPI } from '../../components';
import { RootState, setSelectedPlan } from '../../redux';
import { InsuranceRequestGBGType, InsuranceRequestWellAwayType, PlanType } from '../../repos';
import { FirestoreReference, downloadFile } from '../../utils';

import Styles from './InsuranceDocs.module.sass';
import moment from 'moment';

interface InsuranceDocsProps {
  hideTitle?: boolean;
}

export const InsuranceDocs: React.FC<InsuranceDocsProps> = (props) => {
  const { hideTitle } = props;
  const { selectedPlan, allInsurances } = useSelector((state: RootState) => state.Insurance);
  const [plans, setPlans] = useState<PlanType[]>([]);
  const [showDocs, setShowDocs] = useState(false);
  const dispatch = useDispatch();

  async function getInsurancePlans(allInsurances: InsuranceRequestGBGType[] | InsuranceRequestWellAwayType[]) {
    const ps = [];
    for (const ins of allInsurances) {
      const plan = (await FirestoreReference.InsurancePlan(ins.insurancePlanID).get()).data() as PlanType;
      ps.push(plan);
    }
    if (ps) setPlans(ps);
  }

  useEffect(() => {
    if (allInsurances) {
      getInsurancePlans(allInsurances);
    }
  }, [allInsurances]);

  return (
    <Row align="top" className={Styles.container}>
      <InstructionalModal
        visible={showDocs}
        setVisible={setShowDocs}
        type={'article'}
        url={selectedPlan?.brochureURL || ''}
        title={
          <>
            <SolutionOutlined /> {selectedPlan?.retailName}: Insurance Plan
          </>
        }
        ctaQuestion={'Have questions about the plan?'}
        hideSecondaryTitle
        chatText={'Chat with us'}
        intercomMsg={'Hey! I need help with my insurance brochure.'}
      />
      <Card
        bordered={!!hideTitle}
        className={Styles.cardsContainer}
        title={
          <Row justify="start">
            <Col span={24}>
              <img src={boxClip} alt="ID Card"></img>
            </Col>
            <Col span={24}>
              <h2>Download your waiver</h2>
            </Col>
            <Col span={24}>
              <h3>
                Some of the information given in these documents will be required while filling out the waiver
                application.
              </h3>
            </Col>
          </Row>
        }>
        <Row>
          <Col span={24}>
            <h4>
              <UserOutlined /> Insurance ID cards
            </h4>
            <ul>
              {allInsurances &&
                allInsurances.map((insurance, index) => {
                  const name = ` ${insurance.insurancePlan} (${moment(insurance.coverageStartDate).format(
                    'MMM YYYY',
                  )} - ${moment(insurance.coverageEndDate).format('MMM YYYY')}).pdf`;
                  return (
                    <li
                      key={'card' + index}
                      onClick={() => {
                        downloadFile(insurance?.cardLongURL || '', name);
                      }}>
                      <DownloadOutlined></DownloadOutlined>
                      {name}
                    </li>
                  );
                })}
            </ul>
          </Col>
          <Col span={24}>
            <h4 style={{ marginTop: 24 }}>
              <FilePdfOutlined /> Plan Brochures
            </h4>
            <ul>
              {plans &&
                plans.map((plan, index) => (
                  <li
                    key={'brochure' + index}
                    onClick={() => {
                      dispatch(setSelectedPlan(plan));
                      setShowDocs(true);
                    }}>
                    <DownloadOutlined></DownloadOutlined>
                    {` ${plan.retailName} Brochure.pdf `}
                  </li>
                ))}
            </ul>
          </Col>
          {!hideTitle && (
            <Col span={24} className={Styles.helpButtons}>
              <span>Need help?</span>
              <Button
                type="primary"
                onClick={() => {
                  IntercomAPI.showWithMessagePreFilled(`Hey! I have question about my Insurance documents`, false);
                }}>
                <MessageOutlined />
                Chat with an Insurance Expert
              </Button>
            </Col>
          )}
        </Row>
      </Card>
    </Row>
  );
};
