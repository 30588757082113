import { produce } from 'immer';
import { CustomStep } from '../../repos';
import { CustomStepAction, CustomStepActionTypes } from '../actions';
import { UserJourneyStageId } from '../../config/userJourneyMap';

export type CustomStepKeyedByJourneyStageId = { [key in UserJourneyStageId]?: CustomStep[] };

export interface CustomStepStateType {
  readonly steps: CustomStepKeyedByJourneyStageId;
}

const initialState: CustomStepStateType = { steps: {} };

export const CustomStepsReducer = (
  currentState: CustomStepStateType = initialState,
  action: CustomStepAction,
) => {
  return produce(currentState, draft => {
    switch (action.type) {
      case CustomStepActionTypes.SetCustomSteps:
        draft.steps = action.payload.steps;
        break;
      default:
        break;
    }
    return draft;
  });
};
