import { Button, Input, Radio, Select } from 'antd';
import { startCase, toLower } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { TagSelect } from '../../components';
import { UserJourneyStageId, UserJourneyStages } from '../../config';
import { AdmissionSemester, Degree } from '../../repos';
type SubmitFunction = (
  year: string,
  levelOfStudy: Degree,
  admissionSemester: AdmissionSemester,
  helpItems: string[],
  major?: string,
) => void;

const helpChoices = Object.values(UserJourneyStageId)
  .filter((s) => s !== UserJourneyStageId.SopReview)
  .reduce((a, s) => {
    return { ...a, [s]: UserJourneyStages[s].title };
  }, {});

const toTitleCase = (str: string) => startCase(toLower(str));

const cycleOptions = [
  {
    label: `${toTitleCase(AdmissionSemester.Fall)} 2022`,
    value: `${AdmissionSemester.Fall} 2022`,
  },
  {
    label: `${toTitleCase(AdmissionSemester.Summer)} 2022`,
    value: `${AdmissionSemester.Summer} 2022`,
  },
  {
    label: `${toTitleCase(AdmissionSemester.Spring)} 2023`,
    value: `${AdmissionSemester.Spring} 2023`,
  },
  {
    label: 'I am already enrolled and in the US',
    value: `${AdmissionSemester.Enrolled} 2022`,
  },
];
export const ApplyingStageForm: React.FC<{ onSubmit: SubmitFunction }> = (props) => {
  const [major, setMajor] = useState<string>();
  const [levelOfStudy = Degree.Bachelors, setDegree] = useState<Degree>();
  const [year = moment().year().toString(), setYear] = useState<string>();
  const [helpItems = [], setHelpItems] = useState<UserJourneyStageId[]>();
  const [admissionSemester = AdmissionSemester.Fall, setAdmissionSemester] = useState<any>();
  const [admissionCycle, setAdmissionCycle] = useState<string>();

  return (
    <div style={{ flexDirection: 'column', maxWidth: '600px' }}>
      <div className="subtitle">
        <span role="img" style={{ marginRight: 4 }} aria-label="degree emoticon">
          🎓
        </span>{' '}
        Which degree did you apply for?
      </div>
      <Radio.Group
        buttonStyle="solid"
        onChange={(e) => setDegree(e.target.value)}
        value={levelOfStudy}
        style={{ marginBottom: 16 }}>
        {[Degree.Bachelors, Degree.Masters, Degree.PhD].map((option) => {
          return (
            <Radio.Button key={option} value={option}>
              {option}
            </Radio.Button>
          );
        })}
      </Radio.Group>
      <div style={{ flexDirection: 'column' }}>
        <div className="subtitle">
          <span role="img" style={{ marginRight: 4 }} aria-label="branch emoticon">
            📚
          </span>{' '}
          Which branch or field are you getting your {levelOfStudy} in?
        </div>
        <Input
          value={major}
          onChange={(e) => setMajor(e.target.value)}
          style={{ marginBottom: 16 }}
          placeholder="e.g Computer Science"
        />
      </div>
      <div className="subtitle">
        <span role="img" style={{ marginRight: 4 }} aria-label="cycle emoticon">
          💼
        </span>{' '}
        Which cycle are you coming to the US in?
      </div>
      <Select
        value={admissionCycle}
        placeholder="Select an option..."
        onSelect={(value: string) => {
          setAdmissionSemester(value.split(' ')[0] as AdmissionSemester);
          setYear(value.split(' ')[1]);
          setAdmissionCycle(value);
        }}
        style={{ marginBottom: 16, width: 500 }}
        options={cycleOptions}></Select>
      <div className="subtitle">
        <span role="img" style={{ marginRight: 4 }} aria-label="help emoticon">
          👨‍🔧
        </span>{' '}
        Which steps have you completed in the post-admit process?
      </div>
      <TagSelect
        items={helpChoices}
        onSelect={(e) => {
          setHelpItems(e as UserJourneyStageId[]);
        }}
      />
      <Button
        className="onb-submit"
        onClick={() => {
          props.onSubmit(year, levelOfStudy, admissionSemester, helpItems, major);
        }}
        type="primary">
        Submit
      </Button>
    </div>
  );
};
