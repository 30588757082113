import { Button, Card, Col, Row, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { Accordion, IntercomAPI } from '../../components';
import { bankLogos, bankNames, PredefinedStep, StepStatus } from '../../repos';
import Styles from './FinalizeOffer.module.sass';
import { RightOutlined, SelectOutlined, MessageOutlined, CloseCircleFilled } from '@ant-design/icons';
import { AcceptOfferModal } from './AcceptOfferModal';
import { FirestoreReference } from '../../utils';
import { stampImage } from '../../assets';
import { loanLockModalData } from './LoanStepContainer';

interface FinalizeOfferProps {
  uid: string;
}
export interface LoanOffer {
  id: string;
  bid: string;
  bname?: string;
  rate_of_interest: string;
  mor_period: string;
  rebate_available: boolean;
  processing?: boolean;
  declined?: boolean;
  recommended?: true;
  tncPDF: string;
  amount: string;
}

export const FinalizeOffer: React.FC<FinalizeOfferProps> = (props) => {
  const [offers, setOffers] = useState<LoanOffer[] | undefined>();
  const [selectedOffer, setSelectedOffer] = useState<LoanOffer | undefined>();

  useEffect(() => {
    FirestoreReference.LoanApplication(props.uid)
      .doc('offers')
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            setOffers(
              Object.values(doc.data() || {}).sort((a, b) => {
                let p = 0;
                if (b.recommended) p = 1;
                if (a.recommended) p = -1;
                if (a.declined) p = 1;
                if (b.declined) p = -1;
                return p;
              }) as LoanOffer[],
            );
          }
        },
        (error) => {
          console.debug('Loan app listener', error);
        },
      );
  }, []);

  const renderOfferCards = () => {
    return offers?.map((offer, i) => (
      <Card
        className={`${Styles.offerCard} ${offer.declined === true ? Styles.declined : ''} ${offer.recommended === true ? Styles.recommended : ''} ${
          offer.processing === true ? Styles.processing : ''
        }`}
        hoverable
        key={i}>
        <Row align="middle">
          <Col span={4}>
            <div className={Styles.logo}>
              <img src={bankLogos[offer.bid]} alt={offer.bid}></img>
            </div>
          </Col>
          <Col span={13}>
            {offer.processing ? (
              <Skeleton active paragraph={{ rows: 3, width: 200 }} title={false} />
            ) : (
              <Row align="middle">
                <ul className={Styles.detailsList}>
                  <li>
                    <span>%</span> <span>{offer.rate_of_interest}% Interest Rate</span>
                  </li>
                  <li>
                    <span>#</span> Moratorium Period of {offer.mor_period} years
                  </li>
                  <li>
                    <span>₹</span> Tax rebate{offer.rebate_available ? ' ' : ' not '}available
                  </li>
                </ul>
              </Row>
            )}
          </Col>
          <Col span={7}>
            {offer.declined ? (
              <div className={Styles.declinedTag}>
                <CloseCircleFilled /> Application declined
              </div>
            ) : (
              <Row gutter={[0, 8]} justify="end">
                <Button
                  block
                  type="primary"
                  onClick={() => {
                    setSelectedOffer({ ...offer, bname: bankNames[offer.bid] });
                  }}>
                  VIEW OFFER LETTER
                  <SelectOutlined rotate={90} />
                </Button>
                <Button
                  block
                  icon={<MessageOutlined />}
                  onClick={() => {
                    IntercomAPI.showWithMessagePreFilled(`Hey! I have questions about my loan offer.`, false);
                  }}>
                  CHAT WITH LOAN EXPERT
                </Button>
              </Row>
            )}
          </Col>
        </Row>
      </Card>
    ));
  };

  return (
    <Row>
      {selectedOffer ? <AcceptOfferModal uid={props.uid} offer={selectedOffer} showHelp={!!selectedOffer} setShowHelp={setSelectedOffer} /> : <></>}
      {renderOfferCards()}
    </Row>
  );
};

export const ProcessingOffer: React.FC = () => {
  return (
    <Row justify="center" align="middle" className={Styles.awaiting}>
      <Col span={12}>
        <h1>Awaiting offers from banks...</h1>
        <h2>Your documents have been submitted to your banks of choice! Your loan offer comes next.</h2>
      </Col>
      <Col span={12}>
        <img alt={'Stamp'} src={stampImage}></img>
      </Col>
    </Row>
  );
};

export const FinalizeOfferStep = (currentStep: PredefinedStep, uid: string) => {
  return (
    <Accordion.Item key={currentStep.id} icon={'file-done'} lockModal={loanLockModalData} lockOnComplete status={currentStep.status || StepStatus.Locked} title={currentStep.title}>
      {currentStep.status === StepStatus.Processing ? <ProcessingOffer /> : <FinalizeOffer uid={uid}></FinalizeOffer>}
    </Accordion.Item>
  );
};
