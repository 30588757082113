import { navigate, RouteComponentProps } from '@reach/router';
import { CheckCircleFilled, LockOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import { findIndex } from 'lodash';
import React from 'react';
import { useLocation } from 'react-use';
import {
  journeyStepFirstIcon,
  journeyStepFirstIconHovered,
  journeyStepFirstIconSelected,
  journeyStepIcon,
  journeyStepIconHovered,
  journeyStepIconSelected,
  journeyStepLocked,
} from '../../assets';
import { UserJourneyStageId, UserJourneyStages } from '../../config/userJourneyMap';
import { CustomStepKeyedByJourneyStageId } from '../../redux/reducers/CustomStepsReducer';
import { Colors } from '../../theme';
import { Analytics, AnalyticsEventName } from '../../utils';
import Styles from './UserJourneyBar.module.sass';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { StepStatus } from '../../repos';

interface UserJourneyBarItemProps extends RouteComponentProps {
  selected?: boolean;
  title: string;
  index: number;
  completed: boolean;
  locked?: boolean;
  badges: number;
  onSelect: () => void;
}

const renderTitle = (title: string) => {
  const parts = title.split('\n');
  if (parts.length === 1) {
    return title;
  }
  return (
    <span>
      {parts[0]}
      <br />
      {parts[1]}
    </span>
  );
};

export const UserJourneyBarItem: React.FC<UserJourneyBarItemProps> = (props) => {
  const { selected, onSelect, title, index, completed, badges, locked } = props;
  const isFirstItem = index === 0;
  const hoverIcon = isFirstItem ? journeyStepFirstIconHovered : journeyStepIconHovered;
  const selectedIcon = isFirstItem ? journeyStepFirstIconSelected : journeyStepIconSelected;
  const normalIcon = isFirstItem ? journeyStepFirstIcon : journeyStepIcon;

  let image: HTMLImageElement | null = null;

  if (completed) {
    return (
      <div
        onClick={onSelect}
        onMouseOver={() => {
          if (selected) {
            return;
          }
          image!.src = hoverIcon;
        }}
        onMouseOut={() => {
          if (selected) {
            return;
          }
          image!.src = normalIcon;
        }}
        style={{
          alignItems: 'center',
          padding: '12px 32px 12px 8px',
          minWidth: 195,
          height: 70,
          cursor: 'pointer',
        }}>
        <Badge className={Styles.badge} count={0}>
          <img
            alt=""
            style={{ position: 'relative', marginLeft: -15 }}
            ref={(ref) => (image = ref)}
            src={selected ? selectedIcon : normalIcon}
          />
        </Badge>
        <CheckCircleFilled
          style={{
            color: Colors.Teal600,
            marginRight: 8,
            zIndex: 5,
            marginLeft: isFirstItem ? -170 : -150,
          }}
        />
        <div
          style={{
            fontWeight: 500,
            fontSize: 14,
            zIndex: 5,
            color: selected ? Colors.GreyLightest : Colors.GreyDarkest,
            lineHeight: 1.2,
          }}>
          {renderTitle(title)}
        </div>
      </div>
    );
  }

  if (locked) {
    return (
      <div
        onMouseOver={() => {
          if (selected) {
            return;
          }
          image!.src = hoverIcon;
        }}
        onMouseOut={() => {
          if (selected) {
            return;
          }
          image!.src = journeyStepLocked;
        }}
        style={{
          alignItems: 'center',
          padding: '12px 32px 12px 8px',
          minWidth: 195,
          height: 70,
          cursor: 'pointer',
        }}>
        <Badge className={Styles.badge} count={0}>
          <img
            alt=""
            style={{ position: 'relative', marginLeft: -15 }}
            ref={(ref) => (image = ref)}
            src={selected ? selectedIcon : journeyStepLocked}
          />
        </Badge>
        <LockOutlined
          style={{
            color: selected ? Colors.GreyLightest : Colors.Grey,
            marginRight: 8,
            zIndex: 5,
            marginLeft: isFirstItem ? (selected ? -170 : -150) : -150,
          }}
        />
        <div
          style={{
            fontWeight: 500,
            fontSize: 14,
            zIndex: 5,
            color: selected ? Colors.GreyLightest : Colors.Grey,
            lineHeight: 1.2,
          }}>
          {renderTitle(title)}
        </div>
      </div>
    );
  }

  return (
    <div
      onClick={onSelect}
      onMouseOver={() => {
        if (selected) {
          return;
        }
        image!.src = hoverIcon;
      }}
      onMouseOut={() => {
        if (selected) {
          return;
        }
        image!.src = normalIcon;
      }}
      style={{
        alignItems: 'center',
        padding: '12px 32px 12px 8px',
        minWidth: 195,
        height: 70,
        cursor: 'pointer',
      }}>
      <Badge className={Styles.badge} count={badges}>
        <img
          alt=""
          style={{ position: 'relative', marginLeft: -12 }}
          ref={(ref) => (image = ref)}
          src={selected ? selectedIcon : normalIcon}
        />
      </Badge>
      <div
        style={{
          backgroundColor: selected ? Colors.GreyLightest : Colors.Primary,
          height: 6,
          width: 6,
          borderRadius: 3,
          marginRight: 8,
          zIndex: 5,
          marginLeft: isFirstItem ? -170 : -140,
        }}
      />
      <div
        style={{
          fontWeight: 500,
          fontSize: 14,
          zIndex: 5,
          color: selected ? Colors.GreyLightest : Colors.GreyDarkest,
          lineHeight: 1.2,
        }}>
        {renderTitle(title)}
      </div>
    </div>
  );
};

const journeyItems = Object.entries(UserJourneyStages)
  .map(([stageId, stage]) => ({
    id: stageId as UserJourneyStageId,
    item: stage,
  }))
  .filter((item) => item.item.hide !== true);

export const getBarOffsetForCurrentRoute = () => {
  const index = findIndex(journeyItems, ({ item }) => {
    return window.location.pathname.includes(item.route);
  });
  return Math.max(index, 0) * 180;
};

interface UserJourneyBarProps {
  customSteps: CustomStepKeyedByJourneyStageId;
}

export const UserJourneyBar: React.FC<UserJourneyBarProps> = (props) => {
  const { pathname = '' } = useLocation();
  const stepsStatus = useSelector((state: RootState) => state.Content.stepsStatus);
  const currentUser = useSelector((state: RootState) => state.Auth.currentUser);
  const completedStages = currentUser?.completedStages || [];
  const { customSteps } = props;
  return (
    <div
      style={{
        paddingLeft: 16,
        paddingRight: 16,
      }}>
      {journeyItems.map(({ item, id: stageId }, index) => {
        const completed = completedStages.includes(stageId as UserJourneyStageId);
        const customStepsForStage = customSteps[stageId];
        let badgeNumber = customStepsForStage
          ? customStepsForStage.filter(
              (step) =>
                step.status === StepStatus.ReadyForReview ||
                !((currentUser?.shownBadges && currentUser?.shownBadges[stageId]) || []).includes(
                  step.id,
                ),
            ).length
          : 0;
        badgeNumber = stepsStatus[stageId]
          ? Object.values(stepsStatus[stageId]).filter(
              (step: any) => step.status === StepStatus.ReadyForReview,
            ).length + badgeNumber
          : badgeNumber;

        const locked = currentUser?.isAnonymous && stageId !== UserJourneyStageId.HealthInsurance;

        return (
          <UserJourneyBarItem
            key={stageId}
            onSelect={() => {
              Analytics.log(AnalyticsEventName.NavigationButtonClicked, {
                step: item,
              });
              navigate(`/${item.route}`);
            }}
            completed={completed}
            locked={locked}
            title={item.title}
            index={index}
            badges={badgeNumber}
            selected={pathname.includes(item.route)}
          />
        );
      })}
    </div>
  );
};
