import { FileSearchOutlined, InfoCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Modal, Row, Tooltip } from 'antd';
import React from 'react';
import Styles from './WelcomeScreenModal.module.sass';
import { CardTable, CardTableData } from '../../components/CardTable';
import { bankLogos } from '../../repos';

interface WelcomeScreenModalProps {
  showAllOffers: boolean;
  setShowAllOffers: (showAllOffers: any) => void;
  setShowWelcomeScreen: (showAllOffers: boolean) => void;
}

const offersData: CardTableData = {
  headers: [
    { content: '' },
    { content: 'BANK' },
    {
      content: (
        <>
          INTEREST RATE{' '}
          <Tooltip placement="top" title={'Lorem Ipsum'}>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
    },
    {
      content: (
        <>
          MORATORIUM PERIOD{' '}
          <Tooltip placement="top" title={'Lorem Ipsum'}>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
    },
    {
      content: (
        <>
          TAX REBATE{' '}
          <Tooltip placement="top" title={'Lorem Ipsum'}>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
    },
    {
      content: (
        <>
          MARGIN MONEY{' '}
          <Tooltip placement="top" title={'Lorem Ipsum'}>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
    },
    {
      content: (
        <>
          HIDDEN FEES{' '}
          <Tooltip placement="top" title={'Lorem Ipsum'}>
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
    },
  ],
  rows: [
    {
      contents: [
        { content: <img src={bankLogos['axis']} alt={'Axis bank'} /> },
        { content: <span>AXIS BANK</span> },
        { content: '8.9%' },
        { content: '3 years' },
        { content: 'Yes' },
        { content: '15%' },
        { content: '10%' },
      ],
    },
    {
      contents: [
        { content: <img src={bankLogos['sbi']} alt={'SBI'} /> },
        { content: <span>SBI</span> },
        { content: '6.9%' },
        { content: '2 years' },
        { content: 'Yes' },
        { content: '0%' },
        { content: '5%' },
      ],
    },
    {
      contents: [
        { content: <img src={bankLogos['mpower']} alt={'MPOWER FINANCING'} /> },
        { content: <span>MPOWER FINANCING</span> },
        { content: '7.2%' },
        { content: '1 year' },
        { content: 'Yes' },
        { content: '0%' },
        { content: '12%' },
      ],
    },
    {
      contents: [
        { content: <img src={bankLogos['avanse']} alt={'AVANSE'} /> },
        { content: <span>AVANSE</span> },
        { content: '8.4%' },
        { content: 'NA' },
        { content: 'No' },
        { content: '10%' },
        { content: '10%' },
      ],
    },
    {
      contents: [
        { content: <img src={bankLogos['prodigy']} alt={'PRODIGY FINANCE'} /> },
        { content: <span>PRODIGY FINANCE</span> },
        { content: '6.9%' },
        { content: '2 years' },
        { content: 'Yes' },
        { content: '0%' },
        { content: '5%' },
      ],
    },
    {
      contents: [
        { content: <img src={bankLogos['credilia']} alt={'HDFC CREDILA'} /> },
        { content: <span>HDFC CREDILA</span> },
        { content: '7.2%' },
        { content: '1 year' },
        { content: 'Yes' },
        { content: '0%' },
        { content: '12%' },
      ],
    },
    {
      contents: [
        { content: <img src={bankLogos['icici']} alt={'ICICI BANK'} /> },
        { content: <span>ICICI BANK</span> },
        { content: '8.4%' },
        { content: 'NA' },
        { content: 'No' },
        { content: '10%' },
        { content: '10%' },
      ],
    },
    {
      contents: [
        { content: <img src={bankLogos['incred']} alt={'INCRED'} /> },
        { content: <span>INCRED</span> },
        { content: '7.2%' },
        { content: '1 year' },
        { content: 'Yes' },
        { content: '0%' },
        { content: '12%' },
      ],
    },
    {
      contents: [
        { content: <img src={bankLogos['bob']} alt={'BANK OF  BARODA'} /> },
        { content: <span>BANK OF BARODA</span> },
        { content: '8.4%' },
        { content: 'NA' },
        { content: 'No' },
        { content: '10%' },
        { content: '10%' },
      ],
    },
  ],
  spans: [2, 3, 4, 5, 3, 4, 3],
};

export const WelcomeScreenModal: React.FC<WelcomeScreenModalProps> = (props) => {
  const { showAllOffers, setShowAllOffers, setShowWelcomeScreen } = props;

  return (
    <Modal
      centered
      className={Styles.modal}
      onCancel={() => {
        setShowAllOffers(undefined);
      }}
      title={
        <Row align="middle" className={Styles.title}>
          <FileSearchOutlined />
          Education Loan Options
          <Button
            type="primary"
            onClick={() => {
              setShowWelcomeScreen(false);
            }}>
            Get Started <RightOutlined />
          </Button>
        </Row>
      }
      footer={null}
      closable
      maskClosable
      visible={showAllOffers}>
      <Row justify="center" className={Styles.container}>
        <CardTable data={offersData} className={Styles.offerTable} />
      </Row>
    </Modal>
  );
};
