import {
  sopIcon,
  apartmentsIcon,
  finalizeUniversityIcon,
  bankIcon,
  forexIcon,
  insuranceIcon,
  loansIcon,
  phoneIcon,
  vaccinesIcon,
  visaIcon,
  creditCardIcon,
} from '../assets';

export enum UserJourneyRoutes {
  SopReview = 'sop-review',
  Loans = 'loans',
  FinalizeUniversity = 'finalize-university',
  VisaApplication = 'visas',
  HealthInsurance = 'health-insurance',
  Vaccines = 'vaccines',
  Forex = 'forex',
  Apartments = 'apartments',
  PhoneConnection = 'phone-connection',
  USBankAccounts = 'bank-accounts',
  USCreditCard = 'credit-card',
}

export enum UserJourneyTitle {
  SopReview = 'SOP Review',
  Loans = 'Loans',
  FinalizeUniversity = 'Finalize University',
  VisaApplication = 'Visa Application',
  HealthInsurance = 'Health Insurance',
  Vaccines = 'Vaccines',
  Forex = 'Forex &\nTuition Fee',
  Apartments = 'Apartments',
  PhoneConnection = 'Phone',
  USBankAccounts = 'US Bank Accounts',
  USCreditCard = 'US Credit Card',
}

export enum UserJourneyStageId {
  SopReview = 'sop-review',
  FinalizeUniversity = 'finalize-university',
  Loans = 'loans',
  VisaApplication = 'visas',
  HealthInsurance = 'health-insurance',
  Vaccines = 'vaccines',
  Forex = 'forex',
  PhoneConnection = 'phone-connection',
  Apartments = 'apartments',
  USBankAccounts = 'bank-accounts',
  USCreditCard = 'credit-card',
}

export type UserJourneyStage = {
  title: UserJourneyTitle;
  route: UserJourneyRoutes;
  order: number;
  icon: any;
  completed: boolean;
  hide?: boolean;
};

export const UserJourneyStages: {
  [stepId in UserJourneyStageId]: UserJourneyStage;
} = {
  [UserJourneyStageId.SopReview]: {
    title: UserJourneyTitle.SopReview,
    route: UserJourneyRoutes.SopReview,
    order: 1,
    icon: sopIcon,
    completed: false,
    hide: true,
  },
  [UserJourneyStageId.FinalizeUniversity]: {
    title: UserJourneyTitle.FinalizeUniversity,
    route: UserJourneyRoutes.FinalizeUniversity,
    order: 2,
    icon: finalizeUniversityIcon,
    completed: false,
  },
  [UserJourneyStageId.Loans]: {
    title: UserJourneyTitle.Loans,
    route: UserJourneyRoutes.Loans,
    order: 3,
    icon: loansIcon,
    completed: false,
  },
  [UserJourneyStageId.VisaApplication]: {
    title: UserJourneyTitle.VisaApplication,
    route: UserJourneyRoutes.VisaApplication,
    order: 4,
    icon: visaIcon,
    completed: false,
  },
  [UserJourneyStageId.HealthInsurance]: {
    title: UserJourneyTitle.HealthInsurance,
    route: UserJourneyRoutes.HealthInsurance,
    order: 5,
    icon: insuranceIcon,
    completed: false,
  },
  [UserJourneyStageId.Vaccines]: {
    title: UserJourneyTitle.Vaccines,
    route: UserJourneyRoutes.Vaccines,
    order: 6,
    icon: vaccinesIcon,
    completed: false,
  },
  [UserJourneyStageId.Forex]: {
    title: UserJourneyTitle.Forex,
    route: UserJourneyRoutes.Forex,
    order: 7,
    icon: forexIcon,
    completed: false,
  },
  [UserJourneyStageId.PhoneConnection]: {
    title: UserJourneyTitle.PhoneConnection,
    route: UserJourneyRoutes.PhoneConnection,
    order: 8,
    icon: phoneIcon,
    completed: false,
  },
  [UserJourneyStageId.Apartments]: {
    title: UserJourneyTitle.Apartments,
    route: UserJourneyRoutes.Apartments,
    order: 9,
    icon: apartmentsIcon,
    completed: false,
  },
  [UserJourneyStageId.USBankAccounts]: {
    title: UserJourneyTitle.USBankAccounts,
    route: UserJourneyRoutes.USBankAccounts,
    order: 10,
    icon: bankIcon,
    completed: false,
  },
  [UserJourneyStageId.USCreditCard]: {
    title: UserJourneyTitle.USCreditCard,
    route: UserJourneyRoutes.USCreditCard,
    order: 11,
    icon: creditCardIcon,
    completed: false,
  },
};
