import React, { FC, useState } from 'react';

import { ComparisonData } from './HealthInsuranceStepContainer';
import { Recommendation } from './Recommendation';
import Styles from './PurchaseForms.module.sass';
import { RightOutlined } from '@ant-design/icons';
import {
	Form,
	Row,
	Col,
	Input,
	DatePicker,
	Select,
	Button,
	InputNumber,
	Switch,
	Radio,
	Slider,
} from 'antd';
import { isValidNumber } from 'libphonenumber-js';
import { PhoneInput } from '../../components/PhoneInput';
import { useDispatch } from 'react-redux';
import { SliderMarks } from 'antd/lib/slider';
import { Match } from '@reach/router';
interface Props {
	comparisonData: ComparisonData;
}

const formItemLayout = {
	labelCol: { span: 24 },
	wrapperCol: { span: 20 },
};

const getLabel = (label: string, subLabel: string, required?: boolean) => {
	return (
		<div className={Styles.label}>
			{label} {required && <div className={Styles.requiredMark}>*</div>}
			<p className={Styles.subLabel}>{subLabel}</p>
		</div>
	);
};

export const ViewRecommended: FC<Props> = (props) => {
	const { comparisonData } = props;
	const [use, setUse] = useState<any>('lowUse');
	const dispatch = useDispatch();

	const marks: SliderMarks = {
		0: 'not sensitive at all',
		4: 'extremely sensitive',
	}

	if (use) return <Recommendation comparisonData={comparisonData} />;
	else
		return (
			<Row style={{ marginTop: 24 }}>
				<Form
					{...formItemLayout}
					size={'large'}
					colon={false}
					className={Styles.form}
					labelWrap
					onFinish={(data) => {
						let score = 0;
						score += data.docVisits === '0-3' ? 0 : data.docVisits === '4-6' ? 1 : 2;
						score += data.coldImpact === 2 ? 1 : Math.floor(data.coldImpact / 2) + Math.ceil(data.coldImpact % 2);
						score += data.mentalCare === undefined ? 0 : 1 + data.mentalCare;
						score += 6 + data.dentalCare + data.playSports + data.pastInjuries + data.preExisting + data.medRegular + data.visionCare
						if (score < 10) {
							setUse('lowUse');
						} else if (score > 10 && score < 15) {
							setUse('mediumUse');
						} else {
							setUse('highUse');
						}
					}}>
					<Row>
						<Col sm={24} md={12}>
							<Form.Item
								className={Styles.noMark}
								rules={[{ required: true, message: 'Please enter your pas doctor visits.' }]}
								label={getLabel("How many times have you visited the doctor in the past year?", "Not counting routine checkups or physicals.", true)}
								name="docVisits">
								<Select
									options={[
										{ label: 'Less that or equal to 3', value: '0-3' },
										{ label: 'Between 3 to 6 times', value: '4+' },
										{ label: 'More than 6 times', value: '7+' },
									]}></Select>
							</Form.Item>
						</Col>
						<Col sm={24} md={6}>
							<Form.Item
								className={Styles.noMark}
								rules={[{ required: true, message: 'Please select yes or no.' }]}
								label={getLabel("Do you plan to play intercollegiate or intramural sports while at university?", "", true)}
								name="playSports">
								<Radio.Group>
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
						<Col sm={24} md={6}>
							<Form.Item
								className={Styles.noMark}
								rules={[{ required: true, message: 'Please select yes or no.' }]}
								label={getLabel("Have you had any major surgeries, injuries or conditions in the past that you have received treatment for?", "", true)}
								name="pastInjuries">
								<Radio.Group>
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
						<Col sm={24} md={12}>
							<Form.Item
								rules={[{ required: true, message: 'Please select yes or no.' }]}
								className={Styles.noMark}
								label={getLabel(
									'Do you have currently have any pre-existing conditions or illnesses?',
									'All of this information will be kept completely confidential. Please answer truthfully so that we can match you with a plan that will ensure you do not have to pay huge amounts out of your own pocket.',
									true,
								)}
								name="preExisting">
								<Radio.Group>
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
						<Col sm={24} md={12}>
							<Form.Item
								rules={[{ required: true, message: 'Please select yes or no.' }]}
								className={Styles.noMark}
								label={getLabel(
									'Do you take any medication regularly?',
									'If you do, we can match you to a plan that will minimize what you pay for medication in the US.',
									true,
								)}
								name="medRegular">
								<Radio.Group>
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
						<Col sm={24} md={12}>
							<Form.Item
								rules={[{ required: true, message: 'Please select how sensitive you are to cold.' }]}
								className={`${Styles.noMark} ${Styles.sliderForm}`}
								label={getLabel(
									'How sensitive are you to cold weathers?',
									'Temperatures in the US winters frequently dip below 0 degrees celsius. For many students, adjusting to this takes time and bouts of cold or fever in the first year. If you are sensitive to cold, we can make sure to choose a plan that can accommodate the necessary treatment at the cheapest prices.',
									true,
								)}
								name="coldImpact">
								<Slider min={0} max={4} marks={marks} />
							</Form.Item>
						</Col>
						<Col sm={24} md={12}>
							<Form.Item
								className={Styles.noMark}
								label={getLabel(
									'Have you had or do you have any mental healthcare needs?',
									'This information allows us to ensure mental health services are adequately covered for options such as therapy and medication. If you do not feel comfortable discussing this, you can skip this question as it is not mandatory. However, more information will only help us pick the best plan for your needs.',
									true,
								)}
								name="mentalCare">
								<Radio.Group>
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
									<Radio value={undefined}>Prefer not to say</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
						<Col sm={24} md={12}>
							<Form.Item
								rules={[{ required: true, message: 'Please select yes or no.' }]}
								className={Styles.noMark}
								label={getLabel(
									'Do you have any dental conditions for which you expect to receive treatment for in the US?',
									'Dental coverage is typically not a part of insurance plans in the US. If you have a chronic condition and you are sure that you will need to undergo dental procedures (not regular cleanings or checkups), please check "yes".',
									true,
								)}
								name="dentalCare">
								<Radio.Group>
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>

						<Col sm={24} md={12}>
							<Form.Item
								rules={[{ required: true, message: 'Please select yes or no.' }]}
								className={Styles.noMark}
								label={getLabel(
									'Do you have any vision-related conditions for which you expect to receive treatment for in the US?',
									'Vision-related coverage, similar to dental, is typically not a part of insurance plans in the US. Indicate "yes" if you have an existing condition for which you expect to undergo specialized tests or procedures/surgeries. (again, not counting a routine consultation)',
									true,
								)}
								name="visionCare">
								<Radio.Group>
									<Radio value={true}>Yes</Radio>
									<Radio value={false}>No</Radio>
								</Radio.Group>
							</Form.Item>
						</Col>
					</Row>
					<Form.Item>
						<Button type="primary" htmlType="submit">
							Next <RightOutlined />
						</Button>
					</Form.Item>
				</Form>
			</Row>
		);
};
