import { Row } from 'antd';
import React, { FC, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useWindowDimensions } from '../../services';
import Styles from './PDFViewer.module.sass';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PDFViewerProps {
  pdfFile: string;
  pageNumber: number;
}

export const PDFViewer: FC<PDFViewerProps> = (props) => {
  const { pdfFile, pageNumber } = props;
  let { width: vpWidth, height: vpHeight } = useWindowDimensions();
  const [scale, setScale] = useState(0);

  const onDocumentLoadSuccess = async (pdf: any) => {
    const page = await pdf.getPage(1);
    const { width, height } = page.getViewport({ scale: 1 });
    vpWidth = width;
    vpHeight = vpHeight - 100;
    const wScale = vpWidth / width;
    const hScale = vpHeight / height;
    setScale(height > width ? hScale : wScale);
  };
  return (
    <Row justify="center">
      <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          className={Styles.pdfContainer}
          scale={scale}
          pageNumber={pageNumber + 1}
          renderTextLayer={false}
        />
      </Document>
    </Row>
  );
};
