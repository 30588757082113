import {
  BookOutlined,
  RightOutlined,
  BankOutlined,
  EnvironmentOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons';
import { Form, Select, Button, Row, message, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import Styles from './NaturalForm.module.sass';
import { UniversityInput } from '../UniversityInput';
import { SearchParams } from '../../containers/VisaStep/VisaTranscript';
import { FirestoreReference } from '../../utils';

interface VisaTranscriptNaturalFormProps {
  onSubmit: (values: any) => void;
  uid: string | undefined;
}

export const VisaTranscriptNaturalForm: React.FC<VisaTranscriptNaturalFormProps> = (props) => {
  const [inputWidth, setInputWidth] = useState(0);
  const [loading, setLoading] = useState(false);
  const { onSubmit, uid } = props;

  useEffect(() => {
    setLoading(true);
    if (uid)
      FirestoreReference.VisaApplications(uid)
        .doc('transcript_params')
        .get()
        .then((snapshot) => {
          if (snapshot.exists) {
            onSubmit(snapshot.data() as SearchParams);
          } else setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
  }, [uid]);

  return (
    <div className={Styles.container} style={{ maxWidth: '80%', marginTop: 48 }}>
      <Form
        onFinish={(data) => {
          if (!data.university) {
            message.error('Please select the university you are going to.');
            return;
          }
          if (!data.funding) {
            message.error('Please select your source of funding.');
            return;
          }
          if (!data.subject) {
            message.error('Please enter the subject you are going to study.');
            return;
          }
          if (!data.consulate) {
            message.error('Please select your consulate.');
            return;
          }
          setLoading(true);
          if (uid)
            FirestoreReference.VisaApplications(uid)
              .doc('transcript_params')
              .set({
                ...data,
                university: data.university[0].name,
              })
              .catch((error) => {});
          onSubmit({
            ...data,
            university: data.university[0].name,
          });
        }}
        layout="inline">
        <div className={Styles.formText}>
          I am going to <BankOutlined />
          <Form.Item required noStyle name="university">
            <UniversityInput
              showArrow={false}
              multiple={false}
              className={Styles.uniSelect}
              optionsClassName={Styles.naturalOptions}></UniversityInput>
          </Form.Item>{' '}
          to major in <BookOutlined />
          <Form.Item required noStyle name="subject">
            <Input
              style={{ width: inputWidth * 15 }}
              onChange={(e) => {
                const len = e.target.value.length;
                if (len % 3 === 0) {
                  setInputWidth(e.target.value.length + 3);
                }
              }}
              placeholder="subject"
              className={Styles.input}></Input>
          </Form.Item>
          {'. I will be interviewing at the '}
          <EnvironmentOutlined />
          <Form.Item required noStyle name="consulate">
            <Select
              placeholder="city"
              showArrow={false}
              className={Styles.naturalSelect}
              dropdownClassName={Styles.naturalOptions}
              bordered={false}
              options={[
                { label: 'Mumbai', value: 'Mumbai' },
                { label: 'Delhi', value: 'Delhi' },
                { label: 'Chennai', value: 'Chennai' },
                { label: 'Kolkata', value: 'Kolkata' },
                { label: 'Hyderabad', value: 'Hyderabad' },
              ]}></Select>
          </Form.Item>
          {' consulate and my source of funding is '}
          <DollarCircleOutlined />
          <Form.Item required noStyle name="funding">
            <Select
              placeholder="education loan"
              showArrow={false}
              dropdownClassName={Styles.naturalOptions}
              className={Styles.naturalSelect}
              bordered={false}
              options={[
                { value: 'funding-loan', label: 'education loan' },
                { value: 'funding-self', label: 'self funded' },
              ]}></Select>
          </Form.Item>
          .
        </div>
        <Form.Item style={{ width: '100%' }}>
          <Row justify="center" style={{ width: '100%' }}>
            <Button loading={loading} type="primary" htmlType="submit">
              View transcripts <RightOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};
