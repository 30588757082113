import { Icon as LegacyIcon } from '@ant-design/compatible';
import {
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
} from '@ant-design/icons';
import React, { CSSProperties, ReactElement } from 'react';
import Styles from './Alert.module.sass';

export enum AlertType {
  Processing = 'processing',
  Info = 'info',
  Neutral = 'neutral',
  Success = 'success',
  Ready = 'ready',
  Error = 'error',
}

export interface AlertProps {
  message: string | ReactElement;
  type: AlertType;
  style?: CSSProperties;
  className?: string;
  icon?: ReactElement;
}

const getIcon = (alertType: AlertType) => {
  switch (alertType) {
    case AlertType.Processing:
      return <ClockCircleFilled />;
    case AlertType.Success:
      return <CheckCircleFilled />;
    case AlertType.Info:
      return <InfoCircleFilled />;
    case AlertType.Error:
      return <CloseCircleFilled />;
    default:
      return <ExclamationCircleFilled />;
  }
};

export const Alert: React.FC<AlertProps> = (props) => {
  const { type, message, className, style, icon } = props;
  return (
    <div
      aria-label={type}
      className={[Styles.container, className].filter((className) => !!className).join(' ')}
      style={style}>
      <div className={Styles.icon}>{icon || getIcon(type)}</div>
      <div className={Styles.message}>{message}</div>
    </div>
  );
};
