import React, { CSSProperties, useEffect } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Card, Tag, Avatar } from 'antd';
import { Video, VideoType } from '../../repos';
import './styles.scss';
import { DOMHelpers } from '../../utils';

interface VideoCardProps {
  video: Video;
  videoHeight: string | number;
  onVideoPlayed?: () => void;
  style?: CSSProperties;
}

export const VideoCard: React.SFC<VideoCardProps> = (props) => {
  const { video, style, videoHeight, onVideoPlayed } = props;

  useEffect(() => {
    const url = `https://fast.wistia.com/embed/medias/${video.videoId}.jsonp`;
    if (!DOMHelpers.isScriptAlreadyLoaded(url)) {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  const titleComponent = (
    <div className="header-container">
      {video.author ? (
        <Avatar size={58} src={video.author.displayPictureUrl}>
          {video.author.name.substr(0, 1)}
        </Avatar>
      ) : (
        <Avatar size={58} icon={<UserOutlined />} />
      )}
      <div className="title-container">
        <span className="title">{video.title}</span>
        {video.author && <span className="description">by {video.author.name}</span>}
      </div>
    </div>
  );

  const tagComponent = <Tag color="geekblue">{video.topics[0].name.toUpperCase()}</Tag>;

  const videoPlayer = () => {
    switch (video.videoType) {
      case VideoType.Wistia:
        return (
          <div
            onClick={() => onVideoPlayed && onVideoPlayed()}
            className="wistia_responsive_padding"
            style={{ height: '100%', width: '100%', position: 'relative' }}>
            <div
              className="wistia_responsive_wrapper"
              style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}>
              <span
                className={`wistia_embed wistia_async_${video.videoId} popover=true`}
                style={{
                  display: 'inline-block',
                  height: '100%',
                  position: 'relative',
                  width: '100%',
                }}>
                &nbsp;
              </span>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Card
      key={video.id}
      title={titleComponent}
      className="video-card"
      hoverable
      extra={tagComponent}
      style={style}>
      <div className="video-container" style={{ height: videoHeight }}>
        {videoPlayer()}
      </div>
    </Card>
  );
};
