import React, { CSSProperties } from 'react';
import './styles.scss';

interface BigMarkerWebinarPopOverContentProps {
  url: string | undefined;
  chatURL: string | undefined;
  title: string;
  recordingId: string | undefined;
  mode: 'live' | 'recording';
  style?: CSSProperties;
}

// TODO: Vamsee Chamakura 29/10/19 - Figure out loading screen
// TODO: Vamsee Chamakura 29/10/19 - Figure out iframe render height and width for recordings
export const BigMarkerWebinarPopOverContent: React.FC<BigMarkerWebinarPopOverContentProps> = (
  props,
) => {
  // const [isLoading, setLoading] = useState<boolean>(true);
  const { url, chatURL, title, recordingId, mode } = props;
  const recordingNotPublished = mode === 'recording' && recordingId;
  return recordingNotPublished ? (
    <div>Recording is being processed. Check back in a couple of hours to view.</div>
  ) : (
    <>
      <iframe
        title={title}
        className="popup-iframe"
        id="bigmarker_embed_conference_room"
        width="65vw"
        height="80vh"
        allowFullScreen
        frameBorder="0"
        style={{ width: '65vw' }}
        src={mode === 'live' ? url : `https://www.bigmarker.com/recordings/${recordingId}`}
      />
      {mode === 'live' ? (
        <iframe
          width="20vw"
          height="80vh"
          className="popup-iframe"
          style={{ width: '20vw', minWidth: 400 }}
          title={'chat'}
          src={chatURL}></iframe>
      ) : null}
    </>
  );
};
