import { concat, of } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { RootEpic } from '.';
import { ContentRepo, UserRepo, WebinarRegistrationRepo } from '../../repos';
import { catchAndReportError } from '../../utils';
import {
  ContentActionTypes,
  joinWebinarError,
  registerForWebinarError,
  registerForWebinarSuccess,
  setAuthError,
  setContentItems,
  setError,
  setPredefinedSteps,
  setStepsStatus,
  setUrlToJoinWebinar,
  setWebinar,
  setWebinarRegistrations,
  unregisterForWebinarError,
  unregisterForWebinarSuccess,
} from '../actions';

export const listenToStepRelatedContentItemsEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(ContentActionTypes.ListenToStepRelatedContentItems)),
    switchMap((_) => {
      return ContentRepo.fetchContentItems().pipe(map((items) => setContentItems(items)));
    }),
  );
};

export const listenToWebinarRegistrationsEpic: RootEpic = (action$, store) => {
  return action$.pipe(
    filter(isOfType(ContentActionTypes.ListenToWebinarRegistrations)),
    withLatestFrom(store),
    switchMap(([_, state]) => {
      const currentUser = state.Auth.currentUser;
      if (!currentUser) {
        // TODO: Vamsee Chamakura 28/10/19 - Automatically logout and ask them to sign-in again
        return of(setAuthError());
      }
      return WebinarRegistrationRepo.listenToWebinarRegistrations(currentUser.id).pipe(
        map((registrations) => setWebinarRegistrations(registrations)),
        catchAndReportError((error) => of(setError(error.message))),
      );
    }),
  );
};

export const registerForWebinarEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(ContentActionTypes.RegisterForWebinar)),
    switchMap((action) => {
      return UserRepo.getIdToken().pipe(
        switchMap((token) =>
          WebinarRegistrationRepo.registerForWebinar(action.payload.conferenceId, token),
        ),
        mapTo(registerForWebinarSuccess(action.payload.conferenceId)),
        catchAndReportError((error) =>
          concat([setError(error.message), registerForWebinarError()]),
        ),
      );
    }),
  );
};

export const unregisterForWebinarEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(ContentActionTypes.UnregisterForWebinar)),
    switchMap((action) => {
      return UserRepo.getIdToken().pipe(
        switchMap((token) =>
          WebinarRegistrationRepo.unregisterForWebinar(action.payload.conferenceId, token),
        ),
        mapTo(unregisterForWebinarSuccess(action.payload.conferenceId)),
        catchAndReportError((error) =>
          concat([setError(error.message), unregisterForWebinarError()]),
        ),
      );
    }),
  );
};

export const joinWebinarEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(ContentActionTypes.JoinWebinar)),
    switchMap((action) => {
      return UserRepo.getIdToken().pipe(
        switchMap((token) => ContentRepo.getEnterUrlForWebinar(action.payload.conferenceId, token)),
        map((data: { urlToEnter: string; chatURL: string }) =>
          setUrlToJoinWebinar(data.urlToEnter, data.chatURL),
        ),
        catchAndReportError((error) => concat([setError(error.message), joinWebinarError()])),
      );
    }),
  );
};

export const listenToCustomStepWebinar: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(ContentActionTypes.GetWebinar)),
    switchMap((action) => {
      const { webinarId } = action.payload;
      return ContentRepo.listenToWebinar(webinarId).pipe(
        map((webinar) => setWebinar(webinarId, webinar)),
        catchError((error) => of(setError(error.message))),
      );
    }),
  );
};

export const listenToPredefinedStepsEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(ContentActionTypes.ListenToPredefinedSteps)),
    switchMap(() => {
      return ContentRepo.listenToPredefinedSteps().pipe(
        map(setPredefinedSteps),
        catchAndReportError((error) => of(setPredefinedSteps({}))),
      );
    }),
  );
};

export const listenToStepsStatusEpic: RootEpic = (action$, store) => {
  return action$.pipe(
    filter(isOfType(ContentActionTypes.ListenToStepsStatus)),
    withLatestFrom(store),
    switchMap(([_, state]) => {
      const currentUser = state.Auth.currentUser;
      if (!currentUser) {
        // TODO: Amar Sharma - Automatically logout and ask them to sign-in again
        return of(setAuthError());
      }
      return ContentRepo.listenToStepsStatus(currentUser.id).pipe(
        map(setStepsStatus),
        catchAndReportError((error) => of(setStepsStatus({}))),
      );
    }),
  );
};
