import { Dayjs } from 'dayjs';
import { produce } from 'immer';
import { InsuranceRequestGBGType, InsuranceRequestWellAwayType, PlanType } from '../../repos';
import { InsuranceAction, InsuranceActionTypes } from '../actions';

export enum VisaType {
  F1 = 'F1',
  OPT = 'OPT',
}
export interface InsuranceStateType {
  readonly selectedPlan?: PlanType;
  readonly availablePlans?: PlanType[];
  readonly visaType?: VisaType;
  readonly formData?: Record<string, any>;
  readonly insuranceData?: InsuranceRequestGBGType | InsuranceRequestWellAwayType;
  readonly allInsurances?: InsuranceRequestGBGType[] | InsuranceRequestWellAwayType[];
  readonly pastEndDate?: Dayjs;
}

const initialState: InsuranceStateType = { visaType: VisaType.F1 };

export const InsuranceReducer = (state = initialState, action: InsuranceAction) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case InsuranceActionTypes.SetSelectedPlan:
        draft.selectedPlan = action.payload;
        break;
      case InsuranceActionTypes.SetFormData:
        draft.formData = action.payload;
        break;
      case InsuranceActionTypes.SetAvailablePlans:
        draft.availablePlans = action.payload;
        break;
      case InsuranceActionTypes.SetInsuranceData:
        draft.insuranceData = action.payload;
        break;
      case InsuranceActionTypes.SetPastEndDate:
        draft.pastEndDate = action.payload;
        break;
      case InsuranceActionTypes.SetVisaType:
        draft.visaType = action.payload;
        break;
      case InsuranceActionTypes.AddToAllInsurance:
        if (action.payload) {
          if (state.allInsurances) {
            draft.allInsurances = [...state.allInsurances, action.payload].filter(function ({
              enrolledIndividualId,
              cardLongURL,
            }) {
              const key = `${enrolledIndividualId}${cardLongURL}`;
              // @ts-ignore
              return !this.has(key) && this.add(key) && cardLongURL;
            },
            new Set());
          } else {
            draft.allInsurances = [action.payload];
          }
        }
        break;
      default:
        break;
    }
    return draft;
  });
};
