import { HelpDataType } from '../../components';

export const helpData: HelpDataType = {
  result_10: {
    title: 'Grade 10 Result',
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1645629129/assets/class10result_cfy3pq.jpg',
    subheading: '',
    description: 'This refers to your Class 10 (Senior Secondary Certificate Examination) result.',
    dos: [
      'Ensure that your roll no., name and date of birth are clearly visible.',
      'Ensure that the entire marksheet, including the Principal’s signature and date, is uploaded.',
      'Ensure that the document is in a pdf format only.',
    ],
    donts: [
      'Do not upload a digital copy of your marksheet. Only a scanned copy of the physical marksheet provided by the board is accepted.',
    ],
    column: 'Academics',
  },
  result_12: {
    title: 'Grade 12 Result',
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1645629128/assets/class12result_i6joqa.png',
    subheading: '',
    description: 'This refers to your Class 12 (Higher Secondary Certificate Examination) result.',
    dos: [
      'Ensure that your roll no., name and date of birth are clearly visible.',
      ' Ensure that the entire marksheet, including the Principal’s signature and date, is uploaded.',
      ' Ensure that the document is in a pdf format only.',
    ],
    donts: [
      'Do not upload a digital copy of your marksheet. Only a scanned copy of the physical marksheet provided by the board is accepted.',
    ],
    column: 'Academics',
  },
  proof_of_admission: {
    title: 'Proof of Admission',
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1645629128/assets/acceptance_letter_f4ipfv.png',
    subheading: '',
    description:
      "This document must be an official letter or an email from your school confirming that you've been accepted into your program.",
    dos: [
      'Ensure that the university’s letterhead information is clearly visible.',
      'Ensure that the document is legible.',
      'Ensure that the document is in a pdf format only.',
    ],
    donts: [],
    column: 'Academics',
  },
  under_graduate_result: {
    title: 'Undergraduate Results',
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1645629129/assets/undergrad_result_bwdrrb.png',
    subheading: '',
    description:
      'This refers to all semester examination results during your undergraduate program.',
    dos: [
      'Ensure that marksheets of all the semesters are added.',
      'Ensure that the documents are uploaded as a compiled zip file, and not individually.',
      'Ensure that the document is in a pdf format only.',
    ],
    donts: [
      'Do not upload a digital copy of your marksheet. Only a scanned copy of the physical marksheet provided by the board is accepted',
    ],
    column: 'Academics',
  },
  entrance_exam_result_abroad: {
    title: 'Entrance Exam Result (International)',
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1645629129/assets/entrance_abroad_ogjgst.jpg',
    subheading: '',
    description:
      'This refers to scores from all entrance exams including GRE, TOEFL, IELTS, GMAT, etc.',
    dos: [
      'Ensure that scores of all entrance exams are added.',
      'Ensure that the documents are uploaded as a compiled zip file, and not individually.',
      'Ensure that all the necessary stamps and signatures are clearly visible.',
    ],
    donts: [],
    column: 'Academics',
  },
  entrance_exam_result_domestic: {
    title: 'Entrance Exam Result (Indian)',
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1645629129/assets/entrance_domestic_pgav71.jpg',
    subheading: '',
    description: 'This refers to scores from all entrance exams including CAT, JEE, NEET etc.',
    dos: [
      'Ensure that scores of all entrance exams are added.',
      'Ensure that the documents are uploaded as a compiled zip file, and not individually.',
      'Ensure that all the necessary stamps and signatures are clearly visible.',
    ],
    donts: [],
    column: 'Academics',
  },
  pan_card: {
    title: 'Pan Card',
    image: 'https://res.cloudinary.com/gradly/image/upload/v1645629130/assets/pancard_bruhxj.png',
    subheading: '',
    description:
      'Permanent Account Number or PAN is a means of identifying various taxpayers in the country. PAN is a 10-digit unique identification alphanumeric number (containing both alphabets and numbers) assigned to Indians, mostly to those who pay tax.',
    dos: [
      'Ensure that the entire card is uploaded with all the information clearly visible.',
      'Ensure that the original card is scanned and uploaded.',
    ],
    donts: [],
    column: 'Identity',
  },
  aadhaar_card: {
    title: 'Aadhaar Card',
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1645629128/assets/Adhaar-Card-Sample-Vector-File-sdfied_onunxi.png',
    subheading: '',
    description:
      'Aadhaar is a 12 digit individual identification number issued by the Unique Identification Authority of India on behalf of the Government of India. The number serves as a proof of identity and address, anywhere in India.',
    dos: [
      'Ensure that the entire card is uploaded with all the information clearly visible.',
      'Ensure that the original card is scanned and uploaded.',
    ],
    donts: [],
    column: 'Identity',
  },
  driving_license: {
    title: "Driver's licence",
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1645629128/assets/drivinglicence_bjcaok.jpg',
    subheading: '',
    description:
      'Your Driving licence is an official document issued by the Government of India to drive your car, truck, bus, bike, et all. It authorizes or clearly dictates that the owner knows how to drive the vehicle and has undergone tests that abide by the traffic rules and regulations.',
    dos: [
      'Ensure that the entire card is uploaded with all the information clearly visible.',
      'Ensure that the original card is scanned and uploaded.',
    ],
    donts: [],
    column: 'Identity',
  },
  voter_id: {
    title: 'Voter ID',
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1645629128/assets/voteridcard_qkdi4v.jpg',
    subheading: '',
    description:
      'A Voter ID Card, also known as the Electors Photo Identity Card (EPIC) is a photo identity card that is issued by the Election Commission of India to Indian nationals who are above the age of 18.',
    dos: [
      'Ensure that the entire card is uploaded with all the information clearly visible.',
      'Ensure that the original card is scanned and uploaded.',
    ],
    donts: [],
    column: 'Identity',
  },
  valid_passport: {
    title: 'Passport',
    image: 'https://res.cloudinary.com/gradly/image/upload/v1645629130/assets/passport_ncmgw5.png',
    subheading: '',
    description:
      'An Indian passport is issued by the Ministry of External Affairs of the Republic of India to Indian citizens for the purpose of international travel.',
    dos: [
      'Ensure that the original card is scanned and uploaded.',
      'Ensure that all the pages with your information are uploaded.',
    ],
    donts: [],
    column: 'Identity',
  },
  residential_address: {
    title: 'Your current residential address',
    image: '',
    subheading: '',
    description: '',
    dos: [
      'Ensure that the residential address is your current address for communication.',
      'Ensure that you are available at the given address for documentation purposes.',
    ],
    donts: [],
    column: 'Identity',
  },
  valid_bill: {
    title: 'Valid water/electricity/LPG Bill',
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1645629128/assets/electricity_bill_jpr4nn.jpg',
    subheading: '',
    description: '',
    dos: [
      'Ensure that the entire bill is uploaded with your name, address and date clearly visible.',
      'Ensure that the original bill is scanned and uploaded.',
      'Ensure that it’s attested by an authorized gazetted officer.',
    ],
    donts: [],
    column: 'Identity',
  },
  current_house_lease_agreement: {
    title: 'House Lease Agreement',
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1645629129/assets/lease_agreement_c4baoe.jpg',
    subheading: '',
    description:
      'A lease agreement is a document or a written contract between the property owner or a landlord also known as lessor and the tenant or lessee, which contains all the terms and conditions, including the rent to be paid, security deposit to be made, etc.',
    dos: [
      'Ensure that the entire agreement is uploaded with all the information clearly visible.',
      'Ensure that the original agreement is scanned and uploaded.',
      'Ensure that it’s attested by an authorized gazetted officer.',
    ],
    donts: [],
    column: 'Identity',
  },
  allotment_letter: {
    title: 'Allotment Letter',
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1645629129/assets/allotmentletter_hnvrof.jpg',
    subheading: '',
    description:
      'An allotment letter is an important document required for getting a home loan. It is issued by a developer or the housing authority, stating the description of the property and details of the amount paid by the buyer to the developer',
    dos: [
      'Ensure that the entire letter is uploaded with all the information clearly visible.',
      'Ensure that the original agreement is scanned and uploaded.',
      'Ensure that it’s attested by the authorized individual.',
    ],
    donts: [],
    column: 'Financial',
  },
  chain_deed: {
    title: 'Chain Deed',
    image: 'https://res.cloudinary.com/gradly/image/upload/v1645971141/assets/sale_deed_i2xdv8.jpg',
    subheading: '',
    description:
      "The chain deed/chain of title is a real estate records search that lists the successive owners of a home or property. You may get it from the Sub-registrar's office in your area.",
    dos: [
      'Ensure that the entire deed is uploaded with all the information clearly visible.',
      'Ensure that the original deed is scanned and uploaded.',
      'Ensure that the original deed is scanned and uploaded.',
    ],
    donts: [],
    column: 'Financial',
  },
  tax_copy: {
    title: 'Property Tax copy',
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1645629128/assets/propertytaxcopy_wwwxd1.jpg',
    subheading: '',
    description:
      'Property tax is the annual amount paid by a land owner to the local government or the municipal corporation of his area.',
    dos: [
      'Ensure that the entire copy is uploaded with your name, tax amount and date clearly visible.',
      'Ensure that it’s attested by an authorized gazetted officer.',
    ],
    donts: [],
    column: 'Financial',
  },
  building_plan: {
    title: 'Government Approved Building Plan',
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1645629130/assets/appovedbuildingplan_fxrja6.jpg',
    subheading: '',
    description:
      'Government approved building plans are the final construction plans/documents that have been approved by the local governing body that will be utilized for the construction of the Project.',
    dos: [
      'Ensure that the entire plan along with the approval letter is uploaded.',
      'Ensure that the documents are uploaded as a compiled zip file, and not individually.',
      'Ensure that it’s attested by an authorized gazetted officer.',
    ],
    donts: [],
    column: 'Financial',
  },
  completion_certificates: {
    title: 'OC and Completion Certificate',
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1645629130/assets/oc_and_completion_certificate_vs1glg.png',
    subheading: '',
    description:
      'A completion certificate is a legal document given by the concerned authority that signifies that any building/ complex is constructed by adhering to the approved plan and the set regulations of the local development authority.',
    dos: [
      'Ensure that the entire letter is uploaded with all the information clearly visible.',
      'Ensure that the original letter is scanned and uploaded.',
      'Ensure that the documents are uploaded as a compiled zip file, and not individually.',
      'Ensure that it’s attested by an authorized gazetted officer.',
    ],
    donts: [],
    column: 'Financial',
  },
  registration_receipt: {
    title: 'Registration Receipt',
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1646017871/assets/registration_receipt_mpuqp6.jpg',
    subheading: '',
    description:
      'Registration of the property is a full and final agreement signed between two parties. Registration receipt is the proof of registration.',
    dos: [
      'Ensure that the entire agreement is uploaded with all the information clearly visible.',
      'Ensure that the original agreement is scanned and uploaded.',
      'Ensure that it’s attested by an authorized gazetted officer.',
    ],
    donts: [],
    column: 'Financial',
  },
  certificate_of_qualification: {
    title: 'Certificate of Qualification',
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1646017916/assets/qualifcation_certificate_le82pj.jpg',
    subheading: '',
    description:
      'A certificate provided by the concerned authorized institution as proof of qualification in respective professions.',
    dos: [
      'Ensure that the entire agreement is uploaded with all the information clearly visible.',
      'Ensure that the original agreement is scanned and uploaded.',
      'Ensure that it’s attested by an authorized gazetted officer.',
    ],
    donts: [],
    column: 'Financial',
  },
  salary_slip: {
    title: 'Salary Slip',
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1645629127/assets/salary_slip_hrxsgg.jpg',
    subheading: '',
    description:
      'A salary slip, also known as a payslip, is a document containing an employee’s salary details, including basic pay, bonuses, deductions, etc., given to the employee by the employer each month.',
    dos: [
      'Ensure that the entire copy is uploaded with your name, salary amount and date clearly visible.',
      'Ensure that it’s attested by an authorized gazetted officer.',
    ],
    donts: [],
    column: 'Financial',
  },
  form16_or_it_returns_copy: {
    title: 'Form 16',
    image: 'https://res.cloudinary.com/gradly/image/upload/v1645629129/assets/form_16_bgl9mp.png',
    subheading: '',
    description:
      'Form 16 is a certificate (issued under section 203 of the Income Tax deducted at source (TDS) by the employer and submitted by them to the Income Tax Department (IT Department).',
    dos: [
      'Ensure that the entire copy is uploaded with your name, tax amount and date clearly visible.',
      'Ensure that it’s attested by an authorized gazetted officer.',
    ],
    donts: [],
    column: 'Financial',
  },
  tds_certificate: {
    title: 'TDS Certificate (Form 16, if applicable)',
    image: 'https://res.cloudinary.com/gradly/image/upload/v1645629129/assets/form_16_bgl9mp.png',
    subheading: '',
    description:
      'Form 16 is a certificate (issued under section 203 of the Income Tax deducted at source (TDS) by the employer and submitted by them to the Income Tax Department (IT Department).',
    dos: [
      'Ensure that the entire copy is uploaded with your name, tax amount and date clearly visible.',
      'Ensure that it’s attested by an authorized gazetted officer.',
    ],
    donts: [],
    column: 'Financial',
  },
  sales_deed: {
    title: 'Title Deed or Sales Deed',
    image: 'https://res.cloudinary.com/gradly/image/upload/v1645971141/assets/sale_deed_i2xdv8.jpg',
    subheading: '',
    description:
      'The Deed of Sale is a binding document that specifies every agreement that has been made between the buyer and seller of the property.',
    dos: [
      'Ensure that the entire agreement is uploaded with all the information clearly visible.',
      'Ensure that the original agreement is scanned and uploaded.',
      'Ensure that it’s attested by an authorized gazetted officer.',
    ],
    donts: [],
    column: 'Financial',
  },
  last_3years_itr: {
    title: 'ITR',
    image: 'https://res.cloudinary.com/gradly/image/upload/v1646016812/assets/itr_uw6wpo.jpg',
    subheading: '',
    description:
      'An Income tax return (ITR) is a form used to file information about your income and tax to the Income Tax Department. As per the income tax laws, the return must be filed every year by an individual or business that earns any income during a financial year.',
    dos: [
      'Ensure that the entire copy is uploaded with your name, tax amount and date clearly visible.',
      "Ensure that it's attested by an authorized gazetted officer.",
    ],
    donts: [],
    column: 'Financial',
  },
  bank_account_statement: {
    title: 'Bank Account Statement',
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1645971209/assets/bank_account_statement_pfnzvn.jpg',
    subheading: '',
    description:
      "A bank statement or account statement is a document issued by a bank describing the activities in a depositor's savings account or current account during the period. Account holders can keep a track of their transactions and account through the bank statements.",
    dos: [
      'Ensure that the bank’s letterhead information is clearly visible.',
      'Ensure that the document is legible.',
      'Ensure that the document is in a pdf format only.',
    ],
    donts: [],
    column: 'Financial',
  },

  business_address_proof: {
    title: 'Business Address Proof',
    image:
      'https://res.cloudinary.com/gradly/image/upload/v1645629128/assets/electricity_bill_jpr4nn.jpg',
    subheading: '',
    description: '',
    dos: [
      'Ensure that the entire bill is uploaded with your name, address and date clearly visible.',
      'Ensure that the original bill is scanned and uploaded.',
      'Ensure that it’s attested by an authorized gazetted officer.',
    ],
    donts: [],
    column: 'Financial',
  },
};
