import { Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Accordion, HelpContentView, IFrameGuide } from '../../components';
import { PredefinedStep, StepStatus } from '../../repos';
import { ContactsOutlined, FileSearchOutlined, MessageFilled } from '@ant-design/icons';
import { consularAppointmentHelpData } from './ConsularAppointmentData';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { navigate } from '@reach/router';
import { GradlyLoading } from '../../App';

interface ConsularAppointmentProps {
  step: PredefinedStep;
}

export const ConsularAppointmentView: React.FC<ConsularAppointmentProps> = (props) => {
  const { step } = props;
  const [showHelp, setShowHelp] = useState(false);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  const subSteps = [
    {
      title: step.title,
      content: {
        left: (
          <Row justify="center">
            <Spin spinning={loading} indicator={<GradlyLoading height={'120%'} />}>
              <iframe
                width="100%"
                height="100%"
                style={{ minHeight: '70vh' }}
                src="https://cgifederal.secure.force.com/?country=India&language=English"
                title="Consular appointment"
                frameBorder="0"
                onLoad={() => setLoading(false)}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
            </Spin>
          </Row>
        ),
        right: (
          <HelpContentView
            helpData={consularAppointmentHelpData}
            showHelp={showHelp}
            setShowHelp={setShowHelp}
            title="Consular appointment"
            ctaText={
              <>
                <MessageFilled />
                Chat with immigration expert
              </>
            }
            helpTitle={
              <>
                <FileSearchOutlined /> FORM SECTIONS
              </>
            }
          />
        ),
      },
      flex: showHelp ? ['auto', '472px'] : ['auto', '150px'],
    },
  ];

  useEffect(() => {
    if (window.location.pathname.includes(step.route)) {
      setVisible(true);
    }
  }, [window.location.pathname]);

  return (
    <Accordion.Item
      key={step.route}
      icon={'contacts'}
      highlighted
      showCohere
      status={step.status || StepStatus.Incomplete}
      title={step.title}
      onExpanded={() => {
        navigate!(`/${UserJourneyStageId.VisaApplication}/${step.route}`);
        setVisible(true);
        setShowHelp(false);
        setLoading(true);
      }}>
      <IFrameGuide
        width={1600}
        journeyStageId={UserJourneyStageId.VisaApplication}
        visible={visible}
        setVisible={setVisible}
        step={step}
        setShowHelp={setShowHelp}
        subSteps={subSteps}
        titleIcon={<ContactsOutlined />}
      />
    </Accordion.Item>
  );
};
