import React, { CSSProperties } from 'react';
import { Article } from '../../repos';
import './styles.scss';

interface ArticlePopOverContentProps {
  article: Article;
  style?: CSSProperties;
}

// TODO: Vamsee Chamakura 30/10/19 - Figure out loading
export const ArticlePopOverContent: React.FC<ArticlePopOverContentProps> = props => {
  // const [isLoading, setLoading] = useState<boolean>(true);
  const { article } = props;
  return (
    <iframe
      title={article.title}
      className="popup-iframe"
      // onLoad={() => setLoading(false)}
      src={article.link}
      width="100%"
    />
  );
};
