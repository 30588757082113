import { message } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  RootState,
  updateAcademicInformation,
  updateApplicationInterest,
  updateUserApplications,
  updateUserProfile,
} from '../../redux';
import { ApplicationStage, UserApplicationInterest, UserProfile } from '../../repos';
import { ApplyingStageForm } from './ApplyingStageForm';
import './styles.sass';

interface Props {
  readonly currentUser?: UserProfile;
  readonly updateUserProfile: typeof updateUserProfile;
  readonly updateAcademicInformation: typeof updateAcademicInformation;
  readonly updateApplicationInterest: typeof updateApplicationInterest;
  readonly updateUserApplications: typeof updateUserApplications;
}

const OnboardingContainer = (props: Props) => {
  const [selectedStage = ApplicationStage.Applying, _] = useState<ApplicationStage>();

  const { updateUserProfile, updateApplicationInterest, currentUser } = props;

  const renderStage = () => {
    switch (selectedStage) {
      case ApplicationStage.Applying:
        return (
          <ApplyingStageForm
            onSubmit={(targetYear, levelOfStudy, targetSemester, helpItems, major) => {
              if (!major) {
                message.error('Please enter your branch or field name');
                return;
              }
              const applicationInterest: Partial<UserApplicationInterest> = {
                targetSemester,
                targetYear,
                major,
                degree: levelOfStudy,
              };
              updateApplicationInterest(applicationInterest);
              updateUserProfile({
                completedStages: helpItems.map((item) => item),
                stage: selectedStage,
                onboardingCompleted: true,
              });
            }}
          />
        );
    }
  };

  return (
    <div className="onboarding-container">
      <p>
        <h2>
          <span role="img" style={{ marginRight: 4 }} aria-label="waving hand emoticon">
            👋
          </span>{' '}
          Hey, <span>{currentUser && currentUser.name.split(' ')[0]}!</span>
        </h2>
        <h3>Tell us a bit about yourself so we can best help you.</h3>
      </p>

      <div style={{ margin: '16px 0 48px', width: '100%', overflowY: 'auto' }}>{renderStage()}</div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { currentUser } = state.Auth;
  return { currentUser: currentUser ? currentUser : undefined };
};

export default connect(mapStateToProps, {
  updateAcademicInformation,
  updateUserProfile,
  updateApplicationInterest,
  updateUserApplications,
})(OnboardingContainer);
