import { navigate } from '@reach/router';
import moment from 'moment';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import { RootEpic } from '.';
import { ResumeRepo, SopRepo, SopTag, UserRepo, UserTestScoreInformation } from '../../repos';
import {
  Analytics,
  AnalyticsEventName,
  catchAndReportError,
  firestoreDocumentListener,
  FirestoreReference,
} from '../../utils';
import {
  setError,
  setMessage,
  setSopTags,
  setSopVersion,
  setUserTestScoreInformation,
  SopReviewActionTypes,
} from '../actions';

export const getSopVersionEpic: RootEpic = (action$, store) => {
  return action$.pipe(
    filter(isOfType(SopReviewActionTypes.GetSopVersion)),
    withLatestFrom(store),
    switchMap(([, state]) => {
      const { currentUser } = state.Auth;
      if (!currentUser) {
        return of(setError('Something went wrong. Please sign in again'));
      }
      return SopRepo.listenToSopVersion(currentUser.id).pipe(
        map((sopVersion) => setSopVersion(sopVersion)),
        catchError((error) => of(setError(error.message))),
      );
    }),
  );
};

export const getScoresEpic: RootEpic = (action$, store) => {
  return action$.pipe(
    filter(isOfType(SopReviewActionTypes.SetSopVersion)),
    switchMap((action) => {
      const { sopVersion } = action.payload;
      if (!sopVersion) {
        return of(setError());
      }
      return UserRepo.listenToUserTestScoreInformation(sopVersion.userId).pipe(
        map(setUserTestScoreInformation),
        catchAndReportError((error) => of(setError(error.message))),
      );
    }),
  );
};

export const submitSopVersionEpic: RootEpic = (action$, store) => {
  return action$.pipe(
    filter(isOfType(SopReviewActionTypes.SubmitSopForm)),
    withLatestFrom(store),
    switchMap(([action, state]) => {
      const { currentUser } = state.Auth;
      if (!currentUser) {
        return of(setError('Something went wrong. Please sign in again'));
      }
      const {
        sop,
        greScore,
        greDate,
        toeflScore,
        toeflDate,
        universities,
        sopHelp,
        loanStatus,
        sopDeadlineDate,
        resumeFile,
      } = action.payload;

      if (!sop || sop.trim().length === 0) {
        return of(setError('Please add your sop as a text'));
      }

      if (!sopDeadlineDate) {
        return of(setError('Please add Sop deadline'));
      }

      if (!sopHelp) {
        return of(setError('Please state if you are taking paid Sop help'));
      }

      if (!universities || universities.length === 0) {
        return of(setError('Please add the name of the universities to which you are applying'));
      }

      if (toeflScore && (toeflScore > 120 || toeflScore < 0)) {
        return of(setError('Please enter a valid TOEFL score.'));
      }

      if (!greDate && !greScore) {
        return of(setError('Please add your GRE Score or GRE Date'));
      }

      if (!toeflDate && !toeflScore) {
        return of(setError('Please add your GRE Score or GRE Date'));
      }

      const scores: UserTestScoreInformation = {};

      if (greScore) {
        scores.gre = [
          {
            id: FirestoreReference.UserGreScores().doc().id,
            createdAt: moment(),
            ...greScore,
          },
        ];
      }

      if (toeflScore) {
        scores.toefl = [
          {
            id: FirestoreReference.UserToeflScores().doc().id,
            createdAt: moment(),
            score: toeflScore,
          },
        ];
      }
      const uploadResumeObservable: Observable<{
        url?: string;
        storagePath?: string;
      }> = resumeFile ? SopRepo.uploadResume(resumeFile, currentUser.id) : of({});

      return uploadResumeObservable.pipe(
        switchMap(({ url, storagePath }) => [
          SopRepo.createSopVersion({
            greDate,
            toeflDate,
            universities,
            studentName: currentUser.name,
            text: sop.trim(),
            userId: currentUser.id,
            feedbackSubmitted: false,
            submissionDeadline: sopDeadlineDate,
            takingPaidHelp: sopHelp === 'Yes',
            resume: { url, storagePath },
          }),
          ResumeRepo.createResumeVersion({
            url,
            storagePath,
            studentName: currentUser.name,
            userId: currentUser.id,
          }),
          loanStatus === 'No' ? UserRepo.addHelpItem(currentUser.id, 'Need help with loans') : of(),
          UserRepo.updateUserTestScoreInformation(currentUser.id, scores),
        ]),
        tap(() => Analytics.log(AnalyticsEventName.SOPRequestReviewFormSubmitted)),
        map(() => {
          navigate('/sop-review/review-feedback');
          return setMessage('Your SOP has been submitted for review');
        }),
      );
    }),
  );
};

export const listenToSopReviewComments: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(SopReviewActionTypes.SetSopVersion)),
    filter(
      (action) =>
        !!action.payload.sopVersion &&
        action.payload.sopVersion.feedbackSubmitted &&
        !!action.payload.sopVersion.tagAnnotations,
    ),
    switchMap((action) => {
      const { sopVersion } = action.payload;
      return combineLatest(
        sopVersion!.tagAnnotations!.map((tag) =>
          firestoreDocumentListener<SopTag>(FirestoreReference.SopTags().doc(tag.tagId)),
        ),
      ).pipe(
        map((tags) => {
          return setSopTags(
            tags.reduce((prev, current) => {
              return { ...prev, [current.id]: current };
            }, {} as { [key: string]: SopTag }),
          );
        }),
        catchError((error) => of(setError(error.message))),
      );
    }),
  );
};

export const updateSopReviewRatingEpic: RootEpic = (action$, store) => {
  return action$.pipe(
    filter(isOfType(SopReviewActionTypes.SubmitSopReviewRating)),
    withLatestFrom(store),
    switchMap(([action, state]) => {
      const { sopVersion } = state.SopReview;
      if (!sopVersion) {
        return of(
          setError('Your SOP application is not found. Please contact us to get this fixed'),
        );
      }
      return SopRepo.updateSopReviewRating(sopVersion.sopId, action.payload.rating).pipe(
        mapTo(setMessage('Your feedback has been submitted')),
        catchError((error) => of(setError(error.message))),
      );
    }),
  );
};

export const markSopReviewAsSeenEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(SopReviewActionTypes.MarkedCurrentReviewAsSeen)),
    switchMap((action) => {
      const { sopVersionId } = action.payload;
      return SopRepo.markReviewAsSeen(sopVersionId).pipe(
        mapTo(setMessage()),
        catchError((error) => of(setError(error.message))),
      );
    }),
  );
};

export const markHelpItemsEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(SopReviewActionTypes.MarkHelpItems)),
    switchMap((action) => {
      const { sopVersionId, comments, tagAnnotations } = action.payload;
      return SopRepo.markForHelp(sopVersionId, comments, tagAnnotations).pipe(
        mapTo(setMessage()),
        catchError((error) => of(setError(error.message))),
      );
    }),
  );
};

export const submitHelpRequestEpic: RootEpic = (action$) => {
  return action$.pipe(
    filter(isOfType(SopReviewActionTypes.SubmitHelpRequest)),
    switchMap((action) => {
      const { sopVersionId } = action.payload;
      return SopRepo.submitForHelp(sopVersionId).pipe(
        mapTo(setMessage()),
        catchError((error) => of(setError(error.message))),
      );
    }),
  );
};
