import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { groupBy } from 'lodash';
import { Moment } from 'moment';
import { forkJoin, from, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { db } from '../../redux/store';

import {
  AnyObject,
  applyDeleteMarkersForUndefined,
  convertFirestoreTimestampFieldsToMoment,
  firestoreDocumentListener,
  firestoreDocumentListenerWithFallback,
  firestoreQueryListener,
  FirestoreReference,
} from '../../utils';
import { CountryData, Gender, Location } from '../Common';

const { auth, firestore } = firebase;

export enum ApplicationStage {
  Applying = 'applying',
  PostAdmit = 'postAdmit',
  Enrolled = 'enrolled',
}

export interface UserProfile {
  id: string;
  primaryEmail: string;
  primaryPhone: string;
  name: string;
  isEmailVerified?: string;
  onboardingCompleted?: boolean;
  helpItems?: string[];
  stage: ApplicationStage;
  card_funds: number;
  flightDate: Moment;
  stripeCustomerID?: string;
  cardDetails?: {
    cardNumber: string;
    exp_month: number;
    exp_year: number;
    cvc: string;
    nameOnCard: string;
  };
  completedStages: string[];
  isAnonymous: boolean | firebase.firestore.FieldValue;
  shownBadges: { [key: string]: string[] };
}

export interface UserInformation {
  firstName: string;
  middleName?: string;
  lastName: string;
  email: {
    personal?: string;
    university?: string;
  };
  maritalStatus?: 'married' | 'unmarried';
  citizenshipCountry?: CountryData;
  placeOfBirth?: CountryData;
  dob?: Moment;
  gender?: Gender;
  phone: {
    homeCountry?: string;
    destinationCountry?: string;
  };
  photoUrl?: string;
  address: {
    homeCountry: Location;
    destinationCountry: Location;
  };
}

export enum Degree {
  HighSchool = 'High School',
  Bachelors = "Bachelor's",
  Masters = "Master's",
  PhD = 'PhD',
  NonDegree = 'Other',
}

export interface UserAcademicInformation {
  highSchool?: {
    id: string;
    nameOfInstitution: string;
    startDate: Moment;
    endDate?: Moment;
    gpa?: string;
  }[];
  bachelors?: {
    id: string;
    universityName: string;
    department: string;
    startDate: Moment;
    endDate?: Moment;
    gpa?: string;
  }[];
  masters?: {
    id: string;
    universityName: string;
    school: string;
    startDate: Moment;
    endDate?: Moment;
    programme: string;
  }[];
  phd?: {
    id: string;
    universityName: string;
    school: string;
    startDate: Moment;
    endDate?: Moment;
    programme: string;
  }[];
  nonDegree?: {
    id: string;
    programme: string;
    startDate: Moment;
    endDate?: Moment;
    nameOfInstitution: string;
  }[];
}

export enum WorkType {
  Internship = 'Internship',
  FullTime = 'Full Time',
  PartTime = 'Part Time',
}

export interface UserWorkInformation {
  internships?: {
    id: string;
    organizationName: string;
    startDate: string;
    endDate: string;
    positionTitle: string;
    department: string;
  }[];
  fullTime?: {
    id: string;
    organizationName: string;
    startDate: string;
    endDate: string;
    positionTitle: string;
    department: string;
  }[];
  partTime?: {
    id: string;
    organizationName: string;
    startDate: string;
    endDate: string;
    positionTitle: string;
    department: string;
  }[];
}

export enum AdmissionSemester {
  Fall = 'fall',
  Spring = 'spring',
  Summer = 'summer',
  Enrolled = 'enrolled',
}

export interface UserApplicationInterest {
  id: string; // document id in firestore
  userId: string;
  targetSemester: AdmissionSemester;
  targetYear: string;
  major: string;
  degree: Degree;
  createdAt: Moment;
}

export interface UserApplication {
  id: string; // document id in firestore
  userId: string;
  targetSemester: AdmissionSemester;
  targetYear: string;
  degree: Degree;
  universityName: string;
  major: string;
}

export enum ScoreType {
  GRE = 'GRE',
  GMAT = 'GMAT',
  TOEFL = 'TOEFL',
}

export interface UserTestScoreInformation {
  gre?: {
    id: string;
    quant: number;
    verbal: number;
    writing: number;
    takenOn?: Moment;
    createdAt: Moment;
  }[];
  gmat?: {
    id: string;
    analyticalWritingAssessment: number;
    integratedReasoning: number;
    quantitativeAndVerbal: number;
    total: number;
    takenOn?: Moment;
    createdAt: Moment;
  }[];
  toefl?: {
    id: string;
    score: number;
    takenOn?: Moment;
    createdAt: Moment;
  }[];
}

export interface UserLorVersion {
  id: string; // firestore document id
  createdAt: Moment;
  userId: string;
  lorId: string; // for versioning
  url: string;
  recommender: {
    name: string;
    title: string;
    relationship: string;
  };
}

export interface SopTag {
  id: string; // firestore document id
  createdAt: Moment;
  label: string;
  textInHtml: string;
  deleted: boolean; // to ensure backwards compatibility
}

export const listenToUserProfile = (userId: string): Observable<UserProfile> => {
  return firestoreDocumentListenerWithFallback(FirestoreReference.UserProfiles().doc(userId), undefined, (snapshot) => {
    const data = convertFirestoreTimestampFieldsToMoment(snapshot.data());
    return { id: userId, ...data } as UserProfile;
  }).pipe(
    filter((val) => !!val),
    map((val) => val!),
  );
};

export const listenToUserInformation = (userId: string): Observable<UserInformation> => {
  return firestoreDocumentListener(FirestoreReference.UserInformation().doc(userId));
};

export const listenToUserAcademicInformation = (userId: string): Observable<UserAcademicInformation> => {
  return firestoreQueryListener<AnyObject>(
    FirestoreReference.UserAcademicInformation().where('userID', '==', userId),
  ).pipe(
    map((documents) => {
      return groupBy(documents, (document) => {
        switch (document.type) {
          case Degree.Bachelors:
            return 'bachelors';
          case Degree.HighSchool:
            return 'highSchool';
          case Degree.Masters:
            return 'masters';
          case Degree.NonDegree:
            return 'nonDegree';
          case Degree.PhD:
            return 'phd';
        }
      }) as UserAcademicInformation;
    }),
  );
};

export const listenToUserWorkInformation = (userId: string): Observable<UserWorkInformation> => {
  return firestoreQueryListener<AnyObject>(
    FirestoreReference.UserAcademicInformation().where('userID', '==', userId),
  ).pipe(
    map((documents) => {
      return groupBy(documents, (document) => {
        switch (document.type) {
          case WorkType.Internship:
            return 'internship';
          case WorkType.PartTime:
            return 'partTime';
          case WorkType.FullTime:
            return 'fullTime';
        }
      }) as UserWorkInformation;
    }),
  );
};

export const listenToUserTestScoreInformation = (userId: string): Observable<UserTestScoreInformation> => {
  return forkJoin(
    firestoreQueryListener(FirestoreReference.UserGreScores().where('userID', '==', userId)),
    firestoreQueryListener(FirestoreReference.UserToeflScores().where('userID', '==', userId)),
    firestoreQueryListener(FirestoreReference.UserGmatScores().where('userID', '==', userId)),
  ).pipe(
    map(([gre, toefl, gmat]) => {
      return { gre, toefl, gmat } as UserTestScoreInformation;
    }),
  );
};

export const updateUserProfile = (userId: string, data: Partial<UserProfile>) => {
  return from(FirestoreReference.UserProfiles().doc(userId).set(data, { merge: true }));
};

export const addHelpItem = (userId: string, helpTopic: string) => {
  return from(
    FirestoreReference.UserProfiles()
      .doc(userId)
      .update({ helpItems: firestore.FieldValue.arrayUnion(helpTopic) }),
  );
};

export const updateUserInformation = (userId: string, data: Partial<UserInformation>) => {
  return from(
    FirestoreReference.UserInformation().doc(userId).set(applyDeleteMarkersForUndefined(data), { merge: true }),
  );
};

export const updateUserAcademicInformation = (userId: string, data: Partial<UserAcademicInformation>) => {
  return from(
    FirestoreReference.UserAcademicInformation().doc(userId).set(applyDeleteMarkersForUndefined(data), { merge: true }),
  );
};

export const updateUserApplicationInterest = (userId: string, data: Partial<UserApplicationInterest>) => {
  const { id = FirestoreReference.UserApplicationInterests().doc().id } = data;
  delete data.id;
  return from(
    FirestoreReference.UserApplicationInterests()
      .doc(id)
      .set(applyDeleteMarkersForUndefined({ ...data, userId }), { merge: true }),
  );
};

export const updateUserApplications = (userId: string, data: Partial<UserApplication>[]) => {
  const promises = [];
  for (const application of data) {
    const { id = FirestoreReference.UserApplications().doc().id } = application;
    delete application.id;
    promises.push(
      FirestoreReference.UserApplications()
        .doc(id)
        .set(applyDeleteMarkersForUndefined({ ...application, userId }), { merge: true }),
    );
  }
  return forkJoin(promises).pipe(map((data) => data));
};

export const updateUserWorkInformation = (userId: string, data: Partial<UserWorkInformation>) => {
  return from(
    FirestoreReference.UserWorkInformation().doc(userId).set(applyDeleteMarkersForUndefined(data), { merge: true }),
  );
};

export const updateUserTestScoreInformation = (userId: string, data: Partial<UserTestScoreInformation>) => {
  const batch = db.batch();
  if (data.gre) {
    for (const score of data.gre) {
      batch.set(
        FirestoreReference.UserGreScores().doc(score.id),
        {
          ...applyDeleteMarkersForUndefined({ ...score, userId, id: undefined }),
          createdAt: firestore.FieldValue.serverTimestamp(),
        },
        { merge: true },
      );
    }
  }
  if (data.gmat) {
    for (const score of data.gmat) {
      batch.set(
        FirestoreReference.UserGmatScores().doc(score.id),
        {
          ...applyDeleteMarkersForUndefined({ ...score, userId, id: undefined }),
          createdAt: firestore.FieldValue.serverTimestamp(),
        },
        { merge: true },
      );
    }
  }
  if (data.toefl) {
    for (const score of data.toefl) {
      batch.set(
        FirestoreReference.UserToeflScores().doc(score.id),
        {
          ...applyDeleteMarkersForUndefined({ ...score, userId, id: undefined }),
          createdAt: firestore.FieldValue.serverTimestamp(),
        },
        { merge: true },
      );
    }
  }
  return from(batch.commit());
};

export const getIdToken = (): Observable<string> => {
  const currentUser = auth().currentUser;
  if (!currentUser) {
    throw new Error('Cannot get Firebase ID Token since the user in not logged in.');
  }
  return from(currentUser.getIdToken());
};
