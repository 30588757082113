import { MessageFilled } from '@ant-design/icons';
import { Button, Modal, Row } from 'antd';
import React from 'react';
import { IntercomAPI } from '../Intercom';
import Styles from './ImageHelpModal.module.sass';

export interface ImageHelpData {
  image: any;
  title: string | React.ReactElement;
  chatText: string;
  helpText: string;
}

interface Props extends ImageHelpData {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export const ImageHelpModal: React.FC<Props> = (props) => {
  const { visible, setVisible, image, helpText, title, chatText } = props;

  return (
    <Modal
      className={Styles.insModal}
      visible={visible}
      centered
      closable
      mask={false}
      maskClosable
      destroyOnClose
      footer={null}
      onCancel={() => {
        setVisible(false);
      }}
      title={<div className={Styles.title}>{title}</div>}>
      <Row justify="center">
        <h2>{helpText}</h2>
        <Button
          className={Styles.chatButton}
          icon={<MessageFilled />}
          onClick={() => {
            IntercomAPI.showWithMessagePreFilled(
              `Hey! I have questions about my visa slip.`,
              false,
            );
          }}>
          {chatText}
        </Button>
      </Row>
      <div className={Styles.content}>
        <img src={image} alt={title.toString()}></img>
      </div>
    </Modal>
  );
};
