import { RightOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React, { FC } from 'react';
import Styles from './ComingSoonWelcomeScreen.module.sass';
import { stageIcons } from './../../repos/Common/Common';
import { UserJourneyStageId } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckDouble, faCircleCheck } from '@fortawesome/pro-duotone-svg-icons';
import { IntercomAPI } from '../Intercom';

interface Props {
  title: string;
  description: string;
  stageID: UserJourneyStageId;
  listItems: string[];
  steps: { icon: any; title: string; items: string[] }[];
}

export const ComingSoonWelcomeScreen: FC<Props> = (props) => {
  const { title, stageID, steps, description, listItems } = props;

  return (
    <>
      <div className={Styles.welcomeScreen + ' animate__animated animate__fadeIn'}>
        <div className={Styles.welcomeScreenContainer}>
          <Row justify="space-between" align="top" className={Styles.welcomeBanner}>
            <Col span={24}>
              <h2>Coming Soon</h2>
              <h1>
                <img src={stageIcons[stageID]} alt={stageID}></img>
                {title}
              </h1>
            </Col>
            <Col span={10}>
              <p>{description}</p>
              <Button
                size="large"
                type={'primary'}
                onClick={() => {
                  IntercomAPI.showWithMessagePreFilled(
                    `Hey! I want early access to ${title}.`,
                    false,
                  );
                }}>
                Get Early Access <RightOutlined />
              </Button>
            </Col>
            <Col span={12}>
              <ul>
                {listItems.map((item, index) => (
                  <li key={index}>
                    <FontAwesomeIcon icon={faCircleCheck} /> {item}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
          <Row wrap={false} className={Styles.stepsContainer}>
            {steps.map((step, index) => (
              <Col className={Styles.step} key={index}>
                <span className={Styles.floatingHead}>Step {index + 1}</span>
                {step.icon}
                <div className={Styles.box}>
                  <span>{step.title}</span>
                  <ul>
                    {step.items.map((item, index) => (
                      <li key={index}>
                        <FontAwesomeIcon icon={faCircleCheck} />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            ))}
            <span className={Styles.floatingHead}>
              <FontAwesomeIcon icon={faCheckDouble} />
            </span>
          </Row>
        </div>
      </div>
    </>
  );
};
