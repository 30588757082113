import { Icon as LegacyIcon } from '@ant-design/compatible';
import { union } from 'lodash';
import React, { useState } from 'react';
import './styles.sass';

interface TagProps {
  title: string;
  value: string;
  selected: boolean;
}

interface Options {
  [title: string]: string;
}

interface TagSelectProps {
  items: Options;
  onSelect: (keys: string[]) => void;
}

export const TagSelect: React.FC<TagSelectProps> = (props) => {
  const [selectedTags = [], setSelectedTags] = useState<string[]>();
  const { items, onSelect } = props;

  const allItems = Object.keys(items);

  const Tag: React.FC<TagProps> = (props) => {
    const { title, value, selected } = props;
    return (
      <div
        className={selected ? 'tag-selected' : 'tag'}
        onClick={() => {
          let newSelected = selectedTags;
          if (selectedTags.includes(value)) {
            newSelected = selectedTags.filter((tag) => tag !== value);
          } else newSelected = [...selectedTags, value];
          setSelectedTags(newSelected);
          onSelect(newSelected);
        }}>
        <LegacyIcon type={selected ? 'check' : 'plus'} />
        <div style={{ marginLeft: 4 }}>{title}</div>
      </div>
    );
  };

  return (
    <div className="tag-select">
      {allItems.map((item) => {
        return (
          <Tag title={items[item]} key={item} value={item} selected={selectedTags.includes(item)} />
        );
      })}
    </div>
  );
};
