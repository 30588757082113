import firebase from 'firebase/compat/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import 'firebase/compat/firestore';
import { createEpicMiddleware } from 'redux-observable';
import { RootAction } from './actions';
import { RootState, rootReducer } from './reducers';
import { Store, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import epics from './epics';
import { Environment } from '../utils';

// Setup firebase

firebase.initializeApp(JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG || '{}'));

setPersistence(getAuth(), browserLocalPersistence);

firebase.firestore().settings({
  ignoreUndefinedProperties: true,
});
const db = firebase.firestore();
if (Environment.useEmulator) db.useEmulator('localhost', 8080);
firebase
  .firestore()
  .enablePersistence({ experimentalForceOwningTab: true })
  .catch((error) => {
    console.error(error);
  });

const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState>();

const store: Store<RootState> = createStore(rootReducer, composeWithDevTools({})(applyMiddleware(epicMiddleware)));

epicMiddleware.run(epics);

export { store, db };
