import { action, ActionType } from 'typesafe-actions';
import { CustomStepKeyedByJourneyStageId } from '../reducers/CustomStepsReducer';
import { StepStatus } from '../../repos';

export enum CustomStepActionTypes {
  ListenToCustomSteps = 'CustomStepAction/ListenToCustomSteps',
  SetCustomSteps = 'CustomStepAction/SetCustomSteps',
  UpdateCustomStepStatus = 'CustomStepAction/UpdateCustomStepStatus',
  UpdateCustomStepStatusSuccess = 'CustomStepAction/UpdateCustomStepStatusSuccess',
}

export const listenToCustomSteps = () => action(CustomStepActionTypes.ListenToCustomSteps);

export const setCustomSteps = (steps: CustomStepKeyedByJourneyStageId) =>
  action(CustomStepActionTypes.SetCustomSteps, { steps });

export const updateCustomStepStatus = (stepId: string, status: StepStatus, payload?: any) =>
  action(CustomStepActionTypes.UpdateCustomStepStatus, { stepId, status, payload });

export const updateCustomStepStatusSuccess = () =>
  action(CustomStepActionTypes.UpdateCustomStepStatusSuccess);

const customStepActions = {
  listenToCustomSteps,
  setCustomSteps,
  updateCustomStepStatus,
  updateCustomStepStatusSuccess,
};

export type CustomStepAction = ActionType<typeof customStepActions>;
