export const apartmentsIcon = require('./apartments.png').default;
export const bankIcon = require('./bank.png').default;
export const finalizeUniversityIcon = require('./finalize-university.png').default;
export const forexIcon = require('./forex.png').default;
export const insuranceIcon = require('./insurance.png').default;
export const loansIcon = require('./loans.png').default;
export const phoneIcon = require('./phone.png').default;
export const sopIcon = require('./sop.png').default;
export const vaccinesIcon = require('./vaccines.png').default;
export const visaIcon = require('./visa.png').default;
export const creditCardIcon = require('./credit-card.png').default;
