import { navigate } from '@reach/router';
import { Col, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { cardImage, cardStackImage } from '../../assets';
import { Accordion, AlertType } from '../../components';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { RootState } from '../../redux';
import { PredefinedStep, StepStatus } from '../../repos';
import Styles from './CardDetails.module.sass';
import { CreateGradlyCardForm } from './CreateGradlyCard';
const { Paragraph } = Typography;

export const CardInfo: React.FC = () => {
  const currentUser = useSelector((state: RootState) => state.Auth.currentUser);
  if (currentUser && currentUser.cardDetails) {
    const { cvc, exp_month, exp_year, nameOnCard, cardNumber } = currentUser!.cardDetails;
    return (
      <Row className={Styles.cardDetails}>
        <Col style={{ fontSize: 16 }} span={24}>
          <p>{nameOnCard}</p>
        </Col>
        <Col span={24}>
          <Paragraph
            style={{ margin: 0, fontSize: 18, fontWeight: 600, lineHeight: 1.33 }}
            copyable={{ text: cardNumber }}>
            <p>
              {cardNumber
                .toString()
                .match(/.{1,4}/g)
                ?.join('-')}
            </p>
          </Paragraph>
        </Col>
        <Col span={10}>
          <p>
            <span className={Styles.cardLable}>Valid through</span>
            {exp_month}/{exp_year % 100}
          </p>
        </Col>
        <Col span={14}>
          <p>
            <span className={Styles.cardLable}>CVV</span>
            {cvc}
          </p>
        </Col>
      </Row>
    );
  } else return null;
};

export const CardImage: React.FC = () => {
  const currentUser = useSelector((state: RootState) => state.Auth.currentUser);
  if (currentUser && currentUser.cardDetails) {
    const { cvc, exp_month, exp_year, nameOnCard, cardNumber } = currentUser!.cardDetails;
    const { card_funds } = currentUser!;
    return (
      <Row justify="center">
        <div className={Styles.cardContainer}>
          <img className={Styles.illustration} alt="Gradly Card" src={cardImage} />
          <div className={Styles.cardBalance}>$ {(card_funds / 100).toFixed(2)}</div>
          <div className={Styles.cardNumber}>
            {cardNumber
              .toString()
              .match(/.{1,4}/g)
              ?.join(' - ')}
          </div>
          <div className={Styles.cardName}>{nameOnCard}</div>
          <div className={Styles.cardExp}>
            {exp_month}/{exp_year % 100}
          </div>
          <div className={Styles.cardCVC}>{cvc}</div>
        </div>
      </Row>
    );
  } else return null;
};

export const CardDetails: React.FC<any> = (props) => {
  useEffect(() => {
    navigate!(`/${UserJourneyStageId.Forex}/${props.route}`);
  }, []);
  const currentUser = useSelector((state: RootState) => state.Auth.currentUser);
  if (!currentUser || !currentUser.cardDetails) {
    return (
      <Row>
        <Col span={14} className={Styles.header}>
          You have no cards yet
        </Col>
        <Col>
          <img className={Styles.cardStack} alt="Gradly Card" src={cardStackImage} />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col span={12}>
        <CardInfo></CardInfo>
        <Row className={Styles.topupContainer}>
          <CreateGradlyCardForm topup={true} />
        </Row>
      </Col>
      <Col span={12}>
        <CardImage />
      </Col>
    </Row>
  );
};

export const CardDetailsCard = (currentStep: PredefinedStep, status: StepStatus) => {
  return (
    <Accordion.Item
      alert={{
        message: 'Your US Debit Card is generated and ready to use!',
        type: AlertType.Success,
        className: Styles.alert,
      }}
      status={'View'}
      key={currentStep.id}
      icon={'credit-card'}
      title={currentStep.title}>
      <CardDetails route={currentStep.route} />
    </Accordion.Item>
  );
};
