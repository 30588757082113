import { Link, RouteComponentProps, useNavigate } from '@reach/router';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Routes } from '../../App';
import { authIllustration, logo } from '../../assets';
import { RootState, setMessage, signInUsingEasyEmail } from '../../redux';
import { Analytics, AnalyticsEventName } from '../../utils';
import { Alert, AlertType } from '../../components';
import Styles from './styles.module.sass';

interface Props extends RouteComponentProps {
  loading: boolean;
  signInUsingEasyEmail: typeof signInUsingEasyEmail;
}

const PasswordlessContainer: React.FC<Props> = (props) => {
  const { loading, signInUsingEasyEmail } = props;
  const { successMessage, error } = useSelector((state: RootState) => state.Common);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMessage());
  }, []);

  const signIn = (email: string) => {
    Analytics.log(AnalyticsEventName.UserSignInClicked, { origin: 'email-link-auth' });
    signInUsingEasyEmail(email);
  };

  return (
    <Row wrap={false} className={Styles.container}>
      <Col xs={0} md={0} xl={8}>
        <div className={Styles.authIllustration}>
          <img src={authIllustration} alt="Auth"></img>
        </div>
      </Col>
      <Col flex={'auto'}>
        <Row justify="center" className={Styles.authContainer}>
          <img src={logo} alt="Gradly Logo" />
          <h1>Sign in without password</h1>
          {error && (
            <Alert
              className={Styles.alerts}
              message={error}
              type={AlertType.Error}
              icon={<CloseCircleOutlined />}></Alert>
          )}
          {successMessage ? (
            <Alert
              className={Styles.alerts}
              message={successMessage}
              type={AlertType.Success}
              icon={<CheckCircleOutlined />}></Alert>
          ) : (
            <Form
              name="easy-login"
              size="large"
              validateTrigger="onBlur"
              className={Styles.form}
              onFinish={(data) => {
                if (data.email) signIn(data.email);
              }}>
              <p>Please enter your email to get the magic link.</p>

              <Form.Item
                name={'email'}
                rules={[
                  {
                    type: 'email',
                    message: 'This is not a valid email address!',
                  },
                  {
                    required: true,
                    message: 'Please input your email address!',
                  },
                ]}>
                <Input type="email" placeholder="Email address"></Input>
              </Form.Item>

              <Form.Item>
                <Button loading={loading} block type="primary" htmlType="submit">
                  Get link
                </Button>
              </Form.Item>
            </Form>
          )}
          <Link className={Styles.secondaryButton} to={Routes.Home}>
            Sign in with password
          </Link>
        </Row>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: RootState) => {
  const { loading } = state.Auth;
  return { loading };
};

export default connect(mapStateToProps, { signInUsingEasyEmail })(PasswordlessContainer);
