import { Tabs, Image, Modal, Row, Col, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionModal,
  AlertType,
  AssistanceCard,
  InstructionalData,
  InstructionalModal,
} from '../../components';
import { PredefinedStep, StepStatus } from '../../repos';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { RightOutlined, SolutionOutlined } from '@ant-design/icons';
import { navigate } from '@reach/router';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import Styles from './DS160.module.sass';
import { ds160HelpData } from './DS160Data';

interface DS160Props {
  step: PredefinedStep;
}

export const DS160View: React.FC<DS160Props> = (props) => {
  const { step } = props;
  const currentUser = useSelector((state: RootState) => state.Auth.currentUser);
  const [visible, setVisible] = useState(false);
  const [insVisible, setInsVisible] = useState(false);
  const [tabKey, setTabKey] = useState('0');

  const [insData, sInsData] = useState<InstructionalData>({
    title: (
      <>
        <SolutionOutlined /> {step.title}
      </>
    ),
    url: 'https://www.gradly.us/immigration',
    type: 'article',
    chatText: 'Chat with Immigration Expert',
    intercomMsg: `Hey! I've questions about DS-160 Application`,
  });

  const [currentStep, setCurrentStep] = useState(step.status === StepStatus.Complete ? 2 : 0);

  const alert = {
    message: (
      <>
        You can complete your DS-160 application using the guide. To learn more about the DS-160 application,{' '}
        <a
          onClick={() => {
            sInsData({
              url: 'https://www.youtube-nocookie.com/embed/VFw5j0rYkKI?&autoplay=1',
              title: (
                <>
                  <SolutionOutlined /> {step.title}
                </>
              ),
              type: 'video',
              chatText: 'Chat with Immigration Expert',
              intercomMsg: `Hey! I've questions about DS-160 Application.`,
            });
            setInsVisible(true);
          }}>
          watch this video
        </a>{' '}
        or{' '}
        <a
          onClick={() => {
            sInsData({
              title: (
                <>
                  <SolutionOutlined /> {step.title}
                </>
              ),
              url: 'https://www.gradly.us/immigration',
              type: 'article',
              chatText: 'Chat with Immigration Expert',
              intercomMsg: `Hey! I've questions about DS-160 Application.`,
            });
            setInsVisible(true);
          }}>
          read this article
        </a>
        .
      </>
    ),
    type: AlertType.Info,
  };

  useEffect(() => {
    if (window.location.pathname.includes(step.route)) {
      setVisible(true);
    }
  }, [window.location.pathname]);

  useEffect(() => {
    setTabKey('0');
  }, [currentStep]);

  if (!currentUser) return null;
  const subSteps = Object.keys(ds160HelpData).map((k, index) => {
    const data = ds160HelpData[k];
    return {
      title: k,
      content: {
        left: (
          <Row className={Styles.tabContent} justify={'center'}>
            <Col>
              <h1>Step: {k}</h1>
            </Col>
            <Col span={24}>
              <Tabs
                className={Styles.dsTabs}
                defaultActiveKey="0"
                activeKey={tabKey.toString()}
                onTabClick={(k) => setTabKey(k)}
                tabPosition={'left'}
                items={data.map((d, index) => {
                  const id = String(index);
                  return {
                    label: d.title,
                    key: id,
                    children: (
                      <>
                        <h2>{d.message}</h2>
                        {(d.thumbnail || d.img) && (
                          <Image
                            className={Styles.infoImg}
                            src={d.thumbnail || d.img}
                            onClick={() => {
                              Modal.info({
                                centered: true,
                                className: Styles.imgModal,
                                maskClosable: true,
                                mask: false,
                                closable: true,
                                title: d.title,
                                content: (
                                  <Image
                                    fallback={d.thumbnail}
                                    preview={false}
                                    className={Styles.infoImg}
                                    src={d.img}
                                  />
                                ),
                              });
                            }}
                            preview={{
                              visible: false,
                            }}
                          />
                        )}
                        <div>
                          {!(currentStep >= Object.keys(ds160HelpData).length - 1 && index + 1 === data.length) && (
                            <Button
                              type="primary"
                              onClick={() => {
                                index + 1 < data.length
                                  ? setTabKey((index + 1).toString())
                                  : setCurrentStep(currentStep + 1);
                              }}>
                              Next <RightOutlined />
                            </Button>
                          )}
                        </div>
                      </>
                    ),
                  };
                })}
              />
            </Col>
          </Row>
        ),
        right: null,
      },
      spans: [24, 0],
      alert: alert,
    };
  });

  return (
    <>
      <InstructionalModal visible={insVisible} setVisible={setInsVisible} {...insData} />
      <AccordionModal
        width={1316}
        title={step.title}
        icon={<SolutionOutlined />}
        step={step}
        showMarkAsComplete={{
          uid: currentUser.id,
          route: [UserJourneyStageId.VisaApplication, step.id],
        }}
        visible={visible}
        onCompleted={() => setVisible(false)}
        onCancel={() => setVisible(false)}>
        <AssistanceCard
          footer={[]}
          steps={subSteps}
          currentStep={currentStep}
          containerClass={Styles.paysevisContainer}
          setCurrentStep={setCurrentStep}></AssistanceCard>
      </AccordionModal>
      <Accordion.Item
        key={step.route}
        icon="solution"
        highlighted
        status={step.status || StepStatus.Incomplete}
        title={step.title}
        onExpanded={() => {
          navigate!(`/${UserJourneyStageId.VisaApplication}/${step.route}`);
          setVisible(true);
        }}></Accordion.Item>
    </>
  );
};
