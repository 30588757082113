import React from 'react';
import { ConsumeContentStep, StepStatus } from '../../../repos';
import { AlertProps, AlertType } from '../../Alert';
import { HtmlToReact } from '../../HtmlToReact';
import Styles from '../CustomStep.module.sass';
import { StepFulfillmentView } from './StepFulfillmentView';

interface Props {
  step: ConsumeContentStep;
  onMarkAsComplete(): void;
}

export const getConsumeContentAlert = (step: ConsumeContentStep): AlertProps | undefined => {
  switch (step.status) {
    case StepStatus.Locked:
      return;
    case StepStatus.Incomplete:
      return;
    case StepStatus.Processing:
      return;
    case StepStatus.ReadyForReview:
      return {
        type: AlertType.Ready,
        message: 'Your request is ready for review. Please review it below.',
      };
    case StepStatus.Complete:
      return {
        type: AlertType.Success,
        message: 'This step is done, you can always view our response below.',
      };
  }
};

export const ConsumeContentStepView: React.FC<Props> = (props) => {
  const { step, onMarkAsComplete } = props;

  switch (step.status) {
    case StepStatus.Incomplete:
      return <div></div>;
    case StepStatus.Processing:
      return <div></div>;
    case StepStatus.Pending:
      return <div></div>;
    case StepStatus.ReadyForReview:
      return (
        <div className={Styles.consumeContentContainerIncomplete}>
          {step.descriptionInHtml && (
            <HtmlToReact className={Styles.description} htmlString={step.descriptionInHtml} />
          )}
          <StepFulfillmentView
            className={Styles.stepFulfillmentView}
            response={{ attachments: step.attachments, htmlString: step.htmlString }}
            primaryButton={{ text: 'Mark as Complete', onClick: () => onMarkAsComplete() }}
          />
        </div>
      );
    case StepStatus.Complete:
      return (
        <div className={Styles.consumeContentContainerComplete}>
          {step.descriptionInHtml && (
            <HtmlToReact className={Styles.description} htmlString={step.descriptionInHtml} />
          )}
          <StepFulfillmentView
            className={Styles.stepFulfillmentView}
            response={{ attachments: step.attachments, htmlString: step.htmlString }}
          />
        </div>
      );
    case StepStatus.Locked:
      return <div></div>;
  }
};
