import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Moment } from 'moment';
import { from, Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { PredefinedStep } from '../Content';
import { applyDeleteMarkersForUndefined, convertFirestoreTimestampFieldsToMoment, firestoreQueryListener, FirestoreReference, Void } from '../../utils';

const { firestore } = firebase;

export enum StepStatus {
  Locked = 'locked',
  Incomplete = 'incomplete',
  Processing = 'processing',
  ReadyForReview = 'readyForReview',
  Complete = 'complete',
  Pending = 'pending',
}

export enum StepType {
  'FillForm' = 'FillForm',
  'RsvpToWebinar' = 'RsvpToWebinar',
  'ConsumeContent' = 'ConsumeContent',
  'Custom' = 'Custom',
}

export interface FulfillmentResponse {
  attachments: {
    url: string;
    type: string;
    title: string;
  }[];
  htmlString?: string;
}
export interface CustomStep extends PredefinedStep {
  id: string;
  userId: string;
  createdBy: {
    id: string;
    name: string;
    displayPictureUrl?: string;
  };
  status: StepStatus;
  dueDate?: Moment;
  associatedStageId: UserJourneyStageId;
  associatedStage: string;
  type: StepType;
  isHidden: boolean;
  stageProgressionOn: {
    [StepStatus.Incomplete]: Moment | undefined;
    [StepStatus.Processing]: Moment | undefined;
    [StepStatus.ReadyForReview]: Moment | undefined;
    [StepStatus.Complete]: Moment | undefined;
    [StepStatus.Pending]: Moment | undefined;
  };
  fulfillmentResponse?: FulfillmentResponse;
  createdAt: Moment;
  updatedAt: Moment;
  isCustom: boolean;
}

export interface FillFormStep extends CustomStep {
  typeformUrl: string;
  userResponse?: { [k: string]: { question: string; answer: any } };
}

export interface RsvpStep extends CustomStep {
  webinarId: string;
}

export interface ConsumeContentStep extends CustomStep, FulfillmentResponse {
  userResponse?: { finishedReading: boolean };
}

export const listenToCustomSteps = (userId: string): Observable<CustomStep[]> => {
  return firestoreQueryListener(
    FirestoreReference.CustomSteps().where('userID', '==', userId).orderBy('order', 'asc'),
    (snapshot) =>
      convertFirestoreTimestampFieldsToMoment({
        ...snapshot.data(),
        isCustom: true,
        route: snapshot.id,
        id: snapshot.id,
      }) as CustomStep,
  );
};

export const updateStatus = (stepId: string, status: StepStatus, payload?: any): Observable<void> => {
  return from(
    FirestoreReference.CustomStep(stepId).update(
      applyDeleteMarkersForUndefined({
        status,
        userResponse: payload,
        updatedAt: firestore.FieldValue.serverTimestamp(),
        [`stageProgressionOn.${status}`]: new Date(),
      }),
    ),
  ).pipe(mapTo(Void));
};
