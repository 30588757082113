import { useEffect, useMemo, useState } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);

  return windowDimensions;
}

export function useLandScape() {
  const windowDimensions = getWindowDimensions();
  return useMemo(() => {
    return windowDimensions.width > windowDimensions.height;
  }, [windowDimensions.width, windowDimensions.height]);
}

export function useMobile() {
  const { width, height } = useWindowDimensions();
  return useMemo(() => {
    return height > width && width <= 768;
  }, [width, height]);
}
