import { QuestionCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Form, Radio, Space } from 'antd';
import React, { useState } from 'react';
import { FileUploader } from '../../components/Uploader';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { FirestoreReference } from '../../utils';
import Styles from './Subforms.module.sass';
import { StepID } from './LoanStepContainer';
import { StepStatus } from '../../repos';
import { LoanSubStatus } from './ApplyBanksStep';
import { DocsHelpModal } from '../../components';
import { helpData } from './loanHelpData';
import { SubFormProps } from './LoanForm';

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 6, offset: 2 },
};

export const FinancialForm: React.FC<SubFormProps> = (props) => {
  const { uid, allFormData, setAllFormData } = props;
  const [showHelp, setShowHelp] = useState(false);
  const [salaried, setSalaried] = useState<boolean | undefined>();
  const [helpID, setHelpID] = useState('salary_slip');
  const FINANCIAL_CURRENT = LoanSubStatus.Financial;
  const [form] = Form.useForm();

  const onSubmit = (values: any) => {
    if (uid) {
      FirestoreReference.LoanApplication(uid).doc('financial').set(values);
      FirestoreReference.UserStepsStatus(uid).set(
        {
          [UserJourneyStageId.Loans]: {
            [StepID.ApplyToBanks]: { status: StepStatus.Processing, subStatus: 4 },
          },
        },
        { merge: true },
      );
    }
  };

  const getLabel = (label: string, hid: string, subLabel?: string) => {
    return (
      <div className={Styles.labelDiv}>
        {label}
        <QuestionCircleOutlined
          onClick={(e) => {
            e.preventDefault();
            setHelpID(hid);
            setTimeout(() => {
              setShowHelp(true);
            }, 300);
          }}
        />
        {subLabel && <p>{subLabel}</p>}
      </div>
    );
  };

  return (
    <>
      <DocsHelpModal helpData={helpData[helpID]} showHelp={showHelp} setShowHelp={setShowHelp} />
      <Form
        form={form}
        onFinish={onSubmit}
        labelAlign={'left'}
        labelWrap
        initialValues={allFormData.financial}
        onValuesChange={(_, values) => {
          setAllFormData({ financial: values });
        }}
        className={
          `${Styles.form} ${props.current > FINANCIAL_CURRENT ? 'all-disabled' : ''}` +
          ' animate__animated animate__fadeIn'
        }
        {...layout}
        requiredMark={false}>
        <h1>Documents from Co-Applicant:</h1>
        <h2>(select one type)</h2>
        <li>
          <Form.Item
            rules={[{ required: true, message: 'Please select co-applicant type' }]}
            wrapperCol={{ span: 8 }}
            name="co-applicant_type">
            <Radio.Group
              onChange={(e) => {
                setSalaried(e.target.value === 'salaried');
              }}>
              <Space direction="vertical">
                <Radio value={'salaried'}>Salaried Co-Applicant</Radio>
                <Radio value={'self_employed'}>Self-employed Co-applicant</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </li>
        {salaried === undefined ? (
          <></>
        ) : salaried ? (
          <ol>
            <li>
              <Form.Item
                rules={[
                  { required: true, message: 'Please upload your Salary slips of last 3 months' },
                ]}
                name="salary_slip"
                label={getLabel('Salary slips of the last 3 months', 'salary_slip')}
                valuePropName="fileList">
                <FileUploader
                  form={form}
                  maxFileSize={2}
                  maxCount={1}
                  storagePath={`userApplication/${uid}/loans/salary_slip`}
                />
              </Form.Item>
            </li>
            <li>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Please upload your Copy of Form 16 or copy of IT Returns',
                  },
                ]}
                name="form16_or_it_returns_copy"
                label={getLabel(
                  'Copy of Form 16 for last 2-3 years',
                  'form16_or_it_returns_copy',
                  'or copy of IT Returns for last 2-3 financial years, acknowledged by Income Tax Dept.',
                )}
                valuePropName="fileList">
                <FileUploader
                  form={form}
                  maxFileSize={2}
                  maxCount={1}
                  storagePath={`userApplication/${uid}/loans/form16_or_it_returns_copy`}
                />
              </Form.Item>
            </li>
            <li>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Please upload your Bank Account Statements for the last 6 months',
                  },
                ]}
                name="bank_account_statement"
                label={getLabel(
                  'Bank Account Statements for the last 6 months',
                  'bank_account_statement',
                  '(Salary account)',
                )}
                valuePropName="fileList">
                <FileUploader
                  form={form}
                  maxFileSize={2}
                  maxCount={1}
                  storagePath={`userApplication/${uid}/loans/bank_account_statement`}
                />
              </Form.Item>
            </li>
          </ol>
        ) : (
          <ol>
            <li>
              <Form.Item
                rules={[{ required: true, message: 'Please upload your Business address proof' }]}
                name="business_address_proof"
                label={getLabel(
                  'Business address proof',
                  'business_address_proof',
                  '(If applicable)',
                )}
                valuePropName="fileList">
                <FileUploader
                  form={form}
                  maxFileSize={2}
                  maxCount={1}
                  storagePath={`userApplication/${uid}/loans/business_address_proof`}
                />
              </Form.Item>
            </li>
            <li>
              <Form.Item
                rules={[{ required: true, message: 'Please upload your Title Deed & Sales Deed' }]}
                name="tds_certificate"
                label={getLabel('TDS Certificate', 'tds_certificate', '(Form 16A, if applicable)')}
                valuePropName="fileList">
                <FileUploader
                  form={form}
                  maxFileSize={2}
                  maxCount={1}
                  storagePath={`userApplication/${uid}/loans/tds_certificate`}
                />
              </Form.Item>
            </li>
            <li>
              <Form.Item
                rules={[{ required: true, message: 'Please upload your Last 3 years ITR' }]}
                name="last_3years_itr"
                label={getLabel('Last 3 years ITR', 'last_3years_itr')}
                valuePropName="fileList">
                <FileUploader
                  form={form}
                  maxFileSize={2}
                  maxCount={1}
                  storagePath={`userApplication/${uid}/loans/last_3years_itr`}
                />
              </Form.Item>
            </li>
            <li>
              <Form.Item
                rules={[
                  { required: true, message: 'Please upload your Certificate of Qualification' },
                ]}
                name="certificate_of_qualification"
                label={getLabel(
                  'Certificate of Qualification',
                  'certificate_of_qualification',
                  '(For C.A./Doctor and other professionals)',
                )}
                valuePropName="fileList">
                <FileUploader
                  form={form}
                  maxFileSize={2}
                  maxCount={1}
                  storagePath={`userApplication/${uid}/loans/certificate_of_qualification`}
                />
              </Form.Item>
            </li>
            <li>
              <Form.Item
                rules={[{ required: true, message: 'Please upload your Bank Account Statement' }]}
                name="bank_account_statement"
                label={getLabel(
                  'Bank Account Statement for the last 6 months',
                  'bank_account_statement',
                )}
                valuePropName="fileList">
                <FileUploader
                  form={form}
                  maxFileSize={2}
                  maxCount={1}
                  storagePath={`userApplication/${uid}/loans/bank_account_statement`}
                />
              </Form.Item>
            </li>
          </ol>
        )}
        <h1 style={{ marginTop: 64 }}>Collateral Related Documents:</h1>
        <ol>
          <li>
            <Form.Item
              // rules={[{ required: true, message: 'Please upload your Title Deed & Sales Deed' }]}
              name="sales_deed"
              label={getLabel('Title Deed & Sales Deed', 'sales_deed')}
              valuePropName="fileList">
              <FileUploader
                form={form}
                maxFileSize={2}
                maxCount={1}
                storagePath={`userApplication/${uid}/loans/sales_deed`}
              />
            </Form.Item>
          </li>
          <li>
            <Form.Item
              rules={[{ required: true, message: 'Please upload your Registration Receipt' }]}
              name="registration_receipt"
              label={getLabel('Registration Receipt', 'registration_receipt')}
              valuePropName="fileList">
              <FileUploader
                form={form}
                maxFileSize={2}
                maxCount={1}
                storagePath={`userApplication/${uid}/loans/registration_receipt`}
              />
            </Form.Item>
          </li>
          <li>
            <Form.Item
              rules={[{ required: true, message: 'Please upload your Allotment Letter' }]}
              name="allotment_letter"
              label={getLabel('Allotment Letter', 'allotment_letter')}
              valuePropName="fileList">
              <FileUploader
                form={form}
                maxFileSize={2}
                maxCount={1}
                storagePath={`userApplication/${uid}/loans/allotment_letter`}
              />
            </Form.Item>
          </li>
          <li>
            <Form.Item
              rules={[{ required: true, message: 'Please upload your Chain Deed of 30 years' }]}
              name="chain_deed"
              label={getLabel('Chain Deed of 30 years', 'chain_deed')}
              valuePropName="fileList">
              <FileUploader
                form={form}
                maxFileSize={2}
                maxCount={1}
                storagePath={`userApplication/${uid}/loans/chain_deed`}
              />
            </Form.Item>
          </li>
          <li>
            <Form.Item
              rules={[
                { required: true, message: 'Please upload your Tax copy or electricity bill' },
              ]}
              name="tax_copy"
              label={getLabel('Tax copy or electricity bill', 'tax_copy')}
              valuePropName="fileList">
              <FileUploader
                form={form}
                maxFileSize={2}
                maxCount={1}
                storagePath={`userApplication/${uid}/loans/tax_copy`}
              />
            </Form.Item>
          </li>
          <li>
            <Form.Item
              rules={[
                { required: true, message: 'Please upload your Government-approved building plan' },
              ]}
              name="building_plan"
              label={getLabel('Government-approved building plan', 'building_plan')}
              valuePropName="fileList">
              <FileUploader
                form={form}
                maxFileSize={2}
                maxCount={1}
                storagePath={`userApplication/${uid}/loans/building_plan`}
              />
            </Form.Item>
          </li>
          <li>
            <Form.Item
              rules={[
                { required: true, message: 'Please upload your OC and Completion Certificates' },
              ]}
              name="completion_certificates"
              label={getLabel(
                'OC and Completion Certificates',
                'completion_certificates',
                '(If the property is contructed)',
              )}
              valuePropName="fileList">
              <FileUploader
                form={form}
                maxFileSize={2}
                maxCount={1}
                storagePath={`userApplication/${uid}/loans/completion_certificates`}
              />
            </Form.Item>
          </li>
        </ol>
        <Form.Item wrapperCol={{ span: 8 }}>
          <Button disabled={props.current !== FINANCIAL_CURRENT} type="primary" htmlType="submit">
            Submit <RightOutlined />
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
