import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { produce } from 'immer';
import { UserProfile } from '../../repos';
import { AuthAction, AuthActionTypes } from '../actions';

export interface AuthStateType {
  readonly currentUser?: UserProfile | null;
  readonly confirmationResult?: firebase.auth.ConfirmationResult;
  readonly loading: boolean;
}

const initialState: AuthStateType = { loading: false };

export const AuthReducer = (currentState: AuthStateType = initialState, action: AuthAction) => {
  return produce(currentState, (draft) => {
    draft.loading = false;
    switch (action.type) {
      case AuthActionTypes.SignInUsingEmail:
      case AuthActionTypes.SendRecoveryEmail:
      case AuthActionTypes.SignInUsingPhone:
      case AuthActionTypes.SignUp:
      case AuthActionTypes.VerifyOtp:
      case AuthActionTypes.SignInUsingEasyEmail:
        draft.loading = true;
        break;
      case AuthActionTypes.SetCurrentUser:
        draft.currentUser = action.payload.currentUser;
        draft.confirmationResult = undefined;
        break;
      case AuthActionTypes.SetConfirmationResult:
        draft.confirmationResult = action.payload.confirmationResult;
        break;
      default:
        break;
    }
    return draft;
  });
};
