import React from 'react';
import { Row } from 'antd';
import { ComparisonData } from './HealthInsuranceStepContainer';
import { InsuranceDetailsForm } from './../../components';
import Styles from './EnterDetails.module.sass';
import { navigate } from '@reach/router';
import { University } from '../../repos';

interface Props {
  setComparisonData: (data: ComparisonData) => void;
  university?: University
}

export const EnterDetails: React.FC<Props> = (props) => {
  const { setComparisonData, university } = props;

  const onSubmit = (data: ComparisonData) => {
    setComparisonData(data);
    navigate!(`view-plans`);
  };

  return (
    <Row justify="center" className={Styles.container}>
      <InsuranceDetailsForm university={university} onSubmit={onSubmit} />
    </Row>
  );
};
