import { BankFilled, LikeFilled, FundFilled } from '@ant-design/icons';
import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Accordion, WelcomeUserWithProgressHeading } from '../../components';
import { ComingSoonWelcomeScreen } from '../../components/WelcomeScreen';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { RootState } from '../../redux';
import { CustomStep, StagePredefinedSteps, UserProfile } from '../../repos';
import { renderCustomStep } from '../Home';
import { getIndexFromRoute, useStepCalculation } from '../utils';
import Styles from './ApartmentsStepContainer.module.sass';

interface ApartmentsStepContainerProps extends RouteComponentProps {
  customSteps: CustomStep[];
  currentUser?: UserProfile | null;
  allPredefinedSteps: StagePredefinedSteps;
}

export enum StepID {}

const ApartmentsStepContainer: React.FC<ApartmentsStepContainerProps> = (props) => {
  const { customSteps, currentUser, allPredefinedSteps } = props;
  const AccordionInstance = useRef<Accordion>(null);
  const { pathname } = useLocation();
  const [showWelcomeScreen, setShowWelcomeScreen] = useState<boolean>(true);
  const {
    steps,
    stepsLeft,
    calculatedStepsCompleted,
    setCalculatedStepsCompleted,
    predefinedStepsStatus,
  } = useStepCalculation(
    Object.values(StepID),
    allPredefinedSteps,
    customSteps,
    UserJourneyStageId.Apartments,
    showWelcomeScreen,
    currentUser?.id,
  );

  const comingSoonsteps = [
    {
      icon: <BankFilled />,
      title: 'Find roommates & listings',
      items: [
        'Filter roommate based on lifestyle & budget preferences',
        'Filters & actionable data for listings both on & off-campus',
        'Single forum for all questions & requests',
      ],
    },
    {
      icon: <LikeFilled />,
      title: 'Finalize the best-fit properties',
      items: [
        'On Request Video Tours',
        '48 Hour Response Time',
        'Digital Verification of Landlord & Property',
      ],
    },
    {
      icon: <FundFilled />,
      title: 'Complete lease signing & deposit payment',
      items: ['Digital KYC', 'Secure Cross-Border Deposit', 'Digital Lease Signing'],
    },
  ];

  if (currentUser)
    return !predefinedStepsStatus && showWelcomeScreen ? (
      <ComingSoonWelcomeScreen
        title={'Apartments & Student Housing'}
        stageID={UserJourneyStageId.Apartments}
        steps={comingSoonsteps}
        description={
          'Find best-in-class student accommodations at and around your university for free. Simple, secure & an all-in-one solution.'
        }
        listItems={[
          'Digital Lease & Secure Deposit Payment via Gradly',
          'Gradly As Co-Guarantor',
          'No SSN or US Credit History Needed',
        ]}
      />
    ) : (
      <div className={Styles.container + ' animate__animated animate__fadeIn'}>
        <div className={Styles.childContainer}>
          <WelcomeUserWithProgressHeading
            stageID={UserJourneyStageId.Apartments}
            title="Get help in Visa Application"
            currentUser={currentUser}
            stepCount={{ total: steps.length, left: stepsLeft || 0 }}
          />
          <Accordion
            ref={AccordionInstance}
            onExpanded={(index) => {
              if (getIndexFromRoute(pathname, steps) === index) {
                return navigate!(`/${UserJourneyStageId.Apartments}`);
              }
              const route = steps[index].route;
              navigate!(`/${UserJourneyStageId.Apartments}/${route}`);
            }}
            defaultExpanded={getIndexFromRoute(pathname, steps)}
            className={Styles.accordion}>
            {steps.map((step) => {
              switch (step.id) {
                default: {
                  return renderCustomStep(
                    step,
                    calculatedStepsCompleted,
                    setCalculatedStepsCompleted,
                    currentUser.shownBadges[UserJourneyStageId.Apartments] || [],
                  );
                }
              }
            })}
          </Accordion>
        </div>
      </div>
    );
  else return <> </>;
};

const mapStateToProps = (state: RootState) => {
  const { steps } = state.CustomSteps;
  const { currentUser } = state.Auth;
  const allPredefinedSteps = state.Content.predefinedSteps[UserJourneyStageId.Apartments];
  return {
    currentUser,
    customSteps: steps[UserJourneyStageId.Apartments] || [],
    allPredefinedSteps,
  };
};

export default connect(mapStateToProps, {})(ApartmentsStepContainer);
