import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Accordion, WelcomeUserWithProgressHeading } from '../../components';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { RootState } from '../../redux';
import {
  CustomStep,
  PredefinedStep,
  StagePredefinedSteps,
  StepStatus,
  StepType,
  UserProfile,
} from '../../repos';
import { getIndexFromRoute } from '../utils';
import Styles from './ForexStepContainer.module.sass';
import { CreateGradlyCard } from './CreateGradlyCard';
import { CardDetailsCard } from './CardDetails';
import { renderCustomStep } from '..';
import { WelcomeScreen } from './WelcomeScreen';

interface ForexStepContainerProps extends RouteComponentProps {
  customSteps: CustomStep[];
  currentUser?: UserProfile | null;
  allPredefinedSteps: StagePredefinedSteps;
}

enum StepID {
  GetForexCard = 'GetForexCard',
  ForexCardDetails = 'ForexCardDetails',
}

const ForexStepContainer: React.FC<ForexStepContainerProps> = (props) => {
  const { customSteps, currentUser, allPredefinedSteps } = props;
  const [calculatedStepsCompleted = [], setCalculatedStepsCompleted] = useState<string[]>();
  const [showWelcomeScreen, setShowWelcomeScreen] = useState<boolean>(true);
  const AccordionInstance = useRef<Accordion>(null);
  const { pathname } = useLocation();

  const predefinedSteps = Object.values(StepID).map((step: string) => ({
    ...(allPredefinedSteps[step] as PredefinedStep),
    id: step,
  }));

  const steps: (PredefinedStep | CustomStep)[] = predefinedSteps
    .map((step, idx) => ({
      ...step,
      order: idx,
    }))
    .concat(...customSteps)
    .sort((step1, step2) => step1.order - step2.order);

  const stepsLeft =
    steps.length -
    customSteps.filter(
      (step) => step.type !== StepType.RsvpToWebinar && step.status === StepStatus.Complete,
    ).length -
    calculatedStepsCompleted.length;

  useEffect(() => {
    if (!window.location.pathname.includes(UserJourneyStageId.Forex)) {
      navigate!(`/${UserJourneyStageId.Forex}`);
    }
    if (currentUser?.cardDetails) {
      setCalculatedStepsCompleted([...calculatedStepsCompleted, StepID.GetForexCard]);
    }
  }, [window.location.pathname, currentUser]);

  return calculatedStepsCompleted.length === 0 && showWelcomeScreen ? (
    <WelcomeScreen setShowWelcomeScreen={setShowWelcomeScreen} />
  ) : (
    <div className={Styles.container + ' animate__animated animate__fadeIn'}>
      <div className={Styles.childContainer}>
        <WelcomeUserWithProgressHeading
          stageID={UserJourneyStageId.Forex}
          title="Get US Debit Card"
          currentUser={currentUser}
          stepCount={{ total: steps.length - 1, left: stepsLeft - 1 > 0 ? stepsLeft - 1 : 0 }}
        />
        <Accordion
          ref={AccordionInstance}
          onExpanded={(index) => {
            if (getIndexFromRoute(pathname, steps) === index) {
              return navigate!(`/${UserJourneyStageId.Forex}`);
            }
            const route = steps[index].route;
            navigate!(`/${UserJourneyStageId.Forex}/${route}`);
          }}
          defaultExpanded={getIndexFromRoute(pathname, steps)}
          className={Styles.accordion}>
          {steps.map((step) => {
            switch (step.id) {
              case StepID.GetForexCard:
                if (currentUser?.cardDetails) return <></>;
                return CreateGradlyCard(
                  step,
                  currentUser?.cardDetails ? StepStatus.Complete : StepStatus.Incomplete,
                );
              case StepID.ForexCardDetails:
                if (!currentUser?.cardDetails) return <></>;
                return CardDetailsCard(step, StepStatus.Incomplete);
              default: {
                renderCustomStep(
                  step,
                  calculatedStepsCompleted,
                  setCalculatedStepsCompleted,
                  currentUser?.shownBadges[UserJourneyStageId.Forex] || [],
                );
              }
            }
          })}
        </Accordion>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { steps } = state.CustomSteps;
  const { currentUser } = state.Auth;
  const allPredefinedSteps = state.Content.predefinedSteps[UserJourneyStageId.Forex];
  return {
    currentUser,
    customSteps: steps[UserJourneyStageId.Forex] || [],
    allPredefinedSteps,
  };
};

export default connect(mapStateToProps, {})(ForexStepContainer);
