import { UserJourneyStage, UserJourneyTitle } from '../config/userJourneyMap';
import { ApplicationStage, ContentType, StepStatus, StepType } from '../repos';

// Before writing any code here for new events, populate the sheet at the below link
// https://www.notion.so/gradlyus/Analytics-2b2df7506aa74e479f714b129bd5a559

interface Analytics {
  track: (eventName: AnalyticsEventName, props?: { [key: string]: any }) => void;
  identify: (userID: string, props?: { [key: string]: any }) => void;
  reset: () => void;
  load: (apiKey: string) => void;
  page: () => void;
}

declare global {
  interface Window {
    analytics: Analytics;
  }
}

type AuthOrigin = 'email-password' | 'mobile-number-otp' | 'email-link-auth' | 'ums-one-click';

// Onboarding
function log(eventName: AnalyticsEventName.OnboardingNeedHelpClicked): void;

// SOP
function log(
  eventName: AnalyticsEventName.SOPRequestReviewFormOpened,
  properties?: undefined,
): void;

function log(
  eventName: AnalyticsEventName.SOPRequestReviewFormStarted,
  properties?: undefined,
): void;

function log(
  eventName: AnalyticsEventName.SOPRequestReviewFormSubmitted,
  properties?: undefined,
): void;

function log(
  eventName: AnalyticsEventName.SOPRequestReviewFeedbackOpened,
  properties?: undefined,
): void;

function log(
  eventName: AnalyticsEventName.SOPRequestReviewFeedbackViewed,
  properties?: undefined,
): void;

function log(
  eventName: AnalyticsEventName.SOPRequestReviewAdditionalHelpRequestStarted,
  properties?: undefined,
): void;

function log(
  eventName: AnalyticsEventName.SOPRequestReviewAdditionalHelpRequestSubmitted,
  properties?: undefined,
): void;

function log(
  eventName: AnalyticsEventName.SOPRequestReviewAttendWebinarOpened,
  properties?: undefined,
): void;

function log(
  eventName: AnalyticsEventName.SOPRequestReviewWebinarRegisterClicked,
  properties?: undefined,
): void;

function log(
  eventName: AnalyticsEventName.SOPRequestReviewWebinarViewed,
  properties?: undefined,
): void;

// Navigation
function log(
  eventName: AnalyticsEventName.NavigationButtonClicked,
  properties: { step: UserJourneyStage },
): void;

// Custom Steps
/* timeInSeconds is the time interval the user takes to either open the step or perform an action.
For e.g:
A step in Incomplete Stage     -> timeInSeconds = currentTime - time when the step was created.
A step in Processing Stage     -> timeInSeconds = currentTime - time when the step moved from
    Incomplete to Processing.
A step in ReadyForReview Stage -> timeInSeconds = currentTime - time when the step was fulfilled
    by Gradly
*/

function log(
  eventName: AnalyticsEventName.CustomStepOpened,
  properties: {
    timeInSeconds: number;
    status: StepStatus;
    type: StepType;
    title: string;
    associatedStage: UserJourneyTitle;
  },
): void;

function log(
  eventName: AnalyticsEventName.CustomStepActionPerformed,
  properties: {
    timeInSeconds: number;
    status: StepStatus;
    type: StepType;
    title: string;
    associatedStage: UserJourneyTitle;
    actionText: string;
  },
): void;

// Content
function log(
  eventName: AnalyticsEventName.ContentConsumed,
  properties: {
    associatedStage: UserJourneyTitle;
    metadata: { contentId: string; contentType: ContentType };
  },
): void;

// User
function log(eventName: AnalyticsEventName.UserLogoutClicked, properties?: undefined): void;

function log(eventName: AnalyticsEventName.UserLoggedOut, properties?: undefined): void;

function log(
  eventName: AnalyticsEventName.UserSignInClicked,
  properties: { origin: AuthOrigin },
): void;

function log(eventName: AnalyticsEventName.UserSignedIn, properties: { origin: AuthOrigin }): void;

function log(eventName: AnalyticsEventName.UserSignUpClicked, properties?: undefined): void;

function log(eventName: AnalyticsEventName.UserSignedUp, properties?: undefined): void;

function log(eventName: AnalyticsEventName.UserHelpButtonClicked, properties: { origin: '' }): void;

/*
Define a function per event that needs to be logged and make sure it adhered to
the following functions type signatures
Examples:
function log(
  eventName: AnalyticsName.SOPSubmitButtonClicked,
  properties: { source: 'home' | 'settings' },
): void;
function log(eventName: AnalyticsName.UserLogoutClicked, properties?: undefined): void;
*/
function log(eventName: AnalyticsEventName, properties: any = undefined) {
  window.analytics.track(eventName, properties);
}

/*
Use Proper Case for Analytics!
Follow Category-Object-Action framework for naming events
At the below link you can read about Object-Action framework
https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/
We have also added Category to make it even simpler to read events
Distinguish between categories below by using comments as headers
*/
export enum AnalyticsEventName {
  // Onboarding
  OnboardingNeedHelpClicked = 'Onboarding Need Help Clicked',

  // SOP
  SOPRequestReviewFormOpened = 'SOP Request Review Form Opened',
  SOPRequestReviewFormStarted = 'SOP Request Review Form Started',
  SOPRequestReviewFormSubmitted = 'SOP Request Review Form Submitted',
  SOPRequestReviewFeedbackOpened = 'SOP Request Review Feedback Opened',
  SOPRequestReviewFeedbackViewed = 'SOP Request Review Feedback Viewed',
  SOPRequestReviewAdditionalHelpRequestStarted = 'SOP Request Review Additional Help Request Started',
  SOPRequestReviewAdditionalHelpRequestSubmitted = 'SOP Request Review Additional Help Request Submitted',
  SOPRequestReviewAttendWebinarOpened = 'SOP Request Review Attend Webinar Opened',
  SOPRequestReviewWebinarRegisterClicked = 'SOP Request Review Webinar Register Clicked',
  SOPRequestReviewWebinarViewed = 'SOP Request Review Webinar Viewed',

  // Navigation
  NavigationButtonClicked = 'Navigation Button Clicked',

  // Custom Steps
  CustomStepOpened = 'Custom Step Opened',
  CustomStepActionPerformed = 'Custom Step Action Performed',

  // Content
  ContentConsumed = 'Content Consumed',

  // User
  UserLogoutClicked = 'User Logout Clicked',
  UserLoggedOut = 'User Logged Out',
  UserSignInClicked = 'User Sign In Clicked',
  UserSignedIn = 'User Signed In',
  UserSignUpClicked = 'User Sign Up Clicked',
  UserSignedUp = 'User Signed Up',
  UserHelpButtonClicked = 'User Help Button Clicked',
}

const identify = (
  userId: string,
  userProfile: {
    primaryEmail: string;
    primaryPhone: string;
    name: string;
    isEmailVerified?: string;
    onboardingCompleted?: boolean;
    helpItems?: string[];
    stage: ApplicationStage;
  },
) => window.analytics.identify(userId, userProfile);

const reset = () => window.analytics.reset();

export const Analytics = {
  identify,
  reset,
  log,
};
