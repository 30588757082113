import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AlertType,
  Alert,
  InstructionalModal,
  InstructionalData,
} from '../../components';
import { PredefinedStep, StepStatus, UserProfile } from '../../repos';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { FirestoreReference } from '../../utils';
import { mockInterviewImage } from '../../assets';
import { HourglassTwoTone, SolutionOutlined } from '@ant-design/icons';
import Styles from './MockInterview.module.sass';

const MockInterview: React.FC<any> = (props) => {
  const { status } = props;
  const currentUser: UserProfile | undefined | null = useSelector(
    (state: RootState) => state.Auth.currentUser,
  );
  const [videoAskURL, setVideoAskURL] = useState('');
  const [insVisible, setInsVisible] = useState(false);
  const [insData, sInsData] = useState<InstructionalData>({
    title: (
      <>
        <SolutionOutlined /> Mock Interview
      </>
    ),
    url: 'https://www.gradly.us/immigration',
    type: 'article',
    chatText: 'Chat with Immigration Expert',
    intercomMsg: `Hey! I've questions about Visa mock interview.`,
  });
  useEffect(() => {
    if (currentUser)
      switch (status) {
        case StepStatus.Pending:
          setVideoAskURL(
            `https://www.videoask.com/fe09ykpa6#contact_email=${currentUser.primaryEmail}&contact_name=${currentUser.name}&gradlyuserid=${currentUser.id}`,
          );
          break;
        case StepStatus.ReadyForReview:
        case StepStatus.Complete:
          FirestoreReference.UserProfiles()
            .doc(currentUser.id)
            .collection('steps')
            .doc('MockInterview')
            .get()
            .then((doc) => {
              const share_id = doc.data()?.share_id;
              setVideoAskURL(`https://www.videoask.com/${share_id}`);
            });
          break;
        default:
          setVideoAskURL(
            `https://www.videoask.com/fe09ykpa6#contact_email=${currentUser.primaryEmail}&contact_name=${currentUser.name}&gradlyuserid=${currentUser.id}`,
          );
          break;
      }
  }, [status, currentUser]);

  const videoAskComponent = (status: StepStatus) => {
    switch (status) {
      case StepStatus.Pending:
        return (
          <Row>
            <InstructionalModal visible={insVisible} setVisible={setInsVisible} {...insData} />
            <Alert
              type={AlertType.Info}
              message={
                <>
                  You can practice visa interview questions from the mock interview below,{' '}
                  <a
                    onClick={() => {
                      sInsData({
                        title: (
                          <>
                            <SolutionOutlined /> Mock Interview
                          </>
                        ),
                        url: 'https://www.gradly.us/immigration',
                        type: 'article',
                        chatText: 'Chat with Immigration Expert',
                        intercomMsg: `Hey! I've questions about Visa mock interview.`,
                      });
                      setInsVisible(true);
                    }}>
                    read this article
                  </a>
                  {' or '}
                  <a
                    onClick={() => {
                      sInsData({
                        url: 'https://www.youtube-nocookie.com/embed/ekhzC4iYVdU?&autoplay=1',
                        title: (
                          <>
                            <SolutionOutlined /> Mock Interview
                          </>
                        ),
                        type: 'video',
                        chatText: 'Chat with Immigration Expert',
                        intercomMsg: `Hey! I've questions about Visa mock interview.`,
                      });
                      setInsVisible(true);
                    }}>
                    watch this video
                  </a>
                  .
                </>
              }
            />
            <Col span={24}>
              <iframe
                title="video-ask-visa-interview"
                src={videoAskURL}
                allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                width="100%"
                height="600px"
                style={{ border: 'none', borderRadius: '24px', marginTop: 48 }}></iframe>
            </Col>
          </Row>
        );
      case StepStatus.Processing:
        return (
          <Row
            justify="center"
            align="middle"
            className={Styles.awaiting + ' animate__animated animate__fadeIn'}>
            <Col span={12}>
              <span>
                <HourglassTwoTone />
                1-3 days
              </span>
              <h1>Processing Applications</h1>
              <h2>
                Your documents have been submitted to your banks of choice! Your loan offer comes
                next.
              </h2>
            </Col>
            <Col span={12}>
              <img alt={'Stamp'} src={mockInterviewImage}></img>
            </Col>
          </Row>
        );
      case StepStatus.ReadyForReview:
        return (
          <Row>
            <Col>
              <h1>Check our responses</h1>
            </Col>
            <Col span={24}>
              <iframe
                title="video-ask-visa-interview"
                src={videoAskURL}
                allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                width="100%"
                height="600px"
                style={{ border: 'none', borderRadius: '24px' }}></iframe>
            </Col>
            <Button type="primary" style={{ padding: 40 }} onClick={props.onMarkCompleted}>
              Mark as Completed
            </Button>
          </Row>
        );
      case StepStatus.Complete:
        return (
          <Row>
            <Col>
              <h1>Check our responses</h1>
            </Col>
            <Col span={24}>
              <iframe
                title="video-ask-visa-interview"
                src={videoAskURL}
                allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                width="100%"
                height="600px"
                style={{ border: 'none', borderRadius: '24px' }}></iframe>
            </Col>
          </Row>
        );
      default:
        return <></>;
    }
  };

  return videoAskComponent(status);
};

export const MockInterviewCard = (
  currentStep: PredefinedStep,
  status: StepStatus,
  onMarkCompleted: any,
) => {
  return (
    <Accordion.Item
      status={status}
      alert={
        status === StepStatus.Processing
          ? {
              message:
                "We are reviewing your Mock Interview. Once your feedback is ready, we'll send you an email and a notification so that you don't miss it",
              type: AlertType.Processing,
            }
          : undefined
      }
      key={currentStep.id}
      icon={'credit-card'}
      title={currentStep.title}>
      <MockInterview status={status} onMarkCompleted={onMarkCompleted} />
    </Accordion.Item>
  );
};
