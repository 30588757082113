import { Modal } from 'antd';
import React, { FC } from 'react';
import { PredefinedStep, StepStatus } from '../../repos';
import { MarkAsCompleteButton } from '../Buttons';
import { CohereButton } from '../Buttons/CohereCallButton';
import Styles from './Accordion.module.sass';
import { AskAIButton } from '../AskAI';
import { Environment } from '../../utils';

interface AccordionModalProps {
  className?: string;
  title: string | React.ReactElement;
  icon: React.ReactElement;
  width?: number | string;
  step: PredefinedStep;
  showMarkAsComplete?: { uid: string; route: string[]; metadata?: any };
  showCohere?: boolean;
  visible: boolean;
  showAI?: boolean;
  onCompleted?: () => void;
  onCancel: () => void;
}

export const AccordionModal: FC<AccordionModalProps> = (props) => {
  const { step, showMarkAsComplete, visible, onCompleted, onCancel, width, icon, title, showCohere, showAI } = props;

  return (
    <Modal
      centered
      width={width || 1408}
      key={step.id}
      mask={false}
      className={
        Styles.formModal +
        (props.className ? ` ${props.className}` : '') +
        ` ${Styles[step.status || StepStatus.Pending]}`
      }
      visible={visible}
      destroyOnClose
      title={
        <>
          {icon} <h1>{title}</h1>
          {showCohere && <CohereButton />}
          {showAI && Environment.env !== 'production' && <AskAIButton route={step.route} />}
          {showMarkAsComplete && (
            <MarkAsCompleteButton
              style={{ justifySelf: 'flex-end' }}
              status={step.status || StepStatus.Incomplete}
              uid={showMarkAsComplete.uid}
              route={showMarkAsComplete.route}
              metadata={showMarkAsComplete.metadata}
              onCompleted={onCompleted}
            />
          )}
        </>
      }
      footer={null}
      onCancel={onCancel}>
      {props.children}
    </Modal>
  );
};
