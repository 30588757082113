import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Col, Row, Timeline } from 'antd';
import moment, { Moment } from 'moment';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { welcomePicture } from '../../assets';
import { IntercomAPI } from '../../components';
import { listenToStepsStatus, RootState } from '../../redux';
import Styles from './HomePage.module.sass';
import { faComments } from '@fortawesome/pro-regular-svg-icons';
import { CheckCircleFilled, DownloadOutlined, RightOutlined } from '@ant-design/icons';
import { UserJourneyStageId, UserJourneyStages } from './../../config/userJourneyMap';
import { PredefinedSteps, Priority, StepStatus } from '../../repos';
import { Link, navigate, RouteComponentProps } from '@reach/router';
import {
  faCircleExclamation,
  faSignalBarsFair,
  faSignalBarsGood,
  faSignalBarsWeak,
} from '@fortawesome/pro-duotone-svg-icons';
import { downloadFile } from '../../utils';

interface Deadlines {
  deadline: Moment | undefined;
  title: string;
  key: UserJourneyStageId;
}

interface ActionItem {
  deadline: Moment | undefined;
  priority: Priority;
  title: string;
  path: string;
  stageID: UserJourneyStageId;
}

const getDeadlines = (predefinedSteps: PredefinedSteps) => {
  return Object.keys(predefinedSteps)
    .reduce((acc: Deadlines[], key) => {
      const ds = Object.values(predefinedSteps[key])
        .filter((step) => step.deadline)
        .map((step) => {
          return { deadline: step.deadline, title: step.title, key: key as UserJourneyStageId };
        });
      return [...acc, ...ds];
    }, [])
    .sort((a, b) => a.deadline!.diff(b.deadline));
};

const getActionItems = (predefinedSteps: PredefinedSteps, stepsStatus: { [key: string]: any }) => {
  return Object.keys(predefinedSteps)
    .filter((key) => key !== 'sop-review')
    .reduce((acc: ActionItem[], key) => {
      const aitems = Object.keys(predefinedSteps[key])
        .filter((stepID) => {
          return !(
            stepsStatus[key] &&
            stepsStatus[key][stepID] &&
            stepsStatus[key][stepID].status === StepStatus.Complete
          );
        })
        .map((stepID) => {
          const step = Object.assign({}, predefinedSteps[key][stepID]);
          if (step.deadline) {
            const diff = step.deadline.diff(moment(), 'days');
            if (diff <= 2) {
              step.priority += Priority.Urgent;
            }
            if (diff > 2 && diff <= 5) {
              step.priority = step.priority > Priority.High ? step.priority : Priority.High;
            }
            if (diff > 5 && diff <= 14) {
              step.priority = step.priority > Priority.Medium ? step.priority : Priority.Medium;
            }
            if (diff > 14) {
              step.priority = step.priority > Priority.Low ? step.priority : Priority.Low;
            }
            if (diff > 28) {
              step.priority = -1;
            }
          }
          return {
            priority: step.priority,
            title: step.title,
            path: `${key}/${predefinedSteps[key][stepID].route}`,
            deadline: step.deadline,
            stageID: key as UserJourneyStageId,
          };
        })
        .filter((step) => step.priority !== -1);
      return [...acc, ...aitems];
    }, [])
    .sort((a, b) => {
      const diff = b.priority - a.priority;
      return diff === 0 ? (b.deadline || moment().add(1, 'd')).diff(a.deadline || moment().add(1, 'd')) : diff;
    });
};

type HomePageProps = RouteComponentProps;

export const HomePage: React.FC<HomePageProps> = (props) => {
  const currentUser = useSelector((state: RootState) => state.Auth.currentUser);
  const predefinedSteps = useSelector((state: RootState) => state.Content.predefinedSteps);
  const stepsStatus = useSelector((state: RootState) => state.Content.stepsStatus);
  const deadlines = getDeadlines(predefinedSteps);
  const dispatch = useDispatch();
  const actionItems = getActionItems(predefinedSteps, stepsStatus);

  const getPriorityComponent = (priority: Priority) => {
    switch (priority) {
      case Priority.High:
        return (
          <Row className={Styles.pHigh}>
            <FontAwesomeIcon icon={faSignalBarsGood} />
            <span>High Priority</span>
          </Row>
        );
      case Priority.Medium:
        return (
          <Row className={Styles.pMedium}>
            <FontAwesomeIcon icon={faSignalBarsFair} />
            <span>Medium Priority</span>
          </Row>
        );
      case Priority.Low:
        return (
          <Row className={Styles.pLow}>
            <FontAwesomeIcon icon={faSignalBarsWeak} />
            <span>Low Priority</span>
          </Row>
        );
      default:
        return (
          <Row className={Styles.pUrgent}>
            <FontAwesomeIcon icon={faCircleExclamation} />
            <span>Urgent</span>
          </Row>
        );
    }
  };

  const ujsi = Object.values(UserJourneyStageId).filter((id) => id !== 'sop-review');

  const getDone = () => {
    if (currentUser && currentUser.onboardingCompleted)
      return ujsi.reduce((acc, stage) => {
        if ((currentUser.completedStages || []).includes(stage)) return acc + 1;
        const doneSteps = Object.keys(predefinedSteps[stage] || []).filter((stepID) => {
          const status =
            (stepsStatus[stage] && stepsStatus[stage][stepID] && stepsStatus[stage][stepID].status) ||
            StepStatus.Pending;
          return status === StepStatus.Complete;
        }).length;
        return acc + (doneSteps === Object.keys(predefinedSteps[stage] || []).length ? 1 : 0);
      }, 0);
    return 0;
  };

  const getNotStarted = () => {
    if (currentUser && currentUser.onboardingCompleted)
      return ujsi.reduce((acc, stage) => {
        return acc + (!(stepsStatus[stage] || (currentUser.completedStages || []).includes(stage)) ? 1 : 0);
      }, 0);
    return 0;
  };

  const getInProgress = () => {
    if (currentUser && currentUser.onboardingCompleted)
      return ujsi.reduce((acc, stage) => {
        return acc + (stepsStatus[stage] ? 1 : 0);
      }, 0);
    return 0;
  };

  useEffect(() => {
    if (currentUser) dispatch(listenToStepsStatus());
  }, [currentUser, dispatch]);

  if (currentUser) {
    const numDays = moment('20230815', 'YYYYMMDD').diff(moment(), 'days'); // TODO Fix flight date

    const notStarted = getNotStarted();
    const inProgress = getInProgress();
    const doneSteps = getDone();

    Object.values(UserJourneyStageId).forEach((stage: UserJourneyStageId) => {
      UserJourneyStages[stage].completed =
        (currentUser.completedStages || []).includes(stage) ||
        (predefinedSteps[stage] === undefined
          ? false
          : Object.keys(predefinedSteps[stage]).filter((stepID) => {
              const status =
                (stepsStatus[stage] && stepsStatus[stage][stepID] && stepsStatus[stage][stepID].status) ||
                StepStatus.Pending;
              return status === StepStatus.Complete;
            }).length === Object.keys(predefinedSteps[stage]).filter((stepID) => stepID !== 'AttendWebinar').length);
    });

    return (
      <Row justify="center" align="top" className={Styles.container + ' animate__animated animate__fadeIn'}>
        <Row>
          <Col span={16}>
            <h1 className={Styles.welcomeMessage}>
              Hi {currentUser.name.split(' ')[0]}!{' '}
              {numDays > 0
                ? `${numDays} day${numDays > 1 ? 's' : ''} until your
            flight.`
                : 'You are flying today!'}
            </h1>
            <div className={Styles.badges}>
              <Badge
                status="default"
                text={
                  <>
                    <b>{notStarted}</b> Not Started
                  </>
                }
              />
              <Badge
                status="warning"
                text={
                  <>
                    <b>{inProgress}</b> In Progress
                  </>
                }
              />
              <Badge
                status="success"
                text={
                  <>
                    <b>{doneSteps}</b> Done
                  </>
                }
              />
            </div>
          </Col>
          <Col span={8}>
            <Row className={Styles.welcomeBanner}>
              <Col span={19} className={Styles.msgContainer}>
                <div className={Styles.msgBubble}>
                  Hi, {currentUser.name.split(' ')[0]} 👋 If you have any questions, I'm here to help you! 🙂
                </div>
                <Button className={Styles.chatButton} onClick={() => IntercomAPI.show()} type="primary" size="small">
                  <FontAwesomeIcon icon={faComments} /> Chat with me <RightOutlined />
                </Button>
              </Col>
              <Col span={5}>
                <div className={Styles.welcomePic}>
                  <span></span>
                  <img src={welcomePicture} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row wrap={false} justify="center" align={'top'} className={Styles.iconsContainer}>
          {Object.values(UserJourneyStages)
            .filter((stage) => stage.hide !== true)
            .sort((a, b) => +b.completed - +a.completed)
            .map((stage, index) => (
              <Col
                onClick={() => {
                  navigate!(`/${stage.route}`);
                }}
                className={stage.completed ? Styles.completed : ''}
                key={index}
                flex={1}>
                {stage.completed ? <CheckCircleFilled /> : null}
                <img src={stage.icon} alt="icon" />
                <span>{stage.title}</span>
              </Col>
            ))}
        </Row>
        <Row>
          <Col span={12}>
            <Row className={Styles.deadlines}>
              <h2>Deadlines</h2>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  downloadFile(
                    'https://storage.googleapis.com/gradly-production.appspot.com/assets/deadlines.pdf',
                    'Deadlines.pdf',
                  );
                }}
                className={Styles.downloadB}
                type="link"
                download={'Deadlines.pdf'}
                icon={<DownloadOutlined />}>
                Download as PDF
              </Button>
              <Timeline className={Styles.timeline}>
                {deadlines.map((deadline, index) => {
                  const isToday = deadline.deadline!.isSame(moment(), 'date');
                  return (
                    <Timeline.Item className={isToday || index === 0 ? Styles.highlight : ''} key={index}>
                      <Row align="middle">
                        <Col className={Styles.dDate} span={4}>
                          <div>
                            {isToday ? <span>Today</span> : null}
                            <p>{deadline.deadline!.format('D MMM')}</p>
                          </div>
                        </Col>
                        <Col className={Styles.dTitle} span={20}>
                          <p>{deadline.title}</p>
                          <span>{UserJourneyStages[deadline.key].title}</span>
                        </Col>
                      </Row>
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            </Row>
          </Col>
          <Col span={12}>
            <Row align="middle" wrap={true} className={Styles.actionItems}>
              <h2>Action Items</h2>
              <div className={Styles.actionItemsContainer}>
                {actionItems.map((item, index) => (
                  <Row key={index} aria-label={item.priority >= 3 ? 'urgent' : ''} className={Styles.actionItem}>
                    <Col span={3}>{getPriorityComponent(item.priority)}</Col>
                    <Col span={21}>
                      <Row align="middle" className={Styles.aTitle}>
                        <Col span={20}>
                          <span>{UserJourneyStages[item.stageID].title}</span>
                          <p>{item.title}</p>
                        </Col>
                        <Col span={4}>
                          <Link to={item.path}>
                            <Button type="primary">
                              View <RightOutlined />
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ))}
              </div>
            </Row>
          </Col>
        </Row>
      </Row>
    );
  } else return null;
};
