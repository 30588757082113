import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Accordion, AlertProps } from '../../components';
import { RsvpToWebinarStepView } from '../../components/CustomStep/CustomStepTypes';
import { getWebinar, RootState } from '../../redux';
import { ContentType, RsvpStep, StepStatus, Webinar, WebinarRegistration } from '../../repos';
import RegisterForWebinar from './RegisterForWebinar';
import { WebinarManager } from './WebinarManager';

interface Props {
  webinarRegistration?: WebinarRegistration;
  registeredSlot?: { startTime: Moment; endTime: Moment };
  webinar?: Webinar;
  title: string;
  expanded?: boolean;
  highlighted?: boolean;
  rsvpStep?: RsvpStep;
  resourceId?: string;
  onExpanded?: () => void;
  onCompleted?: (completed?: boolean) => void;
}

const AttendWebinarAccordionItem: React.FC<Props> = (props) => {
  const {
    webinarRegistration,
    registeredSlot,
    title,
    expanded,
    highlighted,
    onExpanded,
    onCompleted,
    resourceId,
    rsvpStep,
    webinar,
  } = props;

  const [stepStatus = StepStatus.Incomplete, setStepStatus] = useState<StepStatus>();
  const [alertType, setAlertType] = useState<any>();
  const [message, setMessage] = useState<any>(webinar?.description);
  const [statusText, setStatusText] = useState<string | React.ReactElement>();
  const dispatch = useDispatch();
  const webinarManager = new WebinarManager(
    (message, status, alertType, statusText) => {
      setMessage(webinar?.description);
      setStatusText(statusText);
      setAlertType(alertType);
      setStepStatus(status);
    },
    registeredSlot,
    webinarRegistration,
  );

  useEffect(() => {
    if (resourceId) {
      dispatch(getWebinar(resourceId));
    }
  }, [dispatch, resourceId]);

  useEffect(() => {
    return webinarManager.startObserver();
  }, [registeredSlot && registeredSlot.endTime.format()]);

  useEffect(() => {
    setMessage(webinar?.description);
    setStatusText(webinarManager.getStatusText());
    setAlertType(webinarManager.getAlertType());
    setStepStatus(webinarManager.getStepStatus());
  }, [webinarRegistration, registeredSlot]);

  useEffect(() => {
    onCompleted && onCompleted(stepStatus === StepStatus.Complete);
  }, [stepStatus]);

  const alert: AlertProps = {
    message: message || webinar?.description,
    type: alertType,
  };

  return (
    <Accordion.Item
      icon="video-camera"
      expanded={expanded}
      status={stepStatus}
      alert={alert}
      statusText={statusText}
      highlighted={highlighted}
      onExpanded={onExpanded}
      title={title}>
      {rsvpStep ? <RsvpToWebinarStepView step={rsvpStep} /> : <RegisterForWebinar />}
    </Accordion.Item>
  );
};

const mapStateToPropsForItem = (state: RootState, props: Props) => {
  const { customStepWebinars, webinarRegistrations = [], contentItems = [] } = state.Content;
  if (props.rsvpStep) {
    const webinar = customStepWebinars[props.rsvpStep.webinarId];
    const webinarRegistration =
      webinar &&
      webinarRegistrations.filter((registration) =>
        webinar.slots.map((slot) => slot.conferenceId).includes(registration.conferenceId),
      )[0];

    const registeredSlot =
      webinarRegistration &&
      webinar.slots.filter((slot) => slot.conferenceId === webinarRegistration.conferenceId)[0];

    return {
      webinarRegistration,
      registeredSlot,
      webinar,
    };
  }

  const webinars = contentItems.filter((item) => item.type === ContentType.Webinar) as Webinar[];

  const webinarRegistration = webinarRegistrations.filter((registration) => {
    return webinars
      .map((webinar) =>
        webinar.slots.map((slot) => slot.conferenceId).includes(registration.conferenceId),
      )
      .reduce((prev, next) => prev || next, false);
  })[0];

  const registeredSlot =
    webinarRegistration &&
    webinars
      .map(
        (item) =>
          item.slots.filter((slot) => slot.conferenceId === webinarRegistration.conferenceId)[0],
      )
      .filter((webinar) => !!webinar)[0];

  return {
    webinarRegistration,
    registeredSlot,
  };
};

export default connect(mapStateToPropsForItem, {})(AttendWebinarAccordionItem);
