import { Row, Modal, Button, Col, Image, Select, Collapse } from 'antd';
import React, { ReactElement, useState } from 'react';
import { AssistanceCard, IntercomAPI } from '..';
import { PredefinedStep } from '../../repos';
import Styles from './IFrameGuide.module.sass';
import {
  ArrowLeftOutlined,
  CaretDownFilled,
  CaretRightOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Colors } from '../../theme';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { AccordionModal } from '../Accordion';

const { Panel } = Collapse;

interface DataContent {
  title: string;
  img?: string;
  thumbnail?: string;
  message?: string;
}

export interface HelpData {
  [key: string]: DataContent[];
}

interface HelpContentViewProps {
  showHelp: boolean;
  setShowHelp: (showHelp: boolean) => void;
  helpData: HelpData;
  title: string;
  ctaText: React.ReactElement;
  helpTitle: React.ReactElement;
}

export const HelpContentView: React.FC<HelpContentViewProps> = (props) => {
  const { showHelp, setShowHelp, helpData, title, ctaText, helpTitle } = props;
  const [slideIn, setSlideIn] = useState(true);
  const firstValue = Object.keys(helpData)[0];
  const [selectedSection, setSelectedSection] = useState<string>(firstValue);
  if (showHelp) {
    const singleItem = selectedSection && helpData[selectedSection]![0];
    return (
      <Row
        className={`${Styles.helpContent} animate__animated animate__slide${
          slideIn ? 'In' : 'Out'
        }Right`}>
        <Button
          className={Styles.closeButton}
          onClick={() => {
            setSlideIn(!slideIn);
            setTimeout(() => {
              setShowHelp(false);
            }, 500);
          }}
          type={'primary'}
          size={'small'}
          danger
          icon={<CloseCircleOutlined />}>
          Close Guide
        </Button>
        <Col span={24} className={Styles.helpButtons}>
          <span>Have a question?</span>
          <Button
            type="primary"
            onClick={() => {
              IntercomAPI.showWithMessagePreFilled(
                `Hey! I have questions about ${selectedSection} in ${title}`,
                false,
              );
            }}>
            {ctaText}
          </Button>
        </Col>
        <Col span={24} className={Styles.accordionContainer}>
          <h1>{helpTitle}</h1>
          <Select
            size="large"
            defaultActiveFirstOption
            className={Styles.selectSection}
            showSearch
            suffixIcon={<CaretDownFilled />}
            placeholder={firstValue}
            optionFilterProp="children"
            defaultValue={firstValue}
            onChange={(value: string) => setSelectedSection(value)}
            options={Object.keys(helpData).map((item) => {
              return { value: item, label: item };
            })}
            filterOption={(input, option) =>
              option!.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
          {helpData[selectedSection].length > 1 ? (
            <Collapse
              accordion
              defaultActiveKey={[0]}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              className={Styles.infoCollapse}>
              {helpData[selectedSection].map((item, index) => {
                return (
                  <Panel header={item.title} key={index} className={Styles.infoPanel}>
                    {item.message && <span className={Styles.infoMsg}>{item.message}</span>}
                    {item.img && (
                      <>
                        <Image
                          className={Styles.infoImg}
                          src={item.thumbnail || item.img}
                          onClick={() => {
                            Modal.info({
                              centered: true,
                              className: Styles.imgModal,
                              maskClosable: true,
                              mask: false,
                              closable: true,
                              title: item.title,
                              content: (
                                <Image
                                  fallback={item.thumbnail}
                                  preview={false}
                                  className={Styles.infoImg}
                                  src={item.img}
                                />
                              ),
                            });
                          }}
                          preview={{
                            visible: false,
                          }}
                        />
                      </>
                    )}
                  </Panel>
                );
              })}
            </Collapse>
          ) : (
            <Row justify="center" className={Styles.singleInfo}>
              {singleItem && singleItem.message && (
                <span className={Styles.infoMsg}>{singleItem.message}</span>
              )}
              {singleItem && singleItem.img && (
                <>
                  <Image
                    className={Styles.infoImg}
                    src={singleItem.img}
                    onClick={() => {
                      Modal.info({
                        className: Styles.imgModal,
                        maskClosable: true,
                        mask: false,
                        closable: true,
                        title: singleItem.title,
                        content: (
                          <Image preview={false} className={Styles.infoImg} src={singleItem.img} />
                        ),
                      });
                    }}
                    preview={{
                      visible: false,
                    }}
                  />
                </>
              )}
            </Row>
          )}
        </Col>
      </Row>
    );
  } else
    return (
      <Row
        align="top"
        justify="center"
        className={Styles.guideOpener}
        onClick={() => {
          setSlideIn(true);
          setShowHelp(true);
        }}>
        <Col>
          <h1>Click here for a guide through the form</h1>
          <Button
            type="primary"
            shape="circle"
            icon={<ArrowLeftOutlined size={24} />}
            style={{ backgroundColor: '#fff', color: Colors.Primary600 }}
          />
        </Col>
      </Row>
    );
};

interface IFrameGuideProps {
  step: PredefinedStep;
  journeyStageId: UserJourneyStageId;
  titleIcon: ReactElement<any, any>;
  setShowHelp: (showHelp: boolean) => void;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  subSteps: any[];
  width?: number | string;
  showCohere?: boolean;
}

export const IFrameGuide: React.FC<IFrameGuideProps> = (props) => {
  const {
    step,
    subSteps,
    titleIcon,
    visible,
    setVisible,
    setShowHelp,
    journeyStageId,
    width,
    showCohere,
  } = props;
  const currentUser = useSelector((state: RootState) => state.Auth.currentUser);
  if (!currentUser) return null;
  return (
    <AccordionModal
      key={step.id}
      width={width || 1408}
      visible={visible}
      title={step.title}
      icon={titleIcon}
      step={step}
      showCohere={showCohere}
      showMarkAsComplete={{
        uid: currentUser.id,
        route: [journeyStageId, step.id],
      }}
      onCancel={function (): void {
        setVisible(false);
        setShowHelp(false);
      }}
      onCompleted={function (): void {
        setVisible(false);
        setShowHelp(false);
      }}>
      <AssistanceCard footer={[]} steps={subSteps} currentStep={0}></AssistanceCard>
    </AccordionModal>
  );
};
