import React, { FC } from 'react';
import { PhoneOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import Styles from './Buttons.module.sass';
import Cohere from 'cohere-js';

interface CohereButtonProps extends ButtonProps {
  className?: string;
  onClick?: (e: any) => void;
}

export const CohereButton: FC<CohereButtonProps> = (props) => {
  const { className, onClick, ...rest } = props;
  return (
    <Button
      {...rest}
      type="ghost"
      className={Styles.cohere + (className ? ` ${className}` : '')}
      onClick={(e) => {
        if (onClick) onClick(e);
        Cohere.makeCall();
      }}>
      <PhoneOutlined /> CALL US
    </Button>
  );
};
