import { DeleteFilled, UploadOutlined } from '@ant-design/icons';
import { Button, FormInstance, Upload } from 'antd';
import { UploadFile, UploadFileStatus } from 'antd/lib/upload/interface';
import firebase from 'firebase/compat';
import React, { useState } from 'react';
import Styles from './FileUploader.module.sass';

interface FileUploaderProps {
  storagePath: string;
  maxCount: number;
  maxFileSize: number; // In MB
  value?: string[];
  onChange?: (value: string[] | undefined) => void;
  form?: FormInstance;
  id?: string;
  fileList?: string[];
}

export const FileUploader: React.FC<FileUploaderProps> = (props) => {
  const { storagePath, maxCount, maxFileSize, onChange, form, id, fileList } = props;
  const uploadFiles = fileList
    ? fileList.map((file: string, index: number) => {
        return {
          uid: index.toString(),
          name: file.split('/').pop() || '',
          status: 'done' as UploadFileStatus,
          url: file,
        };
      })
    : [];

  const [filesList, updateFilesList] = useState<UploadFile[] | undefined>(uploadFiles);

  const onFilesChange = (info: any) => {
    updateFilesList(info.fileList.filter((file: any) => !!file.status));
    const doneFiles = info.fileList.filter(
      (file: any) => file.status === 'done' || file.status === 'uploading',
    );
    if (onChange) {
      onChange(
        doneFiles.length > 0
          ? doneFiles!.map((file: any) => `${storagePath}/${file.name}`)
          : undefined,
      );
    }
  };

  const customUpload = async (props: any) => {
    const { onSuccess, onError, file } = props;
    const storage = firebase.storage();
    const storageRef = await storage.ref();
    const uploadedFile = storageRef.child(`${storagePath}/${file.name}`);
    try {
      const image = await uploadedFile.put(file);
      onSuccess(null, image);
      if (form && id) form.setFields([{ name: id, errors: [] }]);
    } catch (e) {
      onError(e);
      updateFilesList(undefined);
    }
  };
  const beforeUpload = (file: any) => {
    if (filesList!.length > maxCount) {
      if (form && id) {
        form.setFields([{ name: id, errors: [`Only ${maxCount} files upload allowed!`] }]);
      }
      return Upload.LIST_IGNORE;
    }
    const isLt20M = file.size / 1024 / 1024 < maxFileSize;
    if (!isLt20M) {
      if (form && id) {
        form.setFields([{ name: id, errors: [`File must smaller than ${maxFileSize} MB!`] }]);
      }
      return Upload.LIST_IGNORE;
    }
    return isLt20M;
  };

  return (
    <Upload
      className={Styles.container}
      onChange={onFilesChange}
      multiple={false}
      maxCount={maxCount}
      beforeUpload={beforeUpload}
      customRequest={customUpload}
      defaultFileList={uploadFiles}
      showUploadList={{ showRemoveIcon: true, removeIcon: <DeleteFilled /> }}>
      <Button icon={<UploadOutlined />}>Upload</Button>
    </Upload>
  );
};
