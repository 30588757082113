import React, { FC, useEffect, useState } from "react";

import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Skeleton,
  Steps,
  Tooltip,
} from "antd";
import {
  countries,
  InsuranceRequestPartner,
  InsuranceRequestStatus,
  InsuranceRequestWellAwayType,
  markStepsStatus,
  PlanType,
  StepStatus,
  University,
  usCitiesStates,
  UserRepo,
  uStates,
} from "../../repos";
import {
  CheckCircleFilled,
  CheckCircleTwoTone,
  DollarOutlined,
  EditOutlined,
  FileTextFilled,
  HomeOutlined,
  IdcardOutlined,
  RightOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Styles from "./PurchaseForms.module.sass";
import { PhoneInput } from "../../components/PhoneInput";
import { isValidNumber } from "libphonenumber-js";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  setAvailablePlans,
  setFormData,
  setInsuranceData,
  setPastEndDate,
  setSelectedPlan,
  setVisaType,
} from "../../redux";
import { Alert, AlertType, UniversityInput } from "../../components";
import { Environment, FirestoreReference, reportSentryError } from "../../utils";
import moment, { Moment } from "moment";
import dayjs from "dayjs";
import { calculateInsurancePremium, getOPTPlans, getPlans } from "./helpers";
import { GradlyLoading } from "../../App";
import { UserJourneyStageId } from "../../config";
import { ComparisonData, StepID } from "./HealthInsuranceStepContainer";
import { SignUpContainer } from "../SignUp";
import firebase from "firebase/compat";
import { CreateGroupInput, JoinGroupInput } from "./CreateGroupInput";
import { Colors } from "../../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faUser } from "@fortawesome/pro-solid-svg-icons";
import { VisaType } from "../../redux/reducers/InsuranceReducer";
const { Step } = Steps;
const { Option } = Select;

interface Props {
  comparisonData?: ComparisonData;
  status: StepStatus | undefined;
}

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const all_countries: Record<string, string> = {};
countries.forEach((country) => {
  all_countries[country.code] = country.name;
});

export const WellAwayPurchaseForm: FC<Props> = (props) => {
  // Initializing variables
  const { university, dob, email } = props.comparisonData || {};
  const plans = useSelector((state: RootState) => state.Insurance.availablePlans) || [];
  const { insuranceData, selectedPlan, formData, pastEndDate, visaType } = useSelector(
    (state: RootState) => state.Insurance,
  );
  const [currentStep, setCurrentStep] = useState(props.status === StepStatus.Processing ? 3 : 0);
  const currentUser = useSelector((state: RootState) => state.Auth.currentUser);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [finalPremium, setFinalPremium] = useState("0");
  const [code, setCode] = useState<string>("");
  const [prefilledCode, setPrefilledCode] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [paymentString, setPaymentString] = useState("Waiting for payment...");
  const [selectedUniversity, setSelectedUniversity] = useState<University | null>(university || null);
  const [coverageOptions, setCoverageOptions] = useState<any>([]);
  const [months, setMonths] = useState<number[]>([]);

  // Variables initialized

  // Hooks

  useEffect(() => {
    if (university && !formData) {
      form.resetFields();
    }
    if (university && university.id === "pitt") {
      dispatch(setPastEndDate(dayjs(undefined)));
      return;
    } else {
      dispatch(setPastEndDate(undefined));
    }
  }, [university]);

  useEffect(() => {
    if (insuranceData) {
      if (insuranceData.finalPremium) {
        setFinalPremium(insuranceData.finalPremium.toString());
      } else {
        calculateInsurancePremium(
          insuranceData.dob,
          insuranceData.universityID,
          insuranceData.insurancePlanID,
          moment(insuranceData.coverageStartDate, "YYYY-MM-DD"),
          moment(insuranceData.coverageEndDate, "YYYY-MM-DD"),
        ).then((data) => {
          if (data) {
            if (!selectedPlan?.isACA) {
              setFinalPremium(data.toFixed(2));
            } else setFinalPremium(((data / 12) * insuranceData.numberOfMonths).toFixed(2));
          }
          // setCurrentStep(3);
        });
      }
    }
  }, [insuranceData, selectedPlan]);

  useEffect(() => {
    if (!form.getFieldValue("university")) return;
    if (visaType !== form.getFieldValue("visa")) {
      dispatch(setVisaType(form.getFieldValue("visa")));
      dispatch(setSelectedPlan(undefined));
      form.resetFields();
    }
    const visa = form.getFieldValue("visa") || visaType;

    const uni = form.getFieldValue("university")[0] as University;
    setSelectedUniversity(uni);
    if (visa === VisaType.OPT) {
      void getOPTPlans(setPlans);
    } else if (uni && uni.plans) {
      getPlans(uni, setPlans);
    } else {
      setPlans([]);
    }
    if (uni) {
      const options = [];
      if (uni.annualEndDate && uni.annualStartDate) {
        options.push({
          label: getTermLabel("Annual", `${uni.annualStartDate} - ${uni.annualEndDate}`),
          value: `annual:${uni.annualStartDate} - ${uni.annualEndDate}`,
        });
      }
      if (uni.graduatingEndDate && uni.graduatingStartDate) {
        options.push({
          label: getTermLabel("Graduating", `${uni.graduatingStartDate} - ${uni.graduatingEndDate}`),
          value: `graduating:${uni.graduatingStartDate} - ${uni.graduatingEndDate}`,
        });
      }
      if (uni.fallStartDate && uni.fallEndDate) {
        options.push({
          label: getTermLabel("Fall Graduating", `${uni.fallStartDate} - ${uni.fallEndDate}`),
          value: `fallgraduating:${uni.fallStartDate} - ${uni.fallEndDate}`,
        });
      }
      if (uni.winterStartDate && uni.winterEndDate) {
        options.push({
          label: getTermLabel("Winter", `${uni.winterStartDate} - ${uni.winterEndDate}`),
          value: `winter:${uni.winterStartDate} - ${uni.winterEndDate}`,
        });
      }
      if (uni.springStartDate && uni.springEndDate) {
        options.push({
          label: getTermLabel("Spring Graduating", `${uni.springStartDate} - ${uni.springEndDate}`),
          value: `spring:${uni.springStartDate} - ${uni.springEndDate}`,
        });
      }
      if (uni.summerStartDate && uni.summerEndDate) {
        options.push({
          label: getTermLabel("Summer Graduating", `${uni.summerStartDate} - ${uni.summerEndDate}`),
          value: `summer:${uni.summerStartDate} - ${uni.summerEndDate}`,
        });
      }
      setCoverageOptions(options);
    }
  }, [form.getFieldValue("university"), form.getFieldValue("visa")]);

  useEffect(() => {
    if (currentStep > 0 && currentUser)
      FirestoreReference.GroupCodes()
        .where("memberEmails", "array-contains", currentUser.primaryEmail)
        .get()
        .then((snapshot) => {
          if (snapshot.docs && snapshot.docs.length > 0) {
            setPrefilledCode(snapshot.docs[0].data().code);
          }
        })
        .catch((error) => {
          console.debug("gc", error);
        });
    if (currentStep === 2 && pastEndDate) {
      form.setFieldsValue({ coverageStartDate: moment(pastEndDate.add(1, "day").toDate()) });
    }
  }, [currentUser, currentStep, pastEndDate]);

  // Hooks End

  // Methods

  const checkOutStripe = (docID: string, planName: string) => {
    if (!currentUser || !docID) return;
    setPaymentString("Redirecting to deposit payment...");
    markStepsStatus(
      currentUser.id,
      [UserJourneyStageId.HealthInsurance, StepID.PurchaseInsurance],
      StepStatus.Processing,
    );
    UserRepo.getIdToken().subscribe(async (token) => {
      fetch(`${Environment.api.baseUrl}/webhooks/stripe/stripe-checkout`, {
        method: "POST",
        body: JSON.stringify({
          planName,
          email: currentUser?.primaryEmail,
          name: currentUser?.name,
          docID,
          partner: InsuranceRequestPartner.WellAway,
          redirectUri: window.location.href,
          stripeCustomerID: currentUser?.stripeCustomerID,
        }),
        headers: {
          "content-type": "application/json",
          authorization: token,
        },
      })
        .then(async (response) => {
          const responseURL = await response.json();
          if (responseURL.url) {
            window.location.href = responseURL.url;
          }
        })
        .catch((err) => reportSentryError(err));
    });
  };

  const createEnrollment = (formData: any, commit?: boolean) => {
    if (!currentUser || !formData) return;
    setLoading(true);
    const groupCode = formData.account.partOfGroup
      ? formData.account.createGroup === "join"
        ? formData.account.gCode
        : code
      : undefined;
    const university = formData.personal1?.university[0] as University;
    if (!formData.account.partOfGroup) {
      _createEnrollment(formData, undefined, commit);
      setLoading(false);
      setCurrentStep(3);
      return;
    }
    UserRepo.getIdToken().subscribe(async (token) => {
      fetch(`${Environment.api.baseUrl}/webhooks/groupPlan/${formData.account.gCode ? "applyCode" : "createCode"}`, {
        method: "POST",
        body: JSON.stringify({
          code: groupCode.trim(),
          name: currentUser.name,
          universityID: university.id,
          memberEmails: [1, 2, 3, 4].map((i) => {
            return formData.account["rEmail" + i];
          }),
        }),
        headers: {
          "content-type": "application/json",
          authorization: token,
        },
      })
        .then(async (response) => {
          const data = await response.json();
          if (data.code) {
            setCode(data.code);
            _createEnrollment(formData, data.code, commit);
            setLoading(false);
            setCurrentStep(3);
          }
          if (data.error) {
            setLoading(false);
            form.setFields([
              {
                name: "gCode",
                errors: [data.error || "Please input valid group code"],
              },
            ]);
          }
        })
        .catch((error) => {
          message.error("Some error happened with group discount, please contact support!");
          setLoading(false);
        });
    });
  };

  const _createEnrollment = async (formData: any, groupCode?: string, commit?: boolean) => {
    if (!currentUser || !formData) return;
    const university = formData.personal1?.university[0] as University;
    const plan = plans[parseInt(formData.account.insurancePlanID)] || selectedPlan;
    if (plan.insuranceCompany === "WellAway") {
      const docRef = FirestoreReference.InsuranceWellAwayRequests().doc();

      const phonePrefix = formData.personal1.prefix.value || formData.personal1.prefix || "US:1";
      const phoneNumber = `+${phonePrefix.split(":")[1]}${formData.personal1.phone}`;
      const destinationCity = formData.personal2.destinationCity;
      let coverageStartDate, coverageEndDate, period;
      if (plan.isACA || university.noWaiver) {
        period = parseInt(formData.account.coveragePeriod);
        coverageStartDate = formData.account.coverageStartDate;
        coverageEndDate = moment(formData.account.coverageStartDate, "YYYY-MM-DD")
          .add(period, "M")
          .subtract(1, "day")
          .format("YYYY-MM-DD");
      } else {
        period = formData.account.coveragePeriod.split(":");
        coverageStartDate = moment(period[1].split("-")[0]).format("YYYY-MM-DD");
        coverageEndDate = moment(period[1].split("-")[1]).format("YYYY-MM-DD");
      }

      const reqData: InsuranceRequestWellAwayType = {
        groupCode,
        purchaseStartDate: moment().format("YYYY-MM-DD"),
        coverageStartDate,
        coverageEndDate,
        numberOfMonths: period,
        gender: formData.personal1.gender,
        maritalStatus: formData.personal1.maritalStatus,
        securityQuestion1: formData.account.securityQuestion1,
        securityQuestion2: formData.account.securityQuestion2,
        securityAns1: formData.account.securityAns1,
        securityAns2: formData.account.securityAns2,
        status: InsuranceRequestStatus.PesonalisedPlansReceived,
        requestID: docRef.id,
        userID: currentUser.id,
        firstName: formData.personal1.firstName,
        lastName: formData.personal1.lastName,
        middleName: formData.personal1.middleName,
        email: formData.personal1.email,
        mobileNumber: phoneNumber,
        usMobileNumber: formData.personal1.usPhone ? "+1" + formData.personal1.usPhone : undefined,
        dob: formData.personal1.dob.format("YYYY-MM-DD"),
        nationality: formData.personal2.nationality,
        destinationAddress1: formData.personal2.destinationAddress1,
        destinationCity: destinationCity[0].toUpperCase() + destinationCity.slice(1),
        destinationStateCode: uStates[formData.personal2.destinationState],
        destinationPostalCode: formData.personal2.destinationPostalCode,
        university: university.name,
        universityID: university.id,
        studentId: formData.personal1.studentID,
        insurancePlan: plan.retailName,
        insurancePlanID: plan.planID,
        homeCountryCode: formData.personal2.nationality,
        homeAddress1: formData.personal2.homeAddress1,
        homeCity: formData.personal2.homeCity,
        homeStateCode: formData.personal2.homeState,
        homePostalCode: formData.personal2.homePostalCode,
        year: parseInt(moment().format("YYYY")),
        partner: InsuranceRequestPartner.WellAway,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        destinationCountryCode: "US",
        visa: formData.personal1.visa,
        graduationDate: formData.account.graduationDate.format("YYYY-MM-DD"),
      };
      if (Environment.env !== "production") reqData.testMe = true;
      setPaymentString("Redirecting to deposit payment...");
      calculateInsurancePremium(
        reqData.dob,
        reqData.universityID,
        reqData.insurancePlanID,
        moment(reqData.coverageStartDate, "YYYY-MM-DD"),
        moment(reqData.coverageEndDate, "YYYY-MM-DD"),
      ).then(async (data) => {
        if (data) {
          if (!selectedPlan?.isACA) {
            setFinalPremium(data.toFixed(2));
          } else setFinalPremium(((data / 12) * reqData.numberOfMonths).toFixed(2));
        }
        dispatch(setInsuranceData({ ...reqData, draft: true }));
        if (commit) {
          await docRef.set(reqData);
          markStepsStatus(
            currentUser.id,
            [UserJourneyStageId.HealthInsurance, StepID.PurchaseInsurance],
            StepStatus.Processing,
          );
          checkOutStripe(docRef.id, plan.retailName);
        }
      });
    }
  };

  const setPlans = (plan: PlanType[]) => {
    dispatch(setAvailablePlans(plan));
  };

  const checkExistingEnrollment = async (formData: any) => {
    if (!currentUser || !formData) return;
    let docid =
      (formData.firstName || "") +
      (formData.middleName || "") +
      (formData.lastName || "") +
      formData.dob.format("M-D-YYYY");
    docid = docid.replace(/ /g, "").toLowerCase();
    FirestoreReference.WellAwayPastPolicies(docid)
      .get()
      .then((doc) => {
        const data = doc.data();
        if (doc.exists && data) {
          const endDate = dayjs(data.endDate, "M/D/YYYY", true);
          if (endDate.isAfter(dayjs()) || endDate.startOf("d").isSame(dayjs().startOf("d"))) {
            dispatch(setPastEndDate(endDate));
          } else {
            dispatch(setPastEndDate(undefined));
          }
        } else {
          dispatch(setPastEndDate(undefined));
        }
      });
  };
  // Methods End

  // Components

  const getTermLabel = (label: string, subLabel: string) => (
    <div className={Styles.termLabel}>
      {label}
      <div className={Styles.termSubLabel}>{subLabel}</div>
    </div>
  );

  const getLabel = (label: string, subLabel: string, required?: boolean) => {
    return (
      <div className={Styles.label}>
        {label} {required && <div className={Styles.requiredMark}>*</div>}
        <p className={Styles.subLabel}>{subLabel}</p>
      </div>
    );
  };

  const ConfirmationActions = () => {
    if (insuranceData) {
      if (insuranceData.depositConfirmed) {
        return (
          <Row align="middle" className={Styles.depositConfirmed}>
            <Row justify="center">
              <FontAwesomeIcon icon={faCircleCheck} /> <h1>Deposit Confirmed</h1>
            </Row>
          </Row>
        );
      }
      if (insuranceData.draft) {
        return (
          <Row align="middle" justify="center" className={Styles.proceedToPay}>
            <Button
              type={"default"}
              className={Styles.editApp}
              onClick={() => {
                setCurrentStep(0);
              }}
            >
              <EditOutlined /> Edit Application
            </Button>
            <Button
              className={Styles.paynow}
              type={"primary"}
              loading={loading}
              onClick={() => {
                createEnrollment(formData, true);
              }}
            >
              Continue To Payment <RightOutlined />
            </Button>
          </Row>
        );
      } else
        return (
          <Row align="middle" justify="center" className={Styles.processingDeposit}>
            <Row justify="center">
              {GradlyLoading({ height: "100%" }, true)}
              <Row align="middle" className={Styles.paymentFail}>
                <h1>{paymentString}</h1>
                <Button
                  type="default"
                  className={Styles.editApp}
                  onClick={async () => {
                    await FirestoreReference.InsuranceWellAwayRequests().doc(insuranceData.requestID).delete();
                    dispatch(setInsuranceData(undefined));
                    if (currentUser) {
                      void FirestoreReference.UserStepsStatus(currentUser.id).set(
                        { "health-insurance": {} },
                        { merge: false },
                      );
                      setCurrentStep(0);
                    }
                  }}
                >
                  <EditOutlined /> Edit Application
                </Button>
                <Button
                  danger
                  className={Styles.retry}
                  type="primary"
                  onClick={() => {
                    if (insuranceData) checkOutStripe(insuranceData?.requestID, insuranceData.insurancePlan);
                  }}
                >
                  Retry payment
                </Button>
              </Row>
            </Row>
          </Row>
        );
    } else {
      return <GradlyLoading />;
    }
  };

  const ConfirmationScreen = () => {
    return (
      <Row gutter={[12, 12]} justify="center" className={Styles.payment + " animate__animated animate__fadeIn"}>
        <Col xs={24} md={12}>
          <div className={Styles.planInfo}>
            <h3>
              <FileTextFilled /> Plan Details
            </h3>
            <span>{insuranceData?.insurancePlan || selectedPlan?.retailName}</span>
            {["0", "NaN"].includes(finalPremium) ? <Skeleton.Input active={true} /> : <h1>${finalPremium}</h1>}
            <h2>
              {moment(insuranceData?.coverageStartDate, "YYYY-MM-DD").format("MMM Do, YYYY")} -{" "}
              {moment(insuranceData?.coverageEndDate, "YYYY-MM-DD").format("MMM Do, YYYY")}
            </h2>
            <ul className={Styles.liReasons}>
              <li key={1}>
                <CheckCircleTwoTone twoToneColor={Colors.Teal600} /> ${selectedPlan?.planFeatures.deductible}
                {selectedPlan?.planFeatures.isDeductibleAnnual ? " annual deductible" : " per injury/illness"}
              </li>
              <li key={2}>
                <CheckCircleTwoTone twoToneColor={Colors.Teal600} />{" "}
                {(selectedPlan?.planFeatures.coInsurance || 0) * 100}% Coinsurance
              </li>
              <li key={3}>
                <CheckCircleTwoTone twoToneColor={Colors.Teal600} /> {selectedPlan?.planFeatures.outOfPocketMax} out of
                pocket maximum
              </li>
            </ul>
          </div>
        </Col>
        <Col xs={24} md={12}>
          <div className={Styles.yourInfo}>
            <h3>
              <FontAwesomeIcon icon={faUser} /> Your Details
            </h3>
            <Row className={Styles.userDetails}>
              <Col span={8} className={Styles.detailLabel}>
                First Name:
              </Col>
              <Col span={16}>{formData?.firstName || insuranceData?.firstName}</Col>
              {(formData?.middleName || insuranceData?.middleName) && (
                <>
                  <Col span={8} className={Styles.detailLabel}>
                    Middle Name:
                  </Col>
                  <Col span={16}>{formData?.middleName || insuranceData?.middleName}</Col>
                </>
              )}
              <Col span={8} className={Styles.detailLabel}>
                Last Name:
              </Col>
              <Col span={16}>{formData?.lastName || insuranceData?.lastName}</Col>
              <Col span={8} className={Styles.detailLabel}>
                Email:
              </Col>
              <Col span={16}>{formData?.email || insuranceData?.email}</Col>
              <Col span={8} className={Styles.detailLabel}>
                Date of Birth:
              </Col>
              <Col span={16}>{dayjs(formData?.dob || insuranceData?.dob).format("MMMM D, YYYY")}</Col>
            </Row>
          </div>
        </Col>
        <Col span={24} className={Styles.payStatus}>
          {ConfirmationActions()}
        </Col>
      </Row>
    );
  };

  const children: React.ReactNode[] = plans.map((p, index) => <Option key={index}>{p.retailName}</Option>);

  let steps = [
    {
      title: "Personal 1",
      icon: <UserOutlined />,
      form: (
        <Form
          {...formItemLayout}
          form={form}
          initialValues={formData ? formData.personal1 : { email, visa: visaType || VisaType.F1 }}
          size="large"
          colon={false}
          className={Styles.form}
          labelWrap
          onValuesChange={(_, data) => {
            dispatch(setFormData({ ...formData, personal1: { ...data, final: false } }));
          }}
          onFinish={(data) => {
            void checkExistingEnrollment(data);
            dispatch(setFormData({ ...formData, personal1: { ...data, final: true } }));
            setCurrentStep(1);
          }}
        >
          <Row gutter={[12, 0]} align="bottom">
            <Col xs={24} md={8}>
              <Form.Item
                className={Styles.noMark}
                rules={[{ required: true, message: "Please enter your first name." }]}
                label={getLabel("First Name", "Indicate as it appears on the passport.", true)}
                name="firstName"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                label={getLabel("Middle Name", "Indicate as it appears on the passport.", false)}
                name="middleName"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                className={Styles.noMark}
                rules={[{ required: true, message: "Please enter your last name." }]}
                label={getLabel("Last Name", "Indicate as it appears on the passport.", true)}
                name="lastName"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                rules={[{ required: true, message: "Please enter your date of birth." }]}
                label="Date of Birth"
                name="dob"
              >
                <DatePicker
                  defaultPickerValue={dob && dob?.isValid() ? dob : undefined || moment().subtract(17, "years")}
                  hideDisabledOptions={true}
                  disabledDate={(date) => date.isAfter(moment().subtract(17, "years"))}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                rules={[{ required: true, message: "Please select your gender." }]}
                label="Gender"
                name="gender"
              >
                <Select placeholder="Choose">
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                  <Option value={0}>Other</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                rules={[{ required: true, message: "Please select your marital status." }]}
                label="Marital Status"
                name="maritalStatus"
              >
                <Select placeholder="Choose">
                  <Option value="Single">Single</Option>
                  <Option value="Married">Married</Option>
                  <Option value="Divorced">Divorced</Option>
                  <Option value="Widowed">Widowed</Option>
                  <Option value="Other">Other</Option>
                  <Option value="Unknown">Unknown</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={10}>
              <Form.Item
                className={Styles.noMark}
                hasFeedback
                label={getLabel(
                  "Home Country Phone Number",
                  "If you're an Indian national, please provide a +91 phone number that will be active during the duration of your degree. A family member's phone number works.",
                  true,
                )}
                name={"phone"}
                rules={[
                  {
                    required: true,
                    message: "Please enter your home phone number.",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!getFieldValue("prefix")) return Promise.reject(new Error("Please select ISD Code."));
                      const prefix = getFieldValue("prefix").value || getFieldValue("prefix");
                      const phoneNumber = `+${prefix.split(":")[1]}${value}`;
                      if (!value || (phoneNumber && isValidNumber(phoneNumber))) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Please enter a valid phone number."));
                    },
                  }),
                ]}
              >
                <PhoneInput />
              </Form.Item>
            </Col>
            <Col xs={24} md={{ span: 9, offset: 1 }}>
              <Form.Item
                hasFeedback
                label="US Phone Number"
                name={"usPhone"}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const phoneNumber = `+1${value}`;
                      if (!value || (phoneNumber && isValidNumber(phoneNumber))) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error("Please enter a valid phone number."));
                    },
                  }),
                ]}
              >
                <Input prefix="+1" />
              </Form.Item>
            </Col>
            <Col xs={24} md={3} offset={1}>
              <Form.Item
                rules={[{ required: true, message: "Please enter your current Visa." }]}
                label="Visa"
                name="visa"
              >
                <Select placeholder="Choose">
                  <Option value={VisaType.F1}>{VisaType.F1}</Option>
                  <Option value={VisaType.OPT}>{VisaType.OPT}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                rules={[{ required: true, message: "Please enter the university you are attending." }]}
                label="University"
                name="university"
              >
                <UniversityInput
                  defaultSelectedUniversities={university ? [university] : []}
                  style={{ border: "1px solid #CBD2D9", borderRadius: 4 }}
                  multiple={false}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                rules={[
                  { required: true, message: "Please enter your university email address." },
                  { type: "email", message: "Please enter a valid email address." },
                ]}
                label="Student University Email ID"
                name="email"
              >
                <Input type="email" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                rules={[{ required: true, message: "Please enter your student ID." }]}
                label="Student ID #"
                name="studentID"
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Next <RightOutlined />
            </Button>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Personal 2",
      icon: <HomeOutlined />,
      form: (
        <Form
          {...formItemLayout}
          form={form}
          initialValues={formData && formData.personal2 ? formData.personal2 : {}}
          size="large"
          colon={false}
          className={Styles.form}
          labelWrap
          onValuesChange={(_, data) => {
            dispatch(setFormData({ ...formData, personal2: { ...data, final: false } }));
          }}
          onFinish={(data) => {
            dispatch(setFormData({ ...formData, personal2: { ...data, final: true } }));
            setCurrentStep(2);
          }}
        >
          <Row gutter={[12, 0]}>
            <Col xs={24} md={24}>
              <Form.Item
                rules={[{ required: true, message: "Please enter your US residential address." }]}
                label="US Residential Address"
                name="destinationAddress1"
              >
                <Input placeholder="Street Address" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                rules={[{ required: true, message: "Please enter your US residential state." }]}
                name="destinationState"
              >
                <Select
                  placeholder="State"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }
                  options={Object.keys(usCitiesStates)
                    .sort()
                    .map((state) => {
                      return { label: state, value: state };
                    })}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                rules={[{ required: true, message: "Please enter your US residential city." }]}
                name="destinationCity"
                shouldUpdate
              >
                <Input placeholder="City" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                rules={[{ required: true, message: "Please enter your US residential Zip code." }]}
                name="destinationPostalCode"
              >
                <Input placeholder="Zip Code" />
              </Form.Item>
            </Col>
            <Col xs={24} md={24}>
              <Form.Item
                className={Styles.noMark}
                rules={[{ required: true, message: "Please enter your home residential address." }]}
                label={getLabel(
                  "Home Country Residential Address",
                  "This would typically be the information your university has on file for you in your home country.",
                  true,
                )}
                name="homeAddress1"
              >
                <Input placeholder="Street Address" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                rules={[{ required: true, message: "Please enter your home residential state." }]}
                name="homeState"
              >
                <Input placeholder="State" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="homeCity"
                rules={[{ required: true, message: "Please enter your home residential city." }]}
              >
                <Input placeholder="City" />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                rules={[{ required: true, message: "Please enter your home residential Zip code." }]}
                name="homePostalCode"
              >
                <Input placeholder="Zip Code" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 0]} align="bottom">
            <Col xs={24} md={8}>
              <Form.Item
                className={Styles.noMark}
                rules={[{ required: true, message: "Please select your citizenship." }]}
                label={getLabel(
                  "Citizenship",
                  "The country whose passport you actively hold. In case of dual citizenship, please list both countries.",
                  true,
                )}
                name="citizenship"
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Choose"
                  options={Object.entries(all_countries).map((c) => {
                    return { value: c[0], label: c[1] };
                  })}
                ></Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={{ span: 8, offset: 1 }}>
              <Form.Item
                className={Styles.noMark}
                rules={[{ required: true, message: "Please select your nationality." }]}
                label={getLabel("Nationality", "The country in which you were born.", true)}
                name="nationality"
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option!.label as unknown as string).toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Choose"
                  options={Object.entries(all_countries).map((c) => {
                    return { value: c[0], label: c[1] };
                  })}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Tooltip
              placement="bottom"
              title={
                !formData?.personal1?.final ? 'Please submit changes in Personal 1 form by clicking "Next" button' : ""
              }
            >
              <Button disabled={!formData?.personal1?.final} type="primary" htmlType="submit">
                Next <RightOutlined />
              </Button>
            </Tooltip>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Account",
      icon: <IdcardOutlined />,
      form: (
        <Form
          key={"account"}
          form={form}
          {...formItemLayout}
          initialValues={
            formData?.account
              ? formData.account
              : {
                  insurancePlanID: selectedPlan?.retailName,
                  partOfGroup: prefilledCode ? true : undefined,
                  createGroup: prefilledCode ? "join" : undefined,
                  gCode: prefilledCode,
                }
          }
          onValuesChange={(values, data) => {
            if ([values.coverageStartDate, values.graduationDate].includes(null)) {
              setMonths([]);
              form.resetFields(["coveragePeriod"]);
              return;
            }
            if ((values.graduationDate || values.coverageStartDate) && data.coverageStartDate && data.graduationDate) {
              const months = (data.graduationDate as Moment).diff(data.coverageStartDate, "months");
              if (months < 2) {
                setMonths([]);
                form.resetFields(["coveragePeriod"]);
                form.setFields([
                  {
                    name: "coveragePeriod",
                    errors: ["Coverage start date and graduation end date should be at-least 3 months apart."],
                  },
                ]);
                return;
              }
              //@ts-ignore
              setMonths([...Array(months - 1).keys()].map((i) => i + 6).filter((i) => i < 13));
              form.resetFields(["coveragePeriod"]);
            }
            if (values.partOfGroup) {
              if (!currentUser || !selectedUniversity) return;
              UserRepo.getIdToken().subscribe(async (token) => {
                fetch(`${Environment.api.baseUrl}/webhooks/groupPlan/createCode`, {
                  method: "POST",
                  body: JSON.stringify({
                    name: currentUser.name,
                    universityID: selectedUniversity.id,
                  }),
                  headers: {
                    "content-type": "application/json",
                    authorization: token,
                  },
                })
                  .then(async (response) => {
                    const data = await response.json();
                    if (data.code) setCode(data.code);
                    if (data.error) {
                      message.error(data.error);
                      form.setFields([
                        {
                          name: "partOfGroup",
                          errors: [data.error || "Group plan not available at this time."],
                        },
                      ]);
                    }
                  })
                  .catch((error) => {
                    message.error("Some error happened with group discount, please contact support!");
                  });
              });
            }
            if (values.insurancePlanID) {
              const plan = plans[parseInt(values.insurancePlanID)];
              if (plan && plan.insuranceCompany !== InsuranceRequestPartner.WellAway) {
                Modal.confirm({
                  content: "Insurance company has changed for this plan. Do you want to continue?",
                  onOk: () => {
                    dispatch(setSelectedPlan(plan));
                    dispatch(setInsuranceData(undefined));
                    dispatch(setFormData({}));
                  },
                });
              } else dispatch(setSelectedPlan(plan));
            }
            dispatch(setFormData({ ...formData, account: { ...data, final: false } }));
          }}
          size="large"
          colon={false}
          className={Styles.form}
          labelWrap
          onFinish={(data) => {
            dispatch(
              setFormData({
                ...formData,
                account: { ...data, coverageStartDate: data.coverageStartDate?.format("YYYY-MM-DD"), final: true },
              }),
            );
            createEnrollment({
              ...formData,
              account: { ...data, coverageStartDate: data.coverageStartDate?.format("YYYY-MM-DD"), final: true },
            });
          }}
        >
          <Row gutter={[12, 0]}>
            {pastEndDate && university && (
              <Alert
                style={{ marginBottom: 24, borderRadius: 4 }}
                message={`Your current Wellaway plan expires on ${moment(pastEndDate.toDate()).format(
                  "MMM Do",
                )}. Your new plan will start from ${moment(pastEndDate.toDate()).add(1, "d")?.format("MMM Do")}. `}
                type={AlertType.Info}
              ></Alert>
            )}
            <Col xs={24} md={8} className={Styles.planDetails}>
              <Form.Item
                extra={
                  selectedPlan &&
                  selectedPlan.fullBrochureURL && (
                    <a target="_blank" href={selectedPlan.fullBrochureURL} rel="noreferrer">
                      View Plan Information <RightOutlined />
                    </a>
                  )
                }
                rules={[{ required: true, message: "Please select the insurance plan." }]}
                label="Choice of Insurance Plan"
                name="insurancePlanID"
              >
                <Select disabled={!selectedUniversity} placeholder="Choose">
                  {children}
                </Select>
              </Form.Item>
            </Col>
            {!selectedPlan?.isACA && !university?.noWaiver && !university?.customDates && visaType === VisaType.F1 ? (
              <Col span={24}>
                <Form.Item
                  className={Styles.noMark}
                  rules={[{ required: true, message: "Please select your enrolment period." }]}
                  label={getLabel(
                    "Covered Period",
                    "The periods of coverage below are chosen according to your university's waiver requirements.",
                    true,
                  )}
                  name="coveragePeriod"
                >
                  <Radio.Group
                    disabled={!form.getFieldValue("university")}
                    className={Styles.radioGroup}
                    options={coverageOptions}
                  ></Radio.Group>
                </Form.Item>
              </Col>
            ) : (
              <>
                <Col xs={24} md={8} offset={4}>
                  <Form.Item
                    shouldUpdate
                    rules={[{ required: true, message: "Please select your coverage start date." }]}
                    label="Coverage Start Date"
                    name="coverageStartDate"
                  >
                    <DatePicker
                      disabled={!!pastEndDate}
                      style={{ width: "100%" }}
                      disabledDate={(date) => {
                        if (pastEndDate) {
                          return date.isBefore(moment(pastEndDate.toDate()).add(1, "days"));
                        }
                        return date.isBefore(moment()) && !["01-08-2023"].includes(date.format("DD-MM-YYYY"));
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item
                    className={Styles.noMark}
                    shouldUpdate
                    rules={[{ required: true, message: "Please select your graduation date." }]}
                    label={getLabel("Graduation Date", "Program end date as per your I-20.", true)}
                    name="graduationDate"
                  >
                    <DatePicker
                      defaultPickerValue={moment(form.getFieldValue("coverageStartDate") || moment().toISOString()).add(
                        3,
                        "months",
                      )}
                      style={{ width: "100%" }}
                      hideDisabledOptions
                      disabledDate={(date) => {
                        return date.isBefore(
                          moment(form.getFieldValue("coverageStartDate") || moment().toISOString()).add(3, "months"),
                        );
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8} offset={4}>
                  <Form.Item
                    className={Styles.noMark}
                    rules={[{ required: true, message: "Please select your coverage period." }]}
                    label={getLabel("Coverage Period", "Duration in months for which you require coverage.", true)}
                    name="coveragePeriod"
                  >
                    <Select
                      disabled={months.length < 1}
                      options={months.map((m) => {
                        return { key: m, value: m, label: `${m} months` };
                      })}
                    ></Select>
                  </Form.Item>
                </Col>
              </>
            )}
            <Col xs={24} md={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Please select yes if you are purchasing insurance as a part of a group.",
                  },
                ]}
                dependencies={["createGroup"]}
                label="Are you purchasing insurance as a part of a group?"
                name="partOfGroup"
              >
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Form.Item
              style={{ marginTop: -16, display: "block" }}
              shouldUpdate={(p, c) => p.partOfGroup !== c.partOfGroup}
            >
              {({ getFieldValue, resetFields }) => {
                return (
                  getFieldValue("partOfGroup") && (
                    <Form.Item
                      style={{ paddingLeft: 6, marginRight: 200 }}
                      rules={[
                        {
                          required: true,
                          message:
                            "Please select Create a new group if you want to create a new group, otherwise select Join an existing group.",
                        },
                      ]}
                      shouldUpdate
                      name="createGroup"
                    >
                      <Radio.Group>
                        <Radio value={"new"}>Create a new group</Radio>
                        <Radio value={"join"}>Join an existing group</Radio>
                      </Radio.Group>
                    </Form.Item>
                  )
                );
              }}
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please select one choice",
                },
              ]}
              style={{ marginTop: -16 }}
              shouldUpdate={(p, c) => p.partOfGroup !== c.partOfGroup || p.createGroup !== c.createGroup}
            >
              {({ getFieldValue, resetFields }) => {
                return (
                  getFieldValue("partOfGroup") &&
                  (getFieldValue("createGroup") === "new" ? (
                    <CreateGroupInput code={code} />
                  ) : (
                    getFieldValue("createGroup") && <JoinGroupInput />
                  ))
                );
              }}
            </Form.Item>
          </Row>
          <Form.Item>
            <Tooltip
              placement="bottom"
              title={
                !formData?.personal2?.final
                  ? 'Please submit changes in Personal 2 form by clicking "Next" button'
                  : !formData?.personal1?.final
                  ? 'Please submit changes in Personal 1 form by clicking "Next" button'
                  : ""
              }
            >
              <Button
                disabled={!formData?.personal2?.final || !formData?.personal1?.final}
                type="primary"
                htmlType="submit"
              >
                Next <RightOutlined />
              </Button>
            </Tooltip>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Payment",
      icon: <DollarOutlined />,
      form: ConfirmationScreen(),
    },
  ];

  if (currentUser?.isAnonymous)
    steps = [
      {
        title: "Register",
        icon: <UserAddOutlined />,
        form: <SignUpContainer email={currentUser?.primaryEmail} />,
      },
      ...steps,
    ];

  // Components end

  return (
    <Row>
      <Steps current={currentStep} className={Styles.steps}>
        {steps.map((item: any, index: number) => (
          <Step
            icon={currentStep > index || insuranceData?.depositConfirmed ? <CheckCircleFilled /> : item.icon}
            key={index}
            onClick={() => {
              if (!currentUser?.isAnonymous && props.status !== StepStatus.Processing && index !== 3)
                setCurrentStep(index);
            }}
            title={item.title}
          />
        ))}
      </Steps>
      <Row className={Styles.formContainer}>{steps[currentStep] ? steps[currentStep].form : steps[2].form}</Row>
    </Row>
  );
};
