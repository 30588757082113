import { RightOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, Radio, Select, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { countries } from '../../repos';
import Styles from './PersonalData.module.sass';
import { FirestoreReference } from './../../utils';
import moment from 'moment';

interface PersonalDataProps {
  uid: string;
  setStep: (step: number) => void;
}

export const PersonalDataForm: React.FC<PersonalDataProps> = (props) => {
  const { uid } = props;
  const [form] = Form.useForm();

  const onSubmit = (values: any) => {
    FirestoreReference.VisaApplications(uid)
      .doc('i20Data')
      .set({
        ...values,
        dob: values.dob.format('MM/DD/YYYY'),
        check_male: values.gender === 'M',
        check_female: values.gender === 'F',
      });
    props.setStep(1);
  };

  useEffect(() => {
    FirestoreReference.VisaApplications(uid)
      .doc('i20Data')
      .get()
      .then((doc) => {
        if (doc.exists) {
          const data = Object.assign({}, doc.data());
          if (data) {
            data.dob = data.dob ? moment(data.dob, 'MM/DD/YYYY') : null;
            form.setFieldsValue(data);
          }
        }
      });
  }, []);

  const all_countries = countries
    .map((c) => c.name)
    .filter((item, index, array) => array.indexOf(item) === index);
  return (
    <Form form={form} size="small" className={Styles.container} onFinish={onSubmit}>
      <ol>
        <li>
          <span>Name (as it appears on your passport):</span>
          <Form.Item style={{ marginTop: 12 }} label="Family Name (Surname):" name={'last_name'}>
            <Input />
          </Form.Item>
          <Form.Item label="First (Given) Name:" name={'first_name'}>
            <Input />
          </Form.Item>
          <Form.Item label="Middle Name (in full):" name={'middle_name'}>
            <Input />
          </Form.Item>
        </li>
        <li>
          <Form.Item label="Gender:" name={'gender'}>
            <Radio.Group>
              <Radio value="M">Male</Radio>
              <Radio value="F">Female</Radio>
            </Radio.Group>
          </Form.Item>
        </li>
        <li>
          <Form.Item label="Date of birth:" name={'dob'}>
            <DatePicker />
          </Form.Item>
        </li>
        <li>
          <Form.Item label="Country of birth:" name={'birth_country'}>
            <Select
              placeholder="Choose a country"
              options={all_countries.map((c) => {
                return { value: c, label: c };
              })}
            />
          </Form.Item>
        </li>
        <li>
          <Form.Item label="City of birth:" name={'birth_city'}>
            <Input />
          </Form.Item>
        </li>
        <li>
          <Form.Item label="Country of citizenship:" name={'citizenship_country'}>
            <Select
              placeholder="Choose a country"
              options={all_countries.map((c) => {
                return { value: c, label: c };
              })}
            />
          </Form.Item>
        </li>
        <li>
          <Form.Item label="Do you currently hold an I20?" name={'i20_holder'}>
            <Radio.Group>
              <Radio value={'Yes'}>Yes</Radio>
              <Radio value={'No'}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </li>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Next <RightOutlined />
          </Button>
        </Form.Item>
      </ol>
    </Form>
  );
};
