import React, { CSSProperties } from 'react';
import { ReadOutlined, UserOutlined } from '@ant-design/icons';
import { Card, Tag, Avatar, Button } from 'antd';
import { Article } from '../../repos';
import './styles.scss';

export enum ArticleActionClicked {
  Read = 'Read',
}

interface ArticleCardProps {
  article: Article;
  onClick(action: ArticleActionClicked): void;
  style?: CSSProperties;
}

export const ArticleCard: React.SFC<ArticleCardProps> = props => {
  const { article, onClick, style } = props;

  const titleComponent = (
    <div className="header-container">
      {article.author ? (
        <Avatar size={58} src={article.attributionSource.logoUrl}>
          {article.attributionSource.name.substr(0, 1)}
        </Avatar>
      ) : (
        <Avatar size={58} icon={<UserOutlined />} />
      )}
      <div className="title-container">
        <span className="title">{article.title}</span>
      </div>
    </div>
  );

  const tagComponent = <Tag color="cyan">{article.topics[0].name.toUpperCase()}</Tag>;

  return (
    <Card
      key={article.id}
      title={titleComponent}
      className="article-card"
      hoverable
      extra={tagComponent}
      style={style}>
      <div className="description-container">
        <div className="image-container">
          <img src="http://lorempixel.com/640/480" alt={article.title} />
        </div>
        <span>{article.description}</span>
      </div>
      <div className="article-footer">
        <Button type="primary" onClick={() => onClick(ArticleActionClicked.Read)} icon={<ReadOutlined />}>
          Read
        </Button>
        {/* <Button icon="share-alt">
          Share Link
        </Button> */}
      </div>
    </Card>
  );
};
