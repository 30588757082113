import { redirectTo } from '@reach/router';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Cookies from 'js-cookie';
import { from, Observable, Observer, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Routes } from '../../App';
import { Analytics, AnalyticsEventName } from '../../utils';

let verifier: firebase.auth.RecaptchaVerifier;

// firebase.firestore.setLogLevel('debug');

const recreateCaptchaVerifier = () => {
  const verifierInstance = document.getElementById('verifier');
  if (verifier) {
    verifier.clear();
  }
  const parentNode = verifierInstance?.parentElement;
  verifierInstance?.remove();

  const newVerifier = document.createElement('div');
  newVerifier.id = 'verifier';
  newVerifier.style.display = 'none';
  parentNode?.appendChild(newVerifier);
};

export const signInUsingEmail = (email: string, password: string) => {
  return from(firebase.auth().signInWithEmailAndPassword(email, password));
};

export const signInUsingPhone = (phone: string) => {
  recreateCaptchaVerifier();
  verifier = new firebase.auth.RecaptchaVerifier('verifier', { size: 'invisible' });
  return from(firebase.auth().signInWithPhoneNumber(phone, verifier));
};

export const signUp = (email: string, password: string, isAnonymous: boolean) => {
  const currentUser = firebase.auth().currentUser;
  if (isAnonymous && currentUser) {
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);
    return from(currentUser.linkWithCredential(credential));
  }
  return from(firebase.auth().createUserWithEmailAndPassword(email, password));
};

export const sendSignInLink = (email: string) => {
  return from(
    firebase.auth().sendSignInLinkToEmail(email, {
      url: `${window.location.origin}/verify-link`,
      handleCodeInApp: true,
    }),
  ).pipe(
    tap(() => {
      localStorage.setItem('signInEmail', email);
    }),
  );
};

export const signInUsingEmailLink = (): Observable<void> => {
  if (!firebase.auth().isSignInWithEmailLink(window.location.href)) {
    redirectTo(Routes.Home);
    return of();
  }
  let email = localStorage.getItem('signInEmail');
  if (!email) {
    email = window.prompt('Please provide your email for confirmation');
    Analytics.log(AnalyticsEventName.UserSignedIn, { origin: 'ums-one-click' });
  } else {
    Analytics.log(AnalyticsEventName.UserSignedIn, { origin: 'email-link-auth' });
  }
  if (!email) {
    return signInUsingEmailLink();
  }
  return from(firebase.auth().signInWithEmailLink(email, window.location.href)).pipe(
    map(() => {
      localStorage.removeItem('signInEmail');
      return;
    }),
  );
};

// export const signUp = (email: string, phone: string, password: string) => {
//   return from(auth().fetchSignInMethodsForEmail(email)).pipe(
//     switchMap(methods => {
//       recreateCaptchaVerifier();
//       verifier = new auth.RecaptchaVerifier('verifier', { size: 'invisible' });
//       if (methods.length === 0) {
//         return from(auth().signInWithPhoneNumber(phone, verifier));
//       }
//       return from(auth().signInWithEmailAndPassword(email, password)).pipe(
//         switchMap(credential => credential.user!.linkWithPhoneNumber(phone, verifier)),
//       );
//     }),
//   );
// };

export const verifyOtp = (otp: string, confirmationResult: firebase.auth.ConfirmationResult) => {
  return from(confirmationResult.confirm(otp));
};

export const listenToAuthState = (): Observable<firebase.User | null> => {
  return new Observable((observer: Observer<firebase.User | null>) => {
    firebase.auth().onAuthStateChanged(
      (user) => {
        observer.next(user);
      },
      (error) => {
        observer.error(error);
      },
    );
  });
};

export const sendRecoveryEmail = (email: string) => {
  return from(firebase.auth().sendPasswordResetEmail(email));
};

export const signOut = () => {
  Cookies.remove('anon', { domain: '.gradly.us' });
  Cookies.remove('anon', { domain: 'localhost' });
  return from(firebase.auth().signOut());
};
