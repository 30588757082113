import { LoadingOutlined } from '@ant-design/icons';
import { Moment } from 'moment';
import React, { CSSProperties, useEffect } from 'react';
import { connect } from 'react-redux';
import { getWebinar, RootState, updateCustomStepStatus } from '../../../redux';
import { RsvpStep, Webinar, WebinarRegistration } from '../../../repos';
import { RegisterForWebinar } from '../../Webinar';
import { WebinarManager } from '../../Webinar/WebinarManager';
import '../CustomStep.module.sass';

interface Props {
  step: RsvpStep;
  style?: CSSProperties;
  webinar?: Webinar;
  webinarRegistration?: WebinarRegistration;
  registeredSlot?: { startTime: Moment; endTime: Moment };
  getWebinar?: typeof getWebinar;
  updateCustomStepStatus?: typeof updateCustomStepStatus;
}

const RsvpToWebinarStepView: React.FC<Props> = (props) => {
  const {
    step: { webinarId, id, isCustom },
    webinarRegistration,
    registeredSlot,
    getWebinar,
    webinar,
    updateCustomStepStatus,
  } = props;

  const webinarManager = new WebinarManager(
    (_, status) => {
      isCustom && updateCustomStepStatus && updateCustomStepStatus(id, status);
    },
    registeredSlot,
    webinarRegistration,
  );

  const webinarStatus = webinarManager.getStepStatus();

  useEffect(() => {
    webinarId && getWebinar && getWebinar(webinarId);
    if (!webinarManager.getStepStatus() || !updateCustomStepStatus || !isCustom) {
      return;
    }
    console.debug('Updating webinar status', webinarStatus);
    updateCustomStepStatus(id, webinarManager.getStepStatus());
  }, [webinarStatus]);

  useEffect(() => {
    return webinarManager.startObserver();
  }, [registeredSlot && registeredSlot.endTime.format()]);

  if (!webinar) {
    return (
      <div
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 50,
        }}>
        <LoadingOutlined style={{ fontSize: 20 }} />
        <div style={{ marginTop: 16 }}>Loading</div>
      </div>
    );
  }

  return <RegisterForWebinar webinar={webinar} />;
};

const mapStateToProps = (state: RootState, props: Props) => {
  const {
    step: { webinarId },
  } = props;
  const { customStepWebinars, webinarRegistrations = [] } = state.Content;

  const webinar = customStepWebinars[webinarId];

  const webinarRegistration = webinar && webinarRegistrations.filter((registration) => webinar.slots.map((s) => s.conferenceId).includes(registration.conferenceId))[0];

  const registeredSlot = webinarRegistration && webinar.slots.filter((slot) => slot.conferenceId === webinarRegistration.conferenceId)[0];
  return {
    registeredSlot,
    webinarRegistration,
    webinar,
  };
};

export default connect(mapStateToProps, { getWebinar, updateCustomStepStatus })(RsvpToWebinarStepView);
