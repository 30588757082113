import { Dayjs } from 'dayjs';
import { action, ActionType } from 'typesafe-actions';
import { InsuranceRequestGBGType, InsuranceRequestWellAwayType, PlanType } from '../../repos';
import { VisaType } from '../reducers/InsuranceReducer';

export enum InsuranceActionTypes {
  SetSelectedPlan = 'InsuranceAction/SetSelectedPlan',
  SetFormData = 'InsuranceAction/SetFormData',
  SetAvailablePlans = 'InsuranceAction/SetAvailablePlans',
  SetInsuranceData = 'InsuranceAction/SetInsuranceData',
  SetPastEndDate = 'InsuranceAction/SetPastEndDate',
  SetVisaType = 'InsuranceAction/SetVisaType',
  AddToAllInsurance = 'InsuranceAction/AddToAllInsurance',
}

export const setSelectedPlan = (plan?: PlanType) => action(InsuranceActionTypes.SetSelectedPlan, plan);

export const setFormData = (formData: Record<string, any>) => action(InsuranceActionTypes.SetFormData, formData);

export const setAvailablePlans = (plans: PlanType[]) => action(InsuranceActionTypes.SetAvailablePlans, plans);

export const setInsuranceData = (insuranceData: InsuranceRequestGBGType | InsuranceRequestWellAwayType | undefined) =>
  action(InsuranceActionTypes.SetInsuranceData, insuranceData);

export const setPastEndDate = (date: Dayjs | undefined) => action(InsuranceActionTypes.SetPastEndDate, date);
export const setVisaType = (visaType: VisaType) => action(InsuranceActionTypes.SetVisaType, visaType);
export const addToAllInsurance = (insuranceData: InsuranceRequestGBGType | InsuranceRequestWellAwayType | undefined) =>
  action(InsuranceActionTypes.AddToAllInsurance, insuranceData);

const insuranceActions = {
  setSelectedPlan,
  setFormData,
  setAvailablePlans,
  setInsuranceData,
  setPastEndDate,
  setVisaType,
  addToAllInsurance,
};

export type InsuranceAction = ActionType<typeof insuranceActions>;
