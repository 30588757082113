import {
  CheckCircleFilled,
  CloseCircleFilled,
  SelectOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { Button, Col, Progress, Row } from 'antd';
import React, { useState } from 'react';
import { CardTable } from '../../components';
import { Colors } from '../../theme';
import { fundingTypes, Hits, MatchedAttribsType, SearchParams } from './VisaTranscript';
import { VisaTranscriptModal } from './VisaTranscriptModal';
import Styles from './VisaTranscriptsList.module.sass';

interface VisaTranscriptsListProps {
  hits: Hits[];
  searchParams: SearchParams;
}

export const matchColumns = (
  matchedAttribs: MatchedAttribsType,
  searchParams: SearchParams,
  span?: number,
) => {
  return (
    <>
      <Col span={span} className={matchedAttribs.consulate ? '' : Styles.unmatch}>
        {matchedAttribs.consulate ? (
          <>
            <CheckCircleFilled /> Interviewed
          </>
        ) : (
          <>
            <CloseCircleFilled /> Not interviewed
          </>
        )}{' '}
        at <span>{searchParams.consulate}</span>
      </Col>
      <Col span={span} className={matchedAttribs.university ? '' : Styles.unmatch}>
        {matchedAttribs.university ? (
          <>
            <CheckCircleFilled /> Attended
          </>
        ) : (
          <>
            <CloseCircleFilled /> Didn't attend
          </>
        )}{' '}
        <span>{searchParams.university}</span>
      </Col>
      <Col span={span} className={matchedAttribs.subject ? '' : Styles.unmatch}>
        {matchedAttribs.subject ? (
          <>
            <CheckCircleFilled /> Majored
          </>
        ) : (
          <>
            <CloseCircleFilled /> Didn't major
          </>
        )}{' '}
        in <span>{searchParams.subject}</span>
      </Col>
      <Col span={span} className={matchedAttribs.funding ? '' : Styles.unmatch}>
        {matchedAttribs.funding ? (
          <>
            <CheckCircleFilled /> Funded
          </>
        ) : (
          <>
            <CloseCircleFilled /> Not funded
          </>
        )}{' '}
        by <span>{fundingTypes[searchParams.funding]}</span>
      </Col>
    </>
  );
};

export const VisaTranscriptsList: React.FC<VisaTranscriptsListProps> = (props) => {
  const { hits, searchParams } = props;
  const [hit, setHit] = useState<Hits>();
  const [showTranscript, setShowTranscript] = useState(false);
  const data = {
    headers: undefined,
    rows: hits.map((hit, index) => {
      const { matchedAttribs } = hit;
      return {
        contents: [
          {
            content: (
              <Row justify="start" className={Styles.progress}>
                {matchedAttribs.visaAccepted ? (
                  <div className={Styles.visaAccepted}>
                    <CheckOutlined /> Visa Approved
                  </div>
                ) : (
                  <div className={Styles.visaRejected}>
                    <CloseOutlined /> Visa Rejected
                  </div>
                )}
                <Progress
                  strokeColor={{
                    '0%': Colors.Primary600,
                    '100%': Colors.Primary600,
                  }}
                  strokeLinecap="square"
                  width={84}
                  type="circle"
                  format={(percent) => (
                    <Row justify="space-around" className={Styles.percentage}>
                      <Col span={24}> {percent}%</Col>
                      <Col span={24}>
                        <span>Match</span>
                      </Col>
                    </Row>
                  )}
                  percent={matchedAttribs.matchPercent}
                />
              </Row>
            ),
          },
          {
            content: (
              <Row gutter={[0, 6]} align={'middle'} className={Styles.transInfo}>
                {matchColumns(matchedAttribs, searchParams, 24)}
              </Row>
            ),
          },
          {
            content: (
              <Button
                onClick={() => {
                  setHit({ ...hit, index });
                  setTimeout(() => {
                    setShowTranscript(true);
                  }, 200);
                }}
                type="primary">
                READ TRANSCRIPT <SelectOutlined rotate={90} />
              </Button>
            ),
          },
        ],
      };
    }),
    spans: [5, 14, 5],
  };

  return (
    <>
      <VisaTranscriptModal
        hit={hit}
        showTranscript={showTranscript}
        searchParams={searchParams}
        setShowTranscript={setShowTranscript}
      />
      <CardTable
        data={data}
        className={Styles.cardContainer + ` animate__animated animate__fadeIn`}></CardTable>
    </>
  );
};
