import {
  CaretRightOutlined,
  CheckOutlined,
  MinusCircleFilled,
  PlusOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Form, Button, Collapse, Input, Row, Checkbox } from 'antd';
import React, { ReactElement } from 'react';
import { UserRepo } from '../../repos';
import { Colors } from '../../theme';
import { Environment, FirestoreReference } from '../../utils';
import Styles from './FundSourcesForm.module.sass';

interface FundSourcesFormProps {
  uid: string;
}

interface FormElementType {
  name: string;
  label: string;
  type: string;
  placeholder?: string;
  options?: string[];
}
interface FormStructureType {
  [key: string]: FormElementType[];
}

const { Panel } = Collapse;

export const FundSourcesForm: React.FC<FundSourcesFormProps> = (props) => {
  const { uid } = props;
  const [formElements, setFormElements] = React.useState<any[]>();
  const [loading, setLoading] = React.useState<boolean>(false);

  const onSubmit = (values: any) => {
    setLoading(true);
    FirestoreReference.VisaApplications(uid)
      .doc('i20Data')
      .set(
        {
          ...values,
          check_job: values.university_type_of_aid?.includes('Job'),
          check_loan: values.university_type_of_aid?.includes('Loan'),
          check_grant: values.university_type_of_aid?.includes('Grant'),
        },
        { merge: true },
      );
    try {
      UserRepo.getIdToken().subscribe(async (token) => {
        await fetch(`${Environment.api.baseUrl}/visas/i20/fillI20`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token,
          },
          body: null,
        }).catch((err) => {
          console.error(err);
        });
      });
    } catch (e) {
      console.error(e);
    }
  };

  const formStructure: FormStructureType = {
    'Self Support': [
      {
        label: 'Name of the bank:',
        name: 'ss_name_of_bank',
        type: 'input',
        placeholder: 'Enter the name',
      },
      {
        label: 'Assured Support (First Year):',
        name: 'ss_assured_support_first_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
      {
        label: 'Projected Support (Second Year):',
        name: 'ss_projected_support_second_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
      {
        label: 'Projected Support (Third Year):',
        name: 'ss_projected_support_third_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
      {
        label: 'Projected Support (Fourth Year):',
        name: 'ss_projected_support_fourth_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
    ],
    'Parents or individual sponsers': [
      {
        label: 'Name of sponsor:',
        name: 'ps_name_of_sponsor',
        type: 'input',
        placeholder: 'Enter full name',
      },
      {
        label: 'Name of bank:',
        name: 'ps_name_of_bank',
        type: 'input',
        placeholder: 'Enter the name',
      },
      {
        label: 'Assured Support (First Year):',
        name: 'ps_assured_support_first_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
      {
        label: 'Projected Support (Second Year):',
        name: 'ps_projected_support_second_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
      {
        label: 'Projected Support (Third Year):',
        name: 'ps_projected_support_third_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
      {
        label: 'Projected Support (Fourth Year):',
        name: 'ps_projected_support_fourth_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
    ],
    'Your government or other sponsoring agency': [
      {
        label: 'Name of sponsoring agency:',
        name: 'gs_name_of_sponsor',
        type: 'input',
        placeholder: 'Enter full name',
      },
      {
        label: 'Assured Support (First Year):',
        name: 'gs_assured_support_first_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
      {
        label: 'Projected Support (Second Year):',
        name: 'gs_projected_support_second_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
      {
        label: 'Projected Support (Third Year):',
        name: 'gs_projected_support_third_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
      {
        label: 'Projected Support (Fourth Year):',
        name: 'gs_projected_support_fourth_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
    ],
    University: [
      {
        label: 'Type of aid:',
        options: ['Job', 'Loan', 'Grant'],
        name: 'university_type_of_aid',
        type: 'checkbox',
      },
      {
        label: 'Assured Support (First Year):',
        name: 'university_assured_support_first_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
      {
        label: 'Projected Support (Second Year):',
        name: 'university_projected_support_second_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
      {
        label: 'Projected Support (Third Year):',
        name: 'university_projected_support_third_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
      {
        label: 'Projected Support (Fourth Year):',
        name: 'university_projected_support_fourth_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
    ],
    Other: [
      {
        label: 'Name of sponsoring agency:',
        name: 'other_supp',
        type: 'input',
        placeholder: 'Enter full name',
      },
      {
        label: 'Assured Support (First Year):',
        name: 'other_assured_support_first_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
      {
        label: 'Projected Support (Second Year):',
        name: 'other_projected_support_second_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
      {
        label: 'Projected Support (Third Year):',
        name: 'other_projected_support_third_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
      {
        label: 'Projected Support (Fourth Year):',
        name: 'other_projected_support_fourth_year',
        type: 'num_input',
        placeholder: 'Enter an amount',
      },
    ],
  };

  const getFormFields = (key: string) => {
    const formFields: (ReactElement | null)[] = formStructure[key].map((field: FormElementType) => {
      const { label, name, type, options, placeholder } = field;
      switch (type) {
        case 'input':
          return (
            <Form.Item name={name} key={name} label={label}>
              <Input placeholder={placeholder} />
            </Form.Item>
          );
        case 'num_input':
          return (
            <Form.Item name={name} key={name} label={label}>
              <Input type="number" prefix={'$'} placeholder={placeholder} />
            </Form.Item>
          );
        case 'checkbox':
          return (
            <Form.Item name={name} key={name} label={label}>
              <Checkbox.Group>
                {options?.map((option: string, index: number) => (
                  <Checkbox key={index} value={option}>
                    {option}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
          );
        default:
          return null;
      }
    });
    return formFields;
  };

  const renderFormButtons = () => {
    const components = [];
    for (const key in formStructure) {
      if (!formElements?.includes(key)) {
        components.push(
          <Form.Item key={key}>
            <Button
              className={Styles.dashedBtns}
              type="dashed"
              onClick={() => {
                setFormElements([key, ...(formElements || [])]);
              }}
              icon={<PlusOutlined />}>
              {key}
            </Button>
          </Form.Item>,
        );
      } else {
        components.push(
          <Row key={key}>
            <Collapse
              key={key}
              accordion
              defaultActiveKey={[key]}
              expandIconPosition={'right'}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              className={Styles.infoCollapse}>
              <Panel
                header={
                  <>
                    <CheckOutlined style={{ color: Colors.GreenishTeal }} /> {key}
                  </>
                }
                key={key}
                className={Styles.infoPanel}>
                {getFormFields(key)}
              </Panel>
            </Collapse>
            <MinusCircleFilled
              key={key + '-remove'}
              className={Styles.removeIcon}
              onClick={() => {
                setFormElements(formElements.filter((f) => f !== key));
              }}
            />
          </Row>,
        );
      }
    }
    return components;
  };

  return (
    <Form onFinish={onSubmit} className={`${Styles.container} ${loading ? 'all-disabled' : ''}`}>
      {renderFormButtons()}
      <Form.Item>
        <Button loading={loading} className={Styles.submitButton} type="primary" htmlType="submit">
          Generate form <RightOutlined />
        </Button>
      </Form.Item>
    </Form>
  );
};
