import { DollarTwoTone, ExclamationCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Modal, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { cardImageCreate } from '../../assets';
import { Accordion, AlertType } from '../../components';
import { RootState } from '../../redux';
import { PredefinedStep, StepStatus, UserRepo } from '../../repos';
import { Environment } from '../../utils';
import Styles from './CreateGradlyCard.module.sass';
import Styles2 from './ForexStepContainer.module.sass';
import ForexRatesTable from './ForexRatesTable';

export const CreateGradlyCardForm: React.FC<any> = (props) => {
  const [loading, setLoading] = React.useState(false);
  const currentUser = useSelector((state: RootState) => state.Auth.currentUser);
  const { disabled, hideImage } = props;
  const payByRazorPay = async (data: any) => {
    setLoading(true);
    try {
      UserRepo.getIdToken().subscribe(async (token) => {
        const response = await fetch(`${Environment.api.baseUrl}/payment/razorpay/order`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            authorization: token,
          },
          body: JSON.stringify({
            name: data.name,
            amount: data.amount,
            email: currentUser?.primaryEmail,
            callback_url: window.location.href,
          }),
        }).catch((err) => {
          setLoading(false);
          console.error(err);
        });
        if (response instanceof Response) {
          const body = await response.json();
          window.location.href = body;
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: props.topup || hideImage ? 24 : 16 },
  };

  return (
    <Row className={props.className}>
      <Col style={{ marginTop: props.topup ? 0 : 40 }} span={props.topup || hideImage ? 24 : 12}>
        {props.topup && <DollarTwoTone className={Styles.dollarsign} />}
        <Form
          requiredMark={!props.topup}
          className={Styles.form}
          layout="vertical"
          {...layout}
          name="create-gradly-card"
          onFinish={(data) => payByRazorPay(data)}>
          {!props.topup ? (
            <Form.Item name={'name'} label="Your Full Name" rules={[{ required: true }]}>
              <Input disabled={disabled} />
            </Form.Item>
          ) : (
            <></>
          )}
          <Form.Item
            name={'amount'}
            label={!props.topup ? 'Amount to load in your card' : 'Add money to your card'}
            rules={[
              { type: 'number', min: 1 },
              {
                required: true,
                message: (
                  <>
                    <ExclamationCircleOutlined /> An amount in USD is required.
                  </>
                ),
              },
            ]}>
            <InputNumber
              placeholder="Enter Amount"
              style={{ width: props.topup ? 272 : 320 }}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item>
            <Button disabled={disabled} loading={loading} type="primary" htmlType="submit">
              {loading ? (
                'Processing'
              ) : props.topup ? (
                <>
                  Recharge Card <RightOutlined />
                </>
              ) : (
                <>
                  Generate Card <RightOutlined />
                </>
              )}
            </Button>
          </Form.Item>
        </Form>
      </Col>
      {props.topup
        ? ''
        : !hideImage && (
            <Col span={12}>
              <Row justify="center">
                <img
                  className={Styles.illustration}
                  alt="Create Forex Card"
                  src={cardImageCreate}
                />
              </Row>
            </Col>
          )}
    </Row>
  );
};

export const CreateGradlyCard = (currentStep: PredefinedStep, status: StepStatus) => {
  return (
    <Accordion.Item
      alert={{
        message: (
          <>
            Enter your name and an initial amount to load on your card. To view a comparison of
            forex cards, view the{' '}
            <a
              onClick={() => {
                Modal.info({
                  content: <ForexRatesTable expanded={true} />,
                  className: Styles2.modalContainer,
                  maskClosable: true,
                });
              }}>
              live forex rates
            </a>{' '}
            .
          </>
        ),
        type: AlertType.Info,
      }}
      key={currentStep.id}
      icon={'idcard'}
      status={status}
      title={currentStep.title}>
      <CreateGradlyCardForm disabled={status === StepStatus.Complete} />
    </Accordion.Item>
  );
};
