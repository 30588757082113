import React, { FC, useEffect, useState } from 'react';
import { Row, Card, Col, Button, Modal, Image, Rate, Input } from 'antd';
import {
  Accordion,
  Alert,
  AlertType,
  ImageHelpModal,
  IntercomAPI,
  SlotBook,
} from '../../components';
import { markStepsStatus, PredefinedStep, StepStatus, UserProfile } from '../../repos';
import Styles from './PostInterview.module.sass';
import { celebrationVisa, checkVisa, crossVisa, searchVisa } from '../../assets';
import {
  PhoneFilled,
  RightOutlined,
  ClockCircleOutlined,
  UserOutlined,
  CheckCircleOutlined,
  MessageOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { navigate } from '@reach/router';
import { UserJourneyStageId } from '../../config';
import { StepID } from './VisaStepContainer';
import { Moment } from 'moment';
import moment from 'moment';
import { FirestoreReference } from '../../utils';

const { TextArea } = Input;

enum VisaStatus {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Processing = 'Processing',
}

const attorneys: { [key: string]: any } = {
  thushanti: {
    heading: 'Book a free call with a Gradly immigration expert',
    name: 'Thushanti Kamalakanth',
    title: 'Independent Attorney',
    license: 2002,
    rating: 4.5,
    img: 'https://res.cloudinary.com/gradly/image/upload/v1648225258/assets/visa-result/image-79_3x_idljrl.png',
    profile: 'https://www.avvo.com/attorneys/46528-in-thushanti-kamalakanth-4353679.html',
    zoomlink: 'https://us04web.zoom.us/j/79793369058?pwd=kLCLz8416UvcQ83U6qafOGvd8ERjXK.1',
  },
  mcdonald: {
    heading: 'Book a paid call with an immigration lawyer',
    name: 'John McDonald',
    title: 'Law Office of John E. Macdonald, Inc.',
    license: 1995,
    rating: 5,
    img: 'https://res.cloudinary.com/gradly/image/upload/v1648225259/assets/visa-result/image-78_3x_vptoda.png',
    profile: 'https://www.avvo.com/attorneys/02903-ri-john-macdonald-1428179.html',
    zoomlink: 'https://us04web.zoom.us/j/72205994010?pwd=NIxUXxiqkYr8-ghE5KcSdhGXjVGQqR.1',
  },
};

const AcceptedVisa = () => {
  return (
    <Row align="middle" className={Styles.accepted}>
      <Col span={12}>
        <h1>Congratulations on getting your visa!</h1>
        <h2>We recommend that you get your health insurance next.</h2>
        <Button
          type={'primary'}
          onClick={() => {
            navigate!(`/${UserJourneyStageId.HealthInsurance}`);
          }}>
          Go to Health Insurance <RightOutlined />
        </Button>
      </Col>
      <Col span={12}>
        <img src={celebrationVisa} alt="Celebrations"></img>
      </Col>
    </Row>
  );
};

const ProcessingVisa = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState<{ title: string; image: any }>();
  const slipsData = [
    {
      title: '221g Pink Slip',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/v1648202280/assets/visa-result/group-204_2x_ahighi.png',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1648205349/assets/visa-result/221g_pink_form_1_a13m0c.png',
    },
    {
      title: '221g White Slip',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/v1648202280/assets/visa-result/group-204-2_2x_yhibv7.png',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1648205349/assets/visa-result/221g_white_form_1_diqv1g.png',
    },
    {
      title: '221g Blue Slip',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/v1648202280/assets/visa-result/group-204-3_2x_nyzogv.png',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1648205349/assets/visa-result/221gblue_form_1_hlewkd.png',
    },
    {
      title: '221g Yellow Slip',
      thumbnail:
        'https://res.cloudinary.com/gradly/image/upload/v1648202280/assets/visa-result/group-204-4_2x_jma9er.png',
      img: 'https://res.cloudinary.com/gradly/image/upload/v1648205349/assets/visa-result/221g_yellow_form_1_ntgv2w.png',
    },
  ];

  const SlipCard = (title: string, img: string, thumbnail: string) => {
    return (
      <Card key={title} hoverable onClick={() => {}} className={Styles.typeCard}>
        <ImageHelpModal
          visible={visible}
          setVisible={setVisible}
          image={data?.image}
          title={
            <>
              <FileTextOutlined /> {data?.title}
            </>
          }
          chatText={'Chat with Immigration Expert'}
          helpText={'Not sure what to do next?'}
        />
        <Image
          className={Styles.infoImg}
          src={thumbnail}
          onClick={() => {
            setData({ title, image: img });
            setVisible(true);
          }}
          preview={{
            visible: false,
          }}
        />
        <span>{title}</span>
      </Card>
    );
  };

  return (
    <Row align="middle" className={Styles.processing}>
      <Col span={24}>
        <h1>Which administrative processing category were you put into?</h1>
      </Col>
      <Col span={24}>
        <Row justify={'space-around'} className={Styles.processingTypes}>
          {slipsData.map((slip, index) => SlipCard(slip.title, slip.img, slip.thumbnail))}
          <Card
            hoverable
            onClick={() => {
              IntercomAPI.showWithMessagePreFilled(
                'Hey! I had a question about my visa status.',
                false,
              );
            }}
            className={Styles.typeCard}>
            <h1>?</h1>
            <span>I'm not sure</span>
          </Card>
        </Row>
      </Col>
    </Row>
  );
};

const RejectedVisa: FC<any> = (props) => {
  const { step, uid } = props;
  const [agent, setAgent] = useState<string>();
  const [selectedDate, setSelectedDate] = useState<Moment>(moment());
  const [selectedSlot, setSelectedSlot] = useState<Moment>();
  const [slots, setSlots] = useState<any>([]);
  const [issue, setIssue] = useState<string>();

  const confirmSlot = () => {
    if (selectedSlot && agent) {
      FirestoreReference.VisaApplications(uid)
        .doc('slotInfo')
        .set({ agent: agent, slot: selectedSlot?.toDate(), issue: issue });
      markStepsStatus(
        uid,
        [UserJourneyStageId.VisaApplication, StepID.PostInterview],
        StepStatus.Complete,
        { visaStatus: VisaStatus.Rejected },
      );
    }
  };

  useEffect(() => {
    if (step.status === StepStatus.Complete) {
      FirestoreReference.VisaApplications(uid)
        .doc('slotInfo')
        .get()
        .then((doc) => {
          const data = doc.data();
          if (data) {
            setAgent(data.agent);
            setSelectedSlot(data.slot ? moment(data.slot) : moment());
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  useEffect(() => {
    if (agent)
      FirestoreReference.Slots()
        .doc('visaAgents')
        .collection(agent)
        .doc(selectedDate.format('MM-DD-YYYY'))
        .get()
        .then((doc) => {
          setSlots(doc.data()?.slots?.map((slot: Moment) => moment(slot.toDate())) || []);
        })
        .catch((err) => {
          console.error(err);
        });
  }, [setSlots, agent, selectedDate]);

  if (!agent) {
    return (
      <Row wrap={false} className={Styles.rejected}>
        {Object.keys(attorneys).map((key, index) => {
          const attorney = attorneys[key];
          return (
            <Col key={index} span={12} className={Styles.attorney}>
              <h1>
                <PhoneFilled></PhoneFilled> {attorney.heading}
              </h1>
              <Row>
                <Col span={6}>
                  <img src={attorney.img} alt={attorney.name}></img>
                </Col>
                <Col className={Styles.attorneyDetails} span={18}>
                  <h2>{attorney.name}</h2>
                  <h3>{attorney.title}</h3>
                  <div>
                    <span>License: </span> Active since {attorney.license}
                  </div>
                  <div>
                    <span>Avvo Rating: </span>{' '}
                    <Rate disabled allowHalf defaultValue={attorney.rating} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Button
                  onClick={() => {
                    setAgent(key);
                  }}
                  type="primary"
                  icon={<PhoneFilled />}>
                  Book a call
                </Button>
                <Button target={'_blank'} href={attorney.profile}>
                  Visit Avvo Profile
                  <RightOutlined />
                </Button>
              </Row>
            </Col>
          );
        })}
      </Row>
    );
  } else {
    if (step.status === StepStatus.Complete) {
      const attorney = attorneys[agent];
      return (
        <Row justify="center" className={Styles.bookedInfo}>
          <h1>
            <CheckCircleOutlined />
            Your appointment is booked with {attorney.name}!
          </h1>
          <Row>
            <img src={attorney.img} alt={attorney.name}></img>
            <Col span={12}>
              <h2>{attorney.name}</h2>
              <h3>{attorney.title}</h3>
              <p>Zoom meeting link:</p>
              <a target={'_blank'} href={attorney.zoomlink} rel="noreferrer">
                {attorney.zoomlink}
              </a>
            </Col>
          </Row>
        </Row>
      );
    } else
      return (
        <Row className={Styles.explainerContainer}>
          <Alert
            message={`Book a slot for your call with ${attorneys[agent].name}.`}
            type={AlertType.Info}></Alert>
          <Col span={12}>
            <SlotBook
              setSelectedDate={setSelectedDate}
              setSelectedSlot={setSelectedSlot}
              slots={slots}></SlotBook>
          </Col>
          <Col className={Styles.explainer} span={12}>
            <h2>Explain your issues below:</h2>
            <TextArea
              maxLength={500}
              onChange={(e) => {
                setIssue(e.target.value);
              }}
              placeholder="Type here"></TextArea>
            <Row className={Styles.slotInfo}>
              <div>
                <ClockCircleOutlined /> Your chosen slot:{' '}
                <span>{selectedSlot?.format('h:mm a, MMMM Do')}</span>
              </div>
              <div>
                <UserOutlined /> Your chosen lawyer:
                <span>{attorneys[agent].name}</span>
              </div>
            </Row>
            <Button onClick={() => confirmSlot()} type="primary">
              Confirm Slot <RightOutlined />
            </Button>
          </Col>
        </Row>
      );
  }
};

const SelectionScreen: FC<any> = (props) => {
  const { visaStatus, currentUser, step } = props;

  if (!visaStatus)
    return (
      <Row justify={'space-around'} className={Styles.selectContainer}>
        <Card
          hoverable
          onClick={() => {
            markStepsStatus(
              currentUser.id,
              [UserJourneyStageId.VisaApplication, StepID.PostInterview],
              StepStatus.Complete,
              { visaStatus: VisaStatus.Accepted },
            );
          }}
          className={Styles.typeCard}>
          <img src={checkVisa} alt="" />
          <span>My visa application was accepted.</span>
        </Card>
        <Card
          hoverable
          onClick={() => {
            markStepsStatus(
              currentUser.id,
              [UserJourneyStageId.VisaApplication, StepID.PostInterview],
              StepStatus.Processing,
              { visaStatus: VisaStatus.Processing },
            );
          }}
          className={Styles.typeCard}>
          <img src={searchVisa} alt="" />
          <span>My visa application was sent to administrative processing.</span>
        </Card>
        <Card
          hoverable
          onClick={() => {
            markStepsStatus(
              currentUser.id,
              [UserJourneyStageId.VisaApplication, StepID.PostInterview],
              StepStatus.Processing,
              { visaStatus: VisaStatus.Rejected },
            );
          }}
          className={Styles.typeCard}>
          <img src={crossVisa} alt="" />
          <span>My visa application was rejected.</span>
        </Card>
      </Row>
    );
  else {
    switch (visaStatus) {
      case VisaStatus.Accepted:
        return <AcceptedVisa></AcceptedVisa>;
      case VisaStatus.Processing:
        return <ProcessingVisa></ProcessingVisa>;
      case VisaStatus.Rejected:
        return <RejectedVisa step={step} uid={currentUser.id}></RejectedVisa>;
      default:
        return <></>;
    }
  }
};

export const PostInteview = (
  step: PredefinedStep,
  currentUser: UserProfile,
  visaStatus: VisaStatus,
) => {
  return (
    <Accordion.Item
      alert={
        !visaStatus
          ? {
              type: AlertType.Info,
              message: 'Select your current status from below to view the next steps',
            }
          : undefined
      }
      key={step.route}
      icon={'contacts'}
      status={step.status || StepStatus.Incomplete}
      showMarkAsComplete={
        step.status === StepStatus.Processing
          ? { uid: currentUser.id, route: [UserJourneyStageId.VisaApplication, step.id] }
          : undefined
      }
      title={step.title}
      step={step}>
      <SelectionScreen currentUser={currentUser} visaStatus={visaStatus} step={step} />
    </Accordion.Item>
  );
};
