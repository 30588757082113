import { QuestionCircleOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { FileUploader } from '../../components/Uploader';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { FirestoreReference } from '../../utils';
import Styles from './Subforms.module.sass';
import { LoanSubStatus } from './ApplyBanksStep';
import { StepID } from './LoanStepContainer';
import { DocsHelpModal } from '../../components';
import { helpData } from './loanHelpData';
import { SubFormProps } from './LoanForm';

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 6 },
};

export const IdentityForm: React.FC<SubFormProps> = (props) => {
  const [showHelp, setShowHelp] = useState(false);
  const [helpID, setHelpID] = useState('pan_card');
  const { uid, allFormData, setAllFormData } = props;
  const IDENTITY_CURRENT = LoanSubStatus.Identity;
  const [form] = Form.useForm();

  const onSubmit = (values: any) => {
    if (uid) {
      FirestoreReference.LoanApplication(uid).doc('identity').set(values);
      FirestoreReference.UserStepsStatus(uid).set(
        {
          [UserJourneyStageId.Loans]: {
            [StepID.ApplyToBanks]: { subStatus: LoanSubStatus.Financial },
          },
        },
        { merge: true },
      );
    }
  };

  const getLabel = (label: string, hid: string, subLabel?: string) => {
    return (
      <div className={Styles.labelDiv}>
        {label}
        <QuestionCircleOutlined
          onClick={(e) => {
            e.preventDefault();
            setHelpID(hid);
            setTimeout(() => {
              setShowHelp(true);
            }, 300);
          }}
        />
        {subLabel && <p>{subLabel}</p>}
      </div>
    );
  };

  return (
    <>
      <DocsHelpModal
        helpData={helpData[helpID]}
        showHelp={showHelp}
        setShowHelp={setShowHelp}></DocsHelpModal>
      <Form
        form={form}
        onFinish={onSubmit}
        labelAlign={'left'}
        initialValues={allFormData.identity}
        onValuesChange={(_, values) => {
          setAllFormData({ identity: values });
        }}
        labelWrap
        className={
          `${Styles.form} ${props.current > IDENTITY_CURRENT ? 'all-disabled' : ''}` +
          ' animate__animated animate__fadeIn'
        }
        requiredMark={false}
        {...layout}>
        <h1>KYC Documents</h1>
        <Form.Item
          rules={[{ required: true, message: 'Please upload your Pan Card' }]}
          name="pan_card"
          label={getLabel('Pan Card', 'pan_card')}
          valuePropName="fileList">
          <FileUploader
            form={form}
            maxFileSize={2}
            maxCount={1}
            storagePath={`userApplication/${uid}/loans/pan_card`}
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Please upload your Aadhaar Card' }]}
          name="aadhaar_card"
          label={getLabel('Aadhaar Card', 'aadhaar_card')}
          valuePropName="fileList">
          <FileUploader
            form={form}
            maxFileSize={2}
            maxCount={1}
            storagePath={`userApplication/${uid}/loans/aadhaar_card`}
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Please upload your Driving License' }]}
          name="driving_license"
          label={getLabel('Driving License', 'driving_license')}
          valuePropName="fileList">
          <FileUploader
            form={form}
            maxFileSize={2}
            maxCount={1}
            storagePath={`userApplication/${uid}/loans/driving_license`}
          />
        </Form.Item>
        <h1 style={{ marginTop: 48 }}>Address Proof</h1>
        <Form.Item
          rules={[{ required: true, message: 'Please enter your residential address' }]}
          name="residential_address"
          label={getLabel('Your residential address', 'residential_address')}>
          <Input.TextArea
            placeholder="Your current residential address"
            autoSize={{ minRows: 2, maxRows: 6 }}
          />
        </Form.Item>
        <Form.Item
          rules={[
            { required: true, message: 'Please upload your Valid water/electricity/LPG Bill' },
          ]}
          name="valid_bill"
          label={getLabel('Valid water/electricity/LPG Bill', 'valid_bill')}
          valuePropName="fileList">
          <FileUploader
            form={form}
            maxFileSize={2}
            maxCount={1}
            storagePath={`userApplication/${uid}/loans/valid_bill`}
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Please upload your Voter ID Card' }]}
          name="voter_id"
          label={getLabel('Voter ID card', 'voter_id')}
          valuePropName="fileList">
          <FileUploader
            form={form}
            maxFileSize={2}
            maxCount={1}
            storagePath={`userApplication/${uid}/loans/voter_id`}
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Please upload your Current House Lease Agreement' }]}
          name="current_house_lease_agreement"
          label={getLabel('Current House Lease Agreement', 'current_house_lease_agreement')}
          valuePropName="fileList">
          <FileUploader
            form={form}
            maxFileSize={2}
            maxCount={1}
            storagePath={`userApplication/${uid}/loans/current_house_lease_agreement`}
          />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: 'Please upload your Valid Passport' }]}
          name="valid_passport"
          label={getLabel('Valid Passport', 'valid_passport')}
          valuePropName="fileList">
          <FileUploader
            form={form}
            maxFileSize={2}
            maxCount={1}
            storagePath={`userApplication/${uid}/loans/valid_passport`}
          />
        </Form.Item>
        <Form.Item>
          <Button disabled={props.current !== IDENTITY_CURRENT} type="primary" htmlType="submit">
            Submit <RightOutlined />
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
