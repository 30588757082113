import { UserAcademicInformation, UserInformation } from '../../repos';
import { ProfileAction, ProfileActionTypes } from '../actions';
import { produce } from 'immer';

export interface ProfileStateType {
  readonly userInformation?: UserInformation;
  readonly academicInformation?: UserAcademicInformation;
}

const initialState: ProfileStateType = {};

export const ProfileReducer = (state = initialState, action: ProfileAction) => {
  return produce(state, draft => {
    switch (action.type) {
      case ProfileActionTypes.SetAcademicInformation:
        draft.academicInformation = action.payload.academicInformation;
        break;
      case ProfileActionTypes.SetUserInformation:
        draft.userInformation = action.payload.userInformation;
        break;
      default:
        break;
    }
    return draft;
  });
};
