import { BankFilled, LikeFilled, FundFilled } from '@ant-design/icons';
import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Accordion, WelcomeUserWithProgressHeading } from '../../components';
import { ComingSoonWelcomeScreen } from '../../components/WelcomeScreen';
import { UserJourneyStageId } from '../../config/userJourneyMap';
import { RootState } from '../../redux';
import { CustomStep, StagePredefinedSteps, UserProfile } from '../../repos';
import { renderCustomStep } from '../Home';
import { getIndexFromRoute, useStepCalculation } from '../utils';
import Styles from './USCreditCardStepContainer.module.sass';

interface USCreditCardStepContainerProps extends RouteComponentProps {
  customSteps: CustomStep[];
  currentUser?: UserProfile | null;
  allPredefinedSteps: StagePredefinedSteps;
}

export enum StepID {}

const USCreditCardStepContainer: React.FC<USCreditCardStepContainerProps> = (props) => {
  const { customSteps, currentUser, allPredefinedSteps } = props;
  const AccordionInstance = useRef<Accordion>(null);
  const { pathname } = useLocation();
  const [showWelcomeScreen, setShowWelcomeScreen] = useState<boolean>(true);
  const {
    steps,
    stepsLeft,
    calculatedStepsCompleted,
    setCalculatedStepsCompleted,
    predefinedStepsStatus,
  } = useStepCalculation(
    Object.values(StepID),
    allPredefinedSteps,
    customSteps,
    UserJourneyStageId.USCreditCard,
    showWelcomeScreen,
    currentUser?.id,
  );

  const comingSoonsteps = [
    {
      icon: <BankFilled />,
      title: 'Activate Card in Minutes',
      items: ['Use Passport to Activate', 'Digital KYC'],
    },
    {
      icon: <LikeFilled />,
      title: 'Use Before You Fly',
      items: ['Active Before You Fly', 'Pre-Approved in Home Country'],
    },
    {
      icon: <FundFilled />,
      title: 'Build Your FICO Score',
      items: ['High Credit Limits', 'Best-In-Class Rewards'],
    },
  ];

  if (currentUser)
    return !predefinedStepsStatus && showWelcomeScreen ? (
      <ComingSoonWelcomeScreen
        title={'Get a US credit card'}
        stageID={UserJourneyStageId.USCreditCard}
        steps={comingSoonsteps}
        description={
          'Get a US credit card with no SSN or financial history in the US. Build your FICO score from the get go, with no annual or forex fees.'
        }
        listItems={['No Annual Fees', 'High Credit Limit', 'No SSN or US credit history required']}
      />
    ) : (
      <div className={Styles.container + ' animate__animated animate__fadeIn'}>
        <div className={Styles.childContainer}>
          <WelcomeUserWithProgressHeading
            stageID={UserJourneyStageId.USCreditCard}
            title="US Credit Card"
            currentUser={currentUser}
            stepCount={{ total: steps.length, left: stepsLeft || 0 }}
          />
          <Accordion
            ref={AccordionInstance}
            onExpanded={(index) => {
              if (getIndexFromRoute(pathname, steps) === index) {
                return navigate!(`/${UserJourneyStageId.USCreditCard}`);
              }
              const route = steps[index].route;
              navigate!(`/${UserJourneyStageId.USCreditCard}/${route}`);
            }}
            defaultExpanded={getIndexFromRoute(pathname, steps)}
            className={Styles.accordion}>
            {steps.map((step) => {
              switch (step.id) {
                default: {
                  return renderCustomStep(
                    step,
                    calculatedStepsCompleted,
                    setCalculatedStepsCompleted,
                    currentUser.shownBadges[UserJourneyStageId.USCreditCard] || [],
                  );
                }
              }
            })}
          </Accordion>
        </div>
      </div>
    );
  else return <> </>;
};

const mapStateToProps = (state: RootState) => {
  const { steps } = state.CustomSteps;
  const { currentUser } = state.Auth;
  const allPredefinedSteps = state.Content.predefinedSteps[UserJourneyStageId.USCreditCard];
  return {
    currentUser,
    customSteps: steps[UserJourneyStageId.USCreditCard] || [],
    allPredefinedSteps,
  };
};

export default connect(mapStateToProps, {})(USCreditCardStepContainer);
