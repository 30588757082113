import { Link, RouteComponentProps } from '@reach/router';
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Routes } from '../../App';
import { RootState, setConfirmationResult, signInUsingPhone, verifyOtp } from '../../redux';
import { Colors } from '../../theme';
import { AnalyticsEventName, Analytics } from '../../utils';
interface Props extends RouteComponentProps {
  confirmationResult?: firebase.auth.ConfirmationResult;
  loading: boolean;
  signInUsingPhone: typeof signInUsingPhone;
  setConfirmationResult: typeof setConfirmationResult;
  verifyOtp: typeof verifyOtp;
}

const PhoneSignInContainer = (props: Props) => {
  const [phone, setPhone] = useState<string>();
  const [otp, setOtp] = useState<string>();

  const { signInUsingPhone, confirmationResult, loading, verifyOtp, setConfirmationResult } = props;

  const submit = async () => {
    if (confirmationResult && otp) {
      verifyOtp(otp);
      return;
    }
    Analytics.log(AnalyticsEventName.UserSignInClicked, { origin: 'mobile-number-otp' });
    signInUsingPhone(phone);
  };

  return (
    <div
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100vh',
      }}>
      <div style={{ maxWidth: 300, flexDirection: 'column' }}>
        <div
          style={{
            fontFamily: 'Sofia Pro',
            fontSize: 48,
            fontWeight: 800,
            color: Colors.Primary,
            alignSelf: 'center',
          }}>
          Gradly
        </div>
        <div style={{ marginTop: 16, color: Colors.GreyDark }}>
          Please sign in using your email & password
        </div>
        <Input
          prefix={<PhoneOutlined style={{ transform: 'rotate(90deg)' }} />}
          disabled={!!confirmationResult}
          style={{
            marginTop: 16,
          }}
          onChange={(e) => setPhone(e.target.value)}
          placeholder="Please enter your phone number"
        />
        {confirmationResult && (
          <Input
            placeholder="Please enter your OTP"
            style={{ marginTop: 16 }}
            onChange={(e) => setOtp(e.target.value)}
          />
        )}
        {confirmationResult && (
          <Button style={{ marginTop: 16 }} onClick={() => setConfirmationResult()} type="link">
            Change phone number
          </Button>
        )}
        <div style={{ marginTop: 16, alignItems: 'center' }}>
          <Link
            to={Routes.Home}
            style={{
              marginTop: 12,
              alignSelf: 'flex-end',
              color: Colors.GreyDarker,
              fontSize: 12,
              fontWeight: 500,
              alignItems: 'center',
            }}>
            <MailOutlined style={{ marginRight: 6 }} />
            Sign In Using Email
          </Link>
          <Button loading={loading} onClick={submit} type="primary" style={{ marginLeft: 'auto' }}>
            {confirmationResult ? 'Verify OTP' : 'Get OTP'}
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { confirmationResult, loading } = state.Auth;
  return { confirmationResult, loading };
};

export default connect(mapStateToProps, { signInUsingPhone, verifyOtp, setConfirmationResult })(
  PhoneSignInContainer,
);
