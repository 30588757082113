import {
  UserOutlined,
  DollarOutlined,
  FileTextOutlined,
  CheckCircleFilled,
  HourglassTwoTone,
} from '@ant-design/icons';
import { Col, Row, Steps } from 'antd';
import React, { useEffect } from 'react';
import { AcademicsForm } from './Academics';
import { FinancialForm } from './Financial';
import { IdentityForm } from './Identity';
import Styles from './LoanForm.module.sass';
import { stampImage } from './../../assets';
const { Step } = Steps;

export interface SubFormProps {
  uid: string;
  current: number;
  allFormData: any;
  setAllFormData: (data: any) => void;
}

export const LoanForm: React.FC<any> = (props) => {
  const { subStatus, uid } = props;
  const [current, setCurrent] = React.useState(subStatus);
  const [currentForm, setCurrentForm] = React.useState(subStatus);
  const [allFormData, setAllFormData] = React.useState<any>({});

  useEffect(() => {
    if (subStatus === undefined) {
      setCurrentForm(3);
      setCurrent(3);
    } else {
      setCurrent(subStatus);
      setCurrentForm(subStatus);
    }
  }, [subStatus]);

  const getForm = () => {
    switch (currentForm) {
      case 0:
        return (
          <AcademicsForm
            current={current}
            uid={uid}
            allFormData={allFormData}
            setAllFormData={setAllFormData}
          />
        );
      case 1:
        return (
          <IdentityForm
            current={current}
            uid={uid}
            allFormData={allFormData}
            setAllFormData={setAllFormData}
          />
        );
      case 2:
        return (
          <FinancialForm
            current={current}
            uid={uid}
            allFormData={allFormData}
            setAllFormData={setAllFormData}
          />
        );
      default:
        return <AwaitingResults />;
    }
  };

  const AwaitingResults: React.FC = () => {
    return (
      <Row
        justify="center"
        align="middle"
        className={Styles.awaiting + ' animate__animated animate__fadeIn'}>
        <Col span={12}>
          <span>
            <HourglassTwoTone />
            1-3 days
          </span>
          <h1>Processing Applications</h1>
          <h2>
            Your documents have been submitted to your banks of choice! Your loan offer comes next.
          </h2>
        </Col>
        <Col span={12}>
          <img alt={'Stamp'} src={stampImage}></img>
        </Col>
      </Row>
    );
  };

  const acadStatus =
    currentForm === 0 && current <= 0 ? 'process' : current > 0 ? 'finish' : 'wait';
  const idStatus = currentForm === 1 && current <= 1 ? 'process' : current > 1 ? 'finish' : 'wait';
  const finStatus = currentForm === 2 && current <= 2 ? 'process' : current > 2 ? 'finish' : 'wait';

  return (
    <Row className={Styles.container}>
      {currentForm < 3 && (
        <Steps size="small" className={Styles.steps}>
          <Step
            status={acadStatus}
            onClick={() => {
              setCurrentForm(0);
            }}
            title="Academics"
            icon={acadStatus === 'finish' ? <CheckCircleFilled /> : <FileTextOutlined />}
          />
          <Step
            status={idStatus}
            title="Identity"
            onClick={() => {
              setCurrentForm(1);
            }}
            icon={idStatus === 'finish' ? <CheckCircleFilled /> : <UserOutlined />}
          />
          <Step
            status={finStatus}
            onClick={() => {
              setCurrentForm(2);
            }}
            title="Financial"
            icon={finStatus === 'finish' ? <CheckCircleFilled /> : <DollarOutlined />}
          />
        </Steps>
      )}
      {getForm()}
    </Row>
  );
};
