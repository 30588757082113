import { MessageFilled, MessageOutlined } from '@ant-design/icons';
import { Button, Modal, Row, Spin } from 'antd';
import { type } from 'os';
import { title } from 'process';
import React, { useState } from 'react';
import { GradlyLoading } from '../../App';
import { IntercomAPI } from '../Intercom';
import Styles from './InstructionalModal.module.sass';

export interface InstructionalData {
  type: 'video' | 'article';
  url: string;
  title: string | React.ReactElement;
  chatText: string;
  hideSecondaryTitle?: boolean;
  ctaQuestion?: string;
  intercomMsg?: string;
}

interface Props extends InstructionalData {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

export const InstructionalModal: React.FC<Props> = (props) => {
  const {
    visible,
    setVisible,
    type,
    url,
    title,
    chatText,
    hideSecondaryTitle,
    ctaQuestion,
    intercomMsg,
  } = props;
  const [loading, setLoading] = useState(true);

  const ContentView = (url: string) => {
    return (
      <div aria-label={type} className={Styles.iframe}>
        <Spin spinning={loading} indicator={<GradlyLoading height={'120%'} />}>
          <iframe
            width="560"
            height="315"
            src={url}
            onLoad={() => setLoading(false)}
            title={'Iframe embedd to ' + url}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen></iframe>
        </Spin>
      </div>
    );
  };

  return (
    <Modal
      className={Styles.insModal}
      visible={visible}
      centered
      closable
      maskClosable
      destroyOnClose
      footer={null}
      onCancel={() => {
        setVisible(false);
        setLoading(true);
      }}
      title={
        <div className={Styles.title}>
          {title}
          {!hideSecondaryTitle && (
            <>
              : <b>{type === 'video' ? 'Instructional video' : 'Instructional article'}</b>
            </>
          )}
        </div>
      }>
      <Row justify="center">
        {ctaQuestion ? (
          <h2>{ctaQuestion}</h2>
        ) : type === 'video' ? (
          <h2>Have questions about this video?</h2>
        ) : (
          <h2>Have questions about this article?</h2>
        )}
        <Button
          className={Styles.chatButton}
          icon={<MessageOutlined />}
          onClick={() => {
            IntercomAPI.showWithMessagePreFilled(
              intercomMsg || `Hey! I have questions about ${title}.`,
              false,
            );
          }}>
          {chatText}
        </Button>
      </Row>
      <div className={Styles.content}>{ContentView(url)}</div>
    </Modal>
  );
};
