import { navigate, RouteComponentProps, useLocation } from '@reach/router';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AlertType,
  AttendWebinarAccordionItem,
  renderCoPilot,
  WelcomeUserWithProgressHeading,
} from '../../components';
import { UserJourneyStageId } from '../../config';
import { addToAllInsurance, RootState, setInsuranceData, setSelectedPlan, setVisaType } from '../../redux';
import {
  CustomStep,
  InsuranceRequestGBGType,
  InsuranceRequestPartner,
  InsuranceRequestWellAwayType,
  markStepsStatus,
  PlanType,
  PredefinedStep,
  RsvpStep,
  StagePredefinedSteps,
  StepStatus,
  University,
  UserProfile,
} from '../../repos';
import { renderCustomStep } from '../Home';
import { getIndexFromRoute, useStepCalculation } from '../utils';
import { ViewAllView } from './ViewAll';
import Styles from './HealthInsuranceStepContainer.module.sass';
import { WelcomeScreen } from './WelcomeScreen';
import { EnterDetails } from './EnterDetails';
import moment, { Moment } from 'moment';
import WaiverGuide from './WaiverGuide';
import { FirestoreReference } from '../../utils';
import { InsuranceDocs } from './InsuranceDocs';
import { RightOutlined } from '@ant-design/icons';
import { FileInsurance } from './FileInsurance';
import Cookies from 'js-cookie';
import { GBGPurchaseForm } from './GBGPurchaseForm';
import { PayInvoice } from './PayInvoice';
import { WellAwayPurchaseForm } from './WellAwayPurchaseForm';
import { ViewRecommended } from './ViewRecommended';
import { GradlyLoading } from '../../App';
import { VisaType } from '../../redux/reducers/InsuranceReducer';

interface HealthInsuranceStepContainerProps extends RouteComponentProps {
  customSteps: CustomStep[];
  currentUser?: UserProfile | null;
  allPredefinedSteps: StagePredefinedSteps;
}

export interface ComparisonData {
  university: University;
  dob: Moment;
  visa_type?: string;
  num_months?: number;
  email?: string;
  plan?: PlanType;
  hasPastEnrollment?: boolean;
}

export enum StepID {
  EnterDetails = 'EnterDetails',
  ViewRecommended = 'ViewRecommended',
  ViewAll = 'ViewAll',
  AttendWebinar = 'AttendWebinar',
  PurchaseInsurance = 'PurchaseInsurance',
  WaiverGuide = 'WaiverGuide',
  PayInvoice = 'PayInvoice',
  YourInsurance = 'YourInsurance',
  FileInsuranceClaim = 'FileInsuranceClaim',
  CoPilot = 'CoPilot',
}

const HealthInsuranceStepContainer: React.FC<HealthInsuranceStepContainerProps> = (props) => {
  const { customSteps, currentUser, allPredefinedSteps } = props;
  const AccordionInstance = useRef<Accordion>(null);
  const { pathname } = useLocation();
  const [comparisonData, setComparisonData] = useState<ComparisonData>();
  const [showWelcomeScreen, setShowWelcomeScreen] = useState<boolean>(false);
  const plan = useSelector((state: RootState) => state.Insurance.selectedPlan);
  const [selectedUniversity, setSelectedUniversity] = useState<University>();
  let modifiedPredefinedSteps = { ...allPredefinedSteps };
  const { insuranceData, visaType, allInsurances } = useSelector((state: RootState) => state.Insurance);
  const { universityID, email, dob, planID, visa } = {
    universityID: Cookies.get('universityID') || insuranceData?.universityID || 'usc',
    email: Cookies.get('email'),
    dob: Cookies.get('dob'),
    planID: Cookies.get('planID'),
    visa: (Cookies.get('visa') as VisaType) || VisaType.F1,
  };

  if (currentUser?.isAnonymous || comparisonData || universityID || planID) {
    modifiedPredefinedSteps = {
      ...modifiedPredefinedSteps,
      [StepID.PurchaseInsurance]: {
        ...modifiedPredefinedSteps[StepID.PurchaseInsurance],
        order: -1,
      },
    };
  }
  if (insuranceData && insuranceData.cardLongURL) {
    modifiedPredefinedSteps = {
      ...modifiedPredefinedSteps,
      [StepID.YourInsurance]: {
        ...modifiedPredefinedSteps[StepID.YourInsurance],
        order: -2,
      },
    };
  }

  const { steps, stepsLeft, calculatedStepsCompleted, setCalculatedStepsCompleted, predefinedStepsStatus } =
    useStepCalculation(
      Object.values(StepID),
      modifiedPredefinedSteps,
      customSteps,
      UserJourneyStageId.HealthInsurance,
      showWelcomeScreen,
      currentUser?.id,
    );

  const selectedPlan = useSelector((state: RootState) => state.Insurance.selectedPlan);
  const dispatch = useDispatch();

  useEffect(() => {
    if (visa !== VisaType.F1) {
      dispatch(setVisaType(visa));
    }
  }, []);

  const resetHI = async () => {
    if (insuranceData) {
      if (insuranceData.partner === 'GBG') {
        await FirestoreReference.InsuranceGBGRequests().doc(insuranceData.requestID).delete();
      } else {
        await FirestoreReference.InsuranceWellAwayRequests().doc(insuranceData.requestID).delete();
      }
      dispatch(setInsuranceData(undefined));
    }
    if (currentUser) {
      void FirestoreReference.UserStepsStatus(currentUser.id).set({ 'health-insurance': {} }, { merge: true });
    }
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  //@ts-ignore
  window.resetHI = resetHI;

  useEffect(() => {
    if (currentUser) {
      FirestoreReference.InsuranceGBGRequests()
        .where('userID', '==', currentUser.id)
        .orderBy('createdAt', 'desc')
        .onSnapshot(
          (snapshot) => {
            let first = true;
            snapshot.forEach((doc) => {
              const insuranceData = doc.data() as InsuranceRequestGBGType;
              dispatch(addToAllInsurance(insuranceData));
              if (!first) return;
              dispatch(setInsuranceData(insuranceData));
              first = false;
            });
          },
          (error) => {
            console.debug('ERROR InsuranceGBGRequests', error);
          },
        );
      FirestoreReference.InsuranceWellAwayRequests()
        .where('userID', '==', currentUser.id)
        .orderBy('createdAt', 'desc')
        .onSnapshot(
          (snapshot) => {
            let first = true;
            snapshot.forEach((doc) => {
              const insuranceData = doc.data() as InsuranceRequestWellAwayType;
              dispatch(addToAllInsurance(insuranceData));
              if (!first) return;
              first = false;
              dispatch(setInsuranceData(insuranceData));
            });
          },
          (error) => {
            console.debug('ERROR InsuranceWellAwayRequests', error);
          },
        );
      if (universityID) {
        FirestoreReference.Universities()
          .doc(universityID)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setSelectedUniversity({ ...(doc.data() as University), id: universityID });
              setComparisonData({
                university: { ...(doc.data() as University), id: universityID },
                dob: (dob && moment(dob, 'YYYY-MM-DD')) || moment('NA'),
                visa_type: 'F1',
                num_months: 8,
                email,
              });
              if (planID || universityID || insuranceData)
                navigate(`/${UserJourneyStageId.HealthInsurance}/purchase-insurance`);
              else navigate(`/${UserJourneyStageId.HealthInsurance}/enter-details`);
            } else {
              Cookies.remove('universityID', { domain: '.gradly.us' });
              Cookies.remove('universityID', { domain: 'localhost' });
            }
          });
      }
      if (planID) {
        FirestoreReference.InsurancePlans()
          .doc(planID)
          .get()
          .then((doc) => {
            if (doc.exists) {
              const data = doc.data() as PlanType;
              dispatch(setSelectedPlan({ ...data, planID }));
            }
          });
      }
      if (planID || universityID) {
        navigate(`/${UserJourneyStageId.HealthInsurance}/purchase-insurance`);
      }
      if (currentUser.isAnonymous && currentUser.id !== 'temp') {
        markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.EnterDetails], StepStatus.Pending);
      }
    }
  }, [currentUser, currentUser?.id, universityID, planID]);

  useEffect(() => {
    if (!insuranceData || !currentUser || !predefinedStepsStatus) return;
    if (predefinedStepsStatus[StepID.WaiverGuide] && !predefinedStepsStatus[StepID.WaiverGuide].waiverGuide) {
      FirestoreReference.Universities()
        .doc(insuranceData.universityID)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const university = doc.data() as University;
            setSelectedUniversity({ ...university, id: insuranceData.universityID });
            markStepsStatus(
              currentUser.id,
              [UserJourneyStageId.HealthInsurance, StepID.WaiverGuide],
              StepStatus.Processing,
              {
                waiverGuide:
                  (selectedPlan?.insuranceCompany === InsuranceRequestPartner.GBG
                    ? university.waiverGuideGBG
                    : university.waiverGuide) || 'NA',
              },
            );
          }
        });
    }

    if (insuranceData && currentUser) {
      markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.EnterDetails], StepStatus.Complete);
      markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.ViewAll], StepStatus.Complete);
    }
    if (insuranceData.depositConfirmed) {
      !predefinedStepsStatus[StepID.WaiverGuide] &&
      markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.WaiverGuide], StepStatus.Pending);
    }
    if (
      insuranceData.depositConfirmed &&
      (predefinedStepsStatus![StepID.PurchaseInsurance] || {}).status !== StepStatus.Complete
    ) {
      Cookies.remove('universityID', {
        domain: window.location.hostname.includes('localhost') ? 'localhost' : '.gradly.us',
      });
      Cookies.remove('email', { domain: window.location.hostname.includes('localhost') ? 'localhost' : '.gradly.us' });
      Cookies.remove('dob', { domain: window.location.hostname.includes('localhost') ? 'localhost' : '.gradly.us' });
      Cookies.remove('planID', { domain: window.location.hostname.includes('localhost') ? 'localhost' : '.gradly.us' });

      setTimeout(() => {
        markStepsStatus(
          currentUser.id,
          [UserJourneyStageId.HealthInsurance, StepID.PurchaseInsurance],
          StepStatus.Complete,
        );
        void navigate(`/${UserJourneyStageId.HealthInsurance}/get-waiver-guide`);
      }, 2000);

      markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.EnterDetails], StepStatus.Complete);
      markStepsStatus(currentUser.id, [UserJourneyStageId.HealthInsurance, StepID.ViewAll], StepStatus.Complete);
      markStepsStatus(
        currentUser.id,
        [UserJourneyStageId.HealthInsurance, StepID.ViewRecommended],
        StepStatus.Complete,
      );
    }
    if (!insuranceData.depositConfirmed && insuranceData.insurancePlanID !== selectedPlan?.planID) {
      FirestoreReference.InsurancePlan(insuranceData.insurancePlanID).get().then((plan) => {
        dispatch(setSelectedPlan({ ...plan.data() as PlanType, planID: insuranceData.insurancePlanID }));
      });
    }
  }, [currentUser, insuranceData, predefinedStepsStatus]);

  const updateStatus = (step: PredefinedStep, currentUser: UserProfile) => {
    const wg =
      predefinedStepsStatus &&
      predefinedStepsStatus[StepID.WaiverGuide] &&
      predefinedStepsStatus[StepID.WaiverGuide].waiverGuide;
    if (insuranceData)
      if (
        (insuranceData.cardLongURL &&
          step &&
          step.status &&
          ![StepStatus.Complete, StepStatus.Processing].includes(step.status)) ||
        (wg === 'automated' && insuranceData.status === 'WaiverApplicationApproved')
      ) {
        markStepsStatus(
          currentUser.id,
          [UserJourneyStageId.HealthInsurance, StepID.WaiverGuide],
          insuranceData.status === 'WaiverApplicationApproved' ? StepStatus.Complete : StepStatus.Processing,
        );
        markStepsStatus(
          currentUser.id,
          [UserJourneyStageId.HealthInsurance, StepID.YourInsurance],
          StepStatus.Complete,
        );
      }
  };

  const lockPurchaseModalData = {
    description:
      'This step is locked. You need to select the plan you want to purchase before you can proceed with this step.',
    ctaText: (
      <>
        Go to insurance page
        <RightOutlined />
      </>
    ),
    ctaAction: () => {
      // navigate(`/${UserJourneyStageId.HealthInsurance}/enter-details`);
      window.location.href = 'https://insurance.gradly.us';
    },
  };

  const lockOPTPurchaseModalData = {
    description:
      'You must have been enrolled in a WellAway plan within the past 12 months to qualify for the OPT plan. Unfortunately, you don\'t meet this criterion. Contact support for assistance or explore other suitable plan options.',
  };

  const lockModalData = {
    description:
      'This step is locked. You need to complete "Purchase Insurance" before you can proceed with this step.',
    ctaText: (
      <>
        Go to "Purchase Insurance"
        <RightOutlined />
      </>
    ),
    ctaAction: () => {
      navigate(`/${UserJourneyStageId.HealthInsurance}/purchase-insurance`);
    },
  };

  if (universityID && !comparisonData) {
    return <GradlyLoading />;
  }

  if (currentUser)
    return !predefinedStepsStatus && showWelcomeScreen && !currentUser.isAnonymous ? (
      <WelcomeScreen setShowWelcomeScreen={setShowWelcomeScreen} />
    ) : (
      <div className={`${Styles.container} animate__animated animate__fadeIn`}>
        <div className={Styles.childContainer}>
          <WelcomeUserWithProgressHeading
            stageID={UserJourneyStageId.HealthInsurance}
            title='Purchase Health Insurance'
            currentUser={currentUser}
            stepCount={{ total: steps.length, left: stepsLeft || 0 }}
          />
          <Accordion
            ref={AccordionInstance}
            onExpanded={(index) => {
              if (getIndexFromRoute(pathname, steps) === index) {
                return navigate!(`/${UserJourneyStageId.HealthInsurance}`);
              }
              const route = steps[index].route;
              navigate!(`/${UserJourneyStageId.HealthInsurance}/${route}`);
            }}
            defaultExpanded={getIndexFromRoute(pathname, steps)}
            className={Styles.accordion}>
            {steps.map((step) => {
              switch (step.id) {
                case StepID.AttendWebinar:
                  return (
                    <AttendWebinarAccordionItem
                      key={step.route}
                      onCompleted={(completed) => {
                        if (calculatedStepsCompleted.includes(step.title) && !completed) {
                          setCalculatedStepsCompleted([
                            ...calculatedStepsCompleted.filter((title) => title !== step.title),
                          ]);
                          return;
                        }
                        if (!calculatedStepsCompleted.includes(step.title) && completed) {
                          setCalculatedStepsCompleted([...calculatedStepsCompleted, step.title]);
                          return;
                        }
                      }}
                      title={step.title}
                      rsvpStep={step as RsvpStep}
                    />
                  );
                case StepID.EnterDetails:
                  if (universityID || planID || step.status === StepStatus.Complete || process.env.REACT_APP_NO_NAV)
                    return <></>;
                  return (
                    <Accordion.Item
                      lockOnComplete
                      key={step.id}
                      icon={'edit'}
                      status={step.status || StepStatus.Pending}
                      title={step.title}>
                      <EnterDetails setComparisonData={setComparisonData} university={comparisonData?.university} />
                    </Accordion.Item>
                  );

                case StepID.ViewRecommended:
                  if (universityID || planID || step.status === StepStatus.Complete || process.env.REACT_APP_NO_NAV)
                    return <></>;
                  return (
                    <Accordion.Item
                      lockOnComplete
                      key={step.id}
                      icon={'like'}
                      status={step.status || (comparisonData ? StepStatus.Pending : StepStatus.Locked)}
                      lockModal={lockPurchaseModalData}
                      title={step.title}>
                      {comparisonData && comparisonData.university && comparisonData.dob ? (
                        <ViewRecommended comparisonData={comparisonData} />
                      ) : (
                        <></>
                      )}
                    </Accordion.Item>
                  );

                case StepID.ViewAll:
                  if (universityID || planID || step.status === StepStatus.Complete || process.env.REACT_APP_NO_NAV)
                    return <></>;
                  return (
                    <Accordion.Item
                      lockOnComplete
                      key={step.id}
                      icon={'safety-certificate'}
                      status={
                        step.status ||
                        (comparisonData && comparisonData.university && comparisonData.dob.isValid()
                          ? StepStatus.Pending
                          : StepStatus.Locked)
                      }
                      lockModal={lockPurchaseModalData}
                      title={step.title}>
                      {comparisonData && comparisonData.university && comparisonData.dob.isValid() ? (
                        <ViewAllView comparisonData={comparisonData} />
                      ) : (
                        <></>
                      )}
                    </Accordion.Item>
                  );
                case StepID.PurchaseInsurance:
                  let modalData: any = lockPurchaseModalData;
                  let status = comparisonData || insuranceData || selectedPlan ? StepStatus.Pending : StepStatus.Locked;
                  if (visaType === VisaType.OPT && (!allInsurances || allInsurances.filter(i => i.partner === 'WellAway' &&
                    moment(i.coverageEndDate).year() === moment().year() && !!i.depositConfirmed).length === 0)) {
                    status = StepStatus.Locked;
                    modalData = lockOPTPurchaseModalData;
                  }

                  if (step.status === StepStatus.Complete)
                    return (
                      <Accordion.Item
                        key={step.id}
                        icon={'file-protect'}
                        lockModal={modalData}
                        status={status}
                        title={'Renew Insurance'}>
                        {plan && plan.insuranceCompany === 'GBG' ? (
                          <GBGPurchaseForm comparisonData={comparisonData} status={step.status} />
                        ) : (
                          <WellAwayPurchaseForm comparisonData={comparisonData} status={step.status} />
                        )}
                      </Accordion.Item>
                    );
                  return (
                    <Accordion.Item
                      key={step.id}
                      icon={'file-protect'}
                      lockModal={modalData}
                      status={
                        step.status || status
                      }
                      title={step.title}>
                      {plan && plan.insuranceCompany === 'GBG' && plan.visaType === visaType ? (
                        <GBGPurchaseForm comparisonData={comparisonData} status={step.status} />
                      ) : (
                        <WellAwayPurchaseForm comparisonData={comparisonData} status={step.status} />
                      )}
                    </Accordion.Item>
                  );
                case StepID.WaiverGuide: {
                  if (selectedUniversity?.noWaiver) return <></>;
                  updateStatus(step, currentUser);
                  const waiverGuide =
                    predefinedStepsStatus &&
                    predefinedStepsStatus[StepID.WaiverGuide] &&
                    predefinedStepsStatus[StepID.WaiverGuide].waiverGuide;

                  return (
                    <Accordion.Item
                      key={step.id}
                      icon={'read'}
                      lockModal={lockModalData}
                      status={
                        step.status ||
                        (insuranceData && insuranceData.depositConfirmed ? StepStatus.Pending : StepStatus.Locked)
                      }
                      title={step.title}
                      showAI
                      showMarkAsComplete={
                        waiverGuide !== 'automated'
                          ? {
                              uid: currentUser.id,
                              route: [UserJourneyStageId.HealthInsurance, step.id],
                            }
                          : undefined
                      }>
                      <WaiverGuide insuranceData={insuranceData} waiverGuide={waiverGuide} />
                    </Accordion.Item>
                  );
                }
                case StepID.PayInvoice:
                  let alertMessage;
                  if (step.status === StepStatus.Processing)
                    alertMessage = { message: 'Your payment is in processing and will be updated in 14 business days.', type: AlertType.Processing };
                  if(step.status === StepStatus.ReadyForReview && insuranceData?.invoiceFailed)
                    alertMessage = {message: "There was a problem with your payment, please contact our support team for a new invoice to avoid any cancellation.", type: AlertType.Error}
                  return (
                    <Accordion.Item
                      highlighted
                      key={step.id}
                      icon={step.status === StepStatus.Processing ? 'hourglass' : 'dollar'}
                      alert={alertMessage}
                      status={
                        step.status ||
                        (insuranceData && insuranceData.depositConfirmed && insuranceData.invoiceURL
                          ? StepStatus.ReadyForReview
                          : StepStatus.Locked)
                      }
                      lockModal={lockModalData}
                      title={step.title}>
                      <PayInvoice status={step.status} />
                    </Accordion.Item>
                  );
                case StepID.YourInsurance:
                  updateStatus(step, currentUser);
                  return (
                    <Accordion.Item
                      highlighted
                      key={step.id}
                      icon={'folder-open'}
                      status={
                        step.status ||
                        (insuranceData && insuranceData.depositConfirmed ? StepStatus.Pending : StepStatus.Locked)
                      }
                      statusText={(step.status === StepStatus.Complete && 'View') || undefined}
                      lockModal={lockModalData}
                      title={step.title}>
                      <InsuranceDocs />
                    </Accordion.Item>
                  );
                case StepID.FileInsuranceClaim:
                  if (insuranceData && insuranceData.partner === 'WellAway')
                    return (
                      <FileInsurance
                        key={step.id}
                        step={{
                          ...step,
                          status:
                            step.status ||
                            (insuranceData && insuranceData.depositConfirmed ? StepStatus.Pending : StepStatus.Locked),
                        }}
                        lockModal={lockModalData}
                        university={insuranceData && insuranceData.university}
                        currentUser={currentUser}
                      />
                    );
                  else return <></>;
                case StepID.CoPilot:
                  return renderCoPilot(step);
                default: {
                  return renderCustomStep(
                    step,
                    calculatedStepsCompleted,
                    setCalculatedStepsCompleted,
                    currentUser!.shownBadges![UserJourneyStageId.HealthInsurance] || [],
                  );
                }
              }
            })}
          </Accordion>
        </div>
      </div>
    );
  else return null;
};

const mapStateToProps = (state: RootState) => {
  const { steps } = state.CustomSteps;
  const { currentUser } = state.Auth;
  const allPredefinedSteps = state.Content.predefinedSteps[UserJourneyStageId.HealthInsurance];
  return {
    currentUser,
    customSteps: steps[UserJourneyStageId.HealthInsurance] || [],
    allPredefinedSteps,
  };
};

export default connect(mapStateToProps, {})(HealthInsuranceStepContainer);
