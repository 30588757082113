import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Moment } from 'moment';
import { from } from 'rxjs';
import { applyDeleteMarkersForUndefined, FirestoreReference } from '../../utils';
import { convertMomentObjectsToDate } from '../../utils/FirebaseHelpers';

const { firestore } = firebase;
export interface UserResumeVersion {
  id: string; // firestore document id
  studentName: string;
  createdAt: Moment;
  userId: string;
  url?: string;
  text?: string;
  storagePath?: string;
  resumeId: string; // for versioning
}

export const createResumeVersion = (resumeVersion: Partial<UserResumeVersion>) => {
  const id = FirestoreReference.UserResumeVersion().doc().id;
  const data = {
    ...applyDeleteMarkersForUndefined(convertMomentObjectsToDate(resumeVersion)),
    createdAt: firestore.FieldValue.serverTimestamp(),
    resumeId: id,
  };
  return from(FirestoreReference.UserResumeVersion().doc(id).set(data, { merge: true }));
};
