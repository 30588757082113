import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import './index.scss';
import { store } from './redux/store';
import * as serviceWorker from './serviceWorker';

// Setup Sentry
Sentry.init({
  environment: process.env.REACT_APP_PROJECT_ENV,
  dsn: process.env.REACT_APP_SENTRYDSN,
});

// Setup Segment
window.analytics.load(process.env.REACT_APP_SEGMENT_KEY || '');
window.analytics.page();

class ErrorBoundary extends React.Component {
  componentDidCatch(error: Error) {
    console.error(error);
    Sentry.captureException(error);
  }

  render() {
    return (
      <Provider store={store}>
        <div className="app">
          <App />
        </div>
      </Provider>
    );
  }
}

ReactDOM.render(<ErrorBoundary />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
