import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Accordion, Alert, AlertType } from '../../components';
import { PredefinedStep, StepStatus } from '../../repos';
import { UseSearchBoxProps } from 'react-instantsearch-hooks';
import Styles from './VisaTranscript.module.sass';
import { algoliaClient, AlgoliaIndexName } from '../../services';
import { VisaTranscriptNaturalForm } from './../../components/NaturalForm/VisaTranscriptNaturalForm';
import { VisaTranscriptsList } from './VisaTranscriptsList';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { UserJourneyStageId } from '../../config';
import { StepID } from './VisaStepContainer';
type SearchBoxProps = UseSearchBoxProps;

export interface SearchParams {
  university: string;
  subject: string;
  consulate: string;
  funding: 'funding-loan' | 'funding-self';
  loading: boolean;
}

export interface MatchedAttribsType {
  university: boolean;
  subject: boolean;
  consulate: boolean;
  funding: boolean;
  matchPercent: number;
  visaAccepted?: boolean;
}

export interface Hits {
  matchedAttribs: MatchedAttribsType;
  transcript: any;
  index: number;
}

export const fundingTypes = {
  'funding-loan': 'Education Loan',
  'funding-self': 'Self-Funded',
};

const VISA_SEARCH = {
  university: 'Carnegie Mellon University',
  subject: 'Computer Science',
  consulate: 'Mumbai',
  funding: 'funding-loan' as 'funding-loan' | 'funding-self',
  loading: true,
};

const TranscriptsSearch: React.FC<SearchBoxProps> = (props) => {
  const [hit, setHit] = useState<Hits[]>([]);
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const currentUser = useSelector((state: RootState) => state.Auth.currentUser);

  useEffect(() => {
    if (searchParams) {
      algoliaClient
        .initIndex(AlgoliaIndexName.VisaTranscripts)
        .search<any>(`${searchParams.university} ${searchParams.funding} ${searchParams.subject}`, {
          getRankingInfo: true,
          optionalWords: `${searchParams.university} ${searchParams.funding} ${searchParams.subject}`,
          facetFilters: [`consulate:${searchParams.consulate}`],
          hitsPerPage: 30,
        })
        .then((res) => {
          const hits: Hits[] = res.hits.map((hit, index) => {
            const matchedAttribs: MatchedAttribsType = {
              university: hit.university === searchParams.university,
              subject: !!(hit.subject && hit.subject.includes(searchParams.subject)),
              consulate: hit.consulate === searchParams.consulate,
              matchPercent: 0,
              funding: !!(hit._tags && hit._tags!.includes(searchParams.funding)),
            };
            matchedAttribs.matchPercent = Object.values(matchedAttribs).reduce(
              (a: number, m) => (m ? a + 25 : a),
              0,
            );
            matchedAttribs.visaAccepted = !!(hit._tags && hit._tags.includes('visa-approved'));
            return { matchedAttribs, transcript: hit.transcript, index };
          });
          setHit(
            hits.sort((a, b) => {
              const percent = b.matchedAttribs.matchPercent - a.matchedAttribs.matchPercent;
              return percent === 0
                ? (+b.matchedAttribs.university - +a.matchedAttribs.university) * 10 +
                    (+(b.matchedAttribs.visaAccepted || 0) - +(a.matchedAttribs.visaAccepted || 0))
                : percent;
            }),
          );
        })
        .catch((err) => {
          setSearchParams(undefined);
        });
    }
  }, [searchParams]);

  if (!searchParams) {
    return (
      <Row justify="center" className={'full-width'}>
        <Alert
          type={AlertType.Info}
          message={'Provide your details below to see personalized interview transcripts'}></Alert>
        <VisaTranscriptNaturalForm
          uid={currentUser?.id}
          onSubmit={(data) => {
            setSearchParams(data);
          }}
        />
      </Row>
    );
  } else
    return (
      <Row className={Styles.vstContainer + ' animate__animated animate__fadeIn'}>
        <Col className={Styles.topMsg}>
          Showing transcripts similar to your profile:{' '}
          <span>
            {searchParams.university}, {searchParams.subject}, {searchParams.consulate}
            (Consulate), Funded by {fundingTypes[searchParams.funding]}
          </span>
        </Col>
        <Alert
          message={
            <>
              You can <a>watch this video</a> to understand how to use these visa transcripts to
              prepare for your interview.
            </>
          }
          type={AlertType.Info}
          style={{ marginBottom: '24px' }}></Alert>
        <Col span={24}>
          <Row>
            <VisaTranscriptsList hits={hit} searchParams={searchParams}></VisaTranscriptsList>
          </Row>
        </Col>
      </Row>
    );
};

export const VisaTranscriptsCard = (currentStep: PredefinedStep, uid: string) => {
  return (
    <Accordion.Item
      destroyOnClose
      showMarkAsComplete={{
        uid,
        route: [UserJourneyStageId.VisaApplication, StepID.VisaTranscripts],
      }}
      status={currentStep.status || ' '}
      key={currentStep.id}
      icon={'file-search'}
      title={currentStep.title}>
      <TranscriptsSearch />
    </Accordion.Item>
  );
};
